import { createStore } from '@zen/common-app-parts';
import useActivateStore from './useActivateStore';

const { Provider, useStore } = createStore({
  useStoreHook: useActivateStore,
  contextDisplayName: 'ActivateStoreContext',
});

export default {
  Provider,
  /**
   * @type {TUseActivateStore}
   */
  useStore,
};

/**
 * @typedef {() => import('./types').TActivateStore} TUseActivateStore
 */
