import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { space, color, typography, layout, position, border } from 'styled-system';
import Box from '../Box';
import { containerXPadding } from '../../utils/styles/mixins';
import tu from '../../utils/themeUtils';

const StatusBar = styled.nav`
  display: flex;
  flex-wrap: wrap;
  text-transform: uppercase;
  padding-top: ${tu.space('smd')};
  background-color: ${tu.color('bg2')};
  color: ${tu.color('text')};
  font-size: ${tu.fontSize('xs')};
  letter-spacing: 0.29px;
  border-top: 1px solid ${tu.color('borderDark')};
  ${p =>
    tu.mq({
      paddingBottom: tu.space(['smd', '0'])(p),
      flexDirection: ['column', 'row'],
    })};
  ${containerXPadding}
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
`;

const itemCss = css`
  margin-bottom: ${tu.space('smd')};
  ${tu.mq({
    marginRight: [0, tu.space('smd')],
  })}
  &:last-child {
    margin-right: 0;
    ${tu.mq({
      marginBottom: [0, tu.space('smd')],
    })}
  }
`;

const StatusBarItem = styled(Box)`
  ${itemCss}
`;

const TextItemLabel = styled.span`
  color: ${tu.color('label')};
`;

const StatusBarTextItem = ({ label, children, ...props }) => {
  return (
    <Box css={itemCss} {...props}>
      <TextItemLabel>{label}: </TextItemLabel>
      <span>{children}</span>
    </Box>
  );
};
StatusBarTextItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
};

export { StatusBar, StatusBarItem, StatusBarTextItem };
