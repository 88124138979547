import { css } from 'styled-components';

export default function getTransition({
  property = 'all',
  duration = '0.2s',
  timing = 'ease',
  important = false,
} = {}) {
  return css`
    transition: ${property} ${duration} ${timing} ${important ? '!important' : ''};
  `;
}
