const counter = Counter();

/**
 * Generate a unique id based on the current time and random with an addition of a counter for safety
 */
export default function uniqueId() {
  const now =
    typeof performance === 'object' && typeof performance.now === 'function'
      ? performance
          .now()
          .toString()
          .replace('.', '')
      : Date.now().toString();

  const randomString = Math.random()
    .toString(16)
    .substr(2);

  return `${now}-${randomString}-${counter.next()}`;
}

/**
 * A counter which counts up to MAX_SAFE_INTEGER and goes back to zero
 */
function Counter() {
  let value = 0;

  const next = () => {
    if (value >= Number.MAX_SAFE_INTEGER) {
      value = 0;
    }
    return value++;
  };

  return { next };
}
