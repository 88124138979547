import * as React from 'react';
import { useUnmountedRef } from '@zen/common-react-hooks';
import WalletStore from '../../../stores/WalletStore';
import PasswordRequiredModalContentBase from './PasswordRequiredModalContentBase';

/**
 * Contains the logic of checking the password
 * @param {Object} props
 * @param {string} props.cancelText
 * @param {string} props.submitText
 * @param {(password: string) => void} props.onSuccess
 * @param {() => void} props.onCancel
 */
export default function PasswordRequiredModalContent({
  onSuccess,
  onCancel,
  cancelText,
  submitText,
}) {
  const unmounted = useUnmountedRef();
  const wallet = WalletStore.useStore();
  const [progress, setProgress] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleSubmit = async (password) => {
    setError('');
    setProgress(true);
    if (await wallet.actions.checkPassword(password)) {
      onSuccess(password);
    } else {
      if (!unmounted.current) {
        setError('Wrong password.');
        setProgress(false);
      }
    }
  };

  return (
    <PasswordRequiredModalContentBase
      error={error}
      progress={progress}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      cancelText={cancelText}
      submitText={submitText}
    />
  );
}
