import createStore from '../../utils/createStore';
import useDatePickerStore from './useDatePickerStore';

const { Provider, useStore } = createStore({
  useStoreHook: useDatePickerStore,
  contextDisplayName: 'DatePickerRangeStoreContext',
});

export default {
  Provider,
  /**
   * @type {TUseDatePickerRangeStore}
   */
  useStore,
};

/**
 * @typedef {() => import('./types').TDatePickerRangeStore} TUseDatePickerRangeStore
 */
