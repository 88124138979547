import React from 'react';
import PropTypes from 'prop-types';
import { useModal, Modal, ModalContent } from '@zen-common/components-base';
import SettingLayout from '../containers/SettingLayout';
import SettingButton from '../common/SettingButton';
import BackupWalletFormProcess from './BackupWalletSettingProcess';

const BackupWalletSetting = ({ useSetting, ...props }) => {
  const { show, hide } = useModal();

  function openModal() {
    show(() => (
      <Modal>
        <ModalContent>
          <BackupWalletFormProcess onCancel={hide} onDone={hide} useSetting={useSetting} />
        </ModalContent>
      </Modal>
    ));
  }

  return (
    <SettingLayout header="Backup Wallet" paragraph="Show your mnemonic and private key" {...props}>
      <SettingButton onClick={openModal}>Show</SettingButton>
    </SettingLayout>
  );
};

BackupWalletSetting.propTypes = {
  useSetting: PropTypes.func.isRequired,
};

export default BackupWalletSetting;
