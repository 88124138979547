import { useState } from 'react';

/**
 * Calls submit and set loading to true
 *
 * @param {Object} params
 * @param {() => void} params.onSubmit
 * @param {() => boolean} params.validate
 */
export default function useSubmitWithLoading({ onSubmit, validate = defaultValidate } = {}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setTimeout(() => onSubmit(), 0);
    }
  };

  return { loading, handleSubmit };
}

function defaultValidate() {
  return true;
}
