import createStore from '../../utils/createStore';
import useBlockchainInfoStore from './modules/useBlockchainInfoStore';

const { Provider, useStore } = createStore({
  useStoreHook: useBlockchainInfoStore,
  contextDisplayName: 'BlockchainInfoStoreContext',
});

export default {
  Provider,
  /**
   * @type {UseBlockchainInfoStore}
   */
  useStore,
};

/**
 * @typedef {() => ({
 *  state: {
 *    infos: {},
 *    error: boolean,
 *    loading: boolean,
 *  },
 *  actions: {
 *    reset: () => void
 *  }
 * })} UseBlockchainInfoStore
 */
