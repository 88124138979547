import styled from 'styled-components';
import tu from '../../utils/themeUtils';
import Box from '../Box';

/**
 * A wrapper for page descriptions, it limits the width on big screens and sets the font size
 */

const Description = styled(Box)`
  font-size: ${tu.fontSize('md')};
  ${tu.mq({
    width: ['auto', null, '80%', '60%'],
  })}
`;

export default Description;
