import createStore from '../../utils/createStore';
import useSendExecuteStore from './useSendExecuteStore';

const { Provider, useStore } = createStore({
  useStoreHook: useSendExecuteStore,
  contextDisplayName: 'SendExecuteStoreContext',
});

/**
 * This store combines send and execute stores
 */
export default {
  Provider,
  /** @type {() => TSendExecuteStore} */
  useStore,
};

/**
 * @typedef {{
 *  executeStore: import('../ExecuteContractStore/types').TExecuteContractStore,
 *  sendStore: import('../SendStore/types').SendStore,
 *  isExecute: boolean,
 *  resetIsExecute: () => void,
 * }} TSendExecuteStore
 */
