import { Asset, DataFactory, ShaDigest } from '@zen/zenjs';
import { constants } from '@zen/common-utils';
import * as ZenJsUtils from './zenJsUtils';
import * as NamingUtils from './NamingUtils';

/**
 * Get a message body
 *
 * @param {Object} params
 * @param {boolean} params.isRedeem
 * @param {string} params.oraclePubKey
 * @param {string} params.oracleContractId
 * @param {string} params.ticker
 * @param {string} params.price
 * @param {string} params.start
 * @param {string=} params.expiry
 * @param {string} params.position
 * @param {string} params.timestamp
 * @param {string} params.value
 * @param {string} params.root
 * @param {Array<string>} params.auditPath
 * @param {string} params.index
 * @param {string} params.asset
 *
 * @returns {string} the message body ready for the contract
 */
export function getMessageBody({
  isRedeem,
  oraclePubKey,
  oracleContractId,
  ticker,
  price,
  start,
  expiry,
  position,
  timestamp,
  value,
  root,
  auditPath,
  index,
  asset,
} = {}) {
  return (
    `OraclePubKey: !pk ${oraclePubKey} \n` +
    `OracleContractId: !!str ${oracleContractId}\n` +
    `Ticker: !!str ${ticker}\n` +
    `Price: ${price}UL\n` +
    `Start: ${start}UL\n` +
    (expiry ? `Expiry: ${expiry}UL\n` : '') +
    `Collateral: !!str ${asset}\n` +
    (isRedeem
      ? `Position: !!str ${position}\n` +
        `Timestamp: ${timestamp}UL\n` +
        `Value: ${value}UL\n` +
        `Root: ${root}\n` +
        `Index: ${index}UL\n` +
        `AuditPath:\n - ${auditPath.join('\n - ')}`
      : '')
  );
}

/**
 * Parse Issue contract asset
 *
 * @param {Object} execution
 * @param {string} execution.command
 * @param {string} execution.messageBodyRaw
 *
 * @returns {{
 *  publicKey: string,
 *  contractId: string,
 *  ticker: string,
 *  price: string,
 *  start: string,
 *  expiry: string,
 *  asset: string,
 * }} issue
 **/
export function parseIssue(execution) {
  let publicKey = '';
  let contractId = '';
  let ticker = '';
  let price = 0;
  let start = 0;
  let expiry;
  let asset = '';

  if (execution.command !== 'Issue') {
    throw new Error('Only an Issue Command is valid');
  }

  DataFactory.fromHex(execution.messageBodyRaw).data.forEach((data) => {
    const value = data[1];
    switch (data[0]) {
      case 'Start':
        start = value.value.toString(10);
        break;
      case 'Expiry':
        expiry = value.value.toString(10);
        break;
      case 'Ticker':
        ticker = value.s;
        break;
      case 'Price':
        price = value.value.toString(10);
        break;
      case 'OraclePubKey':
        publicKey = value.pk.toString();
        break;
      case 'OracleContractId':
        contractId = value.s;
        break;
      case 'Collateral':
        asset = value.s || constants.ZP_FULL_ID;
        break;
      default:
    }
  });
  return {
    publicKey,
    contractId,
    ticker,
    price,
    start,
    expiry,
    asset,
  };
}

/**
 * Get the subtype of the FP contract
 *
 * @param {Object} params
 * @param {string} params.publicKey,
 * @param {string} params.contractId,
 * @param {string} params.ticker,
 * @param {string} params.price,
 * @param {string} params.start,
 * @param {string=} params.expiry,
 * @param {string} params.asset,
 * @param {string} params.position
 *
 * @returns {string} subtype of FPC
 **/
function getSubType({ publicKey, contractId, ticker, price, start, expiry, asset, position }) {
  return ShaDigest.updateString(
    position,
    ShaDigest.updateAsset(
      asset,
      ShaDigest.updateU64(
        expiry,
        ShaDigest.updateU64(
          start,
          ShaDigest.updateU64(
            price,
            ShaDigest.updateString(
              ticker,
              ShaDigest.updateContractId(contractId, ShaDigest.updatePublicKey(publicKey))
            )
          )
        )
      )
    )
  ).toString();
}

/**
 * Get the subtype of the FP contract
 *
 * @param {Object} params
 * @param {string} params.fpContractId,
 * @param {string} params.publicKey,
 * @param {string} params.contractId,
 * @param {string} params.ticker,
 * @param {string} params.price,
 * @param {string} params.start,
 * @param {string=} params.expiry,
 * @param {string} params.asset,
 * @param {string=} params.position
 *
 * @returns {string} subtype of FPC
 **/
export function getAssetId({
  fpContractId,
  publicKey,
  contractId,
  ticker,
  price,
  start,
  expiry,
  asset,
  position,
}) {
  const subtype = getSubType({
    publicKey,
    contractId,
    ticker,
    price,
    start,
    expiry,
    asset,
    position,
  });

  return new Asset(fpContractId.concat(subtype.toString())).getAsset();
}
/**
 * Get the Bull/Bear subtype of the FP contract
 *
 * @param {Object} params
 * @param {string} params.fpContractId,
 * @param {string} params.publicKey,
 * @param {string} params.contractId,
 * @param {string} params.ticker,
 * @param {string} params.price,
 * @param {string} params.start,
 * @param {string=} params.expiry,
 * @param {string} params.asset,
 *
 * @returns {string} subtype of FPC
 **/
export function getPositionsAssetId({
  fpContractId,
  publicKey,
  contractId,
  ticker,
  price,
  start,
  expiry,
  asset,
}) {
  const subTypeBull = getSubType({
    publicKey,
    contractId,
    ticker,
    price,
    start,
    expiry,
    asset,
    position: 'Bull',
  });
  const subTypeBear = getSubType({
    publicKey,
    contractId,
    ticker,
    price,
    start,
    expiry,
    asset,
    position: 'Bear',
  });
  const bull = new Asset(fpContractId.concat(subTypeBull.toString())).getAsset();
  const bear = new Asset(fpContractId.concat(subTypeBear.toString())).getAsset();
  return { bull, bear };
}
/**
 * Get the subtype of the FP contract
 *
 * @param {Object} params
 * @param {string} params.assetId,
 * @param {string} params.publicKey,
 * @param {string} params.contractId,
 * @param {string} params.ticker,
 * @param {string} params.price,
 * @param {string} params.start,
 * @param {string=} params.expiry,
 * @param {string} params.asset,
 *
 * @returns {{position: string}}
 **/
export function getPosition({
  assetId,
  publicKey,
  contractId,
  ticker,
  price,
  start,
  asset,
  expiry,
}) {
  const subTypeBull = getSubType({
    publicKey,
    contractId,
    ticker,
    price,
    start,
    expiry,
    asset,
    position: 'Bull',
  });
  const subTypeBear = getSubType({
    publicKey,
    contractId,
    ticker,
    price,
    start,
    expiry,
    asset,
    position: 'Bear',
  });
  if (new Asset(assetId).getSubType() === subTypeBear) return { position: 'Bear' };
  else if (new Asset(assetId).getSubType() === subTypeBull) return { position: 'Bull' };
  else throw new Error('Not a Bull or Bear');
}

/**
 * Get the subtype of the FP contract
 *
 * @param {Object} params
 * @param {string} params.position,
 * @param {boolean} params.isBull,
 *
 * @returns {boolean}
 **/
export function checkRedeemPosition({ position, isBull }) {
  if (isBull) return position.toLowerCase().trim() === 'bull';
  return position.toLowerCase().trim() === 'bear';
}

/**
 * Get the title of the FP assets
 *
 * @param {Object} params
 * @param {string} params.asset,
 * @param {string} params.title,
 * @param {string} params.naming,
 *
 * @returns {string}
 **/

export function getTitle({ asset, title = 'Asset', naming }) {
  return naming && naming[asset] ? `${title} - ${naming[asset].name}` : `${title}`;
}

/**
 * Get if the asset is created by the FP
 * @param {Object} params
 * @param {string} params.asset,
 * @param {string} params.contractId,
 * @param {string} params.name,
 *
 * @returns {boolean}
 **/

export function checkAsset({ asset, contracts, name }) {
  if (contracts.includes(ZenJsUtils.getContractIdFromAsset(asset).toHex()))
    return NamingUtils.isRedeemableDayAsset(name);
  return false;
}

/**
 * From the redeemable list get the asset that are redeemable
 * @param {Object} params
 * @param {string} params.asset,
 * @param {Array} params.list,
 *
 * @returns {boolean}
 **/

export function isRedeem({ asset, list }) {
  const data = isInList({ asset, list });
  return data && data.isRedeemable;
}

/**
 * From the redeemable list get the asset that are redeemable
 * @param {Object} params
 * @param {string} params.asset,
 * @param {Array} params.list,
 *
 * @returns {boolean}
 **/

export function isInList({ asset, list }) {
  return list.find((a) => a.asset === asset);
}

/**
 * From the redeemable list get the asset that are redeemable
 * @param {Object} params
 * @param {string} params.asset,
 * @param {Array} params.list,
 *
 * @returns {boolean}
 **/

export function getValue({ asset, list }) {
  const data = isInList({ asset, list });
  return data && data.value;
}

/**
 * Check trivially if two assets name are the same by checking their asset name
 * @param {string} assetName1,
 * @param {string} assetName2,
 *
 * @returns {boolean}
 **/

const regexPosition =
  '^[A-Z]{1,}[0-9]{6}H[0-9]{6}[A-Za-z]{4}[0-9]+|^[A-Z]{1,}[0-9]{6}[A-Za-z]{4}[0-9]+';
export function isSameEvent(assetName1, assetName2) {
  if (
    !!assetName1 &&
    !!assetName2 &&
    assetName1.name.replace(/Bull|Bear/, '') === assetName2.name.replace(/Bull|Bear/, '') &&
    assetName1.name.match(regexPosition) &&
    assetName2.name.match(regexPosition)
  ) {
    return (
      (assetName1.name.includes('Bull') && assetName2.name.includes('Bear')) ||
      (assetName1.name.includes('Bear') && assetName2.name.includes('Bull'))
    );
  }
  return false;
}
