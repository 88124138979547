import styled from 'styled-components';
import { color, space, size, typography, border, layout, compose } from 'styled-system';
import tu from '../../../utils/themeUtils';

const StyledLink = styled.a`
  color: ${tu.color('link')};
  ${compose(border, color, space, size, typography, layout)}
`;

export default StyledLink;
