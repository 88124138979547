/**
 * Turn a function into a promise and detach it from the execution stack
 * @param {function} func
 */
export function toDetachedPromise(func) {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        resolve(await func());
      } catch (error) {
        reject(error);
      }
    }, 0);
  });
}

/**
 * Return a function which returns a detached promise
 * @param {function} func
 */
export function toDetached(func) {
  return args =>
    new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          resolve(await func(args));
        } catch (error) {
          reject(error);
        }
      }, 0);
    });
}
