import React, { useMemo } from 'react';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import ToggleButton from './components/ToggleButton';
import { StyledDropDown, StyledMenu, StyledMenuItem, StyledMenuWrapper } from './DropDown.styled';

/**
 * A dropdown component
 *
 * @param {Object} props
 * @param {[{label: string, value: *}]} props.items - the items to display
 * @param {string} props.placeholder - placeholder text when nothing is selected
 * @param {Function} props.onChange - is called when an item is selected
 * @param {*} props.value - the current selected value
 */
const DropDown = ({ items, placeholder, onChange, value, ...props }) => {
  const selectedItem = useMemo(() => items.find((item) => item.value === value), [items, value]);

  return (
    <Downshift
      onSelect={(selection) => onChange(selection ? selection : '')}
      itemToString={(item) => (item ? String(item.value) : '')}
    >
      {({ getItemProps, getMenuProps, getRootProps, isOpen, closeMenu, openMenu }) => (
        <StyledMenuWrapper {...getRootProps()} {...props}>
          <StyledDropDown onClick={!isOpen ? openMenu : closeMenu} data-testid="DropDown-input">
            {selectedItem ? (
              selectedItem.label
            ) : placeholder ? (
              <label>{placeholder}</label>
            ) : (
              <label>Select...</label>
            )}
            <ToggleButton isOpen={isOpen} />
          </StyledDropDown>
          {!isOpen ? null : (
            <StyledMenu {...getMenuProps()} data-testid="DropDown-menu">
              {items
                ? items.map((item, index) => (
                    <StyledMenuItem
                      className={
                        selectedItem && selectedItem.value === item.value ? 'active' : null
                      }
                      key={String(item.value)}
                      {...getItemProps({
                        key: String(item.value),
                        index,
                        item,
                        isSelected: selectedItem && selectedItem.value === item.value,
                      })}
                    >
                      {item.label}
                    </StyledMenuItem>
                  ))
                : null}
            </StyledMenu>
          )}
        </StyledMenuWrapper>
      )}
    </Downshift>
  );
};

DropDown.propTypes = {
  hasClearButton: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }))
    .isRequired,
  value: PropTypes.any,
};

export default DropDown;
