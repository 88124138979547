import React, { useState } from 'react';
import styled from 'styled-components';
import { faEye, faLockAlt, faClone, faTrashRestore } from '@fortawesome/pro-solid-svg-icons';
import {
  Box,
  Checkbox,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  useModal,
  Title,
  Paragraph,
  FaSvg,
  themeUtils as tu,
} from '@zen-common/components-base';

export default function SecurityWarningsModal() {
  const [agree, setAgree] = useState(false);
  const { hide } = useModal();
  const onCheckboxChange = (e) => setAgree(e.target.checked);
  return (
    <Modal dismissOnClickOutside={false}>
      <ModalContent showClose={false} large>
        <ModalHeader>
          <Title>Security Notice</Title>
          <Paragraph>
            Creating a new wallet will generate a 24 words Seed phrase. It’s the most important
            secret you have to keep. Knowing the Seed phrase enables you (or anyone else) to access
            your funds.
          </Paragraph>
        </ModalHeader>
        <ModalSection>
          <IconAndText icon={faEye} text="Never share your Seed phrase with anyone" />
          <IconAndText icon={faLockAlt} text="Never keep your Seed phrase in a password manager" />
          <IconAndText icon={faClone} text="Keep a copy of your Seed phrase in a secure place" />
          <IconAndText icon={faTrashRestore} text="We can not recover or change your Seed phrase" />
        </ModalSection>
        <ModalSeparator />
        <StyledModalFooter>
          <Box mb={[2, 0]}>
            <Checkbox label="I understand" checked={agree} onChange={onCheckboxChange} />
          </Box>
          <Button onClick={hide} disabled={!agree}>
            Continue
          </Button>
        </StyledModalFooter>
      </ModalContent>
    </Modal>
  );
}

function IconAndText({ icon, text }) {
  return (
    <IconAndTextContainer>
      <FaSvg icon={icon} mr="4" color="primary" fontSize="1rem" />
      <Box color="text">{text}</Box>
    </IconAndTextContainer>
  );
}

const IconAndTextContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: ${tu.space(5)};
`;
const StyledModalFooter = styled(ModalFooter)`
  ${tu.mq({
    flexDirection: ['column', 'row'],
    alignItems: ['flex-start', 'center'],
    justifyContent: ['flex-start', 'space-between'],
  })}
`;
