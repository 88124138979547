import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  LabelText,
  Form,
  FormBody,
  FormGroup,
  FormFooter,
  ClearInput,
  Box,
  Button,
  LoadingOr,
  InputMessage,
} from '@zen-common/components-base';
import PasteButton from '../PasteButton';
import { SpendsGroup } from '../Spend';

/**
 * @param {Object} props
 * @param {boolean} props.disabled - disable the whole form
 * @param {() => void} props.onSubmit
 * @param {import('../../stores/SendStore/types').SendStore} props.sendStore
 */
export default function SendForm({
  disabled,
  onSubmit,
  sendStore,
  sendText = 'Send',
  sendingText = 'Sending...',
  naming,
}) {
  const {
    address,
    addressValid,
    spends,
    spendsValid,
    assets,
    balance,
    valid,
    progress,
  } = sendStore.state;
  const { setAddress, setAsset, setAmount, addSpend, removeSpend, reset } = sendStore.actions;
  const addressRef = React.useRef();
  const spendsRef = React.useRef();

  const sendDisabled = disabled || !valid || progress;

  const handleRemoveSpend = (...params) => {
    removeSpend(...params);
    // make sure focus after the removed
    setTimeout(() => {
      spendsRef.current && spendsRef.current.focus();
    }, 0);
  };
  const handleAddSpend = (...params) => {
    addSpend(...params);
    // make sure focus after the add
    setTimeout(() => {
      spendsRef.current && spendsRef.current.focus();
    }, 0);
  };
  const handleReset = () => {
    reset();
    addressRef.current && addressRef.current.focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!sendDisabled) {
      onSubmit();
    }
  };

  const resetDisabled = React.useMemo(
    () => sendStore.state.address === '' && spends[0].asset === '' && spends[0].amount.value === '',
    [spends, sendStore.state.address]
  );

  return (
    <Form onSubmit={handleSubmit} disabled={disabled || progress}>
      <FormBody>
        <FormGroup>
          <Label htmlFor="send-form-destination-address">
            <LabelText>Destination address</LabelText>
          </Label>
          <Box display="flex">
            <Box flexGrow="1">
              <ClearInput
                ref={addressRef}
                id="send-form-destination-address"
                {...(address && { valid: addressValid })}
                variant="block"
                width="100%"
                value={address}
                onChange={(e) => setAddress(e.target.value.trim())}
                onClear={() => setAddress('')}
                inputType="expandable"
                disabled={disabled || progress}
                data-testid="SendForm-input-address"
              />
            </Box>
            <PasteButton onPaste={setAddress} />
          </Box>
          <InputMessage>{!address || addressValid ? '' : 'Invalid address'}</InputMessage>
        </FormGroup>

        <SpendsGroup
          ref={spendsRef}
          alignment="grid"
          noMarginTop
          spends={spends}
          spendsValid={spendsValid}
          assets={assets}
          balance={balance}
          addSpend={handleAddSpend}
          removeSpend={handleRemoveSpend}
          setAmount={setAmount}
          setAsset={setAsset}
          naming={naming}
          disabled={disabled || progress}
        />

        <FormFooter>
          <Button
            disabled={resetDisabled || disabled || progress}
            type="button"
            bg="secondary"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button disabled={sendDisabled} type="submit">
            <LoadingOr loading={progress} loadingContent={sendingText}>
              {sendText}
            </LoadingOr>
          </Button>
        </FormFooter>
      </FormBody>
    </Form>
  );
}
SendForm.propTypes = {
  sendStore: PropTypes.object,
  onSubmit: PropTypes.func,
};
