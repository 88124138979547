import * as React from 'react';
import { useStorageState } from '@zen/common-react-hooks';
import { SettingsStore, BlockchainInfoStore } from '@zen/common-app-parts';

const STORAGE_KEY = 'zen-protocol-wallet:cgp-phase-changed';

/**
 * Sets a flag when the cgp phase is changed
 */
export default function useCgpPhaseChanged() {
  const [state, setState] = useStorageState(STORAGE_KEY, {}, { storage: 'localStorage' });
  const [changed, setChanged] = React.useState(false);

  const { interval: lastViewedInterval, voteStatus: lastViewedStatus } = state;

  const {
    state: {
      infos: { headers },
    },
  } = BlockchainInfoStore.useStore();
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();

  const { currentInterval, voteStatus } = React.useMemo(() => {
    if (!headers) return {};

    const intervalLength = chain === 'main' ? 10000 : 100;
    const votingIntervalLength = intervalLength / 10;
    const currentInterval = Math.ceil((headers + 1) / intervalLength);
    const snapshotBlock = (currentInterval - 1) * intervalLength + intervalLength * 0.9;
    const tallyBlock = currentInterval * intervalLength;
    const nominationBlock = snapshotBlock + votingIntervalLength / 2;

    const voteStatus =
      headers >= snapshotBlock && headers < nominationBlock
        ? 'nomination'
        : headers >= nominationBlock && headers < tallyBlock
        ? 'voting'
        : 'between';

    return { currentInterval, voteStatus };
  }, [chain, headers]);

  React.useEffect(() => {
    if (!currentInterval || !voteStatus) return;

    // exit if user already saw the update
    if (currentInterval === lastViewedInterval && voteStatus === lastViewedStatus) return;

    if (['nomination', 'voting'].includes(voteStatus)) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [currentInterval, lastViewedInterval, lastViewedStatus, voteStatus]);

  const clear = React.useCallback(() => {
    setChanged(false);
    setState({ interval: currentInterval, voteStatus });
  }, [currentInterval, setState, voteStatus]);
  const reset = React.useCallback(() => {
    setChanged(false);
  }, []);

  return {
    state: {
      changed,
    },
    actions: {
      clear,
      reset,
    },
  };
}
