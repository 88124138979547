import { useEffect, useReducer, useCallback, useState } from 'react';
import { defaultTo, mapObjIndexed } from 'ramda';
import { useQuery } from 'react-query';
import { errorUtils, toDetachedPromise, assetUtils } from '@zen/common-utils';
import {
  encryptPassword,
  decryptPassword,
  walletFromMnemonic,
  getPrivateKey,
  getXPub,
  privateKeys,
} from '../../../utils/zenJsUtils';
import reducer, { ACTIONS } from './walletReducer';
import * as walletActions from './walletActions';

const { captureError } = errorUtils;
const PASSWORD_ERROR_MSG = 'Wrong password.';
const NO_WALLET_MSG = 'Wallet is not yet instantiated';

export const LS_PASS_PHRASE_MAIN = 'pass-phrase-main';
export const LS_PASS_PHRASE_TEST = 'pass-phrase-test';

export default function useWalletState({
  chain = 'main',
  nodeUrl = '',
  storage = {
    get() {},
    set() {},
  },
} = {}) {
  const [fetchingBalance, setFetchingBalance] = useState(false);
  /** @type {[state: import('../WalletStore').TWalletState, dispatch: React.Dispatch]} */
  const [state, dispatch] = useReducer(reducer, { chain, storage }, ({ chain, storage }) => ({
    isLoggedIn: false,
    hasPassword: !!storage.get(chain === 'test' ? LS_PASS_PHRASE_TEST : LS_PASS_PHRASE_MAIN),
    keys: {
      address: '',
      pkHash: '',
      pubKey: '',
      addresses: [],
    },
    encryptedSeed: {
      test: defaultTo('', storage.get(LS_PASS_PHRASE_TEST)),
      main: defaultTo('', storage.get(LS_PASS_PHRASE_MAIN)),
    },
    wallet: null,
    initialBalanceFetched: false,
    balance: {},
    balances: [],
    assets: [],
    loading: {}, // address: true
    executing: false, // true when performing actions like send or execute contract
  }));

  // fetch balance
  const { data: balance, refetch: refetchBalance } = useQuery(
    ['wallet-store-balance'],
    useCallback(async () => {
      try {
        dispatch({ type: ACTIONS.FETCH_BALANCE.REQUESTED });
        return await _fetchBalance({
          wallet: state.wallet,
        });
      } catch (error) {
        dispatch({ type: ACTIONS.FETCH_BALANCE.FAILED });
      }
    }, [state.wallet]),
    { enabled: fetchingBalance && state.wallet, refetchInterval: 5000 }
  );

  // set balance on change
  useEffect(() => {
    if (balance) {
      dispatch({
        type: ACTIONS.FETCH_BALANCE.SUCCEEDED,
        payload: { balance },
      });
    }
  }, [balance]);

  // destroy wallet when props change
  useEffect(() => {
    dispatch({ type: ACTIONS.WALLET_REMOVED, payload: { chain } });
  }, [chain, nodeUrl]);

  // chain changed
  useEffect(() => {
    dispatch({ type: ACTIONS.CHAIN_CHANGED, payload: { chain } });
  }, [chain]);

  // store pass phrase on change
  useEffect(() => {
    storage.set(LS_PASS_PHRASE_TEST, state.encryptedSeed.test);
    storage.set(LS_PASS_PHRASE_MAIN, state.encryptedSeed.main);
  }, [state.encryptedSeed, chain, storage]);

  // parameters to change when wallet state changes
  useEffect(() => {
    if (state.wallet) {
      setFetchingBalance(true);
    } else {
      setFetchingBalance(false);
      dispatch({ type: ACTIONS.WALLET_REMOVED });
    }
  }, [state.wallet]);

  return Object.freeze({
    state,
    actions: {
      create: useCallback(
        ({ mnemonic, password } = {}) => {
          return _createWallet({
            chain,
            dispatch,
            mnemonic,
            password,
            wallet: state.wallet,
            nodeUrl,
          });
        },
        [chain, state.wallet, nodeUrl]
      ),
      wipeSeed: useCallback(() => {
        dispatch({ type: ACTIONS.WIPE.REQUESTED, payload: { chain } });
      }, [chain]),
      login: useCallback(
        (password) => {
          return _login({
            password,
            chain,
            nodeUrl,
            dispatch,
            wallet: state.wallet,
            encryptedSeed: state.encryptedSeed[chain],
          });
        },
        [chain, state.encryptedSeed, state.wallet, nodeUrl]
      ),
      logout: useCallback(() => {
        dispatch({ type: ACTIONS.WALLET_REMOVED });
      }, []),
      changePassword: useCallback(
        ({ currentPassword, newPassword } = {}) => {
          return _changePassword({
            dispatch,
            wallet: state.wallet,
            encryptedSeed: state.encryptedSeed[chain],
            currentPassword,
            newPassword,
            chain,
          });
        },
        [chain, state.encryptedSeed, state.wallet]
      ),
      getPrivateKeys: useCallback(
        (password) => {
          return _getPrivateKeys({
            dispatch,
            password,
            wallet: state.wallet,
            encryptedSeed: state.encryptedSeed[chain],
          });
        },
        [chain, state.encryptedSeed, state.wallet]
      ),
      getXPubKey: useCallback(
        (password) => {
          return _getPrivateKeys({
            dispatch,
            password,
            wallet: state.wallet,
            encryptedSeed: state.encryptedSeed[chain],
          });
        },
        [chain, state.encryptedSeed, state.wallet]
      ),
      checkPassword: useCallback(
        (password) => _checkPassword({ encryptedSeed: state.encryptedSeed[chain], password }),
        [chain, state.encryptedSeed]
      ),
      fetchBalance: refetchBalance,
      fetchTransactions: useCallback(
        ({ skip, take } = {}) => {
          return _fetchTransactions({
            wallet: state.wallet,
            skip,
            take,
          });
        },
        [state.wallet]
      ),
      fetchTransactionsCount: useCallback(() => {
        return _fetchTransactionsCount({
          wallet: state.wallet,
        });
      }, [state.wallet]),
      send: useCallback(
        ({ address, spends, password } = {}) => {
          return _send({
            dispatch,
            wallet: state.wallet,
            address,
            spends,
            encryptedSeed: state.encryptedSeed[chain],
            password,
          });
        },
        [chain, state.wallet, state.encryptedSeed]
      ),
      signSendTx: useCallback(
        ({ address, spends, password } = {}) => {
          return _signSendTx({
            address,
            dispatch,
            spends,
            wallet: state.wallet,
            encryptedSeed: state.encryptedSeed[chain],
            password,
          });
        },
        [chain, state.encryptedSeed, state.wallet]
      ),
      signTx: useCallback(
        ({ tx, password } = {}) => {
          return _signTx({
            wallet: state.wallet,
            tx,
            encryptedSeed: state.encryptedSeed[chain],
            password,
          });
        },
        [chain, state.encryptedSeed, state.wallet]
      ),
      execute: useCallback(
        ({ address, command, includeReturnAddress, messageBody, path, spends } = {}) => {
          return _execute({
            dispatch,
            wallet: state.wallet,
            chain,
            address,
            command,
            includeReturnAddress,
            messageBody,
            path,
            spends,
          });
        },
        [chain, state.wallet]
      ),
      executeAndPublish: useCallback(
        ({ address, command, includeReturnAddress, messageBody, path, spends, password } = {}) => {
          return _executeAndPublish({
            dispatch,
            wallet: state.wallet,
            chain,
            address,
            command,
            includeReturnAddress,
            messageBody,
            path,
            spends,
            encryptedSeed: state.encryptedSeed[chain],
            password,
          });
        },
        [chain, state.wallet, state.encryptedSeed]
      ),
      signContractExecution: useCallback(
        ({ tx, sign, password } = {}) => {
          return _signContractExecution({
            wallet: state.wallet,
            tx,
            sign,
            encryptedSeed: state.encryptedSeed[chain],
            password,
          });
        },
        [chain, state.encryptedSeed, state.wallet]
      ),
      submitCgpBallot: useCallback(
        ({ command, ballotData, interval, votingContractAddress, password }) =>
          _submitCgpBallot({
            ballotData,
            command,
            dispatch,
            interval,
            votingContractAddress,
            wallet: state.wallet,
            encryptedSeed: state.encryptedSeed[chain],
            password,
          }),
        [chain, state.wallet, state.encryptedSeed]
      ),
      submitRepoVote: useCallback(
        ({ commitId, phase, interval, votingContractAddress, password }) =>
          _submitRepoVote({
            commitId,
            phase,
            dispatch,
            interval,
            votingContractAddress,
            wallet: state.wallet,
            encryptedSeed: state.encryptedSeed[chain],
            password,
          }),
        [chain, state.wallet, state.encryptedSeed]
      ),
      activateContract: useCallback(
        ({ code, limit, numberOfBlocks }) =>
          _activateContract({
            code,
            limit,
            numberOfBlocks,
            dispatch,
            wallet: state.wallet,
          }),
        [state.wallet]
      ),
      extendContract: useCallback(
        ({ contractId, numberOfBlocks }) =>
          _extendContract({
            contractId,
            numberOfBlocks,
            dispatch,
            wallet: state.wallet,
          }),
        [state.wallet]
      ),
      destroy: useCallback(
        ({ spend } = {}) =>
          _destroy({
            dispatch,
            wallet: state.wallet,
            spend,
          }),
        [state.wallet]
      ),
      reset: useCallback(() => dispatch({ type: ACTIONS.RESET_REQUESTED }), []),
    },
  });
}

function _checkPassword({ encryptedSeed, password } = {}) {
  return toDetachedPromise(() => {
    try {
      decryptPassword({ encryptedSeed, password });
      return true;
    } catch (error) {
      return false;
    }
  });
}

async function _login({ password, wallet, encryptedSeed, dispatch, chain, nodeUrl } = {}) {
  if (wallet) return;

  dispatch({ type: ACTIONS.LOGIN.REQUESTED });

  // detach the login process and don't wait for it
  return toDetachedPromise(() => {
    try {
      const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
      if (mnemonic.error) {
        throw new Error(PASSWORD_ERROR_MSG);
      }

      const wallet = walletFromMnemonic({ chain, mnemonic: mnemonic.value, nodeUrl });
      dispatch({ type: ACTIONS.LOGIN.SUCCEEDED, payload: { wallet, chain } });
    } catch (error) {
      dispatch({ type: ACTIONS.LOGIN.FAILED });
      throw error;
    }
  });
}

async function _createWallet({ password, mnemonic, wallet, dispatch, chain, nodeUrl } = {}) {
  if (wallet) return;

  dispatch({ type: ACTIONS.CREATE.REQUESTED });

  return toDetachedPromise(async () => {
    try {
      const wallet = walletFromMnemonic({ chain, mnemonic, nodeUrl });
      const encryptedSeed = encryptPassword({ mnemonic, password });
      dispatch({
        type: ACTIONS.CREATE.SUCCEEDED,
        payload: {
          wallet,
          encryptedSeed,
          chain,
        },
      });
      return wallet;
    } catch (error) {
      dispatch({ type: ACTIONS.CREATE.FAILED });
      throw error;
    }
  });
}

async function _changePassword({
  dispatch,
  wallet,
  encryptedSeed,
  currentPassword,
  newPassword,
  chain,
}) {
  dispatch({ type: ACTIONS.CHANGE_PASSWORD.REQUESTED });

  return toDetachedPromise(() => {
    try {
      if (!wallet) throw new Error(NO_WALLET_MSG);

      const mnemonic = captureError(() =>
        decryptPassword({ encryptedSeed, password: currentPassword })
      );
      if (mnemonic.error) {
        throw new Error(PASSWORD_ERROR_MSG);
      }

      const newEncryptedSeed = captureError(() =>
        encryptPassword({ mnemonic: mnemonic.value, password: newPassword })
      );
      if (newEncryptedSeed.error) {
        throw new Error(`An error occurred while encrypting: ${newEncryptedSeed.error.message}`);
      }
      dispatch({
        type: ACTIONS.CHANGE_PASSWORD.SUCCEEDED,
        payload: { encryptedSeed: newEncryptedSeed.value, chain },
      });
    } catch (error) {
      dispatch({ type: ACTIONS.CHANGE_PASSWORD.FAILED });
      throw error;
    }
  });
}

async function _fetchBalance({ wallet }) {
  return toDetachedPromise(async () => {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    const balance = await walletActions.getBalance({ wallet }).then((data) => {
      return mapObjIndexed((amount) => assetUtils.fromKalapas(amount), data);
    });
    return balance;
  });
}

/**
 * fetch and returns the transactions, will not change the state
 */
async function _fetchTransactions({ wallet, skip, take } = {}) {
  if (!wallet) throw new Error(NO_WALLET_MSG);

  return walletActions
    .getTransactions({ wallet, skip, take })
    .then((data) => data.map((tx) => ({ ...tx, amount: assetUtils.fromKalapas(tx.amount) })));
}
/**
 * fetch and returns the transactions count, will not change the state
 */
async function _fetchTransactionsCount({ wallet } = {}) {
  if (!wallet) throw new Error(NO_WALLET_MSG);

  return walletActions.getTransactionsCount({ wallet });
}

async function _getPrivateKeys({ password, wallet, encryptedSeed, dispatch }) {
  dispatch({ type: ACTIONS.GET_PRIVATE_KEYS.REQUESTED });

  return toDetachedPromise(() => {
    try {
      if (!wallet) throw new Error(NO_WALLET_MSG);

      const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
      if (mnemonic.error) {
        throw new Error(PASSWORD_ERROR_MSG);
      }

      const privateKey = captureError(() => getPrivateKey({ mnemonic: mnemonic.value }));
      if (privateKey.error) {
        throw new Error('Could not get private key from seed');
      }
      const xPubKey = captureError(() => getXPub({ mnemonic: mnemonic.value }));
      if (xPubKey.error) {
        throw new Error('Could not get xpub key from seed');
      }
      dispatch({ type: ACTIONS.GET_PRIVATE_KEYS.SUCCEEDED });
      return {
        mnemonic: mnemonic.value,
        privateKey: privateKey.value,
        xPubKey: xPubKey.value,
      };
    } catch (error) {
      dispatch({ type: ACTIONS.GET_PRIVATE_KEYS.FAILED });
      throw error;
    }
  });
}

/**
 * Signs a tx (send)
 *
 * @param {Object} params
 * @param {React.DispatchWithoutAction} params.dispatch
 * @param {Object} params.wallet
 * @param {string} params.address
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @param {string} params.encryptedSeed
 * @param {string} params.password
 * @returns {Promise}
 */
async function _signSendTx({ dispatch, wallet, address, spends, encryptedSeed, password } = {}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.SEND.STARTED });

    const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
    if (mnemonic.error) {
      throw new Error(PASSWORD_ERROR_MSG);
    }
    const privateKey = captureError(() => privateKeys({ mnemonic: mnemonic.value }));
    if (privateKey.error) {
      throw new Error('Could not get private key from seed');
    }
    const result = await walletActions.send({
      wallet,
      address,
      spends,
      privateKey: privateKey.value,
      publish: false,
    });
    dispatch({ type: ACTIONS.SEND.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.SEND.FINISHED });
    throw error;
  }
}

/**
 * Sends a tx
 *
 * @param {Object} params
 * @param {React.DispatchWithoutAction} params.dispatch
 * @param {Object} params.wallet
 * @param {string} params.address
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @returns {Promise}
 */
async function _send({ dispatch, wallet, address, spends, encryptedSeed, password } = {}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.SEND.STARTED });

    const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
    if (mnemonic.error) {
      throw new Error(PASSWORD_ERROR_MSG);
    }
    const privateKey = captureError(() => privateKeys({ mnemonic: mnemonic.value }));
    if (privateKey.error) {
      throw new Error('Could not get private key from seed');
    }
    const result = await walletActions.send({
      wallet,
      address,
      spends,
      privateKey: privateKey.value,
      publish: true,
    });
    dispatch({ type: ACTIONS.SEND.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.SEND.FINISHED });
    throw error;
  }
}

/**
 * Executes a contract
 *
 * @param {Object} params
 * @param {React.DispatchWithoutAction} params.dispatch
 * @param {Object} params.wallet
 * @param {string} params.chain
 * @param {string} params.address - the contract address
 * @param {string} params.command
 * @param {string} params.messageBody
 * @param {boolean} params.includeReturnAddress
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @param {string} params.path
 * @returns {Promise}
 */
async function _execute({
  dispatch,
  wallet,
  chain,
  address,
  command,
  includeReturnAddress,
  messageBody,
  path,
  spends,
} = {}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });
    const result = await walletActions.execute({
      address,
      chain,
      command,
      includeReturnAddress,
      messageBody,
      path,
      spends,
      wallet,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

/**
 * Executes a contract
 *
 * @param {Object} params
 * @param {React.DispatchWithoutAction} params.dispatch
 * @param {Object} params.wallet
 * @param {string} params.chain
 * @param {string} params.address - the contract address
 * @param {string} params.command
 * @param {string} params.messageBody
 * @param {boolean} params.includeReturnAddress
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @param {string} params.path
 * @returns {Promise}
 */
async function _executeAndPublish({
  dispatch,
  wallet,
  chain,
  address,
  command,
  includeReturnAddress,
  messageBody,
  path,
  spends,
  encryptedSeed,
  password,
} = {}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });

    const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
    if (mnemonic.error) {
      throw new Error(PASSWORD_ERROR_MSG);
    }

    const privateKey = captureError(() => privateKeys({ mnemonic: mnemonic.value }));
    if (privateKey.error) {
      throw new Error('Could not get private key from seed');
    }
    const result = await walletActions.executeAndPublish({
      address,
      chain,
      command,
      includeReturnAddress,
      messageBody,
      path,
      spends,
      wallet,
      privateKey: privateKey.value,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

/**
 * Sign a contract execution
 *
 * @param {Object} params
 * @param {Object} params.wallet
 * @param {string} params.tx - the tx to sign in hex
 * @param {string} params.sign
 * @param {string} params.encryptedSeed
 * @param {string} params.password
 * @returns {string}
 */
async function _signContractExecution({ wallet, tx, sign, encryptedSeed, password } = {}) {
  if (!wallet) throw new Error(NO_WALLET_MSG);

  const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
  if (mnemonic.error) {
    throw new Error(PASSWORD_ERROR_MSG);
  }

  const privateKey = captureError(() => privateKeys({ mnemonic: mnemonic.value }));
  if (privateKey.error) {
    throw new Error('Could not get private key from seed');
  }

  return await walletActions.signContractExecution({
    wallet,
    tx,
    sign,
    privateKey: privateKey.value,
  });
}

/**
 * Sign a general TX
 *
 * @param {Object} params
 * @param {Object} params.wallet
 * @param {string} params.tx - the tx to sign in hex
 * @param {string} params.encryptedSeed
 * @param {string} params.password
 * @returns {string}
 */
async function _signTx({ wallet, tx, encryptedSeed, password } = {}) {
  if (!wallet) throw new Error(NO_WALLET_MSG);

  const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
  if (mnemonic.error) {
    throw new Error(PASSWORD_ERROR_MSG);
  }

  const privateKey = captureError(() => privateKeys({ mnemonic: mnemonic.value }));
  if (privateKey.error) {
    throw new Error('Could not get private key from seed');
  }

  return await walletActions.signTransaction({
    wallet,
    tx,
    privateKey: privateKey.value,
  });
}

async function _submitCgpBallot({
  wallet,
  dispatch,
  ballotData,
  command,
  interval,
  votingContractAddress,
  encryptedSeed,
  password,
}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });

    const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
    if (mnemonic.error) {
      throw new Error(PASSWORD_ERROR_MSG);
    }

    const privateKey = captureError(() => privateKeys({ mnemonic: mnemonic.value }));
    if (privateKey.error) {
      throw new Error('Could not get private key from seed');
    }
    const result = await walletActions.submitCgpBallot({
      ballotData,
      command,
      interval,
      votingContractAddress,
      wallet,
      privateKey: privateKey.value,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

async function _submitRepoVote({
  wallet,
  dispatch,
  commitId,
  phase,
  interval,
  votingContractAddress,
  encryptedSeed,
  password,
}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });

    const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
    if (mnemonic.error) {
      throw new Error(PASSWORD_ERROR_MSG);
    }

    const privateKey = captureError(() => privateKeys({ mnemonic: mnemonic.value }));
    if (privateKey.error) {
      throw new Error('Could not get private key from seed');
    }
    const result = await walletActions.submitRepoVote({
      wallet,
      votingContractAddress,
      phase,
      commitId,
      interval,
      privateKey: privateKey.value,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

async function _activateContract({ wallet, dispatch, code, limit, numberOfBlocks }) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });
    const result = await walletActions.activateContract({
      wallet,
      limit,
      code,
      numberOfBlocks,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

async function _extendContract({ wallet, dispatch, contractId, numberOfBlocks }) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });
    const result = await walletActions.extendContract({
      wallet,
      contractId,
      numberOfBlocks,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

/**
 * Destroy an spend
 *
 * @param {Object} params
 * @param {React.DispatchWithoutAction} params.dispatch
 * @param {Object} params.wallet
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @returns {Promise}
 */
async function _destroy({ dispatch, wallet, spend } = {}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });

    const result = await walletActions.destroy({
      wallet,
      spend,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}
