/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { faPlay, faCode, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import {
  LoadingOr,
  Box,
  Button,
  Code,
  Copyable,
  CopyableHash,
  FaSvg,
  Hash,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalSection,
  PaginationSize,
  Table,
  tableFilterCss,
  TableFiltersContainer,
  themeUtils as tu,
  Title,
  useModal,
  usePagination,
  Alert,
  notification,
} from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';
import {
  TitleContentLayout,
  ExplorerLink,
  StorageUpdaterStore,
  ExecuteContractStore,
  WalletStore,
  SettingsStore,
  NamingJsonStore,
  ExecuteContractModalProcess,
} from '@zen/common-app-parts';
import ExtendStore from '../../stores/ExtendStore';
import ActivateStore from '../../stores/ActivateStore';
import usePreventAction from '../../utils/usePreventAction';
import ActivateContractModalProcess from './components/ActivateContractModalProcess';
import ExtendContractModalProcess from './components/ExtendContractModalProcess';

const STORAGE_KEY = 'active-contracts-table-page-size';

export default function ActiveContracts({ fetching }) {
  const { state: naming } = NamingJsonStore.useStore();
  const storage = StorageUpdaterStore.useStorage();
  const {
    state: { activeContracts },
  } = ExecuteContractStore.useStore();

  const pagination = usePagination({
    manual: true,
    itemsCount: activeContracts.length,
    initialPageSize: storage.get(STORAGE_KEY) || undefined, // prevent passing null
  });

  const paginatedData = useMemo(() => {
    const start = pagination.page * pagination.pageSize;
    return activeContracts.slice(start, start + pagination.pageSize).map((data) => ({
      ...data,
      metadata: naming[data.contractId] || { name: '', shortName: '' },
    }));
  }, [activeContracts, naming, pagination.page, pagination.pageSize]);

  // remember selected page size
  StorageUpdaterStore.useUpdater([
    {
      key: STORAGE_KEY,
      value: pagination.pageSize,
    },
  ]);

  return (
    <TitleContentLayout noPadding dark position="relative" title="Active Contracts">
      <TableFiltersContainer>
        <ActivateContractBtn />
        <PaginationSize {...pagination} css={tableFilterCss} width={['100%', 'auto']} />
      </TableFiltersContainer>
      <Table
        data={paginatedData}
        loading={fetching}
        columns={tableColumns}
        resizable={false}
        sortable={false}
        minRows={0}
        {...pagination}
      />
    </TitleContentLayout>
  );
}

const ActionButton = styled(Button)`
  padding: ${tu.space('xxs')};
`;

const StyledCodeWrapper = styled(Box)`
  pre {
    max-height: 300px;
    overflow: hidden;
    code {
      max-height: 300px;
      overflow: auto;
    }
  }
  @media screen and (min-height: 600px) {
    pre {
      max-height: 500px;
      code {
        max-height: 500px;
      }
    }
  }
`;

const ShowCodeBtn = ({ contract, ...props }) => {
  const { show } = useModal();
  const { state: naming } = NamingJsonStore.useStore();

  const contractName = naming[contract.contractId] ? naming[contract.contractId].shortName : '';

  const openModal = () => {
    show(() => (
      <Modal>
        <ModalContent large showMaximize>
          <ModalHeader>
            <Title>{contractName ? contractName : 'Contract'} Code</Title>
            <Box display="inline-block">
              <CopyableHash hash={contract.address} />
            </Box>
          </ModalHeader>
          <ModalSection>
            <StyledCodeWrapper>
              <Code code={contract.code} />
            </StyledCodeWrapper>
          </ModalSection>
          <ModalFooter>
            <ExecuteContractBtn contract={contract} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    ));
  };

  return (
    <ActionButton bg="secondary" onClick={openModal} {...props}>
      <FaSvg icon={faCode} />
    </ActionButton>
  );
};

const ExtendBtn = ({ contract, ...props }) => {
  const {
    state: { progress },
  } = ExtendStore.useStore();
  const { show, hide, isOpen } = useModal();

  function handleSuccess() {
    hide();
    notification('Contract was extended successfully', {
      type: notification.TYPE.SUCCESS,
    });
  }
  function handleError(error) {
    show(() => (
      <Alert type={Alert.TYPE.ERROR} onDismiss={hide} title="Error">
        Couldn&apos;t extend the contract
        <br />
        Error message: {error.message}
      </Alert>
    ));
  }

  const openModal = () => {
    show(() => (
      <Modal>
        <ModalContent>
          <ExtendContractModalProcess
            contractId={contract.contractId}
            onCancel={hide}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        </ModalContent>
      </Modal>
    ));
  };

  return (
    <ActionButton
      bg="secondary"
      onClick={openModal}
      disabled={isOpen || progress}
      aria-label="extend"
      {...props}
    >
      <IconAndTextWrapper>
        <FaSvg icon={faPlusSquare} />
        <BtnDesktopOnlyText>Extend</BtnDesktopOnlyText>
      </IconAndTextWrapper>
    </ActionButton>
  );
};

const IconAndTextWrapper = styled.span`
  display: flex;
  align-items: center;
`;
const BtnDesktopOnlyText = styled.span`
  margin-left: ${tu.space(2)};
  ${tu.mq({
    display: ['none', null, 'inline-block'],
  })}
`;

const StyledNormalExecuteBtn = styled(Button)`
  line-height: initial;
`;

const ExecuteContractBtn = ({ contract, isInTable, ...props }) => {
  const { show, hide, isOpen } = useModal();
  const {
    actions: { fetchBalance },
  } = WalletStore.useStore();
  const {
    state: { progress },
    actions,
  } = ExecuteContractStore.useStore();
  const { state: naming } = NamingJsonStore.useStore();

  const handleClick = () => {
    fetchBalance().catch(() => {});
    actions.setAddressReadOnly(false);
    actions.reset();
    actions.setAddress(contract.address);
    actions.setAddressReadOnly(true);

    show(() => (
      <Modal>
        <ModalContent>
          <ExecuteContractModalProcess
            contractId={contract.contractId}
            onFinish={hide}
            usePreventAction={usePreventAction}
            naming={naming}
          />
        </ModalContent>
      </Modal>
    ));
  };

  const ButtonType = isInTable ? ActionButton : StyledNormalExecuteBtn;
  const disabled = isInTable ? isOpen || progress : contract.running;

  return (
    <ButtonType onClick={handleClick} disabled={disabled} {...props}>
      <LoadingOr loading={contract.running}>
        <FaSvg icon={faPlay} /> Execute
      </LoadingOr>
    </ButtonType>
  );
};

const ActivateContractBtn = () => {
  const {
    state: { progress },
  } = ActivateStore.useStore();
  const {
    state: {
      settings: { nodeUrl },
    },
  } = SettingsStore.useStore();
  const { show, hide, isOpen } = useModal();

  // ALLOW THIS ON DESKTOP CONNECTED TO LOCALHOST NODE ONLY
  if (!isLocalDesktop(nodeUrl)) {
    return null;
  }

  function handleSuccess() {
    hide();
    notification('Contract was activated successfully', {
      type: notification.TYPE.SUCCESS,
    });
  }
  function handleError(error) {
    show(() => (
      <Alert type={Alert.TYPE.ERROR} onDismiss={hide} title="Error">
        Couldn&apos;t activate the contract
        <br />
        Error message: {error.message}
      </Alert>
    ));
  }

  const handleClick = () => {
    show(() => (
      <Modal>
        <ModalContent>
          <ActivateContractModalProcess
            onCancel={hide}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        </ModalContent>
      </Modal>
    ));
  };

  return (
    <Button
      type="button"
      sizeVariant="small"
      mr="smd"
      display={['none', null, 'block']}
      disabled={isOpen}
      onClick={handleClick}
    >
      <LoadingOr loading={progress}>Activate</LoadingOr>
    </Button>
  );
};

const tableColumns = [
  {
    Header: 'Name',
    accessor: 'metadata.shortName',
    Cell: ({ value, original }) => (
      <Copyable value={original.contractId}>
        <ExplorerLink path={`/contracts/${original.address}`}>
          <Hash hash={original.contractId} display={value} />
        </ExplorerLink>
      </Copyable>
    ),
  },
  {
    Header: 'Address',
    accessor: 'address',
    minWidth: 150,
    Cell: ({ value }) => (
      <Copyable value={value}>
        <ExplorerLink path={`/contracts/${value}`}>
          <Hash hash={value} />
        </ExplorerLink>
      </Copyable>
    ),
  },
  {
    Header: 'Active Until',
    accessor: 'expire',
    minWidth: 150,
    Cell: ({ value }) => numberUtils.toDisplay(value),
  },
  {
    Header: 'Actions',
    accessor: 'code',
    minWidth: 180,
    className: 'align-right',
    headerClassName: 'align-right',
    Cell: ({ original }) => (
      <Box>
        <ShowCodeBtn contract={original} mr="xs" />
        <ExtendBtn contract={original} mr="xs" />
        <ExecuteContractBtn contract={original} isInTable />
      </Box>
    ),
  },
];

function isLocalDesktop(nodeUrl) {
  const isLocalNode = nodeUrl.includes('localhost') || nodeUrl.includes('127.0.0.1');
  return (
    process.env.NODE_ENV !== 'production' ||
    (isLocalNode && process.env.REACT_APP_END_PLATFORM === 'desktop')
  );
}
