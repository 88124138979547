import { useReducer, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import SettingsStore from '../../SettingsStore';
import * as ApiService from '../../../utils/ApiService';
import reducer, { ACTIONS } from './blockchainInfoReducer';

export default function useBlockchainInfoStore() {
  const [fetching, setFetching] = useState(false);
  const {
    state: {
      settings: { nodeUrl },
    },
  } = SettingsStore.useStore();

  const [state, dispatch] = useReducer(reducer, {
    infos: {},
    error: false,
    loading: false,
  });

  const { data } = useQuery(
    ['blockchain-info-store'],
    useCallback(async () => {
      try {
        dispatch({ type: ACTIONS.FETCH_INFOS_REQUESTED });
        return await ApiService.blockchainInfo(nodeUrl);
      } catch (error) {
        dispatch({ type: ACTIONS.FETCH_INFOS_FAILED, payload: error });
      }
    }, [nodeUrl]),
    {
      enabled: fetching,
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
    }
  );

  // set data on change
  useEffect(() => {
    if (data) {
      dispatch({ type: ACTIONS.FETCH_INFOS_SUCCESS, payload: data });
    }
  }, [data]);

  // start polling on mount and stop on unmount
  useEffect(() => {
    setFetching(true);

    return () => setFetching(false);
  }, []);

  return {
    state,
    actions: {
      reset: useCallback(() => dispatch({ type: ACTIONS.RESET_REQUESTED }), []),
    },
  };
}
