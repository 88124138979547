import React from 'react';
import { NamingAssetStore, SettingsStore } from '@zen/common-app-parts';

/**
 * a wrapper around the NamingAssetStore.Provider with the needed settings
 */
export default function Provider({ config, ...props }) {
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();

  return <NamingAssetStore.Provider url={config.contractAssetUrl} chain={chain} {...props} />;
}
