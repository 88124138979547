import createStore from '../../utils/createStore';
import useNamingJsonStore from './useNamingJsonStore';

const { Provider, useStore } = createStore({
  useStoreHook: useNamingJsonStore,
  contextDisplayName: 'NamingJsonStoreStoreContext',
});

export default {
  /**
   * @type {NamingJsonStoreProvider}
   */
  Provider,
  /**
   * @type {() => import('./types').TNamingJsonStore}
   */
  useStore,
};

/**
 * @typedef {({url: string}) => void} NamingJsonStoreProvider
 */
