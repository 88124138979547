import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { createHook, PREFIX } from './modules/useStorageUpdater';

const Context = createContext();
Context.displayName = 'StorageUpdaterContext';

/**
 * @param {Object} props
 * @param {Object} props.storage - the storage object
 * @returns the context provider
 */
const Provider = ({ storage, ...props }) => {
  return <Context.Provider value={storage} {...props} />;
};

Provider.propTypes = {
  storage: PropTypes.shape({ get: PropTypes.func, set: PropTypes.func }),
};

/**
 * @typedef {(items: [{key: string, value: any, setter: (val: any) => void}]) => void} UseUpdaterFunc
 */

/**
 * An effect that loads and saves data from storage
 * @type {UseUpdaterFunc}
 */
const useUpdater = createHook(Context);

/** Get the storage object */
const useStorage = () => {
  const storage = useContext(Context);
  return {
    get(key) {
      return storage.get(PREFIX + key);
    },
    set(key, value) {
      storage.set(PREFIX + key, value);
    },
  };
};

export default { Provider, useUpdater, useStorage };
