import { assetUtils } from '@zen/common-utils';

export const convertZPtoPercentage = (value) => Number(((value / 50) * 100).toFixed(0));

export const convertPercentageToZP = (value) => (50 * value) / 100;

/**
 * Calculate the balance
 *
 * @param {Object} params
 * @param {Array} params.balance
 * @param {Object} params.pointedOutput
 * @returns
 */
export function calcBalance({ balance, pointedOutput } = {}) {
  const { asset, balance: amount } = pointedOutput;

  if (typeof balance[asset] === 'undefined') {
    balance[asset] = '0';
  }
  balance[asset] = assetUtils.fromKalapas(amount);

  return balance;
}

/**
 * Find the contract execution of the first vote for all vote types
 *
 * @param {Object} params
 * @param {Array} params.transactions - the user's transactions
 * @param {Array} params.contractHistory
 * @param {number} params.currentBlock
 * @param {number} params.snapshotBlock
 * @param {number} params.tallyBlock
 */
export function findVotes({
  transactions = [],
  contractHistory = [],
  currentBlock,
  snapshotBlock,
  tallyBlock,
} = {}) {
  if (
    !transactions.length ||
    !contractHistory.length ||
    !currentBlock ||
    !snapshotBlock ||
    !tallyBlock ||
    snapshotBlock > currentBlock
  ) {
    return {
      nomination: null,
      allocation: null,
      payout: null,
    };
  }
  const nominationBlock = snapshotBlock + (tallyBlock - snapshotBlock) / 2;
  let nomination = null;
  let allocation = null;
  let payout = null;

  const higherThanSnapshot = (tx) => currentBlock - tx.confirmations + 1 > snapshotBlock;
  const txsFromSnapshot = transactions.filter(higherThanSnapshot);
  const historyFromSnapshot = contractHistory.filter(higherThanSnapshot);

  // contract history is ordered old to new, find the first valid vote
  for (let i = 0; i < historyFromSnapshot.length; i++) {
    const execution = historyFromSnapshot[i];
    const executionBlock = currentBlock - execution.confirmations + 1;

    const validNomination = execution.command === 'Nomination' && executionBlock <= nominationBlock;
    const validAllocation =
      execution.command === 'Allocation' &&
      executionBlock > nominationBlock &&
      executionBlock <= tallyBlock;
    const validPayout =
      execution.command === 'Payout' &&
      executionBlock > nominationBlock &&
      executionBlock <= tallyBlock;

    if (validNomination || validAllocation || validPayout) {
      if (txsFromSnapshot.some((tx) => tx.txHash === execution.txHash)) {
        if (!nomination && execution.command === 'Nomination') {
          nomination = execution;
        }
        if (!allocation && execution.command === 'Allocation') {
          allocation = execution;
        }
        if (!payout && execution.command === 'Payout') {
          payout = execution;
        }
      }
    }

    // found all
    if (nomination && allocation && payout) {
      break;
    }
  }

  return {
    nomination,
    allocation,
    payout,
  };
}
