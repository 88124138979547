import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent } from './ModalContent';
import ConfirmationModalContent from './ConfirmationModalContent';
export default function ConfirmationModal({
  onConfirm,
  onCancel,
  title,
  subtitle,
  danger,
  dismissOnClickOutside = false,
  showClose,
  cancelText,
  confirmText,
  children,
}) {
  return (
    <Modal dismissOnClickOutside={dismissOnClickOutside} onDismiss={onCancel}>
      <ModalContent onClose={onCancel} showClose={showClose}>
        <ConfirmationModalContent
          title={title}
          subtitle={subtitle}
          cancelText={cancelText}
          confirmText={confirmText}
          danger={danger}
          onCancel={onCancel}
          onConfirm={onConfirm}
        >
          {children}
        </ConfirmationModalContent>
      </ModalContent>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  title: PropTypes.any,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  danger: PropTypes.any,
  dismissOnClickOutside: PropTypes.bool,
};
