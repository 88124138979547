/**
 * Checks that a commit id is a valid hash
 * @param {string} commitId
 */
export function validateCommitId(commitId) {
  return /^([0-9a-fA-F]){40}$/.test(commitId);
}

export function isDuringVote(status) {
  return ['contestant', 'candidate'].includes(status);
}
export function getExplorerUrl(currentPhase) {
  const { interval, phase } = currentPhase || {};
  return interval && phase ? `/governance/${interval}/${phase}` : '/governance';
}
