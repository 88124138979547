import { useMemo } from 'react';
import { Decimal } from 'decimal.js';
import { ZenJsUtils } from '@zen/common-app-parts';
import { calcTotalZpKalapasByHeight } from '@zen/common-utils';

export default function useConstants({ chain, headers = 0, config } = {}) {
  return useMemo(() => {
    const contractIdCgp =
      chain === 'main' ? config.contract.cgp.main.fund : config.contract.cgp.test.fund;
    const contractIdVote =
      chain === 'main' ? config.contract.cgp.main.voting : config.contract.cgp.test.voting;
    const addressCgp = ZenJsUtils.getContractAddress(chain, contractIdCgp);
    const addressVote = ZenJsUtils.getContractAddress(chain, contractIdVote);
    const intervalLength = chain === 'main' ? 10000 : 100;
    const votingIntervalLength = intervalLength / 10;
    const currentInterval = Math.ceil((headers + 1) / intervalLength);
    const intervalDisplay = chain === 'main' ? currentInterval - 24 : currentInterval - 1413;
    const snapshotBlock = (currentInterval - 1) * intervalLength + intervalLength * 0.9;
    const tallyBlock = currentInterval * intervalLength;
    const nominationBlock = snapshotBlock + votingIntervalLength / 2;
    const thresholdZp = new Decimal(
      calcTotalZpKalapasByHeight({ genesis: config.genesis.kalapas[chain], height: snapshotBlock })
    )
      .dividedBy(100000000)
      .times(config.cgp.thresholdPercentage)
      .dividedBy(100);

    const voteStatus =
      headers >= snapshotBlock && headers < nominationBlock
        ? 'nomination'
        : headers >= nominationBlock && headers < tallyBlock
        ? 'voting'
        : 'between';

    return {
      contractIdCgp,
      contractIdVote,
      addressCgp,
      addressVote,
      intervalLength,
      votingIntervalLength,
      currentInterval,
      intervalDisplay,
      snapshotBlock,
      tallyBlock,
      nominationBlock,
      voteStatus,
      thresholdZp: thresholdZp.toFixed(Math.min(thresholdZp.decimalPlaces(), 8)),
    };
  }, [
    chain,
    config.cgp.thresholdPercentage,
    config.contract.cgp.main.fund,
    config.contract.cgp.main.voting,
    config.contract.cgp.test.fund,
    config.contract.cgp.test.voting,
    config.genesis.kalapas,
    headers,
  ]);
}
