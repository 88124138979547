import { faExclamationCircle, faInfoCircle, faCheckCircle } from '@fortawesome/pro-light-svg-icons';

export function getErrorIcon(type) {
  switch (type) {
    case 'warning':
      return faExclamationCircle;
    case 'success':
      return faCheckCircle;
    case 'info':
      return faInfoCircle;
    default:
      return faInfoCircle;
  }
}
