import React, { useState } from 'react';
import { ConfirmationModal, Title } from '@zen-common/components-base';
import { useInterval } from '@zen/common-react-hooks';

export const AutoLogoutModal = ({ onStayLoggedIn, onLogout }) => {
  const [seconds, setSeconds] = useState(10);

  useInterval(() => {
    if (seconds >= 1) {
      setSeconds(seconds - 1);
    } else {
      onLogout();
      return null;
    }
  }, 1000);

  return (
    <ConfirmationModal
      showClose={false}
      onCancel={onLogout}
      onConfirm={onStayLoggedIn}
      cancelText="Logout"
      confirmText="Stay Logged In"
      title={<Title>Auto logout</Title>}
    >
      Due to inactivity, you will be logged out in {seconds} seconds
    </ConfirmationModal>
  );
};
