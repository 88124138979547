import React from 'react';
import { mount, route, compose, withView, redirect, map } from 'navi';
import { View } from 'react-navi';
import { PageLayout, OnBoarding } from '@zen/common-app-parts';
import MainLayout from './components/layouts/MainLayout';
import ContractsNav from './components/navs/ContractsNav';
import WalletNav from './components/navs/WalletNav';
import SettingsNav from './components/navs/SettingsNav';
import VotingNav from './components/navs/VotingNav';
import PATHS from './paths';
import UnlockWallet from './pages/UnlockWallet';
import AccountSettings from './pages/Settings/AccountSettings';
import ConnectivitySettings from './pages/Settings/ConnectivitySettings';
import Balances from './pages/Balances';
import Receive from './pages/Receive';
import Send from './pages/Send';
import TxHistory from './pages/TxHistory';
import ActiveContracts from './pages/ActiveContracts';
import CGP from './pages/CGP';
import Governance from './pages/Governance';

const SHOULD_PROTECT = process.env.REACT_APP_UNPROTECTED !== 'yes';
const titleTemplate = (title) => `Zen Protocol Wallet | ${title}`;

export default mount({
  '/': redirect(PATHS.DEFAULT),
  '/unlock': map((request, context) => {
    const { isLoggedIn, hasPassword } = context.user;
    const redirectTo = request.params.redirectTo;
    const redirectToDecoded = decodeURIComponent(redirectTo);
    if (isLoggedIn) {
      if (!redirectTo || (redirectTo && redirectToDecoded.startsWith(PATHS.UNLOCK))) {
        return redirect(PATHS.DEFAULT);
      }
      return redirect(redirectToDecoded);
    } else if (!hasPassword) {
      return redirect(PATHS.DEFAULT);
    }

    return route({
      title: titleTemplate('Unlock Wallet'),
      view: (
        <MainLayout>
          <UnlockWallet />
        </MainLayout>
      ),
    });
  }),
  '/wipe-seed': map((request, context) => {
    context.user.wipeSeed();
    context.wiping = true;
    return redirect(PATHS.ONBOARDING);
  }),
  '/chain-switched': map(() => {
    return redirect(PATHS.DEFAULT);
  }),
  '/app': compose(
    withView(() => (
      <MainLayout>
        <View />
      </MainLayout>
    )),
    mount({
      '/onboarding/create': map((request, context) => {
        context.type = 'create';
        return redirect(PATHS.ONBOARDING);
      }),
      '/onboarding/import': map((request, context) => {
        context.type = 'import';
        return redirect(PATHS.ONBOARDING);
      }),
      '/onboarding': map((request, context) => {
        const wiping = context.wiping;
        const type = context.type;
        context.wiping = false;
        context.type = null;
        // redirect if user has a seed
        const { hasPassword, isLoggedIn } = context.user;
        return wiping || !hasPassword
          ? route({
              title: titleTemplate('Onboarding'),
              view: <OnBoarding type={type} />,
            })
          : isLoggedIn
          ? redirect(PATHS.DEFAULT)
          : redirect(PATHS.UNLOCK);
      }),
      '/settings': compose(
        withView(() => (
          <PageLayout nav={<SettingsNav />}>
            <View />
          </PageLayout>
        )),
        mount({
          '/': map((request, context) => {
            // if not logged in redirect to connectivity, otherwise to account
            const { isLoggedIn } = context.user;
            return isLoggedIn
              ? redirect(PATHS.SETTINGS_ACCOUNT)
              : redirect(PATHS.SETTINGS_CONNECTIVITY);
          }),
          '/account': withRedirectProtectedPage(
            route({
              title: titleTemplate('Account Settings'),
              view: <AccountSettings />,
            })
          ),
          '/connectivity': route({
            title: titleTemplate('Node Connectivity Settings'),
            view: <ConnectivitySettings />,
          }),
        })
      ),
      '/wallet': compose(
        withView(() => (
          <PageLayout nav={<WalletNav />}>
            <View />
          </PageLayout>
        )),
        mount({
          '/': redirect(PATHS.WALLET_BALANCES),
          '/portfolio': withRedirectProtectedPage(
            route({
              title: titleTemplate('Portfolio'),
              view: <Balances />,
            })
          ),
          '/receive': withRedirectProtectedPage(
            route({
              title: titleTemplate('Receive'),
              view: <Receive />,
            })
          ),
          '/send': withRedirectProtectedPage(
            map((request) => {
              const { fields } = request.params;
              return redirectIfLoggedOut({
                route: route({
                  title: titleTemplate('Send'),
                  view: <Send fields={fields ? decodeURIComponent(fields) : null} />,
                }),
              });
            })
          ),
          '/history': withRedirectProtectedPage(
            route({
              title: titleTemplate('Tx History'),
              view: <TxHistory />,
            })
          ),
        })
      ),
      '/contracts': compose(
        withView(() => (
          <PageLayout nav={<ContractsNav />}>
            <View />
          </PageLayout>
        )),
        mount({
          '/': redirect(PATHS.CONTRACTS_ACTIVE),
          '/active': withRedirectProtectedPage(
            route({
              title: titleTemplate('Active Contracts'),
              view: <ActiveContracts />,
            })
          ),
        })
      ),
      '/voting': compose(
        withView(() => (
          <PageLayout nav={<VotingNav />}>
            <View />
          </PageLayout>
        )),
        mount({
          '/': redirect(PATHS.VOTING_CGP),
          '/governance': withRedirectProtectedPage(
            route({
              title: titleTemplate('Governance'),
              view: <Governance />,
            })
          ),
          '/cgp': withRedirectProtectedPage(
            route({
              title: titleTemplate('Common Goods Pool'),
              view: <CGP />,
            })
          ),
        })
      ),
    })
  ),
});

/**
 * A function to protect sensitive pages
 * it will redirect to unlock if the user is not logged in
 * @param {*} matcher a matcher which returns a route
 */
function withRedirectProtectedPage(matcher) {
  return map((request, context) => {
    if (!SHOULD_PROTECT) {
      return matcher;
    }

    const { isLoggedIn, hasPassword } = context.user;
    return isLoggedIn
      ? matcher
      : hasPassword
      ? redirect(PATHS.UNLOCK + getRedirectTo(request))
      : redirect(PATHS.ONBOARDING);
  });
}

/**
 * Redirect to path (default = unlock) if has password but not logged in, otherwise continue to route
 */
function redirectIfLoggedOut({ route, path = PATHS.UNLOCK } = {}) {
  return map((request, context) => {
    const { isLoggedIn, hasPassword } = context.user;
    return SHOULD_PROTECT && hasPassword && !isLoggedIn
      ? redirect(path + getRedirectTo(request))
      : route;
  });
}

function getRedirectTo(request) {
  return `?redirectTo=${encodeURIComponent(request.mountpath + request.search)}`;
}
