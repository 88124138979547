import React from 'react';
import styled from 'styled-components';
import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { getTransition } from '../../utils/styles/mixins';
import tu from '../../utils/themeUtils';
import FaSvg from '../helpers/FaSvg';
import Box from '../Box';

export default function SideNavToggler({ isOpen, onClick, className, ...props }) {
  return (
    <StyledSideNavToggler>
      <FaSvg
        color="label"
        icon={isOpen ? faTimes : faBars}
        className={tu.cx(className, 'sidenav-toggle-icon', isOpen && 'is-open')}
        onClick={onClick}
        {...props}
      />
    </StyledSideNavToggler>
  );
}

const StyledSideNavToggler = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .sidenav-toggle-icon {
    cursor: pointer;
    font-size: 1.6rem;
    transform: rotate(0deg);
    ${getTransition()}
    &.is-open {
      transform: rotate(90deg);
    }
  }
`;
