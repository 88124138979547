export function getErrorColor(type) {
  switch (type) {
    case 'warning':
      return 'danger';
    case 'success':
      return 'primary';
    case 'info':
      return 'primary';
    default:
      return 'primary';
  }
}
