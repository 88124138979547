import { css } from 'styled-components';
import { themeUtils as tu } from '@zen-common/components-base';

export default function getMarginBottom(props) {
  return css(
    tu.mq({
      marginBottom: tu.space([0, null, 'xl'])(props),
    })
  );
}
