import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const StyledLinkButton = styled(Button).attrs({
  bg: 'transparent',
  px: '0',
  color: 'primary',
  fontSize: 'inherit',
  borderRadius: '0',
})`
  &:hover {
    text-decoration: underline;
  }
  &[disabled] {
    background: transparent;
    pointer-events: none;
  }
`;

const LinkButton = p => <StyledLinkButton {...p} />;

export default LinkButton;
