import { useCallback } from 'react';
import { useNavigation } from 'react-navi';
import { WalletStore } from '@zen/common-app-parts';
import PATHS from '../../../paths';

/**
 * A hook to help navigate away when not authenticated
 * it will check if the user is logged in to the wallet or have a public key
 *
 * @returns {preventAction} preventAction - a function to handle auth redirects
 */
export default function useAuthRedirect() {
  const navigation = useNavigation();
  const {
    state: { isLoggedIn, hasPassword },
  } = WalletStore.useStore();

  /**
   * Handle the not authenticated state
   * @function
   * @typedef {Function} preventAction
   * @returns {boolean} false if logged in, otherwise true
   */
  const preventAction = useCallback(() => {
    if (isLoggedIn) {
      return false;
    } else if (hasPassword) {
      navigation.navigate(PATHS.UNLOCK);
    } else {
      navigation.navigate(PATHS.ONBOARDING);
    }

    return true;
  }, [hasPassword, isLoggedIn, navigation]);

  return preventAction;
}
