import PropTypes from 'prop-types';
import createStore from '../../utils/createStore';
import ErrorReporter from './ErrorReporter';

const { Provider, useStore } = createStore({
  useStoreHook: ({ config }) => ErrorReporter(config),
  contextDisplayName: 'ErrorReportingStoreContext',
});
Provider.propTypes = {
  config: PropTypes.object,
};

export default {
  /**
   * @type {ErrorReportingProvider}
   */
  Provider,
  /**
   * @type {UseErrorReportingStore}
   */
  useStore,
};

/**
 * @typedef {({config: Object}) => void} ErrorReportingProvider
 */

/**
 * @typedef {() => ({report: import('./ErrorReporter').ReportFunc})} UseErrorReportingStore
 */
