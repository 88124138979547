const theme = {
  forms: {
    maxWidth: 678,
  },
  colors: {
    text: '#f2f2f2',
    textDisabled: '#343d45',
    label: '#89939C',
    title: '#f2f2f2',
    link: '#83d3ee',
    primary: '#097aff',
    secondary: '#1b2024',
    success: '#46c897',
    warning: '#f79e1c',
    danger: '#ee3f3f',
    borderLight: '#14171a',
    borderDark: '#1b2024',
    bg: '#000000',
    bg1: '#08090a',
    bg2: '#0f1113',
    bg3: '#14171a',
    bg4: '#1b2024',
    bg5: '#343d45',
    bg6: '#89939c',
    bg7: '#b4c1cc',
  },
  space: [0, 4, 6, 8, 10, 12, 16, 20, 24, 32, 40, 48, 64],
  sizes: [0, 8, 16, 20, 24, 32, 42, 64],
  fontSizes: ['0.6875rem', '0.75rem', '0.875rem', '1rem', '1.25rem', '1.5rem'],
  lineHeights: ['0.6875rem', '0.75rem', '0.875rem', '1rem', '1.25rem', '1.4rem', '1.67rem'],
  borderWidths: [0, 1],
  radii: [0, 2, 4, 16, '50%', '100%'],
  reactDatepicker: {
    daySize: [36, 40],
    datepickerBackground: '#14171a',
    dayColor: '#f2f2f2',
    dayHoverColor: '#f2f2f2',
    daySelectedColor: '#89939c',
    daySelectedHoverColor: '#f2f2f2',
    dayHoverRangeColor: '#f2f2f2',
    daySelectedFirstOrLastColor: '#f2f2f2',
    dayBackground: '#0f1113',
    dayHoverBackground: '#1b2024',
    daySelectedBackground: '#1b2024',
    daySelectedHoverBackground: '#1b2024',
    dayHoverRangeBackground: '#1b2024',
    daySelectedFirstOrLastBackground: '#097aff',
    dayBorderColor: '#343d45',
    daySelectedBorderColor: '#343d45',
    dayHoverRangeBorderColor: '#343d45',
    daySelectedFirstOrLastBorderColor: '#343d45',
    dayAccessibilityBorderColor: '#343d45',
    inputColor: '#f2f2f2',
    inputBackground: '#14171a',
    datepickerSelectDateArrowIconColor: '#14171a',
    navButtonBackground: '#14171a',
    navButtonBorder: '1px solid #343d45',
    inputLabelBorder: '1px solid #14171a',
    inputActiveBoxShadow: 'inset 0px -3px 0 #343d45',
    dayLabelColor: '#89939c',
    dateRangeGridTemplateColumns: '1fr 24px 1fr',
    dateRangeStartDateInputPadding: '0 0 0 48px',
    dateRangeEndDateInputPadding: '0 0 0 48px',
    dateRangeBorderRadius: '-1',
    colors: {
      darcula: '#f2f2f2',
      primaryColor: '#14171a',
    },
  },
};

// aliases ---
theme.space.xxs = theme.space[2];
theme.space.xs = theme.space[4];
theme.space.sm = theme.space[5];
theme.space.smd = theme.space[6]; //16px
theme.space.md = theme.space[7]; // 20px
theme.space.mlg = theme.space[8]; // 24px
theme.space.lg = theme.space[9]; //32px
theme.space.xl = theme.space[10]; // 40px
theme.space.xxl = theme.space[12];

theme.fontSizes.xs = theme.fontSizes[0]; // 11px
theme.fontSizes.sm = theme.fontSizes[1]; // 12px
theme.fontSizes.md = theme.fontSizes[2]; // 14px
theme.fontSizes.lg = theme.fontSizes[3]; // 16px
theme.fontSizes.xl = theme.fontSizes[4]; // 20px
theme.fontSizes.xxl = theme.fontSizes[5]; // 24px

// specialized props
theme.sizes.formsMaxWidth = theme.forms.maxWidth;

export default theme;
