import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent } from './ModalContent';
import AlertModalContent from './AlertModalContent';

/**
 * An alert modal which contain a title and a message
 *
 * @export
 * @param {Object} props
 * @param {Function} props.onDismiss - a callback to ball when dismissing the alert
 * @param {string} props.title
 * @param {boolean} props.dismissOnClickOutside
 * @param {boolean} props.showClose
 * @param {string} props.dismissText - the text of the dismiss button
 * @param {TYPES} props.type - the type of the alert
 */
export default function Alert({
  onDismiss,
  title,
  dismissOnClickOutside = true,
  showClose,
  dismissText = 'Close',
  // eslint-disable-next-line no-unused-vars
  type = AlertModalContent.TYPE.DEFAULT, // to be used in the future
  children,
}) {
  return (
    <Modal dismissOnClickOutside={dismissOnClickOutside} onDismiss={onDismiss}>
      <ModalContent onClose={onDismiss} showClose={showClose}>
        <AlertModalContent title={title} dismissText={dismissText} onDismiss={onDismiss}>
          {children}
        </AlertModalContent>
      </ModalContent>
    </Modal>
  );
}

Alert.propTypes = {
  onDismiss: PropTypes.func,
  title: PropTypes.any,
  dismissOnClickOutside: PropTypes.bool,
  showClose: PropTypes.bool,
  dismissText: PropTypes.string,
};

Alert.TYPE = AlertModalContent.TYPE;
