import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Form,
  FormBody,
  FormGroup,
  PasswordInput,
  Centered,
  Title,
  Paragraph,
  Button,
  LinkButton,
  LoadingOr,
  Label,
  LabelText,
  InputMessage,
  themeUtils as tu,
  cssMixins,
  useModal,
} from '@zen-common/components-base';
import WalletStore from '../../stores/WalletStore';
import ConfirmWipeModal from '../modals/ConfirmWipeModal';

/**
 * @param {Object} props
 * @param {Function} props.onWipe - a function to call when wipe is clicked
 */
export default function UnlockWallet({ onWipe }) {
  const {
    actions: { login },
  } = WalletStore.useStore();
  const { show, hide } = useModal();
  const PasswordInputRef = useRef();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(false);

  const isDisabled = progress || !password;
  const passwordInputValidState = error !== '' ? false : undefined;

  useEffect(() => {
    PasswordInputRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);
    try {
      await login(password);
    } catch (error) {
      setError(error.message);
      setProgress(false);
      PasswordInputRef.current.focus();
    }
  };

  const handleOnChange = (e) => {
    setPassword(e.target.value.trim());
    setError('');
  };

  const onForgotPassword = (e) => {
    e.preventDefault();
    show(() => (
      <ConfirmWipeModal
        onCancel={hide}
        onConfirm={() => {
          hide();
          onWipe && onWipe();
        }}
      />
    ));
  };

  return (
    <AbsoluteCentered>
      <FormContainer>
        <Title mb="4">Welcome Back!</Title>
        <Paragraph color="label" mb="lg" fontSize="md">
          Please enter your password to continue
        </Paragraph>
        <Form onSubmit={handleSubmit} disabled={progress}>
          <FormBody>
            <FormGroup mb="smd">
              <Label htmlFor="UnlockWallet-Password">
                <LabelText>Password</LabelText>
              </Label>
              <PasswordInput
                id="UnlockWallet-Password"
                ref={PasswordInputRef}
                valid={passwordInputValidState}
                variant="block"
                onChange={handleOnChange}
                autoComplete="current-password"
                disabled={progress}
              />
              <InputMessage data-testid="UnlockWallet-error-message">{error}</InputMessage>
            </FormGroup>
            <FormGroup>
              <Button type="submit" sizeVariant="large" width="100%" disabled={isDisabled}>
                <LoadingOr loading={progress} data-testid="UnlockWallet-loading">
                  Unlock Wallet
                </LoadingOr>
              </Button>
            </FormGroup>
            <FormGroup mb="0">
              <LinkButton fontSize="2" onClick={onForgotPassword} disabled={progress}>
                Forgot password?
              </LinkButton>
              <Paragraph mb="0" color="label" fontSize="md">
                Import your wallet again or create a new one
              </Paragraph>
            </FormGroup>
          </FormBody>
        </Form>
      </FormContainer>
    </AbsoluteCentered>
  );
}
UnlockWallet.propTypes = {
  onAction: PropTypes.func,
  error: PropTypes.string,
  progress: PropTypes.bool,
  logo: PropTypes.element,
};

const AbsoluteCentered = styled(Centered)`
  ${cssMixins.fadeIntoView}
  flex-grow: 1;
  flex-shrink: 0;
`;

export const UnlockWalletActions = {
  SUBMIT: 'submit',
  FORGOT_PASSWORD: 'forgot',
  PASSWORD_CHANGE: 'password-change',
};

const FormContainer = styled(Box)`
  padding: ${tu.space('mlg')};
  ${cssMixins.containerXPadding}
  ${tu.mq({
    minWidth: ['100%', null, '450px'],
  })}
`;
