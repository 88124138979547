import { useState, useLayoutEffect, useMemo, useCallback } from 'react';

const PAGE_SIZES = [5, 10, 20, 50, 100];

/**
 * A hook to get the table pagination props
 *
 * @param {Object} props
 * @param {number} props.itemsCount - the number of the items in the table
 * @param {number} props.initialPageSize
 * @param {boolean} props.manual - set the pagination to be manually controlled
 * @returns {PaginationState}
 */
export default function usePagination({ itemsCount, initialPageSize = 10, manual = false } = {}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [hasPagination, setHasPagination] = useState(false);
  const pageSizeOptions = useMemo(() => {
    const indexOfFirstEqualOrBigger = PAGE_SIZES.findIndex((size) => size >= itemsCount);
    return PAGE_SIZES.slice(
      0,
      itemsCount >= PAGE_SIZES[PAGE_SIZES.length - 1]
        ? PAGE_SIZES.length
        : indexOfFirstEqualOrBigger + 1
    );
  }, [itemsCount]);

  useLayoutEffect(() => {
    itemsCount <= pageSizeOptions[0] ? setHasPagination(false) : setHasPagination(true);
  }, [itemsCount, pageSizeOptions]);

  const handlePageSizeChange = useCallback(
    (newPageSize) => {
      const curPageSize = pageSize;
      setPageSize(newPageSize);
      setPage((curPage) => Math.floor((curPageSize * curPage) / newPageSize));
    },
    [pageSize]
  );

  /**
   * @typedef {Object} PaginationState
   * @property {boolean} manual
   * @property {boolean} showPagination
   * @property {Array<number>} pageSizeOptions
   * @property {number} pageSize
   * @property {number} pages
   * @property {Function} setPageSize
   * @property {number} page
   * @property {Function} onPageChange
   */
  const state = {
    manual,
    showPagination: hasPagination,
    pageSizeOptions,
    pageSize,
    pages: Math.ceil(itemsCount / pageSize),
    setPageSize: handlePageSizeChange,
    ...(manual && { page, onPageChange: setPage }),
  };

  return state;
}
