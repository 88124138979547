import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { Box, FaSvg, themeUtils as tu } from '@zen-common/components-base';
import { assetUtils, numberUtils } from '@zen/common-utils';
import { ExplorerLink } from '@zen/common-app-parts';
import InfoBox from '../../../components/InfoBox';

/**
 * @param {Object} props
 * @param {import('../store/CgpStore').CgpStore} props.store
 * @param {{asset: string}} props.balance
 */
export default function InfoBoxes({ store, balance }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen((s) => !s);
  const currentBalanceLength = Object.keys(store.cgpCurrentBalance).length;
  const snapshotBalanceLength = Object.keys(store.cgpSnapshotBalance).length;

  return (
    <InfoBoxesContainer isOpen={isOpen}>
      <StyledInfoBox label="Interval" onClick={toggle}>
        {numberUtils.getOrdinal(store.intervalDisplay)}
        <MobileToggler icon={!isOpen ? faAngleDown : faAngleUp} color="label" />
      </StyledInfoBox>
      {store.voteStatus === 'between' ? (
        <StyledInfoBox label="Potential Vote Weight">
          {assetUtils.getAmountDisplay('00', assetUtils.getAssetBalance('00', balance), 2)}
        </StyledInfoBox>
      ) : (
        <StyledInfoBox label="Vote Weight at Snapshot Block">
          {assetUtils.getAmountDisplay('00', store.snapshotZpBalance, 2)}
        </StyledInfoBox>
      )}
      <StyledInfoBox label="CGP Current Allocation">
        {assetUtils.getAmountDisplay('00', store.cgpCurrentAllocation.zp, 2)}
      </StyledInfoBox>
      {store.voteStatus === 'between' ? (
        <StyledInfoBox label="CGP Current Balance">
          {assetUtils.getAmountDisplay('00', store.cgpCurrentBalance['00'], 2)}
          {currentBalanceLength > 1 && (
            <HasMoreLinkWrapper>
              <StyledExplorerLink path={`/contracts/${store.addressCgp}`}>
                + {currentBalanceLength - 1} more
              </StyledExplorerLink>
            </HasMoreLinkWrapper>
          )}
        </StyledInfoBox>
      ) : (
        <StyledInfoBox label="Funds in CGP at Snapshot">
          {assetUtils.getAmountDisplay('00', store.cgpSnapshotBalance['00'], 2)}
          {snapshotBalanceLength > 1 && (
            <HasMoreLinkWrapper>
              <StyledExplorerLink path={`/contracts/${store.addressCgp}`}>
                + {snapshotBalanceLength - 1} more
              </StyledExplorerLink>
            </HasMoreLinkWrapper>
          )}
        </StyledInfoBox>
      )}
    </InfoBoxesContainer>
  );
}

const InfoBoxesContainer = styled(Box)`
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  ${(p) =>
    css(
      tu.mq({
        flexDirection: ['column', null, 'row'],
        height: [p.isOpen ? 'auto' : tu.space('sm'), null, 'auto'],
        marginBottom: [0, null, `calc(-1 * ${tu.space('smd')(p)})`],
        paddingBottom: ['2px', null, '0'], // for cut out text hidden by the overflow
      })
    )}
`;

const MobileToggler = styled(FaSvg)`
  font-size: 24px;
  position: absolute;
  right: 0;
  top: -6px;
  ${tu.mq({ display: ['block', null, 'none'] })}
`;

const StyledInfoBox = styled(InfoBox)`
  position: relative;
  ${css(
    tu.mq({
      width: ['100%', null, 'auto'],
      marginRight: ['0', null, tu.space(11)],
      marginBottom: [tu.space('sm'), null, tu.space('smd')],
    })
  )}
  &:first-child {
    ${tu.mq({
      cursor: ['pointer', null, 'default'],
    })}
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const HasMoreLinkWrapper = styled(Box)`
  margin-left: ${tu.space('smd')};
  display: inline-block;
`;
const StyledExplorerLink = styled(ExplorerLink)`
  font-size: ${tu.fontSize(1)};
  text-decoration: none;
  border-radius: ${tu.radius(2)};
  background-color: ${tu.color('bg4')};
  padding: 2px;
  border-width: 2px;
  border-color: transparent;
  color: ${tu.color('label')};
  position: relative;
  ${tu.mq({
    top: ['0', null, '-2px'],
  })}
`;
