/* eslint-disable react/display-name */
import * as React from 'react';
import { useFileDropZone, ConfirmationModalContent, Title } from '@zen-common/components-base';
import {
  WalletStore,
  SettingsStore,
  ConfirmPublishTxModalContent,
  PasswordRequiredModalContent,
} from '@zen/common-app-parts';
import { ValueDisplay } from '@zen/common-utils';
import { useUnmountedRef } from '@zen/common-react-hooks';
import usePreventAction from '../../../../utils/usePreventAction';
import ActivateStore from '../../../../stores/ActivateStore';
import * as actions from '../../../../stores/ActivateStore/actions';
import ActivateFormModalContent from './ActivateFormModalContent';

export default function ActivateContractModalProcess({ onCancel, onSuccess, onError }) {
  const store = ActivateStore.useStore();
  const unmounted = useUnmountedRef();
  const prevStatus = React.useRef();
  const [status, setStatus] = React.useState('form');
  /** @type {[numberOfBlocks: ValueDisplay]} */
  const [numberOfBlocks, setNumberOfBlocks] = React.useState(ValueDisplay.create());
  /** @type {[rLimit: ValueDisplay]} */
  const [rLimit, setRLimit] = React.useState(ValueDisplay.create('2723280'));
  const [error, setError] = React.useState('');
  const unsignedTx = React.useRef();
  const [signedTx, setSignedTx] = React.useState();
  const { preventAction } = usePreventAction();
  const wallet = WalletStore.useStore();
  const {
    state: {
      settings: { nodeUrl },
    },
  } = SettingsStore.useStore();
  const fileDropZoneProps = useFileDropZone({ accept: '.fst' });

  const setStatusAndPrev = (s) => {
    prevStatus.current = status;
    setStatus(s);
  };

  const handleNumberOfBlocksChange = (display) =>
    setNumberOfBlocks(numberOfBlocks.onDisplayChange(display));
  const handleRLimitChange = (display) => setRLimit(rLimit.onDisplayChange(display));

  const handleFormCancel = () => {
    onCancel();
  };
  const handleFormContinue = () => {
    if (!fileDropZoneProps.acceptedFiles.length) return;
    if (preventAction()) return;

    const reader = new FileReader();
    reader.onerror = () => {
      setError('There was an error reading the file content');
      setStatusAndPrev('error');
    };
    reader.onload = async () => {
      const code = reader.result;
      try {
        unsignedTx.current = await actions.activate({
          dispatch: store.dispatch,
          code,
          limit: Number(rLimit.safeValue),
          numberOfBlocks: Number(numberOfBlocks.safeValue),
          wallet,
        });
        if (!unmounted.current) {
          setStatusAndPrev('password');
        }
      } catch (error) {
        if (!unmounted.current) {
          setError(error.message);
          setStatusAndPrev('error');
        }
      }
    };
    reader.readAsText(fileDropZoneProps.acceptedFiles[0]);
  };

  const handlePasswordBack = () => {
    unsignedTx.current = null;
    setStatusAndPrev('form');
  };
  const handlePasswordContinue = async (password) => {
    if (preventAction() || !unsignedTx.current) return;

    try {
      const tx = await actions.sign({
        dispatch: store.dispatch,
        password,
        tx: unsignedTx.current,
        wallet,
      });
      if (!unmounted.current) {
        setSignedTx(tx);
        setStatusAndPrev('confirm-after-sign');
      }
    } catch (error) {
      if (!unmounted.current) {
        setError(error.message);
        setStatusAndPrev('error');
      }
    }
  };

  const handleConfirmAfterSignBack = () => {
    unsignedTx.current = null;
    setSignedTx(null);
    setStatusAndPrev('form');
  };
  const handleConfirmAfterSignContinue = async () => {
    if (preventAction() || !signedTx) return;

    try {
      await actions.publish({ dispatch: store.dispatch, nodeUrl, tx: signedTx });
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  const handleErrorBack = () => {
    setStatus(prevStatus.current);
  };
  const handleErrorClose = () => {
    onCancel();
  };

  if (status === 'form') {
    return (
      <ActivateFormModalContent
        progress={store.state.progress}
        fileDropZoneProps={fileDropZoneProps}
        numberOfBlocks={numberOfBlocks}
        onNumberOfBlocksChange={handleNumberOfBlocksChange}
        rLimit={rLimit}
        onRLimitChange={handleRLimitChange}
        onCancel={handleFormCancel}
        onContinue={handleFormContinue}
      />
    );
  }

  if (status === 'password') {
    return (
      <PasswordRequiredModalContent
        cancelText="Back"
        onCancel={handlePasswordBack}
        onSuccess={handlePasswordContinue}
      />
    );
  }

  if (status === 'confirm-after-sign') {
    if (!signedTx) return null;

    return (
      <ConfirmPublishTxModalContent
        progress={store.state.progress}
        title="Confirm TX before publishing"
        tx={signedTx}
        onBack={handleConfirmAfterSignBack}
        onConfirm={handleConfirmAfterSignContinue}
      />
    );
  }

  if (status === 'error') {
    return (
      <ConfirmationModalContent
        title={<Title>An error has occurred</Title>}
        cancelText="Back"
        confirmText="Close"
        onCancel={handleErrorBack}
        onConfirm={handleErrorClose}
      >
        {error}
      </ConfirmationModalContent>
    );
  }

  return null;
}
