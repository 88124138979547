import styled from 'styled-components';
import { Box, themeUtils as tu, cssMixins } from '@zen-common/components-base';

const MessageContainerPadding = styled(Box)`
  @media screen and (min-width: ${tu.breakpoints.md}) {
    ${cssMixins.containerXPadding}
  }
  flex-grow: 1;
  flex-shrink: 0;
`;

export default MessageContainerPadding;
