import styled from 'styled-components';
import { system } from 'styled-system';

const StyledDot = styled.span`
  border-radius: 50%;
  display: inline-block;
  ${system({
    color: {
      property: 'backgroundColor',
      scale: 'colors',
    },
  })}
  ${system({
    size: {
      properties: ['height', 'width', 'minHeight', 'minWidth'],
      scale: 'sizes',
    },
  })}
`;

StyledDot.defaultProps = {
  color: 'success',
  size: 2,
};

export default StyledDot;
