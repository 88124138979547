import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { typography, color, space, compose } from 'styled-system';
import getFontSize from './modules/getFontSize';

const headerBaseStyle = (p) => css`
  letter-spacing: 0.2px;
  font-weight: 500;
  font-size: ${getFontSize(p)};
  ${compose(space, color, typography)}
`;

const H1 = styled.h1`
  ${headerBaseStyle}
`;

const H2 = styled.h2`
  ${headerBaseStyle}
`;

const H3 = styled.h3`
  ${headerBaseStyle}
`;

const H4 = styled.h4`
  ${headerBaseStyle}
`;

const H5 = styled.h5`
  ${headerBaseStyle}
`;

const components = [H1, H2, H3, H4, H5];
const getHeaderComponent = (size) => {
  const index = Number(size) - 1;

  if (index <= 0) {
    return components[0];
  }

  return index >= components.length ? components[components.length - 1] : components[index];
};

const Header = (props) => {
  const Component = getHeaderComponent(props.size);
  return <Component {...props} />;
};

Header.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Header.defaultProps = {
  size: '1',
  color: 'text',
  mt: 0,
  mb: '0.5rem',
};

export default Header;
