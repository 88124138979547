import { wordlists, generateMnemonic as _generateMnemonic, validateMnemonic } from 'bip39';
const BIP39_WORDS = wordlists.english;
const WORDS_WITH_COUNT = getWordListWithCount(BIP39_WORDS);
/**
 * Generate a 24 words mnemonic phrase
 * @returns The mnemonic phrase as a 24 places array
 */
export const generateMnemonic = () => _generateMnemonic(256).split(' ');

export const getEmptyPhrase = () => Array(24).fill('');

export const isWordValid = (word) =>
  !!(word && word.length >= 3 && BIP39_WORDS.find((bip39Word) => word === bip39Word));

/**
 * Validates that each word in the array is a valid bip39 word
 */
export const areAllWordsValid = (words) => words.every(isWordValid);

export const isWordValidAndFinal = (word) => {
  if (!word || word.length < 3) {
    return {
      valid: false,
      final: false,
    };
  }

  const count = WORDS_WITH_COUNT[word];
  return {
    valid: count > 0,
    final: count === 1,
  };
};

export const isValidBip39Phrase = (phrase = []) =>
  Array.isArray(phrase) && phrase.length === 24 && validateMnemonic(phrase.join(' '));

export const getPhraseFromClipboardContents = (clipboardContents) => {
  const parsed = parsePhraseFromClipboard(clipboardContents);
  if (!parsed || !validateMnemonic(parsed)) {
    return [];
  }
  return parsed.split(' ');
};
const parsePhraseFromClipboard = (clipboardContents) => {
  if (!clipboardContents) {
    return '';
  }
  try {
    const parsed = JSON.parse(clipboardContents);
    if (Array.isArray(parsed)) {
      return parsed.join(' ');
    }
  } catch (err) {
    //
  }
  return clipboardContents.replace(/[,\n]/g, ' ').replace(/[\s/]+/g, ' ');
};

/**
 * Get an object with words as keys and number of words which start with that key as value
 */
function getWordListWithCount(words) {
  const all = {};
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    all[word] = 1;
    // count all related
    for (let j = i + 1; j < words.length; j++) {
      if (words[j].startsWith(word)) {
        all[word] += 1;
      } else {
        break;
      }
    }
  }
  return all;
}
