import { useState, useCallback, useMemo } from 'react';
import { textUtils } from '@zen/common-utils';

const { truncateHash } = textUtils;

/**
 * @typedef {Object} UseTruncateOnBlurReturn
 * @property {string} value - the display value
 * @property {Function} onFocus - pass this to the input
 * @property {Function} onBlur - pass this to the input
 */

/**
 * Truncate a hash on blur and show it in full on focus for inputs
 *
 * @param {Object} props
 * @param {string} props.hash - the hash to truncate
 * @returns {UseTruncateOnBlurReturn}
 *          params to be used in the input
 */
export default function useTruncateOnBlur({ hash } = {}) {
  const [truncated, setTruncated] = useState(true);

  const onFocus = useCallback(() => {
    setTruncated(false);
  }, []);

  const onBlur = useCallback(() => {
    setTruncated(true);
  }, []);

  const value = useMemo(() => (truncated ? truncateHash(hash) : hash), [hash, truncated]);

  return { value, onFocus, onBlur };
}
