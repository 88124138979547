import { useRef, useCallback } from 'react';

/**
 * Calculates isExecute once and leave it on true
 */
export default function useIsExecute({ chain, address }) {
  const isExecute = useRef(false);

  if (address && !isExecute.current) {
    isExecute.current =
      (chain === 'test' && address.startsWith('ctzn')) ||
      (chain === 'main' && address.startsWith('czen'));
  }
  if (
    (chain === 'test' && address.startsWith('tzn1')) ||
    (chain === 'main' && address.startsWith('zen1'))
  )
    isExecute.current = false;

  const reset = useCallback(() => {
    isExecute.current = false;
  }, []);

  return [isExecute.current, reset];
}
