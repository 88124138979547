import { css } from 'styled-components';

const resetButton = css`
  box-sizing: content-box;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  &:focus {
    outline: 0;
  }
`;

export default resetButton;
