import { useTxCountChanged as useTxCountChangedInner, createStore } from '@zen/common-app-parts';
import useCgpPhaseChangedInner from './useCgpPhaseChanged';
import useGovernancePhaseChanged from './useGovernancePhaseChanged';

const TxCount = createStore({
  useStoreHook: useTxCountChangedInner,
  contextDisplayName: 'TxCountChangedContext',
});
const CgpPhase = createStore({
  useStoreHook: useCgpPhaseChangedInner,
  contextDisplayName: 'CgpPhaseChangedContext',
});
const GovernancePhase = createStore({
  useStoreHook: useGovernancePhaseChanged,
  contextDisplayName: 'GovernancePhaseChangedContext',
});

export const TxCountChangedStore = {
  Provider: TxCount.Provider,
  /** @type {UseChangeStore} */
  useStore: TxCount.useStore,
};
export const CgpPhaseChangedStore = {
  Provider: CgpPhase.Provider,
  /** @type {UseChangeStore} */
  useStore: CgpPhase.useStore,
};
export const GovernancePhaseChangedStore = {
  Provider: GovernancePhase.Provider,
  /** @type {UseChangeStore} */
  useStore: GovernancePhase.useStore,
};

/**
 * @typedef {() => ({
 *  state: {
 *    changed: boolean,
 *  },
 *  actions: {
 *    clear: () => void,
 *    reset: () => void,
 *  }
 * })} UseChangeStore
 */
