import * as React from 'react';
import { Title, ConfirmationModalContent } from '@zen-common/components-base';

export default function SecurityWarningModalContent({
  onCancel,
  onConfirm,
  cancelText,
  confirmText,
}) {
  return (
    <ConfirmationModalContent
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelText={cancelText}
      confirmText={confirmText}
      title={<Title>Security Warning</Title>}
    >
      If you lost your previous backup of the seed, and you are not sure who might have access to
      it, we recommend you to use another account (and transfer all current assets there). Only keep
      using this seed if you are sure no one else might have access.
    </ConfirmationModalContent>
  );
}
