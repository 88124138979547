import * as React from 'react';
import { useQuery } from 'react-query';
import { ExplorerService } from '@zen/common-app-parts';

/**
 * Fetch the relevant (current or next) interval from the explorer
 *
 * @param {Object} params
 * @param {import('../types').Chain} params.chain
 * @param {Object} params.config
 * @returns {import('react-query').QueryResult<import('../types').Phase>}
 */
export default function useFetchRelevantInterval({ chain, config }) {
  return useQuery(
    ['governance-current-next-or-prev-interval', { chain }],
    React.useCallback(async () => {
      const service = ExplorerService({ chain, config });
      const result = await service.governance.getCurrentNextOrPrevInterval();
      return result.data;
    }, [chain, config]),
    {
      retry: (failureCount, error) => {
        if (error.status === 404) return false; // meaning an interval can not be found

        if (failureCount < 3) {
          return true;
        }
        return false;
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
}
