import React, { useMemo } from 'react';
import { ExternalLink } from '@zen-common/components-base';
import ConfigStore from '../stores/ConfigStore';
import SettingsStore from '../stores/SettingsStore';

/**
 * An external link which knows which explorer to link to
 * @param {Object} props
 * @param {string} props.path - the path in the explorer
 */
export default function ExplorerLink({ path, ...props }) {
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const { explorer } = ConfigStore.useStore();

  const url = useMemo(() => {
    const host = chain === 'test' ? normalizeHost(explorer.test) : normalizeHost(explorer.main);
    const p = normalizePath(path);
    return host + p;
  }, [chain, explorer.main, explorer.test, path]);

  return <ExternalLink {...props} href={url} />;
}

const normalizePath = (path) => (path.indexOf('/') === 0 ? path : '/' + path);
const normalizeHost = (host) =>
  host.lastIndexOf('/') === host.length - 1 ? host.substring(0, host.length - 1) : host;
