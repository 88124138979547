import { toEditDisplay, clean, changeDotPosition, toValue } from './numberUtils';

const ValueDisplay = {
  value: '',
  display: '',
  /**
   * Get a safe value to be used with decimal.js
   */
  get safeValue() {
    return this.value || '0';
  },
  /**
   * Cleans the number string, removes double dots and returns the value and the display
   *
   * @param {string} display the new display value
   */
  onDisplayChange(display) {
    // handle if there is more than 1 dot
    let strippedDisplay = changeDotPosition(clean(this.display), clean(display));
    if (strippedDisplay === '.') {
      strippedDisplay = '0.';
    }
    const cleanedNewDisplay = clean(strippedDisplay);
    return create(toValue(cleanedNewDisplay), toEditDisplay(cleanedNewDisplay));
  },
};

Object.defineProperty(ValueDisplay, 'onDisplayChange', {
  enumerable: false,
  configurable: false,
  writable: false,
});

/**
 * Create a new ValueDisplay object
 *
 * @param {string} [value=''] - the initial value
 * @param {string} [display=''] - the initial display
 * @returns {ValueDisplay} a new ValueDisplay object
 */
export function create(value = '', display = '') {
  if (process.env.NODE_ENV !== 'production') {
    validateInput(value, display);
  }

  const instance = Object.create(ValueDisplay);
  instance.value = String(value);
  instance.display = display ? display : value ? toEditDisplay(value) : '';

  return instance;
}

// make sure there are no leftovers numbers in the app
function validateInput(value, display) {
  if (typeof value !== 'string' && !(value instanceof String)) {
    throw new TypeError('value must be a string');
  }
  if (typeof display !== 'string' && !(display instanceof String)) {
    throw new TypeError('display must be a string');
  }
}

/**
 * @typedef {{
  value: string,
  display: string,
  safeValue: string,
  onDisplayChange: (display: string) => ValueDisplay,
}} ValueDisplay
 */
