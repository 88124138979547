import React, { useCallback } from 'react';
import { Alert, useModal } from '@zen-common/components-base';
import BlockchainInfoStore from '../../stores/BlockchainInfoStore';

/**
 * Exposes a function to check if node is inaccessible
 *
 * @returns {preventAction} preventAction
 */
export default function useNodeInaccessible() {
  const {
    state: { error },
  } = BlockchainInfoStore.useStore();
  const { show, hide } = useModal();

  /**
   * Handles node inaccessible
   * @function
   * @typedef {Function} preventAction
   * @returns {boolean} true if node is inaccessible, otherwise false
   */
  const preventAction = useCallback(() => {
    if (error) {
      show(() => (
        <Alert title="Node is inaccessible" onDismiss={hide} type={Alert.TYPE.WARNING}>
          Operating is not possible while the node is unreachable
          <br />
          Please try again later
        </Alert>
      ));
      return true;
    }
    return false;
  }, [error, hide, show]);

  return preventAction;
}
