import { useEffect, useState } from 'react';

/**
 * Notify on wallet state transitions
 *
 * @param {Object} params
 * @param {('test'|'main')} params.chain
 * @param {boolean} params.isLoggedIn
 * @param {{test: string, main: string}} params.encryptedSeed
 * @param {OnActionCallback} params.onAction
 */
export default function useWalletStateTransitions({
  isLoggedIn,
  chain,
  encryptedSeed,
  onAction,
} = {}) {
  const [state, setState] = useState({ isLoggedIn, chain, encryptedSeed });

  const chainChanged = chain !== state.chain;
  const hasPassword = encryptedSeed[chain];
  const hadPassword = state.encryptedSeed[state.chain];
  const wasLoggedIn = state.isLoggedIn;

  // logged in
  useEffect(() => {
    if (!chainChanged && !wasLoggedIn && hadPassword && hasPassword && isLoggedIn) {
      onAction('login');
    }
  }, [chainChanged, hasPassword, isLoggedIn, onAction, wasLoggedIn, hadPassword]);

  // logged out
  useEffect(() => {
    if (!chainChanged && wasLoggedIn && hasPassword && !isLoggedIn) {
      onAction('logout');
    }
  }, [chainChanged, hasPassword, isLoggedIn, onAction, wasLoggedIn]);

  // created
  useEffect(() => {
    if (!chainChanged && !hadPassword && !wasLoggedIn && hasPassword && isLoggedIn) {
      onAction('create');
    }
  }, [chainChanged, hasPassword, isLoggedIn, onAction, wasLoggedIn, hadPassword]);

  // wiped
  useEffect(() => {
    if (!chainChanged && hadPassword && !hasPassword) {
      onAction('wipe');
    }
  }, [chainChanged, hasPassword, onAction, hadPassword]);

  // update state on change
  useEffect(() => {
    setState({ isLoggedIn, chain, encryptedSeed });
  }, [chain, encryptedSeed, isLoggedIn]);
}

/**
 * @callback OnActionCallback
 * @param {('create'|'wipe'|'login'|'logout')} type
 */
