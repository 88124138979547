import React from 'react';
import { WalletStore, ReceiveForm, TitleContentLayout, ConfigStore } from '@zen/common-app-parts';
import { Box } from '@zen-common/components-base';

export default function Receive() {
  const wallet = WalletStore.useStore();
  const config = ConfigStore.useStore();
  return (
    <TitleContentLayout title="Receive">
      <Box maxWidth="formsMaxWidth">
        <ReceiveForm address={wallet.state.keys.address} config={config} />
      </Box>
    </TitleContentLayout>
  );
}
