import * as React from 'react';
import { ZenJsUtils } from '@zen/common-app-parts';

/**
 * Get basic contract info
 * @param {Object} params
 * @param {Chain} params.chain
 * @param {Object} params.config
 * @returns {{contractId: string, contractAddress: string}}
 */
export default function useContractInfo({ chain, config }) {
  return React.useMemo(() => {
    const contractId =
      chain === 'main' ? config.contract.governance.main : config.contract.governance.test;
    const contractAddress = ZenJsUtils.getContractAddress(chain, contractId);

    return { contractId, contractAddress };
  }, [chain, config.contract.governance.main, config.contract.governance.test]);
}
