import createStore from '../../utils/createStore';
import useRedeemAssetStore from './modules/useRedeemAssetStore';

const { Provider, useStore } = createStore({
  useStoreHook: useRedeemAssetStore,
  contextDisplayName: 'RedeemAssetStoreContext',
});

export default {
  /**
   * @type {RedeemAssetStoreProvider}
   */
  Provider,
  useStore,
};

/**
 * @typedef {(props: RedeemAssetStoreProvider) => void} RedeemAssetStoreProvider
 */

/**
 * @typedef {Object} RedeemAssetStoreProvider
 * @property {{get: Function, set: Function}} props.storage
 * @property {Object} props.initialState - should contain all the needed settings and default values for them if exist
 * @property {Object} props.validation - can contain a validation function per key which returns true for a valid value
 *
 */
