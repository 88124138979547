import createStore from '../../utils/createStore';
import useSettingsStore from './modules/useSettingsStore';

const { Provider, useStore } = createStore({
  useStoreHook: useSettingsStore,
  contextDisplayName: 'SettingsStoreContext',
});

export default {
  /**
   * @type {SettingsStoreProvider}
   */
  Provider,
  useStore,
};

/**
 * @typedef {(props: SettingsStoreProviderProps) => void} SettingsStoreProvider
 */

/**
 * @typedef {Object} SettingsStoreProviderProps
 * @property {{get: Function, set: Function}} props.storage
 * @property {Object} props.initialState - should contain all the needed settings and default values for them if exist
 * @property {Object} props.validation - can contain a validation function per key which returns true for a valid value
 *
 */
