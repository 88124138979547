import useCandidates from './modules/useCandidates';
import useCgpCurrent from './modules/useCgpCurrent';
import useCgpCurrentBalance from './modules/useCgpCurrentBalance';
import useCgpSnapshotBalance from './modules/useCgpSnapshotBalance';
import useNominees from './modules/useNominees';
import useUserSnapshotBalance from './modules/useUserSnapshotBalance';
import useVotes from './modules/useVotes';

/**
 * All CGP fetch operations
 */
export default function useCgpFetch(props) {
  return {
    ...useCgpCurrent(props),
    ...useCgpCurrentBalance(props),
    ...useUserSnapshotBalance(props),
    ...useCandidates(props),
    ...useCgpSnapshotBalance(props),
    ...useNominees(props),
    ...useVotes(props),
  };
}
