import { useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Decimal } from 'decimal.js';
import { ZenJsUtils, ApiService, ExplorerService } from '@zen/common-app-parts';
import { REFETCH_LONG } from './utils/constants';

/**
 * Fetches the payout candidates in the voting phase
 */
export default function useCandidates({
  voteStatus,
  currentInterval,
  nodeUrl,
  chain,
  config,
} = {}) {
  // only when voting is on
  const { data: candidatesData } = useQuery(
    ['cgp-candidates', { currentInterval, nodeUrl }],
    fetchCandidates,
    { enabled: voteStatus === 'voting' }
  );
  const { data: payoutBallots } = useQuery(
    ['cgp-explorer-payout-ballots', { currentInterval, chain, config }],
    fetchPayoutBallots,
    { enabled: voteStatus === 'voting', refetchInterval: REFETCH_LONG }
  );
  const payoutBallotsHash = useMemo(
    () =>
      !payoutBallots
        ? {}
        : (payoutBallots.data.items || []).reduce((all, cur) => {
            all[cur.ballot] = cur.zpAmount;
            return all;
          }, {}),
    [payoutBallots]
  );

  const candidates = useMemo(
    () =>
      (candidatesData || [])
        .map((d) => {
          const ballot = ZenJsUtils.serializePayoutBallotId(chain, d.recipient, d.spendlist);
          const zpAmount = payoutBallotsHash[ballot] || '0';
          return { ballot, zpAmount };
        })
        .sort((a, b) => new Decimal(b.zpAmount).minus(a.zpAmount).toNumber()),
    [candidatesData, chain, payoutBallotsHash]
  );

  /** An array with the ballots only */
  const candidateBallots = useMemo(() => candidates.map((c) => c.ballot), [candidates]);

  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    if (candidatesData) {
      setFetched(true);
    }
  }, [candidatesData]);

  return {
    candidates,
    candidateBallots,
    candidatesFetched: fetched,
  };
}
function fetchCandidates(_, { nodeUrl, currentInterval }) {
  return ApiService.cgp.getCandidates(nodeUrl, currentInterval);
}
function fetchPayoutBallots(_, { currentInterval, chain, config }) {
  const explorerApiService = ExplorerService({ chain, config });
  return explorerApiService.cgp.getPayoutBallots(currentInterval);
}
