import * as React from 'react';
import styled, { css } from 'styled-components';
import { Box, themeUtils as tu, cssMixins, SubNav } from '@zen-common/components-base';
import FormContainerPadding from './FormContainerPadding';

export default function FormAndTableLayout({
  form,
  table,
  formTitle,
  tableTitle,
  formTabTitle,
  tableTabTitle,
}) {
  const [activeTab, setActiveTab] = React.useState('form');

  return (
    <LayoutContainer>
      <StyledSubNav>
        <TabLink
          className={activeTab === 'form' ? 'active' : ''}
          onClick={() => setActiveTab('form')}
        >
          {formTabTitle}
        </TabLink>
        <TabLink
          className={activeTab === 'table' ? 'active' : ''}
          onClick={() => setActiveTab('table')}
        >
          {tableTabTitle}
        </TabLink>
      </StyledSubNav>
      <LayoutContentContainer>
        <FormWrapper activeTab={activeTab}>
          <FormContainerPadding>{formTitle}</FormContainerPadding>
          {form}
        </FormWrapper>
        <TableWrapper activeTab={activeTab}>
          <TableTitleWrapper>{tableTitle}</TableTitleWrapper>
          {table}
        </TableWrapper>
      </LayoutContentContainer>
    </LayoutContainer>
  );
}

const LayoutContainer = styled(Box)`
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;
const LayoutContentContainer = styled(Box)`
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;

  ${tu.mq({
    minHeight: ['auto', null, null, null, '700px'],
  })}
`;

const StyledSubNav = styled(SubNav)`
  padding: 0 !important;
  min-height: auto;

  ${tu.mq({
    display: ['flex', null, null, null, 'none'],
  })}
`;
const TabLink = styled.a`
  cursor: pointer;
  flex-grow: 1;
  display: block;
  color: ${tu.color('text')};
  text-decoration: none;
  margin: 0 !important;
  text-align: center;
  ${cssMixins.getTransition()}
  ${cssMixins.getPaddings({ y: 2, x: 4 })}
`;
const FormWrapper = styled(Box)`
  flex-grow: 1;
  flex-shrink: 0;
  padding-top: ${tu.space(11)};
  margin-bottom: ${tu.space('smd')};
  ${(p) =>
    css(
      tu.mq({
        width: ['100%', null, null, null, '60%'],
        display: [p.activeTab === 'form' ? 'block' : 'none', null, null, null, 'block'],
      })
    )}
`;
const TableWrapper = styled(Box)`
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  padding-top: ${tu.space(11)};
  border-color: ${tu.color('borderDark')};
  border-style: solid;
  border-width: 0;
  ${(p) =>
    css(
      tu.mq({
        marginBottom: [tu.space('smd'), null, null, null, '0'],
        width: ['100%', null, null, null, `calc(40% - ${tu.space('xl')(p)})`],
        borderLeftWidth: ['0', null, null, null, tu.borderWidths(1)],
        display: [p.activeTab === 'table' ? 'flex' : 'none', null, null, null, 'flex'],
      })
    )}
`;
const TableTitleWrapper = styled(Box)`
  ${cssMixins.containerXPadding}
  margin-bottom: ${tu.space('smd')};
`;
