import facepaint from 'facepaint';
import themes from './themes';
import breakpoints from './themes/breakpoints';
import sizeToPx from './modules/sizeToPx';

const mediaQueries = breakpoints.map((bp) => `@media (min-width: ${bp})`);

const getScaleValue = (props, scale, key) =>
  props.theme && props.theme[scale] && sizeToPx(props.theme[scale][key]);

/**
 * A higher order function which returns a getter for one of the scales in the theme.
 * @param {string} scale - the name of the scale in the theme
 * @returns a HO function which accepts a key and returns a function which takes props and returns the value of the key or the key itself
 */
const getVal = (scale) => (key) => (props) =>
  Array.isArray(key)
    ? key.map((k) => getScaleValue(props, scale, k) || k)
    : getScaleValue(props, scale, key) || key;

export default {
  breakpoints,
  mq: facepaint(mediaQueries),
  defaultTheme: themes.dark,
  createTheme(theme = {}) {
    return Object.assign({}, this.defaultTheme, theme);
  },
  cx(...classNames) {
    return classNames.reduce((all, cur) => {
      return cur ? `${all} ${cur}`.trim() : all;
    }, '');
  },
  getVal,
  color: getVal('colors'),
  space: getVal('space'),
  fontSize: getVal('fontSizes'),
  size: getVal('sizes'),
  lineHeight: getVal('lineHeights'),
  radius: getVal('radii'),
  borderWidths: getVal('borderWidths'),
  sizeToPx,
};
