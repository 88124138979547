import styled, { css } from 'styled-components';
import { themeUtils as tu, Box, ExternalLink, LinkButton } from '@zen-common/components-base';
import { ExplorerLink } from '@zen/common-app-parts';

const linkCss = css`
  color: ${tu.color('link')};
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: ${tu.fontSize('md')};
  font-weight: 400;
  line-height: 1;
  padding: 0;
  border: 0;
  white-space: nowrap;
  ${tu.mq({
    marginRight: [0, tu.space('xl')],
    marginBottom: [tu.space('sm'), null, 0],
  })}

  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const buttonLinkCss = css`
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
`;

export const TopLinksContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  ${(p) =>
    tu.mq({
      flexDirection: ['column', 'row'],
      marginBottom: ['-' + tu.space('sm')(p), null, 0],
    })}
`;

export const TopExplorerLink = styled(ExplorerLink)`
  ${linkCss}
`;
export const TopExternalLink = styled(ExternalLink)`
  ${linkCss}
`;
export const TopLinkButton = styled(LinkButton)`
  ${linkCss}
  ${buttonLinkCss}
`;
