import { useQuery } from 'react-query';
import * as ApiService from '../utils/ApiService';
import WalletStore from '../stores/WalletStore';
import SettingsStore from '../stores/SettingsStore';

/**
 * Query for all of the user balance, optionally at a given block number, from the node
 *
 * @param {Object} params
 * @param {number} params.blockNumber - fetch the balance at this block number
 * @param {import('react-query').QueryConfig} params.options
 * @returns {import('react-query').QueryResult<[{asset: string, balance: number}]>}
 */
export default function useQueryUserBalance({ blockNumber, options }) {
  const {
    state: {
      keys: { addresses },
    },
  } = WalletStore.useStore();
  const {
    state: {
      settings: { nodeUrl },
    },
  } = SettingsStore.useStore();

  return useQuery(['user-balance', { nodeUrl, addresses, blockNumber }], fetchUserBalance, options);
}

function fetchUserBalance(_, { nodeUrl, addresses, blockNumber }) {
  return ApiService.getBalance(nodeUrl, { addresses: addresses, blockNumber });
}
