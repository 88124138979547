import { useMemo } from 'react';
import { Decimal } from 'decimal.js';
import { convertPercentageToZP } from './utils';

/**
 * All computed properties
 */
export default function useComputed({
  cgpCurrentAllocation,
  cgpCurrentAllocationFetched,
  cgpSnapshotBalanceFetched,
  snapshotZpBalanceFetched,
  candidatesFetched,
  initialVotesFetchDone,
}) {
  return {
    ...useAllocationMinMax({ cgpCurrentAllocation }),
    ...useIsReadyForVote({
      cgpCurrentAllocationFetched,
      cgpSnapshotBalanceFetched,
      snapshotZpBalanceFetched,
      candidatesFetched,
      initialVotesFetchDone,
    }),
  };
}

function useAllocationMinMax({ cgpCurrentAllocation } = {}) {
  return useMemo(() => {
    const allocationZpMin = (() => {
      const minerAmount = 100 - ((100 - cgpCurrentAllocation.percentage) * 100) / 85;
      const min = minerAmount > 0 ? convertPercentageToZP(minerAmount) : 0;
      return min % 0.5 ? new Decimal(min).toNearest(0.5, Decimal.ROUND_CEIL).toNumber() : min;
    })();
    const allocationZpMax = (() => {
      if (cgpCurrentAllocation.percentage === 0) return 7.5;
      const minerAmount = 100 - (100 - cgpCurrentAllocation.percentage) * 0.85;
      const max = minerAmount < 90 ? convertPercentageToZP(minerAmount) : 45;
      return max % 0.5 ? new Decimal(max).toNearest(0.5, Decimal.ROUND_FLOOR).toNumber() : max;
    })();

    return { allocationZpMax, allocationZpMin };
  }, [cgpCurrentAllocation.percentage]);
}

/**
 * Aggregates all flags for needed data for voting
 */
function useIsReadyForVote({
  cgpSnapshotBalanceFetched,
  cgpCurrentAllocationFetched,
  snapshotZpBalanceFetched,
  candidatesFetched,
  initialVotesFetchDone,
}) {
  return {
    isReadyForNomination: useMemo(
      () => cgpSnapshotBalanceFetched && snapshotZpBalanceFetched && initialVotesFetchDone,
      [cgpSnapshotBalanceFetched, initialVotesFetchDone, snapshotZpBalanceFetched]
    ),
    isReadyForVoting: useMemo(
      () =>
        cgpSnapshotBalanceFetched &&
        cgpCurrentAllocationFetched &&
        snapshotZpBalanceFetched &&
        candidatesFetched &&
        initialVotesFetchDone,
      [
        cgpCurrentAllocationFetched,
        cgpSnapshotBalanceFetched,
        snapshotZpBalanceFetched,
        candidatesFetched,
        initialVotesFetchDone,
      ]
    ),
  };
}
