import React from 'react';
import {
  CgpPhaseChangedStore,
  TxCountChangedStore,
  GovernancePhaseChangedStore,
} from '../../../stores/ChangeNotificationsStore';

export default function Provider({ children }) {
  return (
    <TxCountChangedStore.Provider>
      <CgpPhaseChangedStore.Provider>
        <GovernancePhaseChangedStore.Provider>{children}</GovernancePhaseChangedStore.Provider>
      </CgpPhaseChangedStore.Provider>
    </TxCountChangedStore.Provider>
  );
}
