import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ModalRoot } from '@zen-common/components-base';
import WalletStore from '../../stores/WalletStore';
import SettingsStore from '../../stores/SettingsStore';
import StartOnBoarding from './components/pages/StartOnBoarding';
import Terms from './components/pages/Terms';
import SecretPhrase from './components/pages/SecretPhrase';
import SecretPhraseQuiz from './components/pages/SecretPhraseQuiz';
import SetPassword from './components/pages/SetPassword';
import ImportWallet from './components/pages/ImportWallet';
import { PAGE_NAMES, getPaginationProps } from './onBoardingPagination';

/**
 * Main entry point for the on-boarding module
 *
 * @export
 * @param {Object} props
 * @param {string} props.appName - the app name to display in the start screen
 * @param {('create'|'import')} props.type - start onboarding with this type, skips the start page
 */
export default function OnBoarding({ appName, type: givenType }) {
  const [mnemonic, setMnemonic] = useState('');
  const [curPage, setCurPage] = useState(
    givenType === 'create' || givenType === 'import' ? PAGE_NAMES.Terms : PAGE_NAMES.StartOnBoarding
  );
  const [type, setType] = useState(givenType ? givenType : '');
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(false);
  const {
    actions: { setSetting },
  } = SettingsStore.useStore();
  const {
    actions: { create },
  } = WalletStore.useStore();

  const paginationProps = useMemo(() => getPaginationProps({ type, setType, setCurPage }), [type]);
  const onPasswordCreated = useCallback(
    async ({ password, logoutMinutes } = {}) => {
      setError('');
      setProgress(true);
      setSetting({ name: 'logoutMinutes', value: logoutMinutes });
      try {
        await create({ mnemonic: mnemonic.join(' '), password });
      } catch (error) {
        setProgress(false);
        setError(error.message);
      }
    },
    [create, mnemonic, setSetting]
  );

  return (
    <ModalRoot>
      {curPage === PAGE_NAMES.StartOnBoarding && (
        <StartOnBoarding appName={appName} {...paginationProps[curPage]} />
      )}
      {curPage === PAGE_NAMES.Terms && <Terms {...paginationProps[curPage]} />}
      {curPage === PAGE_NAMES.ImportWallet && (
        <ImportWallet {...paginationProps[curPage]} onValidPhraseEntered={setMnemonic} />
      )}
      {curPage === PAGE_NAMES.SecretPhrase && (
        <SecretPhrase {...paginationProps[curPage]} onPhraseGenerated={setMnemonic} />
      )}
      {curPage === PAGE_NAMES.SecretPhraseQuiz && (
        <SecretPhraseQuiz storedMnemonic={mnemonic} {...paginationProps[curPage]} />
      )}
      {curPage === PAGE_NAMES.SetPassword && (
        <SetPassword
          {...paginationProps[curPage]}
          onContinue={onPasswordCreated}
          error={error}
          loading={progress}
        />
      )}
    </ModalRoot>
  );
}
OnBoarding.propTypes = {
  appName: PropTypes.string,
};
