import React from 'react';
import styled from 'styled-components';
import {
  Box,
  Button,
  ModalHeader,
  ModalFooter,
  ModalSeparator,
  ModalSection,
  Title,
} from '@zen-common/components-base';

export default function OptionsModalContent({ onCancel, onBackupWallet, onDelete }) {
  return (
    <Box>
      <ModalHeader>
        <Title>Confirm wiping your wallet</Title>
      </ModalHeader>
      <ModalSection>
        By wiping your wallet you will delete your Seed. Make sure you saved your 24 Seed phrase.
      </ModalSection>
      <ModalSeparator />
      <ModalFooter>
        <StyledButtonsWrapper>
          <StyledButton onClick={onCancel} bg="secondary" display={['none', 'block']}>
            Cancel
          </StyledButton>
          <StyledButton onClick={onBackupWallet}>Backup Wallet</StyledButton>
          <StyledButton onClick={onDelete}>Wipe Seed</StyledButton>
        </StyledButtonsWrapper>
      </ModalFooter>
    </Box>
  );
}

const StyledButtonsWrapper = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const StyledButton = styled(Button)`
  max-width: 100%;
  flex-grow: 1;
`;
