import * as React from 'react';

/**
 * A hook to show a part or all of an array depending on state
 * @param {Object} params
 * @param {Array} params.items - the items to show
 * @param {number} params.defaultCount - how many items to show by default
 * @param {boolean} params.defaultIsMore - should the default state be opened or closed
 */
export default function useShowMore({ items, defaultCount = 3, defaultIsMore = false }) {
  const [isMore, setIsMore] = React.useState(defaultIsMore);

  const toggleMore = React.useCallback(() => setIsMore((m) => !m), []);

  return {
    items: !items ? [] : isMore ? items : items.slice(0, defaultCount),
    isMore,
    toggleMore,
  };
}
