import { Decimal } from 'decimal.js';
import { toDisplay, toValue } from './numberUtils';
import { truncateHash } from './textUtils';
import constants from './constants';

const { ZEN_TO_KALAPA_RATIO, ZP_FULL_ID, ZP_SHORT_ID } = constants;

/**
 * @param {string} asset
 * @returns {boolean}
 */
export function isZP(asset) {
  return asset === ZP_SHORT_ID || asset == ZP_FULL_ID;
}
/**
 * Convert a number from kalapas
 *
 * @param {(string|number)} amount
 * @returns {string}
 */
export function fromKalapas(amount) {
  return toValue(new Decimal(amount).dividedBy(ZEN_TO_KALAPA_RATIO));
}
/**
 * Check if two assets are equals
 *
 * @param {(string)} asset1
 * @param {(string)} asset2
 * @returns {string}
 */
export function isEqual(asset1, asset2) {
  return getAssetFullId(asset1) === getAssetFullId(asset2);
}
/**
 * Convert a number to kalapas
 *
 * @param {(string|number)} amount
 * @returns {string}
 */
export function toKalapas(amount) {
  return toValue(new Decimal(amount).times(ZEN_TO_KALAPA_RATIO));
}

/**
 * Display an amount, adds asset name if exists
 *
 * @param {string} asset
 * @param {(string|number)} amount
 * @param {number} fractionDigits - the number of decimal places
 * @returns {string}
 */
export function getAmountDisplay(asset, amount, fractionDigits) {
  if (!amount) {
    amount = '0';
  }

  const display = toDisplay(amount, fractionDigits);

  if (isZP(asset)) {
    return `${display} ${getAssetName(asset)}`;
  }
  return display;
}

/**
 * @param {string} id - the asset identifier
 * @returns {string} the asset name if exists, otherwise the asset identifier
 */
export function getAssetName(id) {
  return isZP(id) || (id || '').toLowerCase().trim() === 'zp' ? 'ZP' : id;
}

/**
 * Get the full asset id by name or short id
 *
 * @param {string} asset - the asset name or short id
 */
export function getAssetFullId(asset) {
  return !asset ? '' : isZP(asset) || asset.trim().toLowerCase() === 'zp' ? ZP_FULL_ID : asset;
}

/**
 * Get the asset representation id by name or id
 *
 * @param {string} asset - the asset name or id
 */
export function getAssetRepresentationId(asset) {
  return !asset ? '' : isZP(asset) || asset.trim().toLowerCase() === 'zp' ? ZP_SHORT_ID : asset;
}

/**
 * Get the balance for an asset, from the balance returned by zen.js
 *
 * @param {string} asset - the asset identifier
 * @param {Object} balance - the balance
 * @returns {string} the balance for the given asset identifier
 */
export function getAssetBalance(asset, balance) {
  const key = Object.keys(balance || {}).find(
    (key) => getAssetFullId(key) === getAssetFullId(asset)
  );
  return key ? balance[key] : '0';
}

export function removeLeadingAndTrailingZeros(asset) {
  if (typeof asset !== 'string') {
    return '';
  }
  return asset.length > 8 ? asset.replace(/^0+/, '').replace(/0+$/, '') : asset;
}

/**
 * Replaces the asset id with a name if exist and truncate the hash if not
 *
 * @param {string} str
 * @param {number} trimLength - the number of chars to leave at each side
 * @returns the truncated asset id or the name of the asset
 */
export function truncateOrNameAsset(str, trimLength = 6) {
  const name = getAssetName(str);
  return name === str ? truncateHash(str, trimLength) : name;
}

/**
 * Validate that amount is positive and that balance has enough
 * @param {Object} params
 * @param {string} params.asset
 * @param {{asset: (number|string)}} params.balance - the zenjs balance
 * @param {(number|string)} params.amount
 */
export function validateAmount({ asset, balance = {}, amount } = {}) {
  const dAmount = new Decimal(amount);
  const dBalance = new Decimal(getAssetBalance(asset, balance));
  return (
    dAmount.greaterThan(0) &&
    dBalance.greaterThanOrEqualTo(dAmount) &&
    dAmount.greaterThanOrEqualTo(constants.MINIMUM_DIVIDED_UNIT) &&
    dAmount.decimalPlaces() <= constants.ZEN_DECIMAL_PLACES
  );
}

/**
 * Sort the Asset in React Table
 * @param {Object} a
 * @param {Object} b
 */
export function sortAssetName(a, b) {
  const aName = a.metadata.name || a.asset;
  const bName = b.metadata.name || b.asset;
  if (aName.length === bName.length) {
    return aName > bName ? 1 : -1;
  }
  return aName.length > bName.length ? 1 : -1;
}
