import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import FaSvg, { StyledSvg } from '../helpers/FaSvg';
import Input from './Input';

/**
 * An input with a toggle visibility icon.
 * Visibility can be controlled from the outside by passing props or from the inside by default
 * @type {React.ForwardRefExoticComponent<import('@zen-common/components-base').InputProps & React.RefAttributes<any>}
 */
const PasswordInput = forwardRef(
  ({ visible, onToggleVisibility, renderLeftAddon, ...props }, ref) => {
    const [_visible, _setVisible] = useState(visible || false);

    useEffect(() => {
      typeof visible === 'boolean' && _setVisible(visible);
    }, [visible]);

    const _toggleVisibility = () => {
      typeof onToggleVisibility === 'function' ? onToggleVisibility() : _setVisible((v) => !v);
    };

    return (
      <StyledInput
        ref={ref}
        type={_visible ? 'text' : 'password'}
        renderLeftAddon={renderLeftAddon}
        renderRightAddon={<ToggleVisibilityIcon visible={_visible} onClick={_toggleVisibility} />}
        {...props}
      />
    );
  }
);
PasswordInput.propTypes = {
  visible: PropTypes.bool,
  onToggleVisibility: PropTypes.func,
};
PasswordInput.defaultProps = {
  visible: false,
};

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;

const StyledSpan = styled.span`
  cursor: pointer;
  padding: 0 ${(p) => (p.visible ? '9px' : '10px')};

  &:hover {
    ${StyledSvg} {
      filter: brightness(1.25);
    }
  }
`;

function ToggleVisibilityIcon({ visible, onClick, ...p }) {
  return (
    <StyledSpan visible={visible} {...p} onClick={onClick}>
      {visible ? (
        <FaSvg icon={faEyeSlash} fontSize="1rem" width="18px" />
      ) : (
        <FaSvg icon={faEye} fontSize="1rem" width="16px" />
      )}
    </StyledSpan>
  );
}

const StyledInput = styled(Input)`
  input {
    padding-right: 0;
  }
`;
