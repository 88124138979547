import * as React from 'react';

export default function useDatePickerStore() {
  /** @type {[state: initialState, dispatch: React.Dispatch<{type: string, payload: Object}>]} */
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return {
    state,
    dispatch,
  };
}
const initialState = {
  startDate: undefined,
  endDate: undefined,
  focusedInput: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'focusChange':
      return { ...state, focusedInput: action.payload };
    case 'dateChange':
      return action.payload;
    default:
      throw new Error();
  }
}
