import React from 'react';
import styled from 'styled-components';
import { Box, GeneralMessage, themeUtils as tu } from '@zen-common/components-base';

export default function GeneralMessageWithFooter({ type = 'warning', footer, children }) {
  return (
    <StyledGeneralMessage type={type}>
      {children}
      <BorderTopBox>{footer}</BorderTopBox>
    </StyledGeneralMessage>
  );
}

const StyledGeneralMessage = styled(GeneralMessage)`
  line-height: ${tu.lineHeight(6)};
`;
const BorderTopBox = styled(Box)`
  margin-top: ${tu.space('smd')};
  padding-top: ${tu.space('smd')};
  margin-bottom: 0;
  border-top: 1px solid ${tu.color('borderDark')};
`;
