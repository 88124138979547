import { assetUtils } from '@zen/common-utils';
import * as ZenJsUtils from '../../../utils/zenJsUtils';

/**
 * Calls the right setter
 * @param {Object} params
 * @param {Object} params.PropsSettersMap - an object which maps a prop name to a setter function
 * @param {string} params.prop - the prop name
 * @param {any} params.value - the value to set
 * @param {Object} params.draft - the state object
 */
export function setter({ PropsSettersMap, prop, value, draft } = {}) {
  if (!PropsSettersMap[prop]) {
    throw new Error(`Unhandled prop setter for prop ${prop}`);
  }

  PropsSettersMap[prop](value, draft);
}

export function setResult(value, draft) {
  const { error, assetInfo, messageBody, isRedeem, isRedeemable, redeemBalance } = value;
  draft.result.error = error || '';
  draft.result.assetInfo = assetInfo || '';
  draft.result.messageBody = messageBody || '';
  draft.result.isRedeem = isRedeem || '';
  draft.result.isRedeemable = isRedeemable || [];
  draft.result.redeemBalance = redeemBalance || [];
}

export function setAsset(value, draft) {
  _setAsset({ prop: 'asset', draft, value });
}
export function setFetchPhase(value, draft) {
  draft.fetchPhase = value;
}

/**
 * Helper to set an asset field
 */
function _setAsset({ prop, value, draft }) {
  draft[prop].value = value;
  draft[prop].valid = ZenJsUtils.validateAsset(assetUtils.getAssetFullId(value));
}
