import * as React from 'react';
import styled from 'styled-components';
import {
  Box,
  AutoSuggest,
  Button,
  Form,
  FormBody,
  FormGroup,
  Input,
  Checkbox,
  ClearInput,
  InputMessage,
  Label,
  LabelText,
  LoadingOr,
  Tooltip,
  useTooltip,
  themeUtils as tu,
  InfoBox,
  FaSvg,
} from '@zen-common/components-base';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import ExecuteContractStore from '../../../stores/ExecuteContractStore';
import NamingAssetStore from '../../../stores/NamingAssetStore';
import { SpendsGroup } from '../../Spend';

export default function ExecuteContractForm({ onSubmit }) {
  const { actions, state } = ExecuteContractStore.useStore();
  const { state: naming } = NamingAssetStore.useStore();
  const addressRef = React.useRef();
  const spendsRef = React.useRef();
  const commandRef = React.useRef();

  const isMobile = document.body.clientWidth < tu.breakpoints.xl.replace('px', '');

  const handleRemoveSpend = (...params) => {
    actions.removeSpend(...params);
    // make sure focus after the removed
    setTimeout(() => {
      spendsRef.current && spendsRef.current.focus();
    }, 0);
  };
  const handleAddSpend = (...params) => {
    actions.addSpend(...params);
    // make sure focus after the add
    setTimeout(() => {
      spendsRef.current && spendsRef.current.focus();
    }, 0);
  };
  const handleReset = () => {
    actions.reset();
    // make sure focus after the reset, if address is readonly focus on spend
    setTimeout(() => {
      if (addressRef.current) {
        addressRef.current.focus();
      } else if (commandRef.current) {
        commandRef.current.focus();
      }
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit();
  };

  const resetDisabled = React.useMemo(
    () =>
      (state.addressReadOnly || state.allReadOnly) &&
      state.address &&
      state.spends[0].asset === '' &&
      state.spends[0].amount.value === '' &&
      state.command === '' &&
      state.messageBody === '' &&
      !state.includeReturnAddress &&
      !state.includeSender,
    [
      state.addressReadOnly,
      state.allReadOnly,
      state.address,
      state.spends,
      state.command,
      state.messageBody,
      state.includeReturnAddress,
      state.includeSender,
    ]
  );
  return (
    <Form onSubmit={handleSubmit} disabled={state.progress}>
      <FormBody>
        <FormGroup>
          <Label htmlFor="ExecuteContractForm-address">
            <LabelText>Contract address</LabelText>
          </Label>
          {state.addressReadOnly || state.allReadOnly ? (
            <Input
              value={state.address}
              variant="block"
              inputType="expandable"
              readOnly
              disabled={state.progress}
            />
          ) : (
            <AutoSuggest
              ref={addressRef}
              {...(state.address && { valid: state.addressValid })}
              hasClearButton
              items={(state.activeContracts || []).map((contract) => ({
                value: contract.address,
                label: contract.address,
              }))}
              variant="block"
              value={state.address}
              onChange={actions.setAddress}
              disabled={state.progress}
              inputProps={{ id: 'ExecuteContractForm-address' }}
            />
          )}
          <InputMessage>
            {!state.address || state.addressValid ? '' : 'Invalid address'}
          </InputMessage>
        </FormGroup>

        <SpendsGroup
          ref={spendsRef}
          alignment="grid"
          addButtonAlignment="right"
          noMarginTop
          spends={state.spends}
          spendsValid={state.spendsValid}
          assets={state.assets}
          balance={state.balance}
          addSpend={handleAddSpend}
          removeSpend={handleRemoveSpend}
          setAsset={actions.setAsset}
          setAmount={actions.setAmount}
          disabled={state.progress}
          naming={naming}
          readOnly={state.allReadOnly}
          assetReadOnly={true}
        />

        <FormGroup>
          <InfoBox
            info="String that the contract may use to know what to perform"
            isMobile={isMobile}
          >
            <Label htmlFor="ExecuteContractForm-command">
              <LabelText>Choose command</LabelText>
            </Label>
          </InfoBox>
          <ClearInput
            ref={commandRef}
            id="ExecuteContractForm-command"
            disabled={state.progress}
            variant="block"
            value={state.command}
            onChange={(e) => actions.setCommand(e.target.value.trim())}
            onClear={() => actions.setCommand('')}
            readOnly={state.allReadOnly}
          />
          <InputMessage />
        </FormGroup>

        <FormGroup>
          <InfoBox info="Add an extra message to the contract execution" isMobile={isMobile}>
            <Label htmlFor="ExecuteContractForm-message-body">
              <LabelText>Message body</LabelText>
            </Label>
          </InfoBox>
          <Input
            id="ExecuteContractForm-message-body"
            disabled={state.progress}
            inputType="multiline"
            value={state.messageBody}
            variant="block"
            onChange={(e) => actions.setMessageBody(e.target.value)}
            readOnly={state.allReadOnly}
          />
          <InputMessage>
            {!state.messageBody || state.messageBodyValid ? '' : 'Invalid message body'}
          </InputMessage>
        </FormGroup>

        <FormGroup>
          <CheckBoxWithTooltip
            tooltip="Add the receive address to the message body"
            disabled={state.progress || state.allReadOnly}
            checked={state.includeReturnAddress}
            label="Include return address"
            onChange={(e) => actions.setIncludeReturnAddress(e.target.checked)}
          />
        </FormGroup>
        <FormGroup>
          <CheckBoxWithTooltip
            tooltip="Sign the contract execution"
            disabled={state.progress || state.allReadOnly}
            checked={state.includeSender}
            label="Include authentication"
            onChange={(e) => actions.setIncludeSender(e.target.checked)}
          />
        </FormGroup>
        <StyledFooter>
          {!state.allReadOnly && (
            <StyledButton
              disabled={resetDisabled || state.progress}
              type="button"
              mr="smd"
              bg="secondary"
              onClick={handleReset}
            >
              Reset
            </StyledButton>
          )}
          <StyledButton disabled={!state.valid || state.progress} type="submit">
            <LoadingOr loading={state.progress} loadingContent="Executing...">
              Execute
            </LoadingOr>
          </StyledButton>
        </StyledFooter>
      </FormBody>
    </Form>
  );
}

const StyledButton = styled(Button)`
  margin-top: ${tu.space('xxs')};
  min-width: 134px;
`;
const StyledFooter = styled(FormGroup)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  /* style a footer button */
  ${StyledButton} {
    min-width: 120px;
    margin-right: ${tu.space('smd')};

    ${tu.mq({
      width: ['100%', 'auto'],
    })}

    &:last-child {
      margin-right: 0;
    }
  }
`;

const CheckBoxWithTooltip = ({ tooltip, disabled, checked, label, onChange, isMobile }) => {
  const { hide, ref, showAndUpdate, showAndHide, tooltipProps } = useTooltip({ placement: 'top' });
  const onMouseEnter = isMobile ? showAndHide : showAndUpdate;
  return (
    <Box display="inline-block">
      <Checkbox
        disabled={disabled}
        checked={checked}
        label={
          <Label>
            {label}
            <Box
              onClick={onMouseEnter}
              display="inline-block"
              ref={ref}
              onMouseEnter={onMouseEnter}
              onMouseLeave={hide}
            >
              <Tooltip {...tooltipProps}>{tooltip}</Tooltip>
              <FaSvgWrapper fontSize="sm" icon={faInfoCircle} />
            </Box>
          </Label>
        }
        onChange={onChange}
      />
    </Box>
  );
};

const FaSvgWrapper = styled(FaSvg)`
  position: relative;
  top: -2px;
  left: 3px;
  height: 100%;
  align-items: center;
`;
