import { useState, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';

/**
 * Returns the error and resets it whenever a dependency changes
 */
export default function useResetError({ externalError = '', resetDependencies = [] }) {
  const [error, setError] = useState(externalError);

  // handle error change
  useEffect(() => {
    setError(externalError);
  }, [externalError]);
  // do not run on mount
  useUpdateEffect(() => {
    setError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, resetDependencies);

  return error;
}
