import { throwHttpError, HttpError, assetUtils } from '@zen/common-utils';
import { getMessageBody } from '../../../utils/zenJsUtils';

/**
 * Get transactions of this wallet
 */
export async function getTransactions({ wallet, skip = 0, take = 5 } = {}) {
  return wallet.getTransactions(skip, take).catch(throwHttpError);
}

/**
 * Get the amount of transactions from the wallet
 */
export async function getTransactionsCount({ wallet } = {}) {
  return wallet.getTransactionCount().catch(throwHttpError);
}

export async function getBalance({ wallet }) {
  return wallet.getBalance().catch(throwHttpError);
}

/**
 * Sends a tx with 1 item using the wallet
 *
 * @param {Object} params
 * @param {import('@zen/zenjs').Wallet} params.wallet
 * @param {string} params.address
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @param {PrivateKeyArray} params.privateKey
 * @param {boolean} params.publish - publish immediately or just sign
 * @returns {Promise}
 */
export async function send({ wallet, address, spends, privateKey, publish } = {}) {
  return wallet
    .send(
      spends.map((spend) => ({
        ...spend,
        address,
        asset: assetUtils.getAssetRepresentationId(spend.asset),
      })),
      privateKey,
      publish
    )
    .catch((error) => {
      throw new HttpError(error, { clientErrorMsg: 'Insufficient funds' });
    });
}

function getContractData({
  chain,
  command,
  messageBody,
  includeReturnAddress = true,
  spends,
  path = '0/0',
} = {}) {
  const formattedSpends = spends
    ? spends.map((spend) => ({
        ...spend,
        asset: assetUtils.getAssetRepresentationId(spend.asset),
      }))
    : undefined;

  return {
    command,
    messageBody: getMessageBody(chain, messageBody),
    provideReturnAddress: includeReturnAddress,
    outputs: formattedSpends,
    sign: path,
  };
}

/**
 * Execute a contract, will not publish the tx
 *
 * @param {Object} params
 * @param {string} params.chain
 * @param {import('@zen/zenjs').Wallet} params.wallet - the zenjs wallet
 * @param {string} params.address - the contract address
 * @param {string} params.command
 * @param {string} params.messageBody
 * @param {boolean} params.includeReturnAddress
 * @param {[{asset: string, amount: string}]} params.spends
 * @param {string} params.path
 *
 * @returns {Promise<{tx: string, sign:string}>} the transaction in hex
 */
export async function execute({
  chain,
  wallet,
  address,
  command,
  messageBody,
  includeReturnAddress = true,
  spends,
  path = '0/0',
} = {}) {
  const contractData = getContractData({
    chain,
    command,
    messageBody,
    spends,
    includeReturnAddress,
    path,
  });
  const tx = await wallet.executeContract({ address, contractData, publish: false });

  return {
    tx,
    sign: contractData.sign,
  };
}

/**
 * Execute a contract, publish the tx
 *
 * @param {Object} params
 * @param {string} params.chain
 * @param {import('@zen/zenjs').Wallet} params.wallet - the zenjs wallet
 * @param {string} params.address - the contract address
 * @param {string} params.command
 * @param {string} params.messageBody
 * @param {boolean} params.includeReturnAddress
 * @param {[{asset: string, amount: string}]} params.spends
 * @param {string} params.path
 * @param {PrivateKeyArray} params.privateKey
 */
export async function executeAndPublish({
  chain,
  wallet,
  address,
  command,
  messageBody,
  includeReturnAddress = true,
  spends,
  path = '0/0',
  privateKey,
} = {}) {
  return wallet.executeContract({
    address,
    contractData: getContractData({
      chain,
      command,
      messageBody,
      spends,
      includeReturnAddress,
      path,
    }),
    privates: privateKey,
    publish: true,
  });
}

/**
 * Sign just a contract tx returned by execute()
 *
 * @param {Object} params
 * @param {import('@zen/zenjs').Wallet} params.wallet - the zenjs wallet
 * @param {string} params.tx - the transaction in hex
 * @param {string} params.sign
 * @param {PrivateKeyArray} params.privateKey
 */
export async function signContractExecution({ wallet, tx, sign, privateKey } = {}) {
  return await wallet.signContractExecution(tx, sign, privateKey);
}

/**
 * Sign a tx
 *
 * @param {Object} params
 * @param {import('@zen/zenjs').Wallet} params.wallet - the zenjs wallet
 * @param {string} params.tx - the transaction in hex
 * @param {PrivateKeyArray} params.privateKey
 */
export async function signTransaction({ wallet, tx, privateKey } = {}) {
  return await wallet.signTransaction(tx, privateKey);
}

/**
 * @param {Object} params
 * @param {Object} params.wallet - the zenjs wallet instance
 * @param {('Nomination'|'Allocation'|'Payout')} params.command
 * @param {string} params.votingContractAddress
 * @param {Object} params.ballotData
 * @param {number} params.interval
 * @param {PrivateKeyArray} params.privateKey
 */
export async function submitCgpBallot({
  wallet,
  command,
  votingContractAddress,
  ballotData,
  interval,
  privateKey,
} = {}) {
  return wallet.submitCGPBallot(
    votingContractAddress,
    command,
    ballotData,
    command === 'Nomination',
    interval,
    privateKey,
    true // publish is set by default to true
  );
}

/**
 * @param {Object} params
 * @param {import('@zen/zenjs').Wallet} params.wallet - the zenjs wallet instance
 * @param {string} params.code - contract code
 * @param {number} params.numberOfBlocks
 * @param {PrivateKeyArray} params.privateKey
 */
export async function activateContract({ wallet, limit, code, numberOfBlocks, privateKey } = {}) {
  return wallet.activateContract({
    code,
    limit,
    numberOfBlocks,
    privates: privateKey,
    publish: false,
  });
}

/**
 * @param {Object} params
 * @param {Object} params.wallet - the zenjs wallet instance
 * @param {string} params.contractId - contract code
 * @param {number} params.numberOfBlocks
 * @param {PrivateKeyArray=} params.privateKey
 */
export async function extendContract({ wallet, contractId, numberOfBlocks, privateKey } = {}) {
  return wallet.extendContract({
    contractId,
    numberOfBlocks,
    privates: privateKey,
    publish: false,
  });
}

/**
 * @param {Object} params
 * @param {Object} params.wallet - the zenjs wallet instance
 * @param {string} params.votingContractAddress
 * @param {('Contestant' | 'Candidate')} params.phase
 * @param {string} params.commitId
 * @param {number} params.interval
 * @param {PrivateKeyArray} params.privateKey
 */
export async function submitRepoVote({
  wallet,
  votingContractAddress,
  phase,
  commitId,
  interval,
  privateKey,
} = {}) {
  return wallet.submitRepoVote(
    votingContractAddress,
    commitId,
    phase,
    interval,
    privateKey,
    true // publish is set by default to true
  );
}
/**
 * Create a destory transactionestroy
 *
 * @param {Object} params
 * @param {import('@zen/zenjs').Wallet} params.wallet
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @param {PrivateKeyArray} params.privateKey
 * @returns {Promise}
 */
export async function destroy({ wallet, spend } = {}) {
  return wallet
    .destroy({
      outputs: [
        {
          ...spend,
          amount: assetUtils.toKalapas(spend.amount),
          asset: assetUtils.getAssetRepresentationId(spend.asset),
        },
      ],
    })
    .catch((error) => {
      throw new HttpError(error, { clientErrorMsg: 'Insufficient funds' });
    });
}

/**
 * @typedef {[external: import('@zen/zenjs').PrivateKey, change: import('@zen/zenjs').PrivateKey]} PrivateKeyArray
 */
