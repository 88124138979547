import { css } from 'styled-components';
import { darken, lighten } from 'polished';
const colorMultiplier = 0.05;

/**
 * Get css for hover and active states
 */
const getStateColors = ({ property = 'background-color', value = '', props }) => {
  if (value && !props.disabled) {
    // bg can be a non valid css color (theme color name without an entry)
    let lightened = value;
    let darkened = value;
    try {
      lightened = lighten(colorMultiplier, value);
      darkened = darken(colorMultiplier, value);
    } catch (error) {
      // suppressing errors from polished
    }
    return css`
      &:hover {
        ${`${property}: ${lightened}`}
      }
      &:active {
        ${`${property}: ${darkened}`}
      }
    `;
  }

  return css``;
};

export default getStateColors;
