import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Title,
  RadioGroup,
  ConfirmationModal,
  useModal,
  themeUtils as tu,
} from '@zen-common/components-base';

export const actions = {
  change: 'CHANGE',
};

const NodeConnectivitySetting = ({ useSetting, showWallet = false }) => {
  const { value, items, onAction } = useSetting();
  const { show, hide } = useModal();

  const onChange = (e) => {
    if (typeof onAction === 'function') {
      const selectedValue = e.target.value;
      const selectedChainName = selectedValue.split(' ')[0] === 'main' ? 'Mainnet' : 'Testnet';
      const currentChainName = value.split(' ')[0] === 'main' ? 'Mainnet' : 'Testnet';
      show(() => (
        <ConfirmationModal
          dismissOnClickOutside={true}
          showClose={false}
          onCancel={hide}
          onConfirm={() => {
            hide();
            onAction({ type: actions.change, value: selectedValue });
          }}
          title={<Title>Confirm switching chain</Title>}
        >
          You are about to switch from {currentChainName} to {selectedChainName}.
          {showWallet && 'After switching you will be redirected to import wallet or login screen.'}
        </ConfirmationModal>
      ));
    }
  };

  return (
    <StyledRadioGroup checked={value} onChange={onChange} options={items} column hasSeparator />
  );
};

NodeConnectivitySetting.propTypes = {
  useSetting: PropTypes.func,
};

export default NodeConnectivitySetting;

const StyledRadioGroup = styled(RadioGroup)`
  li {
    padding: ${tu.space('sm')} 0;
  }
`;
