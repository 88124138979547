import { useReducer, useEffect, useCallback } from 'react';
import { defaultTo, mapObjIndexed } from 'ramda';
import reducer, { ACTIONS } from './settingsReducer';

const STORAGE_PREFIX = 'settings-';

export default function useSettingsStore({ storage, initialState = {}, validation = {} } = {}) {
  const [state, dispatch] = useReducer(
    reducer,
    { initialState, validation },
    ({ initialState, validation }) => ({
      settings: mapObjIndexed((val, key) => {
        // try to get all properties of initialState from storage
        const storageVal = storage.get(STORAGE_PREFIX + key);
        const storageValueValid = validate({
          key,
          value: storageVal,
          state: initialState,
          validation,
        });
        return defaultTo(val, storageValueValid ? storageVal : null);
      }, initialState),
    })
  );

  useSaveSettings({ storage, settings: state.settings });

  return {
    state,
    actions: {
      setSetting: useCallback(
        ({ name, value } = {}) => {
          if (validate({ key: name, value, state, validation })) {
            dispatch({
              type: ACTIONS.SET_SETTING_REQUESTED,
              payload: { name, value },
            });
          }
        },
        [state, validation]
      ),
    },
  };
}

/**
 * Save the settings object using storage, key by key, whenever setting change
 */
function useSaveSettings({ storage, settings }) {
  useEffect(() => {
    Object.keys(settings).forEach((key) => {
      storage.set(STORAGE_PREFIX + key, settings[key]);
    });
  }, [settings, storage]);
}

/**
 * Validates a setting
 *
 * @returns {boolean} true if valid, otherwise false
 */
function validate({ key, value, validation, state } = {}) {
  return typeof validation[key] === 'function' ? validation[key](value, state) : true;
}
