import createStore from '../../utils/createStore';
import useWalletState from './modules/useWalletState';

const { Provider, useStore } = createStore({
  useStoreHook: useWalletState,
  contextDisplayName: 'WalletStoreContext',
});

export default {
  /**
   * @type {TWalletStoreProvider}
   */
  Provider,
  /**
   * @type {TUseWalletStore}
   */
  useStore,
};

/**
 * @typedef {({chain: string, storage: Object, nodeUrl: string}) => void} TWalletStoreProvider
 */

/**
 * @typedef {() => TWalletStore} TUseWalletStore
 */

/** @typedef {('Nomination'|'Allocation'|'Payout')} TCgpCommand */

/**
 * @typedef {{
      chain: ('test'|'main');
      isLoggedIn: boolean;
      hasPassword: boolean;
      keys: {
        address: string;
        pkHash: string;
        pubKey: string;
        addresses: sting[]
      },
      encryptedSeed: {
        test: string;
        main: string;
      },
      wallet: {};
      balance: {[asset:string]: string};
      balances: [{asset: string, amount: string}];
      assets: [string];
      loading: {};
      executing: boolean;
    }} TWalletState
 */
/**
 * @typedef {{
      create: ({ mnemonic: string, password: string }) => Promise<Object>;
      wipeSeed: () => void;
      login: (password: string) =>  Promise<void>;
      logout: () => void;
      changePassword: ({ currentPassword: string, newPassword: string }) => Promise<void>;
      getPrivateKeys: (password: string) => Promise<{mnemonic: string, privateKey: string }>;
      checkPassword: (password: string) => Promise<boolean>;
      fetchBalance: () => Promise<void>;
      fetchTransactions: ({ skip: number, take: number }) => Promise<[{confirmations: number, asset: string, amount: number, txHash: string}]>;
      fetchTransactionsCount: () => Promise<number>;
      send: ({ address: string, spends: [{asset: string, amount: string}], password: string }) => Promise<void>;
      signSendTx: ({ address, spends, password }) => Promise<string>;
      signTx: ({tx: string, password: string}) => string,
      execute: ({ address: string, command: string, includeReturnAddress: boolean, messageBody: string, path: string, spends: [{asset: string, amount: string}] }) => Promise<string>;
      executeAndPublish: ({ address: string, command: string, includeReturnAddress: boolean, messageBody: string, path: string, spends: [{asset: string, amount: string}], password: string }) => Promise;
      signContractExecution: ({tx: string, sign: string, password: string}) => string,
      submitCgpBallot: ({ command: TCgpCommand, ballotData: Object, interval: number, votingContractAddress: string, password: string }) => Promise<void>;
      submitRepoVote: ({ commitId: string, phase: TGovernancePhaseName, interval: number, votingContractAddress: string, password: string }) => Promise<void>;
      activateContract: ({ code: string, numberOfBlocks: number }) => Promise<string>,
      extendContract: ({ contractId: string, numberOfBlocks: number }) => Promise<string>,
      reset: () => void,
      poll: {
        balance: {
          start: () => void;
          stop: () => void;
          getPolling: () => boolean;
        },
      },
    }} TWalletActions
 */

/**
 * @typedef {{
 *   state: TWalletState;
 *   actions: TWalletActions;
 * }} TWalletStore
 */

/** @typedef {('Contestant'|'Candidate')} TGovernancePhaseName */
