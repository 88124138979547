import * as React from 'react';
import { Box, CopyableHash, Title, ConfirmationModalContent } from '@zen-common/components-base';
import { numberUtils, ValueDisplay } from '@zen/common-utils';
import {
  TechnicalData as TD,
  SpendsTechnicalData,
  SpendUtils,
  useSpendsTechnicalData,
  NamingAssetStore,
} from '@zen/common-app-parts';

export default function VoteOnceModalContent({
  type,
  ballot,
  recipient,
  spends,
  snapshotZpBalance,
  allocation,
  onCancel,
  onConfirm,
}) {
  const { state: naming } = NamingAssetStore.useStore();
  return (
    <ConfirmationModalContent
      title={<Title>You can only vote once</Title>}
      subtitle="You can only vote once on each phase in an interval and only the first vote counts."
      confirmText="Continue"
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <Box>
        <VoteForSection
          type={type}
          ballot={ballot}
          recipient={recipient}
          allocation={allocation}
          spends={spends}
          naming={naming}
        />
        <TD.Section>
          <TD.Title>Vote/TX Info</TD.Title>
          <Box>
            <TD.Label>Vote weight:</TD.Label>
            <TD.Value>{numberUtils.toDisplay(snapshotZpBalance)} ZP</TD.Value>
          </Box>
          <Box>
            <TD.Label>Tx fee:</TD.Label>
            <TD.Value>0.00000001 ZP</TD.Value>
          </Box>
        </TD.Section>
      </Box>
    </ConfirmationModalContent>
  );
}

function VoteForSection({ type, ballot, recipient, spends, allocation, naming }) {
  const spendsTD = useSpendsTechnicalData({
    spends: (spends || []).map((spend) =>
      SpendUtils.getSpend({
        asset: spend.asset,
        assetValid: true,
        amount: ValueDisplay.create(spend.amount),
        amountValid: true,
      })
    ),
  });
  const showMore =
    (spends || []).length > 3 ? (
      <TD.ShowMoreLink isMore={spendsTD.isMore} onClick={spendsTD.toggleMore} />
    ) : null;
  return (
    <TD.Section>
      <Box>
        <TD.Title>Vote For</TD.Title>
        {showMore}
      </Box>
      {type === 'allocation' ? (
        <Box>
          <TD.Label>Allocation:</TD.Label>
          <TD.Value>{allocation} ZP</TD.Value>
        </Box>
      ) : (
        <>
          <Box>
            <TD.Label>Ballot ID:</TD.Label>
            <TD.Value>
              <CopyableHash hash={ballot} />
            </TD.Value>
          </Box>
          <Box>
            <TD.Label>Recipient address:</TD.Label>
            <TD.Value>
              <CopyableHash hash={recipient} />
            </TD.Value>
          </Box>
          <SpendsTechnicalData spends={spendsTD.spends} naming={naming} />
        </>
      )}
    </TD.Section>
  );
}
