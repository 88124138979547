import React from 'react';
import { SubNav } from '@zen-common/components-base';
import { SubNavLink, WalletStore } from '@zen/common-app-parts';
import PATHS from '../../paths';

export default function SettingsNav() {
  const {
    state: { isLoggedIn },
  } = WalletStore.useStore();

  return (
    <SubNav>
      {isLoggedIn && (
        <SubNavLink href={PATHS.SETTINGS_ACCOUNT} activeClassName="active">
          Account
        </SubNavLink>
      )}
      <SubNavLink href={PATHS.SETTINGS_CONNECTIVITY} activeClassName="active">
        Node Connectivity
      </SubNavLink>
    </SubNav>
  );
}
