/* eslint-disable react/display-name */
import * as React from 'react';
import { ConfirmationModalContent, Title } from '@zen-common/components-base';
import {
  WalletStore,
  SettingsStore,
  ConfirmPublishTxModalContent,
  PasswordRequiredModalContent,
} from '@zen/common-app-parts';
import { ValueDisplay } from '@zen/common-utils';
import { useUnmountedRef } from '@zen/common-react-hooks';
import usePreventAction from '../../../../utils/usePreventAction';
import ExtendStore from '../../../../stores/ExtendStore';
import * as actions from '../../../../stores/ExtendStore/actions';
import ExtendFormModalContent from './ExtendFormModalContent';

export default function ExtendContractModalProcess({ contractId, onCancel, onSuccess, onError }) {
  const store = ExtendStore.useStore();
  const unmounted = useUnmountedRef();
  const prevStatus = React.useRef();
  const [status, setStatus] = React.useState('form');
  /** @type {[numberOfBlocks: ValueDisplay]} */
  const [numberOfBlocks, setNumberOfBlocks] = React.useState(ValueDisplay.create());
  const [error, setError] = React.useState('');
  const unsignedTx = React.useRef();
  const [signedTx, setSignedTx] = React.useState();
  const { preventAction } = usePreventAction();
  const wallet = WalletStore.useStore();
  const {
    state: {
      settings: { nodeUrl },
    },
  } = SettingsStore.useStore();

  const setStatusAndPrev = (s) => {
    prevStatus.current = status;
    setStatus(s);
  };

  const handleNumberOfBlocksChange = (display) =>
    setNumberOfBlocks(numberOfBlocks.onDisplayChange(display));

  const handleFormCancel = () => {
    onCancel();
  };
  const handleFormContinue = async () => {
    if (preventAction()) return;

    try {
      unsignedTx.current = await actions.extend({
        dispatch: store.dispatch,
        contractId,
        numberOfBlocks: Number(numberOfBlocks.safeValue),
        wallet,
      });
      if (!unmounted.current) {
        setStatusAndPrev('password');
      }
    } catch (error) {
      if (!unmounted.current) {
        setError(error.message);
        setStatusAndPrev('error');
      }
    }
  };

  const handlePasswordBack = () => {
    unsignedTx.current = null;
    setStatusAndPrev('form');
  };
  const handlePasswordContinue = async (password) => {
    if (preventAction() || !unsignedTx.current) return;

    try {
      const tx = await actions.sign({
        dispatch: store.dispatch,
        password,
        tx: unsignedTx.current,
        wallet,
      });
      if (!unmounted.current) {
        setSignedTx(tx);
        setStatusAndPrev('confirm-after-sign');
      }
    } catch (error) {
      if (!unmounted.current) {
        setError(error.message);
        setStatusAndPrev('error');
      }
    }
  };

  const handleConfirmAfterSignBack = () => {
    unsignedTx.current = null;
    setSignedTx(null);
    setStatusAndPrev('form');
  };
  const handleConfirmAfterSignContinue = async () => {
    if (preventAction() || !signedTx) return;

    try {
      await actions.publish({ dispatch: store.dispatch, nodeUrl, tx: signedTx });
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  const handleErrorBack = () => {
    setStatus(prevStatus.current);
  };
  const handleErrorClose = () => {
    onCancel();
  };

  if (status === 'form') {
    return (
      <ExtendFormModalContent
        progress={store.state.progress}
        contractId={contractId}
        numberOfBlocks={numberOfBlocks}
        onNumberOfBlocksChange={handleNumberOfBlocksChange}
        onCancel={handleFormCancel}
        onContinue={handleFormContinue}
      />
    );
  }

  if (status === 'password') {
    return (
      <PasswordRequiredModalContent
        cancelText="Back"
        onCancel={handlePasswordBack}
        onSuccess={handlePasswordContinue}
      />
    );
  }

  if (status === 'confirm-after-sign') {
    if (!signedTx) return null;

    return (
      <ConfirmPublishTxModalContent
        progress={store.state.progress}
        title="Confirm TX before publishing"
        tx={signedTx}
        onBack={handleConfirmAfterSignBack}
        onConfirm={handleConfirmAfterSignContinue}
      />
    );
  }

  if (status === 'error') {
    return (
      <ConfirmationModalContent
        title={<Title>An error has occurred</Title>}
        cancelText="Back"
        confirmText="Close"
        onCancel={handleErrorBack}
        onConfirm={handleErrorClose}
      >
        {error}
      </ConfirmationModalContent>
    );
  }

  return null;
}
