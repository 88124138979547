import { useState } from 'react';
import { SeedPhrase } from '@zen/common-utils-seed';

const {
  generateMnemonic,
  getEmptyPhrase,
  isWordValidAndFinal,
  getPhraseFromClipboardContents,
  isValidBip39Phrase,
  areAllWordsValid,
} = SeedPhrase;

/**
 * The inner logic of the mnemonic handling
 * @param {Object} params
 * @param {[string]} params.initialValue - the initial mnemonic
 * @param {Boolean} params.generate - whether to generate a new mnemonic on load
 */
export default function useMnemonic({ initialValue = [], generate = false } = {}) {
  const initialMnemonic = initialValue.length
    ? initialValue
    : generate
    ? generateMnemonic()
    : getEmptyPhrase();
  const [mnemonic, setMnemonic] = useState(initialMnemonic);

  const wordChangeHandler = (index, value) => {
    let _mnemonic = mnemonic.slice(); // do not mutate
    const phraseFromClipboardContent = getPhraseFromClipboardContents(value);
    if (index === 0 && phraseFromClipboardContent.length) {
      _mnemonic = phraseFromClipboardContent.slice(0, initialMnemonic.length); // in case the mnemonic has different length
    } else {
      _mnemonic[index] = value;
    }
    setMnemonic(_mnemonic);
  };

  /**
   * Clears the mnemonic, sets all words to empty strings
   */
  const clear = () => setMnemonic(getEmptyPhrase());

  /**
   * Reset the mnemonic to the initial value
   */
  const reset = () => setMnemonic(initialValue);

  return {
    clear,
    reset,
    wordChangeHandler,
    mnemonic,
    /** @type {[{word: string, validity: {valid: boolean, final: boolean} }]} */
    wordsList: mnemonic.map((word) => ({ word, validity: getWordValidity(word) })),
    /** is every word valid */
    allWordsValid: areAllWordsValid(mnemonic),
    /** is the entire phrase a valid mnemonic */
    validMnemonic: isValidBip39Phrase(mnemonic),
  };
}

/**
 * Get a "validity" object with a valid attribute defined or not
 */
function getWordValidity(word) {
  const validity = {};
  const _validState = isWordValidAndFinal(word);
  validity.valid = !word ? undefined : _validState.valid;
  validity.final = !word ? undefined : _validState.final;
  return validity;
}
