import { useRef, useEffect } from 'react';

/**
 * Returns a ref which is true when the component is unmounted
 */
export default function useUnmountedRef() {
  const ref = useRef(false);

  useEffect(() => () => (ref.current = true), []);

  return ref;
}
