import * as React from 'react';
import {
  WalletStore,
  TitleContentLayout,
  SendExecuteStore,
  ApiQueries,
} from '@zen/common-app-parts';
import { Box, useModal, Modal, ModalContent } from '@zen-common/components-base';
import { useUnmountedRef } from '@zen/common-react-hooks';
import SendExecuteForm from '../../components/SendExecuteForm';
import usePreventAction from '../../utils/usePreventAction';
import ExecuteModalProcess from './components/ExecuteModalProcess';
import ConfirmSendModalProcess from './components/ConfirmSendModalProcess';

export default function Send({ fields }) {
  const unmounted = useUnmountedRef();
  const { hide, show, isOpen } = useModal();
  const { data: contracts } = ApiQueries.useFetchActiveContracts(10000);
  const { sendStore, executeStore, isExecute } = SendExecuteStore.useStore();
  const [processStarted, setProcessStarted] = React.useState(false);
  const { preventAction } = usePreventAction();
  const {
    actions: { fetchBalance },
  } = WalletStore.useStore();

  // mount: fetch balance and reset form
  React.useEffect(() => {
    fetchBalance().catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // in case fields was provided, set it in the stores
  const sendStoreSetAddress = sendStore.actions.setAddress;
  const executeStoreSetAddress = executeStore.actions.setAddress;
  React.useEffect(() => {
    if (fields) {
      sendStoreSetAddress(fields);
      executeStoreSetAddress(fields);
    }
  }, [executeStoreSetAddress, fields, sendStoreSetAddress]);
  // set active contracts in store
  React.useEffect(() => {
    executeStore.actions.setActiveContracts(contracts);
  }, [contracts, executeStore.actions]);

  // close process when modal is closed
  React.useEffect(() => {
    if (!isOpen) {
      setProcessStarted(false);
    }
  }, [isOpen]);

  function cancelProcess() {
    hide();
  }
  function handleSendExecuteSuccess(success) {
    if (!unmounted.current && success) {
      sendStore.actions.reset();
      executeStore.actions.reset();
      hide();
    }
  }
  const handleExecute = async () => {
    if (preventAction()) return;

    setProcessStarted(true);

    // get the tx
    const runResult = await executeStore.actions.run();
    if (!runResult || !runResult.tx) {
      setProcessStarted(false);
      return;
    }

    show(() => (
      <Modal onDismiss={cancelProcess}>
        <ModalContent onClose={cancelProcess}>
          <ExecuteModalProcess
            runResult={runResult}
            onCancel={cancelProcess}
            onFinish={handleSendExecuteSuccess}
          />
        </ModalContent>
      </Modal>
    ));
  };

  const handleSend = async () => {
    setProcessStarted(true);

    show(() => (
      <Modal onDismiss={cancelProcess}>
        <ModalContent onClose={cancelProcess}>
          <ConfirmSendModalProcess onCancel={cancelProcess} onFinish={handleSendExecuteSuccess} />
        </ModalContent>
      </Modal>
    ));
  };

  return (
    <TitleContentLayout title={isExecute ? 'Send/Execute' : 'Send'}>
      <Box maxWidth="formsMaxWidth">
        <SendExecuteForm disabled={processStarted} onExecute={handleExecute} onSend={handleSend} />
      </Box>
    </TitleContentLayout>
  );
}
