import { Decimal } from 'decimal.js';
import constants from './constants';

const { ZEN_DECIMAL_PLACES: PRECISION, NUMBERS_DELIMITER: DELIMITER } = constants;

/**
 * Get the string value representation of a number
 *
 * @param {(number|string|Decimal)} n - the number to convert
 * @returns {string} a string representation of the value
 */
export function toValue(n) {
  if (n === '') return '';

  const d = new Decimal(n);
  return d.toFixed(d.decimalPlaces() || 0); // make sure no exponential notation!
}

/**
 * Verify the input is not Nan
 *
 * @param {(number|string|Decimal)} n - the number to convert
 * @returns {number|string|Decimal} return the input
 */
export function verify(n) {
  if (n === '') return 0;
  if (isNaN(n)) return 0;
  const d = new Decimal(n);
  return d || 0;
}

/**
 * Prepare a number for final display: add a delimiter and trim the fractional part
 *
 * @param {string|number} n - the number to display
 * @param {number} fractionDigits - the number of decimal places
 * @returns {string}
 */
export function toDisplay(n, fractionDigits = PRECISION) {
  if (n == '0') return '0'; // support both number and string
  if (!n) return '';
  if (isNaN(n)) return n;

  const d = new Decimal(n);
  if (d.isZero()) return '0'; // n can be "0." for example

  const minDividedUnit = Math.pow(10, -1 * fractionDigits);

  const fixed = d.abs().lessThan(minDividedUnit)
    ? (() => {
        // get a number with a fractional part with the first digit which is not zero
        const parts = d.toFixed(d.decimalPlaces() || 0).split('.');
        const arrFractional = Array.from(parts[1]);
        const nonZeroIndex = arrFractional.findIndex((char) => char !== '0');
        return parts[0] + '.' + parts[1].substring(0, nonZeroIndex + 1);
      })()
    : // get the number with up to PRECISION decimal places, in this case number has a whole part
      d.toFixed(Math.min(fractionDigits, d.decimalPlaces() || 0), Decimal.ROUND_FLOOR);

  const parts = fixed.split('.');

  let whole = parts[0];
  let fraction = parts.length > 1 && !new Decimal(parts[1]).isZero() ? '.' + parts[1] : '';
  return whole.replace(/\B(?=(\d{3})+(?!\d))/g, DELIMITER) + fraction;
}

/**
 * Prepare a number for edit display within an input: add a delimiter
 *
 * @param {string} n - the number to display
 * @returns {string}
 */
export function toEditDisplay(n) {
  if (!n) return '';
  if (isNaN(n)) return n;

  const parts = n.split('.');
  const whole = parts[0];
  const fraction = parts.length > 1 ? '.' + parts[1] : '';

  return whole.replace(/\B(?=(\d{3})+(?!\d))/g, DELIMITER) + fraction;
}

/**
 * Remove all chars except digits and dots
 * Remove leading zeros
 *
 * @param {string} n - the number to clean
 */
export function clean(n) {
  const stripped = String(n).replace(/[^0-9.]/g, '');
  const parts = stripped.split('.');

  if (parts.length > 1) {
    const whole = parts[0];
    const rest = parts.slice(1);
    const cleanedWhole =
      whole === ''
        ? '0'
        : whole.replace(/^0+/g, (match, offset, original) => {
            // number can be 01.1 or 00.1
            return match === original ? '0' : '';
          });

    return `${cleanedWhole}.${rest.join('.')}`;
  }

  return stripped.replace(/^0+/g, (match, offset, original) => {
    // check if original is just a zero
    const justZeros = new RegExp('^0+$', 'g');
    return justZeros.test(original) ? '0' : '';
  });
}

/**
 * Given a previous number and a new number which differ in only 1 char,
 * returns a new string with only the last dot occurrence
 *
 * @param {string} prevNumber
 * @param {string} newNumber
 */
export function changeDotPosition(prevNumber, newNumber) {
  const newParts = newNumber.split('.');
  // assuming there can only be 2 dots
  if (newParts.length == 3) {
    const option1 = newParts[0] + '.' + newParts[1] + newParts[2];
    const option2 = newParts[0] + newParts[1] + '.' + newParts[2];
    return prevNumber === option1 ? option2 : option1;
  } else if (newParts.length > 3) {
    // more than 2 dots - just strip all except the first
    return newParts[0] + '.' + newParts.slice(1).join('');
  }
  return newNumber;
}

/**
 * Checks if value is zero
 *
 * @param {(string|number)} value
 * @returns {boolean} true if value is zero, otherwise false
 */
export function isZero(value) {
  return new Decimal(value || 0).isZero(); // decimal does not support an empty string
}

/**
 * Truncate the decimal part of a number
 * @param {string} value - the number to truncate
 * @param {string} decimals - the number of decimal places to truncate to, defaults to 8
 */
export function truncateToDecimalPlaces(value, decimals = constants.ZEN_DECIMAL_PLACES) {
  const _value = String(value);

  const parts = _value.split('.');
  return parts.length > 1 && parts[1].length > decimals
    ? toEditDisplay(`${parts[0]}.${parts[1].substring(0, decimals)}`)
    : _value;
}

/**
 * Get a formatted ordinal number (1st, 2nd etc.)
 * @param {(string|number)} number
 */
export function getOrdinal(number) {
  if (isNaN(number)) return '';

  const n = Number(number);
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return toDisplay(n) + (s[(v - 20) % 10] || s[v] || s[0]);
}

/**
 * Clamps a value between a minimum and a maximum
 * @param {(number|string)} value
 * @param {(number|string)} min
 * @param {(number|string)} max
 */
export function clamp(value, min, max) {
  return Decimal.max(min, Decimal.min(value, max)).toNumber();
}

/**
 * Represent price from per multiples of 10 units
 * @param {(number|string)} value
 * @param {(number)} multiples
 */
export function getPrice(value, multiples = 100) {
  return new Decimal(value).dividedBy(multiples).toString();
}
