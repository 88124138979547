import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import {
  Box,
  Title,
  Paragraph,
  Button,
  FaSvg,
  themeUtils as tu,
  Checkbox,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalSeparator,
} from '@zen-common/components-base';
import ErrorReportingStore from '../../stores/ErrorReportingStore';
import SettingsStore from '../../stores/SettingsStore';

export default function ErrorPage({ error, onRestart }) {
  const [reported, setReported] = useState(false);
  const {
    state: {
      settings: { errorReporting },
    },
    actions: { setSetting },
  } = SettingsStore.useStore();
  const { report } = ErrorReportingStore.useStore();

  const displayCheckboxes = useRef(!errorReporting.doNotAsk); // set on mount only

  // report is user agreed and did not reported yet
  const reportError = useCallback(() => {
    if (errorReporting.report && !reported) {
      setReported(true);
      report({ error, attributes: { errorType: 'React Error Boundary' } });
    }
  }, [error, errorReporting.report, reported, report]);

  const handleRestartClick = (e) => {
    e.preventDefault();
    reportError();
    typeof onRestart === 'function' && onRestart(e);
  };

  // try to report on mount
  useEffect(() => {
    reportError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // try to report before window unload
  useEffect(() => {
    window.addEventListener('beforeunload', reportError);

    return () => {
      window.removeEventListener('beforeunload', reportError);
    };
  }, [reportError]);

  return (
    <StyledContainer>
      <StyledModalContent showClose={false}>
        <ModalHeader>
          <FaSvg icon={faExclamationTriangle} color="danger" fontSize="56px" />
        </ModalHeader>
        <ModalHeader>
          <Title>There was an error rendering the app</Title>
          <Paragraph>
            We apologize for any inconvenience. Please try to restart the app again, and if the
            error continue, contact us.
          </Paragraph>
        </ModalHeader>
        <ModalSection>
          <Paragraph>Error description: {error && error.message}</Paragraph>
        </ModalSection>
        <ModalSeparator />
        {displayCheckboxes.current && (
          <ModalSection>
            <Box mb="xs">
              <Checkbox
                checked={errorReporting.report}
                onChange={(e) =>
                  setSetting({
                    name: 'errorReporting',
                    value: { report: e.target.checked, doNotAsk: errorReporting.doNotAsk },
                  })
                }
                label="Send future error reports automatically"
              />
            </Box>
            <Checkbox
              checked={errorReporting.doNotAsk}
              onChange={(e) =>
                setSetting({
                  name: 'errorReporting',
                  value: { report: errorReporting.report, doNotAsk: e.target.checked },
                })
              }
              label="Don't ask me again"
            />
          </ModalSection>
        )}
        <ModalFooter>
          <Button onClick={handleRestartClick}>Restart</Button>
        </ModalFooter>
      </StyledModalContent>
    </StyledContainer>
  );
}

ErrorPage.propTypes = {
  error: PropTypes.object,
};

const StyledContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const StyledModalContent = styled(ModalContent)`
  border-top: 4px solid ${tu.color('danger')};
`;
