import React from 'react';
import { Button } from '@zen-common/components-base';
import { useCanReadClipboard } from '@zen/common-react-hooks';
import { clipboard } from '@zen/common-utils-browser';

/**
 * A paste button to be placed on the right of an input
 * Will not render if browser does not support paste or on mobile
 * @param {import('@zen-common/components-base').ButtonProps} props
 */
export default function PasteButton({ onPaste, ...props }) {
  const canPaste = useCanReadClipboard();

  const handleClick = async () => {
    try {
      if (typeof onPaste === 'function') {
        onPaste(await clipboard.readText());
      }
    } catch (error) {
      console.error('Could not paste');
    }
  };

  return canPaste ? (
    <Button
      bg="secondary"
      ml="xs"
      display={['none', 'block']}
      alignSelf="flex-start"
      {...props}
      onClick={handleClick}
    >
      Paste
    </Button>
  ) : null;
}
