export const PAGE_NAMES = {
  StartOnBoarding: 'StartOnBoarding',
  Terms: 'Terms',
  ImportWallet: 'ImportWallet',
  SecretPhrase: 'SecretPhrase',
  SecretPhraseQuiz: 'SecretPhraseQuiz',
  SetPassword: 'SetPassword',
};

export const STEPS = {
  import: [PAGE_NAMES.ImportWallet, PAGE_NAMES.SetPassword],
  create: [PAGE_NAMES.SecretPhrase, PAGE_NAMES.SecretPhraseQuiz, PAGE_NAMES.SetPassword],
};

/**
 * Get the pagination props for each onboarding page
 */
export const getPaginationProps = ({ type, setType, setCurPage } = {}) => {
  const gotoStart = () => {
    setType('');
    setCurPage(PAGE_NAMES.StartOnBoarding);
  };
  const gotoNext = (page) => {
    const index = findStepIndex(page, type); // if not found, returns -1, then we go to step 0
    if (STEPS.create.length > index + 1) {
      setCurPage(STEPS[type][index + 1]);
    }
  };
  const gotoPrev = (page) => {
    const index = findStepIndex(page, type);
    if (index > 0) {
      setCurPage(STEPS[type][index - 1]);
    } else {
      gotoStart();
    }
  };

  return {
    [PAGE_NAMES.StartOnBoarding]: {
      onCreate() {
        setType('create');
        setCurPage(PAGE_NAMES.Terms);
      },
      onImport() {
        setType('import');
        setCurPage(PAGE_NAMES.Terms);
      },
    },
    [PAGE_NAMES.ImportWallet]: {
      onBack() {
        gotoStart();
      },
      onContinue() {
        gotoNext(PAGE_NAMES.ImportWallet);
      },
      onCreateWallet() {
        setType('create');
        setCurPage(STEPS.create[0]);
      },
    },
    [PAGE_NAMES.Terms]: {
      onCancel() {
        gotoStart();
      },
      onContinue() {
        gotoNext(PAGE_NAMES.Terms);
      },
    },
    [PAGE_NAMES.SecretPhrase]: {
      onBack() {
        gotoStart();
      },
      onContinue() {
        gotoNext(PAGE_NAMES.SecretPhrase);
      },
    },
    [PAGE_NAMES.SecretPhraseQuiz]: {
      onBack() {
        gotoPrev(PAGE_NAMES.SecretPhraseQuiz);
      },
      onContinue() {
        gotoNext(PAGE_NAMES.SecretPhraseQuiz);
      },
      onCancel() {
        gotoStart();
      },
    },
    [PAGE_NAMES.SetPassword]: {
      onBack() {
        gotoStart();
      },
    },
  };
};

const findStepIndex = (page, type) => STEPS[type].findIndex((step) => step === page);
