import React from 'react';
import { Centered, Header } from '@zen-common/components-base';

/**
 * A simple not found page
 */
export default function NotFound() {
  return (
    <Centered fullHeight>
      <section>
        <Header>404</Header>
        <Header size="3">The page you are looking for was not found</Header>
      </section>
    </Centered>
  );
}
