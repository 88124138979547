import * as React from 'react';
import styled from 'styled-components';
import { Box, CopyableAsset } from '@zen-common/components-base';
import * as TD from '../TechnicalData/TechnicalData';

/**
 * To be used in a confirmation modal
 * @param {Object} props
 * @param {[import('./spendUtils').Spend]} props.spends
 */
export default function SpendsTechnicalData({ spends, naming }) {
  return (
    <Table>
      {spends.map((spend) => (
        <Row key={spend.id}>
          <Col>
            <Asset spend={spend} naming={naming} />
          </Col>
          <Col>
            <Amount spend={spend} />
          </Col>
        </Row>
      ))}
    </Table>
  );
}

function Asset({ spend, naming }) {
  return (
    <>
      <TD.Label>Asset:</TD.Label>
      <TD.Value>
        <CopyableAsset hash={spend.asset} naming={naming} />
      </TD.Value>
    </>
  );
}
function Amount({ spend }) {
  return (
    <>
      <TD.Label>Amount:</TD.Label>
      <TD.Value>{spend.amount.display}</TD.Value>
    </>
  );
}

const Table = styled(Box)`
  display: table;
`;

const Row = styled(Box)`
  display: table-row;
`;

const Col = styled(Box)`
  display: table-cell;
`;
