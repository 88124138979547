import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, LoadingOr } from '@zen-common/components-base';
import Layout from '../../blocks/Layout';
import Footer from '../../blocks/Footer';
import Header from '../../blocks/Header';
import useSubmitWithLoading from '../../hooks/useSubmitWithLoading';
import TextContainer from '../../blocks/TextContainer';
import PaginationButton from '../../blocks/PaginationButton';

import TermsText from './components/Text';

export default function Terms({ onCancel, onContinue }) {
  const [checked, setChecked] = useState(false);
  const submitDisabled = !checked;
  const onCheckedChanged = (e) => setChecked(e.target.checked);
  const { handleSubmit, loading } = useSubmitWithLoading({
    validate: () => !submitDisabled,
    onSubmit: onContinue,
  });

  return (
    <Layout
      header={
        <Header
          title="Terms and Conditions"
          text="Please carefully read these terms before accepting."
        />
      }
      body={
        <TextContainer>
          <TermsText />
        </TextContainer>
      }
      footer={
        <Footer
          left={
            <label>
              <Checkbox
                borderColor="primary"
                checked={checked}
                onChange={onCheckedChanged}
                label="By continuing I agree to the Terms of Conditions."
              />
            </label>
          }
          right={
            <>
              <PaginationButton onClick={onCancel} mr="smd" bg="secondary">
                Cancel
              </PaginationButton>
              <PaginationButton disabled={submitDisabled} onClick={handleSubmit}>
                <LoadingOr loading={loading}>I Agree</LoadingOr>
              </PaginationButton>
            </>
          }
        />
      }
    />
  );
}
Terms.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};
