/**
 * Contains an error coming from http
 *
 * @class HttpError
 * @extends {Error}
 */
export default class HttpError extends Error {
  /**
   * @param {*} error
   * @param {HttpErrorOptions} options
   * @memberof HttpError
   */
  constructor(error, options = {}) {
    super(getErrorMessage(error, options));

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError);
    }

    this.name = 'HttpError';
    this.status = (error.response || {}).status || 0; // no status by default
  }
}
/**
 * @param {*} error
 * @param {HttpErrorOptions} options
 * @returns the error message
 */
function getErrorMessage(error, options) {
  const response = error.response || {};
  return response.status >= 400 && response.status < 500
    ? options.clientErrorMsg || response.data || 'There was an error with the supplied data'
    : response.status >= 500
    ? options.serverErrorMsg ||
      response.data ||
      'The server responded with an error, please try again later'
    : response.data
    ? response.data
    : error.request
    ? options.notRespondingErrorMsg || 'The server is not responding, please try again later'
    : error.message;
}

/**
 * Converts an error to a HttpError and throws it
 *
 * @param {*} error - the original error to convert
 */
export function throwHttpError(error) {
  throw new HttpError(error);
}

/**
 * @typedef {Object} HttpErrorOptions
 * @property {string} clientErrorMsg - a custom message for the client error
 * @property {string} serverErrorMsg - a custom message for the server error
 * @property {string} notRespondingErrorMsg - a custom message for the server not responding error
 */
