import React, { useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import {
  Box,
  Form,
  FormBody,
  FormFooter,
  FormGroup,
  InputRange,
  Button,
  Input,
  InputAddonText,
  InputAddonNumberControls,
  useUpDownNumberControl,
  InputMessage,
  LoadingOr,
  Paragraph,
  themeUtils as tu,
} from '@zen-common/components-base';
import { ValueDisplay } from '@zen/common-utils';

export default function AllocationForm({ min = 0, max = 50, prev, onVote, progress, disabled }) {
  const [allocation, setAllocation] = useState(ValueDisplay.create(''));

  const formDisabled = progress || disabled;

  const handleChange = useCallback((value) => {
    value = String(value);
    if (value.indexOf('.0') > -1) value = parseInt(value, 10);
    if (value % 0.5) value = parseInt(value, 10) + 0.5;
    if (value > 50) value = 50;

    setAllocation((a) => a.onDisplayChange(String(value)));
  }, []);

  const valid = useMemo(() => allocation.safeValue >= min && allocation.safeValue <= max, [
    allocation.safeValue,
    max,
    min,
  ]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (valid && !progress) {
      typeof onVote === 'function' && (await onVote({ allocation: allocation.safeValue }));
    }
  };

  const color = !allocation.display ? 'label' : valid ? 'primary' : 'danger';

  const { onKeyDown } = useUpDownNumberControl({
    min,
    max,
    value: allocation.safeValue,
    onChange: handleChange,
  });

  return (
    <Box>
      <StyledParagraph>
        You may vote to move the allocation amount up to 15%. Previous CGP allocation was {prev} ZP
        per block. Vote for at least {min} ZP to at most {max} ZP per block to the CGP.
      </StyledParagraph>
      <Form onSubmit={onSubmit} disabled={formDisabled}>
        <FormBody>
          <StyledRow>
            <SliderFormGroup>
              <SliderTextsContainer>
                <SliderTitle>CGP Allocation</SliderTitle>
                <SliderTitle>Miner Reward</SliderTitle>
              </SliderTextsContainer>
              <SliderTextsContainer>
                <SliderMinMax>{min} ZP</SliderMinMax>
                <SliderMinMax>{max} ZP</SliderMinMax>
              </SliderTextsContainer>
              <InputRange
                min={min}
                max={max}
                step={0.5}
                label="ZP"
                value={Number(allocation.safeValue)}
                onChange={handleChange}
                sliderColor={color}
                bg={color}
                disabled={formDisabled}
              />
            </SliderFormGroup>
            <AmountFormGroup>
              <StyledInput
                placeholder="Enter amount"
                autoComplete="off"
                valid={allocation.display ? valid : undefined}
                variant="block"
                value={allocation.display}
                type="text"
                disabled={formDisabled}
                onChange={(e) => handleChange(e.target.value.trim())}
                onKeyDown={onKeyDown}
                renderRightAddon={
                  <>
                    <InputAddonText>
                      / {min}-{max}
                    </InputAddonText>
                    <InputAddonNumberControls
                      onChange={handleChange}
                      value={allocation.safeValue}
                      min={min}
                      max={max}
                      step="0.5"
                    />
                  </>
                }
              />
              {/* Add an InputMessage for spacing */}
              <InputMessage />
            </AmountFormGroup>
          </StyledRow>
          <FormFooter>
            <Button type="submit" disabled={!valid || progress}>
              <LoadingOr loading={progress} loadingContent="Voting...">
                Vote
              </LoadingOr>
            </Button>
          </FormFooter>
        </FormBody>
      </Form>
    </Box>
  );
}

const StyledParagraph = styled(Paragraph)`
  font-size: ${tu.fontSize('md')};
  ${tu.mq({
    maxWidth: ['auto', null, '70%'],
  })}
`;

const StyledRow = styled(FormGroup)`
  display: flex;

  ${tu.mq({
    flexDirection: ['column', null, 'row'],
    alignItems: [null, null, 'center'],
  })}
`;

const SliderFormGroup = styled(FormGroup)`
  flex-grow: 1;
  flex-shrink: 0;
  ${css(
    tu.mq({
      width: ['auto', null, '70%'],
      maxWidth: ['auto', null, '70%'],
      marginRight: ['0', null, tu.space('xs')],
      marginBottom: ['40px', null, '0'],
    })
  )}
`;
const AmountFormGroup = styled(FormGroup)`
  ${tu.mq({
    width: ['auto', null, '30%'],
    maxWidth: ['auto', null, '30%'],
  })}
`;

const StyledInput = styled(Input)`
  input {
    padding-right: 0;
  }
`;

const SliderTextsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${tu.space('xs')};
  font-size: ${tu.fontSize(1)};
`;

const SliderTitle = styled(Box)`
  color: ${tu.color('label')};
`;

const SliderMinMax = styled(Box)`
  color: ${tu.color('text')};
`;
