import * as React from 'react';
import {
  Box,
  Button,
  Title,
  Label,
  LabelText,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  ModalFooter,
  Form,
  FormGroup,
  InputAddonNumberControls,
  Input,
  InputMessage,
  useUpDownNumberControl,
  LoadingOr,
  CopiedMessage,
} from '@zen-common/components-base';
import { useCopy } from '@zen/common-react-hooks';
import { numberUtils } from '@zen/common-utils';
import { NamingJsonStore } from '@zen/common-app-parts';

/**
 * @param {Object} props
 * @param {Object} props.contractId
 * @param {ValueDisplay} props.numberOfBlocks
 * @param {(display: string) => ValueDisplay} props.onNumberOfBlocksChange
 * @param {boolean} props.progress
 * @param {() => void} props.onCancel
 * @param {() => void} props.onContinue
 */
export default function ExtendFormModalContent({
  contractId,
  numberOfBlocks,
  onNumberOfBlocksChange,
  progress,
  onCancel,
  onContinue,
}) {
  const [copied, errorCopy, onCopy] = useCopy({ value: contractId });
  const submitDisabled =
    progress || !numberOfBlocks.display || numberUtils.isZero(numberOfBlocks.safeValue);
  const error =
    !numberOfBlocks.display || !numberUtils.isZero(numberOfBlocks.safeValue)
      ? ''
      : 'Please choose a positive number of blocks';
  const { state: naming } = NamingJsonStore.useStore();

  const contractName = naming[contractId] ? naming[contractId].shortName : '';

  const numberControl = useUpDownNumberControl({
    min: 0,
    value: numberOfBlocks.safeValue,
    onChange: onNumberOfBlocksChange,
  });
  function handleSubmit(e) {
    e.preventDefault();

    onContinue();
  }
  return (
    <Box>
      <ModalHeader>
        <Title>Extend {contractName ? contractName : 'a Contract'}</Title>
      </ModalHeader>
      <Form onSubmit={handleSubmit} disabled={progress}>
        <ModalSection>
          <FormGroup>
            <Label>
              <LabelText>Contract ID:</LabelText>
            </Label>
            <Input
              inputType="expandable"
              readOnly
              value={contractId}
              variant="block"
              onClick={onCopy}
            />
            <CopiedMessage keepHeight copied={copied} error={errorCopy} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="choose-blocks-modal-content-blocks">
              <LabelText>Number of blocks:</LabelText>
            </Label>
            <Input
              id="choose-blocks-modal-content-blocks"
              inputType="input"
              value={numberOfBlocks.display}
              onChange={(e) => onNumberOfBlocksChange(e.target.value.trim())}
              variant="block"
              valid={numberOfBlocks.display ? !error : undefined}
              disabled={progress}
              renderRightAddon={
                <InputAddonNumberControls
                  onChange={onNumberOfBlocksChange}
                  value={numberOfBlocks.safeValue}
                  min="0"
                />
              }
              {...numberControl}
            />
            <InputMessage>{error}</InputMessage>
          </FormGroup>
        </ModalSection>
        <ModalSeparator />
        <ModalFooter>
          <Button type="button" onClick={onCancel} bg="secondary" disabled={progress}>
            Cancel
          </Button>
          <Button type="submit" disabled={submitDisabled}>
            <LoadingOr loading={progress}>Continue</LoadingOr>
          </Button>
        </ModalFooter>
      </Form>
    </Box>
  );
}
