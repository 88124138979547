import { useCallback } from 'react';
import { Decimal } from 'decimal.js';

export default function useUpDown({
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  step = 1,
  value,
  onChange,
} = {}) {
  const sendLimited = useCallback(
    /** @param {Decimal} decimal */
    (decimal) => {
      // limit between min and max
      const limited = Decimal.min(Decimal.max(min, decimal), max);
      onChange(limited.toFixed(limited.decimalPlaces() || 0));
    },
    [max, min, onChange]
  );
  const onDown = useCallback(() => {
    sendLimited(new Decimal(value || 0).minus(step));
  }, [sendLimited, step, value]);
  const onUp = useCallback(() => {
    sendLimited(new Decimal(value || 0).plus(step));
  }, [sendLimited, step, value]);

  return { onDown, onUp };
}
