const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const timezoneDisplay = getTimezoneDisplay(new Date().getTimezoneOffset());

export function getDateStringFromTimestamp(timestamp) {
  return getDateString(new Date(Number(timestamp)));
}
export function getDateString(date) {
  if (!date || typeof date !== 'object') {
    return '';
  }

  const day = addPrecedingZero(date.getDate());
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = addPrecedingZero(date.getHours());
  const minutes = addPrecedingZero(date.getMinutes());

  return `${day} ${month} ${year} ${hours}:${minutes} ${timezoneDisplay}`;
}
export function getTimezone() {
  return timezoneDisplay;
}
export function getISODateFromNow(daysToAdd = 0) {
  return new Date(Date.now() + daysToAdd * 86400000).toISOString().split('T')[0];
}
/**
 * Truncates a string to the form xxxxxx...xxxxxx
 *
 * @param {string} hash
 * @param {number} trimLength - the number of chars to leave at each side
 * @returns {string} the truncated hash
 */
export function truncateHash(hash, trimLength = 6) {
  if (typeof hash !== 'string' || hash.length <= trimLength * 2 + 3) {
    return hash;
  }

  const hashNoPreZeros = hash.replace(/^0{0,12}/, ''); // at most 12 zeros from start
  const difference = hash.length - hashNoPreZeros.length;
  const zerosCount = difference % 2 === 1 ? 1 : difference > 0 ? 2 : 0;
  const beginPart = hashNoPreZeros
    .slice(0, Math.max(0, trimLength - zerosCount))
    .padStart(trimLength, '0');
  const endPart = hash.slice(-1 * trimLength);

  return `${beginPart}...${endPart}`;
}
export function filterHash(hash) {
  return (hash || '').replace(/([^a-zA-Z0-9\s])+/g, '');
}
export function capitalize(text) {
  if (text && typeof text === 'string') {
    return text.substring(0, 1).toUpperCase() + text.substring(1).toLowerCase();
  }
  return '';
}
export function convertToFilename(str) {
  return str
    .split(' ')
    .map((word) => capitalize(word))
    .join('-')
    .replace(/[^a-zA-Z0-9-]/g, '');
}
/**
 * Get a string in singular or plural depending on the count
 * @param {number} count
 * @param {string} singular
 * @param {string=} plural - can be omitted, than the singular will be used with a "s" at the end
 */
export function getSingularOrPlural(count, singular, plural) {
  const _plural = plural === undefined ? singular + 's' : plural;
  return count > 1 ? _plural : singular;
}
export function getHtmlTitle(page, item, showSeparator = false) {
  const itemDisplay = item ? ` ${item}` : '';
  const separator = showSeparator ? ' - ' : ' ';
  return `Zen Protocol${separator}${page}${itemDisplay}`;
}

export function getTimezoneDisplay(timezoneOffset) {
  const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
  const timezoneOffsetMinutes = Math.abs(timezoneOffsetHours * 60 - Math.abs(timezoneOffset));
  const minutesDisplay = timezoneOffsetMinutes ? `:${timezoneOffsetMinutes}` : '';

  /* eslint-disable indent */
  return timezoneOffset === 0
    ? '(UTC)'
    : timezoneOffset > 0
    ? `(UTC-${timezoneOffsetHours}${minutesDisplay})`
    : `(UTC+${Math.abs(timezoneOffsetHours)}${minutesDisplay})`;
}

/**
 * Add '0' for single digit numbers (eg. 01)
 * @param {(number|string)} number
 */
export function addPrecedingZero(number) {
  return Number(number) < 10 ? `0${number}` : `${number}`;
}
