import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tu from '../../utils/themeUtils';
import Input from '../inputs/Input';
import { InputAddonNumberControls, useUpDownNumberControl } from '../inputs/InputAddons';

/**
 * Display an auto logout input
 *
 * @param {object} props
 * @param {string} props.value
 * @param {(value: string) => {}} props.onChange
 * @param {number} props.min
 * @param {number} props.max
 */
export default function AutoLogoutInput({ value, onChange, min = 1, max = 120, ...props } = {}) {
  const truncatedValue = truncateValue({ max, min, value });
  const { onKeyDown } = useUpDownNumberControl({ min, max, value, onChange });

  const handleChange = (e) => {
    const val = e.target.value.trim();
    if (/^[0-9]*$/.test(val)) {
      onChange(truncateValue({ max, min, value: val }));
    }
  };

  return (
    <Input
      type="text"
      value={truncatedValue}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      renderRightAddon={
        <>
          <InputAddonNumberControls
            onChange={onChange}
            value={truncatedValue}
            max={max}
            min={min}
          />
          <StyledInputMinutesAddon>MIN</StyledInputMinutesAddon>
        </>
      }
      {...props}
    />
  );
}
AutoLogoutInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

function truncateValue({ value, min, max }) {
  return Number(value) < min ? String(min) : Number(value) > max ? String(max) : value;
}

const StyledInputMinutesAddon = styled.span`
  background-color: ${tu.color('bg3')};
  color: ${tu.color('text')};
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding: 0 ${tu.space(3)};
  font-size: ${tu.fontSize('sm')};
`;
