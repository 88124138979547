import React from 'react';
import { Box, Header } from '@zen-common/components-base';

/**
 * Display a title and a message below
 * @param {Object} params
 * @param {*} params.title - the title to render
 * @param {*} params.message - the message to render
 */
export default function TitleMessage({ title, message, color }) {
  return (
    <Box>
      <Header size="2" color={color}>
        {title}
      </Header>
      <Box color="label" fontSize="2">
        {message}
      </Box>
    </Box>
  );
}
TitleMessage.defaultProps = {
  color: 'danger',
};
