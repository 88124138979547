import storage from '@zen/common-utils/lib/utils/localStorage';
import {
  LS_PASS_PHRASE_MAIN,
  LS_PASS_PHRASE_TEST,
} from '@zen/common-app-parts/lib/stores/WalletStore/modules/useWalletState';

const MIGRATED = 'migrated-old-seed';

/**
 * Copy the seed from the old wallet implementation local storage key
 */
export default function migrate() {
  const migrated = storage.get(MIGRATED);
  if (!migrated) {
    const oldTestnetSeed = localStorage.getItem('lsTestnetSeed');
    const oldMainnetSeed = localStorage.getItem('lsMainnetSeed');
    const newTestnetSeed = storage.get(LS_PASS_PHRASE_TEST);
    const newMainnetSeed = storage.get(LS_PASS_PHRASE_MAIN);

    if (!newTestnetSeed && oldTestnetSeed) {
      storage.set(LS_PASS_PHRASE_TEST, oldTestnetSeed);
      console.log('migrated testnet seed from old implementation');
    }
    if (!newMainnetSeed && oldMainnetSeed) {
      storage.set(LS_PASS_PHRASE_MAIN, oldMainnetSeed);
      console.log('migrated mainnet seed from old implementation');
    }

    storage.set(MIGRATED, true);
  }
}
