import { useEffect, useRef, useState } from 'react';

/**
 * Set loading to true only when user performed a pagination action,
 * isFetching is true and latestData is empty.
 * Also on first fetch
 *
 * @param {Object} props
 * @param {boolean} props.isFetching - react-query param
 * @param {*} props.latestData - react-query param
 * @param {number} props.page - pagination page
 * @param {number} props.pageSize - pagination page size
 */
export default function useLoadingOnUserGesture({ isFetching, latestData, page, pageSize } = {}) {
  const [loading, setLoading] = useState(false);
  const gesture = useRef(false);
  useEffect(() => {
    gesture.current = true; // also on mount
  }, [page, pageSize]);

  useEffect(() => {
    if (isFetching && gesture.current) {
      setLoading(true);
      gesture.current = false;
    } else {
      setLoading(false);
    }
  }, [isFetching]);

  return loading && !latestData;
}
