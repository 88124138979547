import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Box from '../Box';
import LoadingOr from '../LoadingOr';
import { ModalHeader, ModalFooter, ModalSeparator, ModalSection } from './ModalContent';

/**
 * A confirmation modal content to be used in a <Modal></Modal>
 */
export default function ConfirmationModalContent({
  title,
  subtitle,
  danger,
  progress,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
  confirmDisabled,
  children,
}) {
  const showCancel = typeof onCancel === 'function';
  const cancelButtonRef = useRef(null);
  const confirmButtonRef = useRef(null);

  useEffect(() => {
    /* focus on the cancel button on the initial render*/
    cancelButtonRef.current && cancelButtonRef.current.focus();
  }, []);

  function onKeyEvent(e) {
    if (e.keyCode === 39) {
      /* user presses right arrow key*/
      confirmButtonRef.current.focus();
    } else if (e.keyCode === 37) {
      /* user presses left arrow key*/
      cancelButtonRef.current && cancelButtonRef.current.focus();
    }
  }

  return (
    <Box onKeyDown={onKeyEvent}>
      <ModalHeader>
        <Box>{title}</Box>
        <Box>{subtitle}</Box>
      </ModalHeader>
      <ModalSection>{children}</ModalSection>
      <ModalSeparator />
      <ModalFooter>
        {showCancel && (
          <Button
            ref={cancelButtonRef}
            disabled={progress}
            bg="secondary"
            onClick={onCancel}
            tabIndex="0"
          >
            {cancelText}
          </Button>
        )}
        <Button
          ref={confirmButtonRef}
          disabled={confirmDisabled || progress}
          bg={danger ? 'danger' : 'primary'}
          onClick={onConfirm}
          tabIndex="1"
        >
          <LoadingOr loading={progress}>{confirmText}</LoadingOr>
        </Button>
      </ModalFooter>
    </Box>
  );
}

ConfirmationModalContent.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  danger: PropTypes.any,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  confirmDisabled: PropTypes.bool,
};
ConfirmationModalContent.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};
