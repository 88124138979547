import * as React from 'react';
import PasswordRequiredModalContentBase from '../../modals/PasswordRequiredModal/PasswordRequiredModalContentBase';
import BackupWalletModalContent from '../common/BackupWalletModalContent';
import SecurityWarningModalContent from '../common/SecurityWarningModalContent';
import OptionsModal from './components/OptionsModalContent';
import { actions } from './actions';

export default function DeleteWalletSettingProcess({ onCancel, onDone, useSetting }) {
  const [status, setStatus] = React.useState('options');
  const [data, setData] = React.useState();

  const { error, loading, onAction } = useSetting();

  function gotoBackup() {
    setStatus('security-warning');
  }
  function gotoWipe() {
    setStatus('password-wipe');
  }

  async function handleSubmitBackup(password) {
    const data = await onAction({
      type: actions.backup,
      value: { password },
    });
    if (data) {
      setData(data);
      setStatus('show-backup');
    }
  }

  async function handleSubmitWipe(password) {
    await onAction({
      type: actions.delete,
      value: { password: password },
    });
    setStatus('wipe');
    onDone();
  }

  if (status === 'options') {
    return <OptionsModal onCancel={onCancel} onBackupWallet={gotoBackup} onDelete={gotoWipe} />;
  }

  if (status === 'security-warning') {
    return (
      <SecurityWarningModalContent
        cancelText="Back"
        onCancel={() => setStatus('options')}
        onConfirm={() => setStatus('password-backup')}
      />
    );
  }

  if (status === 'password-backup') {
    return (
      <PasswordRequiredModalContentBase
        error={error}
        progress={loading}
        onSubmit={handleSubmitBackup}
        cancelText="Back"
        onCancel={() => setStatus('security-warning')}
      />
    );
  }

  if (status === 'password-wipe') {
    return (
      <PasswordRequiredModalContentBase
        error={error}
        progress={loading}
        onSubmit={handleSubmitWipe}
        cancelText="Back"
        onCancel={() => setStatus('options')}
      />
    );
  }

  if (status === 'show-backup') {
    return (
      <BackupWalletModalContent
        {...data}
        onCancel={onDone}
        cancelText="Cancel"
        onConfirm={() => setStatus('password-wipe')}
        confirmText="Wipe Seed"
      />
    );
  }

  return null;
}
