import * as React from 'react';
import {
  Box,
  Label,
  LabelText,
  Form,
  FormBody,
  FormGroup,
  FormFooter,
  ClearInput,
  AutoSuggest,
  InputMessage,
  Button,
  LoadingOr,
} from '@zen-common/components-base';
import { PasteButton } from '@zen/common-app-parts';

const INPUT_ID = 'governance-commit-form-commit-id';

/**
 * renders the governance commit form
 * @param {Object} props
 * @param {string} props.commitId
 * @param {boolean} props.commitIdValid
 * @param {[commitId: string]} props.options - possible options for commits
 * @param {(value: string) => void} props.onCommitIdChange
 * @param {() => void} props.onSubmit
 * @param {boolean} props.progress
 * @param {boolean} props.disabled
 */
export default function CommitForm({
  commitId,
  commitIdValid,
  options,
  onCommitIdChange,
  onSubmit,
  progress,
  disabled,
}) {
  const handleChange = (value) => {
    if (!disabled) {
      onCommitIdChange(value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit();
  };
  return (
    <Form onSubmit={handleSubmit} disabled={disabled || progress}>
      <FormBody>
        <FormGroup>
          <Label htmlFor={INPUT_ID} id={INPUT_ID + '_label'}>
            <LabelText>Commit ID</LabelText>
          </Label>
          <Box display="flex">
            <Box flexGrow="1">
              {options && options.length ? (
                <AutoSuggest
                  disabled={disabled || progress}
                  valid={commitId ? commitIdValid : undefined}
                  inputType="expandable"
                  hasClearButton
                  items={(options || []).map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  variant="block"
                  value={commitId}
                  onChange={handleChange}
                  inputProps={{
                    id: INPUT_ID,
                    'aria-labelledby': INPUT_ID + '_label',
                  }}
                  emptyText=""
                />
              ) : (
                <ClearInput
                  id={INPUT_ID}
                  disabled={disabled || progress}
                  valid={commitId ? commitIdValid : undefined}
                  variant="block"
                  width="100%"
                  value={commitId}
                  onChange={(e) => handleChange(e.target.value.trim())}
                  onClear={() => onCommitIdChange('')}
                  inputType="expandable"
                  aria-labelledby={INPUT_ID + '_label'}
                />
              )}
            </Box>
            <PasteButton onPaste={onCommitIdChange} />
          </Box>
          <InputMessage>{!commitId || commitIdValid ? '' : 'Invalid commit ID'}</InputMessage>
        </FormGroup>
        <FormFooter>
          <Button disabled={!commitIdValid || progress || disabled} type="submit">
            <LoadingOr loading={progress} loadingContent="Voting...">
              Vote
            </LoadingOr>
          </Button>
        </FormFooter>
      </FormBody>
    </Form>
  );
}
