import React from 'react';
import styled from 'styled-components';
import { Box, Button } from '@zen-common/components-base';

/**
 * A section with reset and show all buttons
 *
 * @param {Object} props
 * @param {Function} props.onReset
 * @param {Function} props.onShowAll
 * @param {boolean} props.showingAll
 * @param {boolean} props.disabled
 */
export default function ResetShowAllSection({
  onReset,
  onShowAll,
  showingAll,
  disabled,
  ...props
}) {
  return (
    <Box {...props}>
      <StyledButton onClick={onReset} mr="xs" disabled={disabled}>
        Reset
      </StyledButton>
      <StyledButton onClick={onShowAll} disabled={disabled}>
        {showingAll ? 'Hide All' : 'Show All'}
      </StyledButton>
    </Box>
  );
}

const StyledButton = styled(Button).attrs({ sizeVariant: 'small', bg: 'secondary' })`
  min-width: 92px;
`;
