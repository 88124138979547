import { css } from 'styled-components';
import tu from '../../themeUtils';

const defaultPadding = tu.space('mlg');

/**
 * Get a padding value first from themeUtils and then directly from parameter
 *
 * @param {string} padding
 * @returns {string} the calculated padding value or default
 */
function getPadding(padding) {
  return tu.space(padding) || padding;
}

/**
 * Create paddings
 * by default top + bottom will be the same, as well as left and right
 * Defaults to themeUtils.paddings.mlg
 *
 * @param {string} {x, y, t, r, b, l} paddings for all sides, can be one of the themeUtils values (sm, md ...) or a unit
 * @returns css to mixin in a styled component
 */
export function getPaddings({ all, x, y, t, r, b, l } = {}) {
  const paddingAll = all ? getPadding(all) : defaultPadding;
  const paddingX = x ? getPadding(x) : paddingAll;
  const paddingY = y ? getPadding(y) : paddingAll;
  const paddingTop = t ? getPadding(t) : paddingY;
  const paddingBottom = t ? getPadding(b) : paddingY;
  const paddingRight = t ? getPadding(r) : paddingX;
  const paddingLeft = t ? getPadding(l) : paddingX;
  return css`
    padding-top: ${paddingTop};
    padding-bottom: ${paddingBottom};
    padding-left: ${paddingLeft};
    padding-right: ${paddingRight};
  `;
}

const identity = (x) => x;

/**
 * The default x padding for all containers
 *
 * @param {Object} p
 * @param {(value: string) => string} p.modifyLeft - a function to modify the left values
 * @param {(value: string) => string} p.modifyRight - a function to modify the right values
 */
export const containerXPadding = (p) => {
  const smd = tu.space('smd')(p),
    mlg = tu.space('mlg')(p);

  const modifyLeft = p.modifyLeft || identity;
  const modifyRight = p.modifyRight || identity;

  return tu.mq({
    paddingLeft: [modifyLeft(smd), null, modifyLeft(mlg)],
    paddingRight: [modifyRight(smd), null, modifyRight(mlg)],
  });
};
