import React from 'react';
import {
  AutoLogoutSetting,
  ChangePasswordSetting,
  BackupWalletSetting,
  DeleteWalletSetting,
  LogoutSetting,
  ReportErrorsSetting,
  TitleContentLayout,
  SettingsItem,
} from '@zen/common-app-parts';
import {
  useAutoLogoutSetting,
  useBackupWalletSetting,
  useChangePasswordSetting,
  useDeleteWalletSetting,
  useLogoutSetting,
  useReportErrorSetting,
} from './settingsHooks';

const AccountSettingsPage = () => {
  return (
    <TitleContentLayout dark title="Account Settings">
      <SettingsItem>
        <AutoLogoutSetting useSetting={useAutoLogoutSetting} />
      </SettingsItem>
      <SettingsItem>
        <ChangePasswordSetting useSetting={useChangePasswordSetting} />
      </SettingsItem>
      <SettingsItem>
        <BackupWalletSetting useSetting={useBackupWalletSetting} />
      </SettingsItem>
      <SettingsItem>
        <DeleteWalletSetting useSetting={useDeleteWalletSetting} />
      </SettingsItem>
      <SettingsItem>
        <LogoutSetting useSetting={useLogoutSetting} />
      </SettingsItem>
      {process.env.REACT_APP_END_PLATFORM === 'desktop' && (
        <SettingsItem>
          <ReportErrorsSetting useSetting={useReportErrorSetting} />
        </SettingsItem>
      )}
    </TitleContentLayout>
  );
};

export default AccountSettingsPage;
