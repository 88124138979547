import useExecuteContractStore from '../ExecuteContractStore/useExecuteContractStore';
import useSendStore from '../SendStore/useSendStore';
import useIsExecute from './useIsExecute';

export default function useSendExecuteStore({ wallet, chain, nodeUrl }) {
  const executeStore = useExecuteContractStore({ wallet, chain, nodeUrl });
  const sendStore = useSendStore({ wallet, chain, nodeUrl });
  const [isExecute, resetIsExecute] = useIsExecute({ chain, address: sendStore.state.address });

  return { executeStore, sendStore, isExecute, resetIsExecute };
}
