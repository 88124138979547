import React, {
  useRef,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash.debounce';
import {
  Box,
  FormGroup,
  Label,
  LabelText,
  InputMessage,
  AutoSuggest,
  Input,
  InputAddonText,
  InputAddonNumberControls,
  useUpDownNumberControl,
  themeUtils as tu,
} from '@zen-common/components-base';
import { assetUtils, numberUtils, uniqueId, ValueDisplay } from '@zen/common-utils';

const { getAssetBalance, getAssetName, getAssetFullId, truncateOrNameAsset } = assetUtils;

/**
 * @typedef {Object} SpendProps
 * @property {string} asset
 * @property {boolean} assetValid
 * @property {Array} assets
 * @property {Function} onAssetChange
 * @property {string} amount
 * @property {boolean} amountValid
 * @property {Function} onAmountChange
 * @property {{asset: (string|number)}} balance - the zenjs balance object
 * @property {('default'|'inline'|'grid')} alignment - force alignment, by default change alignment between mobile and desktop
 * @property {('input'|'multiline'|'expandable')} inputType
 * @property {boolean} readOnly
 * @property {boolean} disabled
 */

/**
 * Show an asset/amount pair
 * @type {React.ForwardRefExoticComponent<SpendProps & React.RefAttributes<any>}
 */
const Spend = React.forwardRef(
  (
    {
      asset,
      assetValid,
      assets,
      onAssetChange,
      amount,
      amountValid,
      onAmountChange,
      balance,
      alignment = 'default',
      inputType = 'expandable',
      readOnly,
      disabled,
      naming,
      assetReadOnly = false,
    },
    ref
  ) => {
    const id = useRef(uniqueId()); // support multiple Spend components on the same page
    const selectedAssetBalance = useMemo(() => {
      return ValueDisplay.create(getAssetBalance(asset, balance));
    }, [asset, balance]);
    const { measureByRef, trimLength } = useCalculateTruncateSize();
    const amountValue = useMemo(() => numberUtils.clean(amount), [amount]);
    const assetRef = useRef();
    const amountRef = useRef();

    const max = selectedAssetBalance.safeValue;
    const min = '0';
    const { onKeyDown: onAmountKeyDown } = useUpDownNumberControl({
      min,
      max,
      value: amountValue,
      onChange: onAmountChange,
    });

    // expose the refs
    useImperativeHandle(ref, () => ({
      focus: () => {
        assetRef.current && assetRef.current.focus();
      },
      get asset() {
        return assetRef.current;
      },
      get amount() {
        return amountRef.current;
      },
    }));

    const handleAssetChange = useCallback(
      (value) => {
        // reset amount on asset change, must be first to prevent issues when setting asset+amount at the same time (paste a fields json)
        onAmountChange && onAmountChange('');
        onAssetChange && onAssetChange(value);
      },
      [onAmountChange, onAssetChange]
    );

    const title = naming && naming[asset] ? `Asset - ${naming[asset].name}` : 'Asset';

    return (
      <MainSpendContainer alignment={alignment}>
        <AssetFormGroup alignment={alignment} ref={measureByRef}>
          <Label htmlFor={`asset-${id.current}`} id={`asset-${id.current}-label`}>
            <LabelText>{title}</LabelText>
          </Label>
          {readOnly || disabled ? (
            <Input
              id={`asset-${id.current}`}
              value={getAssetName(asset) || '\u00a0'}
              onChange={() => {}}
              variant="block"
              inputType="expandable"
              readOnly={readOnly}
              disabled={disabled}
              ref={assetRef}
            />
          ) : (
            <AutoSuggest
              {...(asset && { valid: assetValid })}
              inputType={inputType}
              searchItem={searchItem}
              getInputValue={getInputValue}
              hasClearButton
              items={(assets || []).map((asset) => ({
                value: asset,
                label:
                  !!naming && naming[asset]
                    ? `${naming[asset].name} - ${truncateOrNameAsset(asset, trimLength)}`
                    : truncateOrNameAsset(asset, trimLength),
              }))}
              variant="block"
              value={asset}
              onChange={handleAssetChange}
              inputProps={{
                id: `asset-${id.current}`,
                'aria-labelledby': `asset-${id.current}-label`,
              }}
              emptyText="No assets found"
              ref={assetRef}
            />
          )}
          <InputMessage>{!asset || assetValid ? '' : 'Invalid asset ID'}</InputMessage>
        </AssetFormGroup>
        <AmountFormGroup alignment={alignment}>
          <Label htmlFor={`amount-${id.current}`}>
            <LabelText>Amount</LabelText>
          </Label>
          {(readOnly || disabled) && !assetReadOnly ? (
            <Input
              id={`amount-${id.current}`}
              value={amount || '\u00a0'}
              onChange={() => {}}
              variant="block"
              inputType="expandable"
              readOnly={readOnly}
              disabled={disabled}
              ref={amountRef}
            />
          ) : (
            <StyledInput
              id={`amount-${id.current}`}
              autoComplete="off"
              {...(amount && { valid: amountValid })}
              variant="block"
              value={amount}
              type="text"
              onChange={(e) => onAmountChange(e.target.value.trim())}
              onKeyDown={onAmountKeyDown}
              ref={amountRef}
              renderRightAddon={
                <>
                  {selectedAssetBalance.safeValue !== '0' ? (
                    <InputAddonText>/ {selectedAssetBalance.display}</InputAddonText>
                  ) : null}
                  <InputAddonNumberControls
                    onChange={onAmountChange}
                    value={amountValue}
                    max={selectedAssetBalance.safeValue}
                    min="0"
                  />
                </>
              }
            />
          )}

          <InputMessage>{!amount || amountValid ? '' : 'Invalid amount'}</InputMessage>
        </AmountFormGroup>
      </MainSpendContainer>
    );
  }
);
Spend.displayName = 'Spend';
export default Spend;

/**
 * Search by comparing full asset IDs
 */
function searchItem(items = [], search) {
  return items.find((item) => getAssetFullId(item.value) === getAssetFullId(search.toLowerCase()));
}

/**
 * Convert the selected item to a named asset for displaying in the asset input
 */
function getInputValue(item) {
  return getAssetName(item.value);
}

/**
 * Calculate the amount of chars in the hash truncation based on an element width
 */
function useCalculateTruncateSize() {
  const measureByRef = useRef();
  const [assetWidth, setAssetWidth] = useState(0);

  useEffect(() => {
    const handleResize = debounce(
      () => {
        if (measureByRef.current) {
          setAssetWidth(measureByRef.current.offsetWidth);
        }
      },
      200,
      { trailing: true }
    );
    window.addEventListener('resize', handleResize);
    // on mount
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // worst-case estimation, based on a hash with zeros only (biggest char)
  const trimLength = useMemo(
    () =>
      assetWidth < 250
        ? 6
        : assetWidth < 380
        ? 12
        : assetWidth < 480
        ? 18
        : assetWidth < 580
        ? 24
        : assetWidth < 650
        ? 30
        : 72,
    [assetWidth]
  );

  return { measureByRef, trimLength };
}

const MainSpendContainer = styled(Box)`
  display: flex;

  ${(p) =>
    tu.mq({
      flexDirection:
        p.alignment === 'default'
          ? ['column', null, 'row']
          : p.alignment === 'inline'
          ? 'row'
          : 'column',
    })}
`;

const AssetFormGroup = styled(FormGroup)`
  flex-grow: 1;
  flex-shrink: 0;
  ${(p) =>
    p.alignment !== 'grid' &&
    css(
      tu.mq({
        width: ['auto', null, '70%'],
        maxWidth: ['auto', null, '70%'],
        marginRight: ['0', null, tu.space('xs')],
        marginBottom: [null, null, '0'],
      })
    )}
`;
const AmountFormGroup = styled(FormGroup)`
  ${(p) =>
    p.alignment !== 'grid' &&
    tu.mq({
      width: ['auto', null, '30%'],
      maxWidth: ['auto', null, '30%'],
    })}
`;

const StyledInput = styled(Input)`
  input {
    padding-right: 0;
  }
`;
