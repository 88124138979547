import React from 'react';
import { faInfoCircle, faCube, faFileContract, faBallot } from '@fortawesome/pro-light-svg-icons';
import { Box, Description, Paragraph, FaSvg, useModal } from '@zen-common/components-base';
import {
  TopExplorerLink,
  TopExternalLink,
  TopLinkButton,
  TopLinksContainer,
} from '../../../components/voting/TopLinks';
import BallotIdGeneratorModal from './BallotIdGenerator/BallotIdGeneratorModal';

export default function TopParagraph({
  addressCgp,
  addressVote,
  cgpBalance,
  interval,
  voteStatus,
  ...props
}) {
  const { show } = useModal();
  const phase = voteStatus === 'voting' ? 'vote' : 'nomination';
  return (
    <Box {...props}>
      <Description>
        <Paragraph>
          Vote on how to allocate part of the block reward to the CGP and on how it should
          distribute it’s funds.
        </Paragraph>
      </Description>
      <TopLinksContainer>
        <TopExternalLink href="https://docs.zenprotocol.com/common-goods-pool">
          <FaSvg icon={faInfoCircle} mr="2" />
          Learn More
        </TopExternalLink>
        <TopExplorerLink path={`/cgp/${interval}/${phase}`}>
          <FaSvg icon={faCube} mr="2" />
          Block Explorer
        </TopExplorerLink>
        <TopExplorerLink path={`/contracts/${addressCgp}/code`}>
          <FaSvg icon={faFileContract} mr="2" />
          CGP Contract
        </TopExplorerLink>
        <TopExplorerLink path={`/contracts/${addressVote}/code`}>
          <FaSvg icon={faFileContract} mr="2" />
          Voting Contract
        </TopExplorerLink>
        <TopLinkButton
          onClick={() => show(() => <BallotIdGeneratorModal cgpBalance={cgpBalance} />)}
        >
          <FaSvg icon={faBallot} mr="2" />
          Generate Ballot
        </TopLinkButton>
      </TopLinksContainer>
    </Box>
  );
}
