import React, { useState, useEffect, useRef } from 'react';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, padding, system } from 'styled-system';
import tu from '../../utils/themeUtils';
import Input from './../inputs/Input';
import ClearButton from './components/ClearButton';
import ToggleButton from './components/ToggleButton';

/**
 * An auto suggest component
 *
 * @typedef {Object} AutoSuggestProps
 * @property {AutoSuggestItems} props.items - the items to choose from
 * @property {defaultSearchItem} props.searchItem - a custom function to search for an item (optional)
 * @property {defaultGetInputValue} props.getInputValue - a custom function to get the input value from the selected item
 * @property {boolean} props.hasClearButton - display a clear button
 * @property {boolean} props.valid -  mark the prop valid/invalid, set to undefined to remove
 * @property {Function} props.onChange - a callback function to be called on change
 * @property {string} props.value - the value to control from the outside
 * @property {boolean} props.readOnly
 * @property {('input'|'multiline'|'expandable')} props.inputType
 * @property {Object} props.inputProps - props for the input element
 * @property {string} props.placeholder - display a placeholder
 * @property {string} props.emptyText - display text when no items
 */

/**
 * @type {React.ForwardRefExoticComponent<AutoSuggestProps & React.RefAttributes<any>}
 */
const AutoSuggest = React.forwardRef(
  (
    {
      items,
      searchItem,
      getInputValue,
      hasClearButton,
      placeholder,
      valid,
      onChange,
      value,
      readOnly,
      disabled,
      inputType,
      inputProps,
      emptyText,
      ...props
    },
    ref
  ) => {
    const _inputRef = useRef();
    const inputRef = ref || _inputRef;
    const unmounted = useUnmountedRef();
    const [inputValue, setInputValue] = useState(
      getInputValue(searchItemBy({ func: searchItem, items, search: value || '' }))
    );
    // used for inner comparison with the outside value
    const [privateSelectedItem, setPrivateSelectedItem] = useState(
      searchItemBy({ func: searchItem, items, search: value || '' })
    );
    const [isFocus, setIsFocus] = useState(false);
    const [isHandlingBlur, setIsHandlingBlur] = useState(false);

    function handleOnSelectionChange(selection) {
      if (selection && selection.value !== privateSelectedItem.value) {
        setInputValue(getInputValue(selection));
        setPrivateSelectedItem(selection);
        typeof onChange === 'function' && onChange(selection.value);
      }
    }

    function handleOnInputChange(e, clearSelection) {
      clearSelection();
      const _val = e.target.value.trim();
      setInputValue(_val);
      if (typeof onChange === 'function') {
        const item = searchItemBy({ func: searchItem, items, search: _val });
        setPrivateSelectedItem(item);
        onChange(item.value);
      }
    }

    function handleInputFocus({ openMenu }) {
      setTimeout(() => {
        openMenu();
        setIsFocus(true);
      }, 0);
    }

    function handleInputBlur(inputValue, selectItem) {
      setIsFocus(false);
      setIsHandlingBlur(true);
      const item = searchItemBy({ func: searchItem, items, search: inputValue });
      setInputValue(getInputValue(item));
      setPrivateSelectedItem(item);
      selectItem(item);

      // prevent toggle button opening the menu when closing because of blur while clicking on toggle
      setTimeout(() => {
        if (!unmounted.current) {
          setIsHandlingBlur(false);
        }
      }, 200);
    }

    function handleToggle() {
      if (!isHandlingBlur && !isFocus) {
        inputRef.current.focus();
      }
    }

    function clear(clearSelection) {
      setInputValue('');
      setPrivateSelectedItem({ label: '', value: '' });
      clearSelection();
      if (typeof onChange === 'function') {
        onChange('');
      }
      inputRef.current.focus();
    }

    // update if value is updated from the outside
    useEffect(() => {
      if (value !== undefined && value !== privateSelectedItem.value) {
        const item = searchItemBy({ func: searchItem, items, search: value || '' });
        setInputValue(getInputValue(item));
        setPrivateSelectedItem(item);
      }
    }, [getInputValue, items, privateSelectedItem.value, searchItem, value]);

    return (
      <Downshift
        onChange={(selection) => handleOnSelectionChange(selection)}
        itemToString={(item) => (item ? item.value : '')}
        inputValue={inputValue}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getRootProps,
          getToggleButtonProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          selectItem,
          clearSelection,
          openMenu,
        }) => (
          <StyledMenuWrapper {...getRootProps()} {...props}>
            <StyledInput
              ref={inputRef}
              readOnly={readOnly}
              disabled={disabled}
              type="text"
              variant="block"
              inputType={inputType}
              valid={valid}
              renderRightAddon={
                readOnly || disabled ? null : hasClearButton && inputValue ? (
                  <>
                    <ClearButton onClear={() => clear(clearSelection)} />
                  </>
                ) : (
                  <ToggleButton
                    {...getToggleButtonProps()}
                    isOpen={isOpen}
                    data-testid="AutoSuggest-toggle-button"
                    onClick={() => handleToggle({ openMenu })}
                  />
                )
              }
              {...getInputProps({
                placeholder: placeholder ? placeholder : null,
                onBlur: () => {
                  handleInputBlur(inputValue, selectItem);
                },
                onFocus: () => {
                  handleInputFocus({ openMenu });
                },
                onChange: (e) => handleOnInputChange(e, clearSelection),
                focusTrap: isFocus,
              })}
              {...inputProps}
            />
            {!isOpen ? null : (
              <StyledMenu {...getMenuProps()} data-testid="AutoSuggest-menu">
                {items && items.length ? (
                  items
                    .filter(
                      (item) =>
                        !inputValue.toLowerCase() ||
                        item.value.includes(inputValue.toLowerCase()) ||
                        item.label.toLowerCase().includes(inputValue.toLowerCase())
                    )
                    .map((item, index) => (
                      <StyledMenuItem
                        tabIndex="0"
                        key={item.value}
                        {...getItemProps({
                          key: item.value,
                          index,
                          item,
                          isActive: highlightedIndex === index,
                          isSelected: selectedItem === item,
                        })}
                      >
                        {item.label}
                      </StyledMenuItem>
                    ))
                ) : emptyText ? (
                  <StyledMenuItem>{emptyText}</StyledMenuItem>
                ) : null}
              </StyledMenu>
            )}
          </StyledMenuWrapper>
        )}
      </Downshift>
    );
  }
);

AutoSuggest.propTypes = {
  hasClearButton: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  items: PropTypes.array.isRequired,
  searchItem: PropTypes.func,
  getInputValue: PropTypes.func,
  valid: PropTypes.bool,
  inputProps: PropTypes.object,
  emptyText: PropTypes.string,
};
AutoSuggest.defaultProps = {
  searchItem: defaultSearchItem,
  getInputValue: defaultGetInputValue,
  inputType: 'expandable',
};

function useUnmountedRef() {
  const ref = useRef(false);

  useEffect(() => () => (ref.current = true), []);

  return ref;
}

/**
 * Search for an item in the auto suggest items
 *
 * @param {AutoSuggestItems} items
 * @param {string} search
 * @returns {AutoSuggestItem} the found item or undefined
 */
function defaultSearchItem(items = [], search) {
  return items.find(
    (item) =>
      item.value === search.toLowerCase() || item.label.toLowerCase() === search.toLowerCase()
  );
}

/**
 * @param {AutoSuggestItem} item
 */
function defaultGetInputValue(item) {
  return item.label;
}

/**
 * Use the searchItem function to find an item
 *
 * @returns the found item or a new item which has the search in both value and label
 */
function searchItemBy({ func, items, search } = {}) {
  const item = func(items, search);
  return item ? item : { value: search, label: search };
}

const StyledMenuWrapper = styled.div`
  position: relative;
  margin: auto;
  display: ${(p) => (p.variant === 'block' ? 'block' : 'inline-block')};
  min-width: ${(p) => (p.variant === 'block' ? 'auto' : '100px')};
`;

const StyledMenu = styled.ul`
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  font-size: ${tu.fontSize('md')};
  border: 1px solid ${tu.color('borderDark')};
  ${system({
    backgroundColor: {
      property: 'backgroundColor',
      scale: 'colors',
    },
  })}
`;
StyledMenu.defaultProps = {
  color: 'text',
  hoverColor: 'bg3',
  backgroundColor: 'bg1',
  px: 'smd',
  py: 'xs',
};

const StyledMenuItem = styled.li`
  list-style: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${color}
  ${padding}
    &:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  &:hover {
    ${system({
      hoverColor: {
        property: 'backgroundColor',
        scale: 'colors',
      },
    })}
  }
  font-weight: ${(p) => (p.isSelected ? '500' : 'normal')};
  background-color: ${(p) => (p.isActive || p.isSelected ? tu.color('bg4') : 'initial')};
`;
StyledMenuItem.defaultProps = {
  color: 'text',
  hoverColor: 'bg3',
  backgroundColor: 'bg1',
  px: 'smd',
  py: 'xs',
};

const StyledInput = styled(Input)`
  input {
    padding-right: 0;
  }
`;

export default AutoSuggest;

/**
 * @typedef {{label: string, value: *}} AutoSuggestItem
 */

/**
 * @typedef {[AutoSuggestItem]} AutoSuggestItems
 */
