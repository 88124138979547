import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { color, space, border, typography, system, compose } from 'styled-system';
import { getTransition } from '../../utils/styles/mixins';
import tu from '../../utils/themeUtils';

/**
 * Radio group button list
 *
 * @param {Object} props
 * @param {Array} props.options - list of radio items
 * @param {string} props.checked - sets the checked radio item form the list
 * @param {Function} props.onChange - a callback function to be called on change
 * @param {boolean} props.hasSeparator - sets a separator border between radio items
 */
const RadioGroup = ({ options, checked, onChange, hasSeparator, ...props }) => (
  <StyledRadioGroup hasSeparator={hasSeparator} {...props}>
    {options.map(option => {
      return (
        <li key={option.value}>
          <label>
            <input
              data-testid={`RadioGroup-input-${option.value}`}
              type="radio"
              value={option.value}
              checked={checked === option.value}
              onChange={onChange}
            />
            <span className="radio" />
            <StyledRadioLabel checked={checked === option.value}>{option.label}</StyledRadioLabel>
          </label>
        </li>
      );
    })}
  </StyledRadioGroup>
);

const getSeparatorCss = p => {
  if (p.column && p.hasSeparator)
    return css`
      border-bottom: 1px solid ${tu.color('borderDark')};
    `;
};

const StyledRadioGroup = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  flex-direction: ${p => (p.column ? 'column' : 'row')};

  li {
    ${space}
    ${getSeparatorCss}
    label {
      display: inline-flex;
      align-items: center;
      margin: ${p => (p.column ? '10px 0' : '0 10px')};
      cursor: pointer;

      input {
        position: absolute;
        opacity: 0;

        &:checked + .radio {
          ${compose(
            border,
            system({
              checkedBorderColor: {
                property: 'borderColor',
                scale: 'colors',
              },
            })
          )}
          &:after {
            opacity: 1;
            transform: scale(1);
            ${getTransition()}
          }
        }
      }
    }

    .radio {
      position: relative;
      display: block;
      box-sizing: border-box;
      min-width: ${tu.space('md')};
      min-height: ${tu.space('md')};
      width: ${tu.space('md')};
      height: ${tu.space('md')};
      border-radius: 10px;
      ${border};
      ${getTransition()}

      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        opacity: 0;
        transform: scale(0);
        ${color}
      }
    }
  }
`;

StyledRadioGroup.defaultProps = {
  border: '1px solid',
  borderColor: 'label',
  checkedBorderColor: 'primary',
  backgroundColor: 'primary',
};

const StyledRadioLabel = styled.span`
  user-select: none;
  color: ${p => (p.checked ? tu.color('text') : tu.color('label'))};
  &:hover {
  color: ${tu.color('text')};
  }
  ${getTransition()}
  ${space}
  ${typography}
`;

StyledRadioLabel.defaultProps = {
  ml: 'xs',
  fontSize: 'md',
};

RadioGroup.propTypes = {
  options: PropTypes.array.isRequired,
  checked: PropTypes.string,
  hasSeparator: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioGroup;
