import React, { useState, useEffect } from 'react';
import { css } from 'styled-components';
import { Link, useCurrentRoute } from 'react-navi';
import {
  SideNav,
  SideNavItem,
  SideNavMenu,
  SideNavToggler,
  UpdateIndicator,
} from '@zen-common/components-base';
import { WalletStore } from '@zen/common-app-parts';
import { CgpPhaseChangedStore, TxCountChangedStore } from '../../stores/ChangeNotificationsStore';
import PATHS from '../../paths';
import {
  CgpPhaseChangedUpdateIndicator,
  GovernancePhaseChangedUpdateIndicator,
  VotingPhaseChangedUpdateIndicator,
  TxCountChangeIndicator,
} from '../update-indicators';

export default function MainSideNav() {
  const [isOpen, setIsOpen] = useState(false);
  const {
    url: { href },
  } = useCurrentRoute();
  const {
    state: { isLoggedIn, hasPassword },
  } = WalletStore.useStore();

  // close on route change
  useEffect(() => {
    setIsOpen(false);
  }, [href]);

  return (
    <>
      <MenuTogglerUpdateIndicator>
        <SideNavToggler isOpen={isOpen} onClick={() => setIsOpen((o) => !o)} />
      </MenuTogglerUpdateIndicator>
      <SideNav width="100%" marginTop="56px" isOpen={isOpen} onClick={() => setIsOpen(false)}>
        {isLoggedIn && (
          <>
            <SideNavMenu
              title={<TxCountChangeIndicator>My Wallet</TxCountChangeIndicator>}
              active={href.indexOf('/wallet') > -1}
            >
              <SideNavItem>
                <Link href={PATHS.WALLET_BALANCES} activeClassName="active">
                  Portfolio
                </Link>
              </SideNavItem>
              <SideNavItem>
                <Link href={PATHS.WALLET_RECEIVE} activeClassName="active">
                  Receive
                </Link>
              </SideNavItem>
              <SideNavItem>
                <Link href={PATHS.WALLET_SEND} activeClassName="active">
                  Send
                </Link>
              </SideNavItem>
              <SideNavItem>
                <Link href={PATHS.WALLET_TX_HISTORY} activeClassName="active">
                  <TxCountChangeIndicator>Tx History</TxCountChangeIndicator>
                </Link>
              </SideNavItem>
            </SideNavMenu>
            <SideNavMenu title="Contracts" active={href.indexOf('/contracts') > -1}>
              <SideNavItem>
                <Link href={PATHS.CONTRACTS_ACTIVE} activeClassName="active">
                  Active Contracts
                </Link>
              </SideNavItem>
            </SideNavMenu>
            <SideNavMenu
              title={<VotingPhaseChangedUpdateIndicator>Voting</VotingPhaseChangedUpdateIndicator>}
              active={href.indexOf('/voting') > -1}
            >
              <SideNavItem>
                <Link href={PATHS.VOTING_CGP} activeClassName="active">
                  <CgpPhaseChangedUpdateIndicator>Common Goods Pool</CgpPhaseChangedUpdateIndicator>
                </Link>
              </SideNavItem>
              <SideNavItem>
                <Link href={PATHS.VOTING_GOVERNANCE} activeClassName="active">
                  <GovernancePhaseChangedUpdateIndicator>
                    Governance
                  </GovernancePhaseChangedUpdateIndicator>
                </Link>
              </SideNavItem>
            </SideNavMenu>
          </>
        )}
        <SideNavMenu
          title="Settings"
          active={[PATHS.SETTINGS_ACCOUNT, PATHS.SETTINGS_CONNECTIVITY].includes(href)}
        >
          {!hasPassword && (
            <SideNavItem>
              <Link href={PATHS.ONBOARDING} activeClassName="active">
                Connect a Wallet
              </Link>
            </SideNavItem>
          )}
          {hasPassword && !isLoggedIn && (
            <SideNavItem>
              <Link href={PATHS.UNLOCK} activeClassName="active">
                Login
              </Link>
            </SideNavItem>
          )}
          {isLoggedIn && (
            <SideNavItem>
              <Link href={PATHS.SETTINGS_ACCOUNT} activeClassName="active">
                Account
              </Link>
            </SideNavItem>
          )}
          <SideNavItem>
            <Link href={PATHS.SETTINGS_CONNECTIVITY} activeClassName="active">
              Node Connectivity
            </Link>
          </SideNavItem>
        </SideNavMenu>
      </SideNav>
    </>
  );
}

function MenuTogglerUpdateIndicator(props) {
  const {
    state: { changed: cgpChanged },
  } = CgpPhaseChangedStore.useStore();
  const {
    state: { changed: txChanged },
  } = TxCountChangedStore.useStore();
  const {
    state: { isLoggedIn },
  } = WalletStore.useStore();
  return (
    <UpdateIndicator
      show={isLoggedIn && (cgpChanged || txChanged)}
      dotCss={css`
        top: 0;
        right: 0;
        transform: none;
      `}
      {...props}
    />
  );
}
