import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ApiService } from '@zen/common-app-parts';
import { calcBalance } from '../../utils';
import { REFETCH_LONG } from './utils/constants';

/**
 * Fetches the CGP current balance
 */
export default function useCgpCurrentBalance({ nodeUrl, addressCgp }) {
  const { data: contractBalance } = useQuery(
    ['cgp-current-balance', { nodeUrl, addressCgp }],
    fetchCgpCurrentBalance,
    {
      refetchInterval: REFETCH_LONG,
    }
  );

  const cgpCurrentBalance = useMemo(
    () =>
      (contractBalance || []).reduce((balance, pointedOutput) => {
        return calcBalance({ balance, pointedOutput });
      }, {}),
    [contractBalance]
  );

  return { cgpCurrentBalance };
}
function fetchCgpCurrentBalance(_, { nodeUrl, addressCgp }) {
  return ApiService.getBalance(nodeUrl, { addresses: [addressCgp] });
}
