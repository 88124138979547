import styled from 'styled-components';
import { Box, themeUtils as tu, cssMixins } from '@zen-common/components-base';

export const SettingsItem = styled(Box)`
  border-bottom: 1px solid ${tu.color('borderLight')};
`;
export const SettingsItemMobile = styled(SettingsItem)`
  ${cssMixins.showOnMobile()}
`;
export const SettingsItemDesktop = styled(SettingsItem)`
  ${cssMixins.showOnDesktop()}
`;
