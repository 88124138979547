import { produce } from 'immer';

export const ACTIONS = {
  FETCH_INFOS_REQUESTED: 'FETCH_INFOS_REQUESTED',
  FETCH_INFOS_SUCCESS: 'FETCH_INFOS_SUCCESS',
  FETCH_INFOS_FAILED: 'FETCH_INFOS_FAILED',
  RESET_REQUESTED: 'RESET_REQUESTED',
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_INFOS_REQUESTED:
      draft.loading = true;
      break;
    case ACTIONS.FETCH_INFOS_SUCCESS:
      Object.keys(action.payload).forEach((key) => {
        if (draft.infos[key] !== action.payload[key]) {
          draft.infos[key] = action.payload[key];
        }
      });
      draft.loading = false;
      draft.error = false;
      break;
    case ACTIONS.FETCH_INFOS_FAILED:
      draft.loading = false;
      draft.error = true;
      break;
    case ACTIONS.RESET_REQUESTED:
      draft.loading = false;
      draft.error = false;
      draft.infos = {};
      break;
    default:
      throw new Error(`Unhandled action type ${action.type}`);
  }
});

export default reducer;
