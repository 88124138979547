import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Box, themeUtils as tu } from '@zen-common/components-base';

export default function Footer({ left, right }) {
  return (
    <ItemsAligner>
      {left && <LeftWrapper>{left}</LeftWrapper>}
      {right && <RightWrapper>{right}</RightWrapper>}
    </ItemsAligner>
  );
}
Footer.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
};

/**
 * Aligns the footer items in column on mobile and row on desktop
 */
const ItemsAligner = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  ${css(
    tu.mq({
      flexDirection: ['column', null, 'row'],
    })
  )}
`;

const LeftWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  ${css(
    tu.mq({
      marginBottom: [tu.space('mlg'), null, '0'],
    })
  )}
`;

const RightWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  ${css(
    tu.mq({
      width: ['100%', null, 'auto'],
    })
  )}
`;
