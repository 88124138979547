import { produce } from 'immer';
import { equals } from 'ramda';

export const ACTIONS = {
  WALLET_REMOVED: 'WALLET_REMOVED',
  CHAIN_CHANGED: 'CHAIN_CHANGED',
  CREATE: {
    REQUESTED: 'CREATE_REQUESTED',
    FAILED: 'CREATE_FAILED',
    SUCCEEDED: 'CREATE_SUCCEEDED',
  },
  LOGIN: {
    REQUESTED: 'LOGIN_REQUESTED',
    FAILED: 'LOGIN_FAILED',
    SUCCEEDED: 'LOGIN_SUCCEEDED',
  },
  WIPE: {
    REQUESTED: 'WIPE_REQUESTED',
  },
  CHANGE_PASSWORD: {
    REQUESTED: 'CHANGE_PASSWORD_REQUESTED',
    FAILED: 'CHANGE_PASSWORD_FAILED',
    SUCCEEDED: 'CHANGE_PASSWORD_SUCCEEDED',
  },
  GET_PRIVATE_KEYS: {
    REQUESTED: 'GET_PRIVATE_KEYS_REQUESTED',
    FAILED: 'GET_PRIVATE_KEYS_FAILED',
    SUCCEEDED: 'GET_PRIVATE_KEYS_SUCCEEDED',
  },
  FETCH_BALANCE: {
    REQUESTED: 'FETCH_BALANCE_REQUESTED',
    FAILED: 'FETCH_BALANCE_FAILED',
    SUCCEEDED: 'FETCH_BALANCE_SUCCEEDED',
  },
  SEND: {
    STARTED: 'SEND_STARTED',
    FINISHED: 'SEND_FINISHED',
  },
  EXECUTE: {
    STARTED: 'EXECUTE_STARTED',
    FINISHED: 'EXECUTE_FINISHED',
  },
  RESET_REQUESTED: 'RESET_REQUESTED',
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.WALLET_REMOVED:
      if (draft.wallet) {
        draft.wallet = null;
        draft.isLoggedIn = false;
        draft.initialBalanceFetched = false;
        draft.keys.address = '';
        draft.keys.pkHash = '';
        draft.keys.pubKey = '';
        draft.keys.addresses = [];
        draft.balance = {};
        draft.balances = [];
        draft.assets = [];
      }
      return;
    case ACTIONS.CHAIN_CHANGED:
      draft.isLoggedIn = false;
      draft.hasPassword = !!draft.encryptedSeed[action.payload.chain];
      return;
    case ACTIONS.CHANGE_PASSWORD.REQUESTED:
      return;
    case ACTIONS.CHANGE_PASSWORD.SUCCEEDED:
      draft.encryptedSeed[action.payload.chain] = action.payload.encryptedSeed;
      return;
    case ACTIONS.CHANGE_PASSWORD.FAILED:
      return;

    case ACTIONS.CREATE.REQUESTED:
      return;
    case ACTIONS.CREATE.SUCCEEDED:
      draft.encryptedSeed[action.payload.chain] = action.payload.encryptedSeed;
      draft.wallet = action.payload.wallet;
      draft.isLoggedIn = !!action.payload.wallet;
      draft.hasPassword = !!action.payload.encryptedSeed;
      draft.keys = getKeysFromWallet(action.payload.wallet, action.payload.chain);
      return;
    case ACTIONS.CREATE.FAILED:
      return;

    case ACTIONS.GET_PRIVATE_KEYS.REQUESTED:
      return;
    case ACTIONS.GET_PRIVATE_KEYS.SUCCEEDED:
      return;
    case ACTIONS.GET_PRIVATE_KEYS.FAILED:
      return;

    case ACTIONS.LOGIN.REQUESTED:
      return;
    case ACTIONS.LOGIN.SUCCEEDED:
      draft.wallet = action.payload.wallet;
      draft.isLoggedIn = !!action.payload.wallet;
      draft.hasPassword = !!draft.encryptedSeed[action.payload.chain];
      draft.keys = getKeysFromWallet(action.payload.wallet, action.payload.chain);
      return;
    case ACTIONS.LOGIN.FAILED:
      return;

    case ACTIONS.WIPE.REQUESTED:
      draft.isLoggedIn = false;
      draft.hasPassword = false;
      draft.wallet = null;
      draft.initialBalanceFetched = false;
      draft.balance = {};
      draft.balances = [];
      draft.keys = { address: '', pkHash: '', pubKey: '', addresses: [] };
      draft.encryptedSeed[action.payload.chain] = '';
      draft.errors = {};
      draft.success = {};
      draft.loading = {};
      return;

    case ACTIONS.FETCH_BALANCE.REQUESTED:
      draft.loading.fetchBalance = true;
      return;
    case ACTIONS.FETCH_BALANCE.SUCCEEDED:
      if (draft.wallet) {
        setBalance(draft, action.payload.balance);
      }
      draft.initialBalanceFetched = true;
      draft.loading.fetchBalance = false;
      return;
    case ACTIONS.FETCH_BALANCE.FAILED:
      draft.loading.fetchBalance = false;
      return;

    case ACTIONS.SEND.STARTED:
      draft.loading.send = true;
      setExecuting(draft);
      return;
    case ACTIONS.SEND.FINISHED:
      draft.loading.send = false;
      setExecuting(draft);
      return;
    case ACTIONS.EXECUTE.STARTED:
      draft.loading.execute = true;
      setExecuting(draft);
      return;
    case ACTIONS.EXECUTE.FINISHED:
      draft.loading.execute = false;
      setExecuting(draft);
      return;
    case ACTIONS.RESET_REQUESTED:
      draft.initialBalanceFetched = false;
      draft.balance = {};
      draft.balances = [];
      draft.assets = [];
      return;
    default:
      throw new Error(`Unhandled action type ${action.type}`);
  }
});
export default reducer;

function setBalance(draft, balance) {
  if (!equals(draft.balance, balance)) {
    draft.balance = balance;
    draft.balances = getComputedBalances(balance);
    draft.assets = draft.balances.map((b) => b.asset);
  }
}

function setExecuting(draft) {
  draft.executing = !!draft.loading.send || !!draft.loading.execute;
}

/**
 * Convert balance to an array
 *
 * @param {Object} balance
 * @returns {[{asset: string, amount: string}]}
 */
function getComputedBalances(balance) {
  return balance
    ? Object.keys(balance)
        .sort()
        .map((key) => ({ asset: key, amount: balance[key] }))
    : [];
}

/**
 * @param {import('@zen/zenjs').Wallet} wallet
 * @param {string} chain
 */
function getKeysFromWallet(wallet, chain) {
  return {
    address: wallet.getExternalPublicKey().toAddress(chain),
    pkHash: wallet.getExternalPublicKeyHash().hash,
    pubKey: wallet.getExternalPublicKey().toString(),
    addresses: wallet.getAddresses(),
  };
}
