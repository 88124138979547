/**
 * Execute a function and capture error if thrown
 * @param {function} func
 * @returns an object containing the value returned from the function or error
 */
export function captureError(func) {
  const returnValue = {
    error: null,
    value: null,
  };

  try {
    returnValue.value = func();
  } catch (error) {
    returnValue.error = error;
  }
  return returnValue;
}
