import * as React from 'react';
import { actions } from './spendsReducer';

/**
 * Listens to balance change and dispatches a re-validate spends request
 * @param {Object} params
 * @param {React.Dispatch} params.dispatch
 * @param {Object} params.balance - the wallet store balance
 */
export default function useRevalidateSpendsOnBalanceChange({ dispatch, balance }) {
  React.useEffect(() => {
    dispatch({
      type: actions.VALIDATE_SPENDS,
      payload: { balance },
    });
  }, [dispatch, balance]);
}
