import { useQuery } from 'react-query';
import { ExplorerService } from '@zen/common-app-parts';
import { REFETCH_LONG } from './utils/constants';

/**
 * Fetches the last interval voted for ballots
 */
export default function useNominees({ voteStatus, currentInterval, chain, config } = {}) {
  // only when nomination is on
  const { data } = useQuery(['cgp-nominees', { currentInterval, chain, config }], fetchNominees, {
    enabled: voteStatus === 'nomination',
    refetchInterval: REFETCH_LONG,
  });

  return {
    nominees: data ? data.data.items : [],
  };
}
function fetchNominees(_, { currentInterval, chain, config }) {
  const explorerApiService = ExplorerService({ chain, config });
  return explorerApiService.cgp.getResults({
    interval: currentInterval,
    type: 'nomination',
    pageSize: 100,
  });
}
