import { useCallback } from 'react';
import { useQuery } from 'react-query';
import SettingsStore from '../stores/SettingsStore';
import WalletStore from '../stores/WalletStore';
import * as ApiService from './ApiService';

export function useFetchActiveContracts(refetchInterval = false) {
  const {
    state: {
      settings: { nodeUrl },
    },
  } = SettingsStore.useStore();

  const fetch = useCallback(() => ApiService.activeContracts(nodeUrl), [nodeUrl]);

  return useQuery('active-contracts', fetch, {
    refetchInterval,
  });
}

/**
 * @param {(string|number)} interval
 * @param {import('react-query').BaseQueryOptions} options
 */
export function useFetchCgpCandidates(interval, options) {
  const {
    state: {
      settings: { nodeUrl },
    },
  } = SettingsStore.useStore();

  const fetch = useCallback(() => ApiService.cgp.getCandidates(nodeUrl, interval), [
    interval,
    nodeUrl,
  ]);

  return useQuery('cgp-candidates', fetch, options);
}

/**
 * @param {import('react-query').BaseQueryOptions} options
 */
export function useFetchTxCount(options) {
  const {
    state: { isLoggedIn },
    actions: { fetchTransactionsCount },
  } = WalletStore.useStore();

  const fetch = useCallback(async () => {
    if (!isLoggedIn) return undefined;

    return fetchTransactionsCount();
  }, [fetchTransactionsCount, isLoggedIn]);

  return useQuery(['fetch-transactions-count'], fetch, options);
}
