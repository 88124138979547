/* eslint-disable react/display-name */
import * as React from 'react';
import { Box, Title, ModalHeader, ModalSection } from '@zen-common/components-base';
import { useUnmountedRef } from '@zen/common-react-hooks';
import WalletStore from '../../../stores/WalletStore';
import ExecuteContractStore from '../../../stores/ExecuteContractStore';
import ConfirmPublishTxModalContent from '../../modals/ConfirmPublishTxModalContent';
import PasswordRequiredModalContent from '../../modals/PasswordRequiredModal/PasswordRequiredModalContent';
import ConfirmContractExecution from '../ConfirmContractExecution';
import ExecuteContractForm from './ExecuteContractForm';

export default function ExecuteContractModalProcess({
  contractId,
  onFinish,
  usePreventAction,
  naming,
}) {
  const unmounted = useUnmountedRef();
  const [status, setStatus] = React.useState('execute-form');
  const [runResult, setRunResult] = React.useState();
  const [signedTx, setSignedTx] = React.useState();
  const {
    state: {
      keys: { pkHash },
    },
  } = WalletStore.useStore();
  const { preventAction } = usePreventAction();
  const executeStore = ExecuteContractStore.useStore();
  const contractName = naming[contractId] ? naming[contractId].shortName : '';

  const handleExecuteFormSubmit = async () => {
    if (preventAction()) return;

    const runResult = await executeStore.actions.run();
    if (!unmounted.current) {
      setRunResult(runResult);
      setStatus('confirm-after-execution');
    }
  };

  const handleConfirmAfterExecutionBack = () => {
    setStatus('execute-form');
  };
  const handleConfirmAfterExecutionContinue = () => {
    setStatus('password');
  };

  const handlePasswordBack = () => {
    setStatus('confirm-after-execution');
  };
  const handlePasswordContinue = async (password) => {
    const signedTx = await executeStore.actions.sign({ ...runResult, password });
    if (!unmounted.current) {
      setSignedTx(signedTx);
      setStatus('confirm-after-sign');
    }
  };

  const handleConfirmAfterSignBack = () => {
    setStatus('confirm-after-execution');
  };
  const handleConfirmAfterSignContinue = async () => {
    if (preventAction() || !signedTx) return;
    await executeStore.actions.publish(signedTx);

    onFinish();
  };

  if (status === 'execute-form') {
    return (
      <Box>
        <ModalHeader>
          <Title>Execute {contractName ? contractName : 'a Contract'}</Title>
        </ModalHeader>
        <ModalSection>
          <ExecuteContractForm onSubmit={handleExecuteFormSubmit} />
        </ModalSection>
      </Box>
    );
  }

  if (status === 'confirm-after-execution') {
    if (!runResult) return null;

    return (
      <ConfirmContractExecution
        contractName={contractName}
        contractAddress={executeStore.state.address}
        command={executeStore.state.command}
        includeReturnAddress={executeStore.state.includeReturnAddress}
        includeSender={executeStore.state.includeSender}
        messageBody={executeStore.state.messageBody}
        pkHash={pkHash}
        spends={executeStore.state.spends}
        tx={runResult.tx}
        cancelText="Back"
        onCancel={handleConfirmAfterExecutionBack}
        onConfirm={handleConfirmAfterExecutionContinue}
      />
    );
  }

  if (status === 'password') {
    return (
      <PasswordRequiredModalContent
        cancelText="Back"
        onCancel={handlePasswordBack}
        onSuccess={handlePasswordContinue}
      />
    );
  }

  if (status === 'confirm-after-sign') {
    if (!signedTx) return null;

    return (
      <ConfirmPublishTxModalContent
        title="Confirm execution before publishing"
        tx={signedTx}
        onBack={handleConfirmAfterSignBack}
        onConfirm={handleConfirmAfterSignContinue}
      />
    );
  }

  return null;
}
