import createStore from '../../utils/createStore';
import useSendStore from './useSendStore';

const { Provider, useStore } = createStore({
  useStoreHook: useSendStore,
  contextDisplayName: 'SendStoreContext',
});

export default {
  /**
   * @type {SendStoreProvider}
   */
  Provider,
  /**
   * @type {() => import('./types').SendStore}
   */
  useStore,
};

/**
 * @typedef {({chain: string, nodeUrl: string, wallet: Object}) => void} SendStoreProvider
 */
