import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Checkbox,
  Mnemonic,
  useModal,
  Box,
  LoadingOr,
  themeUtils as tu,
} from '@zen-common/components-base';
import { useMnemonic } from '@zen/common-react-hooks';
import Layout from '../blocks/Layout';
import Header from '../blocks/Header';
import Footer from '../blocks/Footer';
import useSubmitWithLoading from '../hooks/useSubmitWithLoading';
import PaginationButton from '../blocks/PaginationButton';
import getMarginBottom from '../modules/getMarginBottom';
import SecurityWarningsModal from './SecurityWarnings';

export default function SecretPhrase({ onBack, onContinue, onPhraseGenerated }) {
  const { show: showModal } = useModal();
  const [modalShown, setModalShown] = useState(false);
  const phrase = useMnemonic({ generate: true });
  const [checked, setChecked] = useState(false);
  const submitDisabled = !checked;
  const { handleSubmit, loading } = useSubmitWithLoading({
    validate: () => !submitDisabled,
    onSubmit: onContinue,
  });

  const onCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    onPhraseGenerated(phrase.mnemonic);
  }, [phrase.mnemonic, onPhraseGenerated]);

  // show modal on mount
  useEffect(() => {
    if (!modalShown) {
      showModal(() => <SecurityWarningsModal />);
    }
    setModalShown(true);
  }, [modalShown, showModal]);

  return (
    <Layout
      header={
        <Header
          title=" Save your Passphrase"
          text="Write down the following 24 words in chronological order and save it in a secure place. If you lose it, you will lose access to all of your funds."
        />
      }
      body={
        <MainContainer>
          <Mnemonic {...phrase} isEdit={false} columns={[2, 3, 4]} />
        </MainContainer>
      }
      footer={
        <Footer
          left={
            <label>
              <Checkbox
                checked={checked}
                onChange={onCheckboxChange}
                label="I saved my passphrase and it’s secure."
              />
            </label>
          }
          right={
            <>
              <PaginationButton onClick={onBack} mr="smd" bg="secondary">
                Cancel
              </PaginationButton>
              <PaginationButton disabled={submitDisabled} onClick={handleSubmit}>
                <LoadingOr loading={loading}>Continue</LoadingOr>
              </PaginationButton>
            </>
          }
        />
      }
    />
  );
}

SecretPhrase.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onPhraseGenerated: PropTypes.func.isRequired,
};

const MainContainer = styled(Box)`
  background-color: ${tu.color('bg1')};
  display: flex;
  justify-content: center;

  ${(p) => {
    const smd = tu.space('smd')(p),
      mlg = tu.space('mlg')(p),
      xl = tu.space('xl')(p);
    return css(
      tu.mq({
        padding: [`${smd} 0 0`, null, `${xl} ${xl} ${mlg} ${xl}`],
        borderStyle: [null, null, 'solid'],
        borderWidth: [null, null, '1px'],
        borderColor: [null, null, tu.color('borderLight')(p)],
      })
    );
  }}
  ${getMarginBottom}
`;
