import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typography, color, space, compose } from 'styled-system';
import getFontSize from './modules/getFontSize';

const StyledTitle = styled.h1`
  letter-spacing: 0.2px;
  margin-top: 0;
  font-size: ${getFontSize};
  font-weight: 500;

  ${compose(space, color, typography)}
`;

StyledTitle.propTypes = {
  size: PropTypes.oneOf(['1', '2', '3', '4', '5']),
  fontFamily: PropTypes.string,
  color: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
StyledTitle.defaultProps = {
  size: '1',
  color: 'text',
  mb: 7,
};

export default StyledTitle;
