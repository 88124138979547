import React from 'react';
import styled from 'styled-components';
import { Box, GlobalStyle } from '@zen-common/components-base';

export default function AppLayout({ children }) {
  return (
    <>
      <GlobalStyle />
      <MainContainer>{children}</MainContainer>
    </>
  );
}

const MainContainer = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 360px;
`;
