import { useContext, useEffect } from 'react';

export const PREFIX = 'storage-updater-';

/**
 * Creates the useStorageUpdater hook with the given context
 * The hook calls the setters on init and updates the storage on every change
 * The items can not be changed during the life of the component
 * The setters are optional
 */
export const createHook = (context) => {
  return function useStorageUpdater(items = []) {
    const storage = useContext(context);

    // call setters on init
    useEffect(() => {
      items.forEach((item) => {
        const val = storage.get(PREFIX + item.key);
        if (val !== null && val !== undefined && typeof item.setter === 'function') {
          item.setter(val);
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // to be used for useEffect, creates a string with a separator for all items
    const itemValuesAsString = items.reduce(
      (combined, cur) => `${combined},${JSON.stringify(cur.value)}`,
      ''
    );

    // save to storage
    useEffect(() => {
      items.forEach((item) => {
        const val = item.value;
        if (val !== null && val !== undefined) {
          storage.set(PREFIX + item.key, val);
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items.length, itemValuesAsString, storage]);
  };
};
