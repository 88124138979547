import { useRef, useEffect } from 'react';
import { Decimal } from 'decimal.js';
import { numberUtils } from '@zen/common-utils';

/**
 * Calls an onChange callback when the balance prop has changed
 * Goes over all of the assets in the balance and checks if any has changed
 * The callback is called with an object containing the difference per asset
 *
 * @param {Object} params
 * @param {Object} params.balance - the wallet balance
 * @param {({asset: string}) => void} params.onChange
 */
export default function useBalanceChanged({ balance, onChange }) {
  const initialized = useRef(false);
  const curBalance = useRef(balance);

  useEffect(() => {
    const changedBalance = Object.keys(balance).reduce((changed, asset) => {
      if (curBalance.current[asset] !== balance[asset]) {
        changed[asset] = numberUtils.toValue(
          new Decimal(balance[asset]).minus(curBalance.current[asset] || 0)
        );
      }
      return changed;
    }, {});

    curBalance.current = balance;

    if (Object.keys(changedBalance).length) {
      if (initialized.current && typeof onChange === 'function') {
        onChange(changedBalance);
      }
    }

    initialized.current = true;
  }, [balance, onChange]);
}
