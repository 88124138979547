import React from 'react';
import styled from 'styled-components';
import Box from '../Box';
import tu from '../../utils/themeUtils';

/**
 * Display a pending text
 */
export default function Pending() {
  return <DangerBox>Pending...</DangerBox>;
}

const DangerBox = styled(Box)`
  color: ${tu.color('danger')};
`;
