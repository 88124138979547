import React, { createContext, useContext } from 'react';

/**
 * Create a store with a provider and a useStore function
 * @param {Object} props
 * @param {({props: Object}) => any} props.useStoreHook - a function that should return the store
 * @param {string} props.contextDisplayName - the display name of the context (for dev tools)
 */
export default function createStore({ useStoreHook, contextDisplayName = 'Context' } = {}) {
  const Context = createContext();
  Context.displayName = contextDisplayName;

  const Provider = ({ children, ...props }) => {
    const store = useStoreHook(props);
    return <Context.Provider value={store}>{children}</Context.Provider>;
  };
  const useStore = () => {
    return useContext(Context);
  };

  return { Context, Provider, useStore };
}
