/* eslint-disable react/display-name */
import * as React from 'react';
import styled from 'styled-components';
import { Box, Panel, Table, themeUtils as tu } from '@zen-common/components-base';

/**
 * Renders a table for past/current votes which has 100% height
 * @param {Object} params
 * @param {Array} params.columns - columns for ReactTable
 * @param {Array} params.data - data to render
 * @param {({value: string, vote: boolean}) => void} params.onRowClick
 */
export default function VoteResultsTable({ onRowClick, columns, data, ...props }) {
  const handleRowClick = (data) => {
    typeof onRowClick === 'function' && onRowClick(data);
  };

  return (
    <StyledPanel>
      <TableWrapper>
        <StyledTable
          resizable={false}
          sortable={false}
          minRows={0}
          pageSize={(data || []).length}
          showPagination={false}
          columns={columns}
          data={data}
          getTrProps={(state, rowInfo) => {
            return {
              onClick: (e) => {
                const buttonTarget = e.target.closest('button');
                const voteBtnWasClicked =
                  !!buttonTarget && buttonTarget.getAttribute('data-vote') === 'true';

                const isMobile = document.body.clientWidth < tu.breakpoints.xl.replace('px', '');
                if (!buttonTarget || voteBtnWasClicked) {
                  handleRowClick({
                    data: rowInfo.original,
                    vote: isMobile || voteBtnWasClicked,
                  });
                }
              },
            };
          }}
          {...props}
        />
      </TableWrapper>
    </StyledPanel>
  );
}

const StyledPanel = styled(Panel)`
  padding: 0;
  display: flex;
  flex-direction: column;
  ${tu.mq({
    height: ['auto', null, null, null, '100%'],
  })}
`;

const TableWrapper = styled(Box)`
  background-color: ${tu.color('bg1')};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  @media (max-width: ${tu.breakpoints.xl.replace('px', '') - 1}px) {
    .force-hidden-mobile {
      display: none !important;
    }
  }
`;

const StyledTable = styled(Table)`
  .rt-noData {
    /* override "no data" position for this table only */
    top: 75px !important;
  }
  @media screen and (min-width: ${tu.breakpoints.xl}) {
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  ${tu.mq({
    height: ['300px', null, null, null, 'auto'],
  })}
`;
