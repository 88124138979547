import * as React from 'react';
import { useQuery } from 'react-query';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  Title,
  useModal,
  Button,
} from '@zen-common/components-base';
import { sendHttpRequest } from '../../utils/ApiService';
import ConfigStore from '../../stores/ConfigStore';

const REFETCH_INTERVAL = 5 * 60 * 1000;

/**
 * Check for an update and report in a Modal
 * @param {Object} props
 * @param {string} props.remotePropPrefix - the prefix for all relevant props in the json (ex. 'wallet' for 'wallet-version')
 * @param {string} props.title - the title of the modal
 */
export default function OnlyOnDesktop({ remotePropPrefix, title }) {
  if (process.env.REACT_APP_END_PLATFORM === 'desktop') {
    return <CheckAppUpdates remotePropPrefix={remotePropPrefix} title={title} />;
  } else {
    return null;
  }
}

function CheckAppUpdates({ remotePropPrefix, title }) {
  const { show } = useModal();
  const { buildVersion } = ConfigStore.useStore();

  const { data } = useQuery(['fetch-app-version', { remotePropPrefix }], fetchVersion, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
  });

  const { version, message } = data || {};

  React.useEffect(() => {
    if (!version) return;

    if (version !== buildVersion) {
      show(() => <DownloadModal title={title} version={version} message={message} />);
    }
  }, [buildVersion, message, show, title, version]);

  return null;
}

function DownloadModal({ title, version, message }) {
  const { hide } = useModal();

  const _message =
    message ||
    `Version ${version} has been release, please use the following link to get your updated app.`;
  return (
    <Modal dismissOnClickOutside={false}>
      <ModalContent showClose={false}>
        <ModalHeader>
          <Title>{title}</Title>
        </ModalHeader>
        <ModalSection>{_message}</ModalSection>
        <ModalSeparator />
        <ModalFooter>
          <Button bg="secondary" onClick={hide}>
            Dismiss
          </Button>
          <Button
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.zenprotocol.com/apps/wallet/preparation/installers"
          >
            View Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

async function fetchVersion(_, { remotePropPrefix }) {
  const data = await sendHttpRequest({
    method: 'GET',
    url: 'https://zen-distributables.s3-eu-west-1.amazonaws.com/version.json',
  });

  return {
    version: data[`${remotePropPrefix}-version`],
    message: data[`${remotePropPrefix}-message`],
  };
}
