import * as React from 'react';
import MessageWithExplorerLink from '../MessageWithExplorerLink';

export default function NoZpMessage({ interval, phase }) {
  return (
    <MessageWithExplorerLink isOngoing interval={interval} phase={phase}>
      You did not have any ZP at snapshot block.
      <br />
      Your vote weight consists of your total ZP at snapshot block.
    </MessageWithExplorerLink>
  );
}
