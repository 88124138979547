const convert = (s) => (isNaN(s) ? s : `${s}px`);

/**
 * Convert a number or an array of numbers to strings with 'px'
 */
export default function sizeToPx(subject) {
  if (!subject) return subject; // can also be 0
  if (!subject.map) {
    if (typeof subject !== 'number' && typeof subject !== 'string') {
      return subject;
    }
    return convert(subject);
  }
  return subject.map(convert);
}
