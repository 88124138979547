const KEY_PREFIX = 'zen-protocol-';

// make sure storage objects are defined for node js environment
let storage;
if (typeof window !== 'undefined') {
  storage = window.localStorage;
} else {
  storage = {};
}
let localStore = {
  get(key) {
    const innerKey = `${KEY_PREFIX}${key}`;
    return JSON.parse(storage.getItem(innerKey));
  },
  set(key, value) {
    const innerKey = `${KEY_PREFIX}${key}`;
    storage.setItem(innerKey, JSON.stringify(value));
  },
  remove(key) {
    const innerKey = `${KEY_PREFIX}${key}`;
    storage.removeItem(innerKey);
  },
  clear() {
    storage.clear();
  },
};

if (Object.keys(storage).length === 0 && storage.constructor === Object) {
  // no storage mechanisms
  localStore = {
    get() {
      return null;
    },
    set() {},
    remove() {},
    clear() {},
  };
}

export default localStore;
