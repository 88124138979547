import React from 'react';
import { Box, Loading, CopyableHash } from '@zen-common/components-base';
import { NamingAssetStore, TechnicalData as TD, ZenJsUtils } from '@zen/common-app-parts';
import { numberUtils, assetUtils } from '@zen/common-utils';
import MessageWithExplorerLink from '../MessageWithExplorerLink';

export function NominationOrPayoutSuccessMessage({ chain, store, isNomination, interval }) {
  const { state: naming } = NamingAssetStore.useStore();
  const ballotId = isNomination ? store.nominationVote : store.payoutVote;
  if (!ballotId) return <Loading />; // vote can still be in fetch progress

  const { address, spends } = ZenJsUtils.deserializePayoutBallotId(chain, ballotId);
  return (
    <MessageWithExplorerLink
      isOngoing
      type="success"
      interval={interval}
      phase={isNomination ? 'nomination' : 'vote'}
    >
      <Box mb="4">{isNomination ? 'Nomination' : 'Payout'} vote was successfully broadcast</Box>
      <Box color="label">You voted for:</Box>
      <Box>
        <TD.Label>Ballot ID:</TD.Label>
        <TD.Value>
          <CopyableHash hash={ballotId} />
        </TD.Value>
      </Box>
      <Box>
        <TD.Label>Vote weight:</TD.Label>
        <TD.Value>{numberUtils.toDisplay(store.snapshotZpBalance)} ZP</TD.Value>
      </Box>
      <Box>
        <TD.Label>Recipient address:</TD.Label>
        <TD.Value>
          <CopyableHash hash={address} />
        </TD.Value>
      </Box>
      <Box display="flex">
        <Box>
          {spends.map((spend) => (
            <Box key={spend.asset}>
              <TD.Label>Amount/Asset:</TD.Label>
              <TD.Value>{numberUtils.toDisplay(spend.amount)}</TD.Value>
              <TD.Value>
                <CopyableHash
                  hash={spend.asset}
                  display={
                    !!naming && naming[spend.asset]
                      ? naming[spend.asset].shortName
                      : assetUtils.truncateOrNameAsset(spend.asset)
                  }
                />
              </TD.Value>
            </Box>
          ))}
        </Box>
      </Box>
    </MessageWithExplorerLink>
  );
}
