import styled from 'styled-components';
import { color, typography, space, layout, compose } from 'styled-system';

const StyledLabelText = styled.span`
  text-transform: uppercase;
  font-weight: 500;
  ${compose(layout, color, typography, space)}
`;
StyledLabelText.defaultProps = {
  color: 'label',
  fontSize: 'sm',
  mb: 2,
  display: 'block',
  letterSpacing: '0.32px',
};

const StyledLabel = styled.label``;

export default StyledLabel;
export { StyledLabelText as LabelText };
