import { useCallback } from 'react';
import useUpDown from './modules/useUpDown';

/**
 * Provides a onKeyDown event handler to in/decrease the value when pressing on the up/down arrows
 * @param {Object} params
 * @param {(number|string)} params.min
 * @param {(number|string)} params.max
 * @param {(number|string)} params.step
 * @param {string} params.value
 * @param {(value: string) => void} params.onChange
 */
export default function useUpDownNumberControl({ min, max, step, value, onChange } = {}) {
  const { onDown, onUp } = useUpDown({ min, max, step, value, onChange });

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        onUp();
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        onDown();
      }
    },
    [onDown, onUp]
  );

  return { onKeyDown };
}
