import React from 'react';
import { Box, Copyable } from '@zen-common/components-base';
import { validateCommitId } from '../../modules/utils';

/**
 * Truncates and show a copy button for a commitId
 *
 * @export
 * @param {Object} props
 * @param {string} props.commitId
 * @param {boolean} props.truncate - whether to truncate, true by default
 */
export default function CopyableCommitId({ commitId, truncate = true, ...props }) {
  const truncated = truncateCommitId(commitId);
  const title = truncate && truncated !== commitId ? commitId : null;

  return (
    <Copyable value={commitId}>
      <Box title={title} {...props}>
        {truncate ? truncated : commitId}
      </Box>
    </Copyable>
  );
}

/**
 * @param {string} commitId
 */
function truncateCommitId(commitId) {
  if (!validateCommitId(commitId)) return commitId;

  return commitId.substring(0, 7);
}
