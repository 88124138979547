import { useReducer, useCallback } from 'react';
import { produce } from 'immer';
import { assetUtils } from '@zen/common-utils';
import * as OracleService from '../../../utils/OracleService';
import * as NamingUtils from '../../../utils/NamingUtils';
import * as FpUtils from '../../../utils/FpUtils';
import { setAsset, setFetchPhase, setResult, setter } from './redeemAssetSetters';

const ACTIONS = {
  SET: 'SET',
  RESET: 'RESET',
  PROGRESS: 'PROGRESS',
};

const initialState = {
  asset: {
    value: '',
    valid: false,
  },
  result: {
    error: '',
    assetInfo: '',
    isRedeem: false,
    isRedeemable: [],
    redeemBalance: [],
  },
  progress: true,
  fetchPhase: '', // node-fetch|node-done|oracle-fetch|oracle-done
};

export default function useRedeemAssetStore({ nodeUrl, oracleData = {} }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  /** @type {initialState} */

  const isBalancesRedeemable = useCallback(
    async ({ balances, contracts, chain }) => {
      if (balances) {
        dispatch({
          type: ACTIONS.SET,
          payload: { prop: 'result', value: { isRedeemable: [], error: '' } },
        });
        dispatch({ type: ACTIONS.PROGRESS, payload: { value: true } });
        let mintData = [];
        let parsedData = [];
        for (let balance of balances) {
          const {
            asset,
            metadata: { name, misc },
          } = balance;
          if (
            FpUtils.checkAsset({
              asset,
              contracts,
              name,
            })
          ) {
            const start = NamingUtils.getRedeemableEODTimestamp(name);
            const ticker = NamingUtils.getRedeemableTickerData(name);
            const position = NamingUtils.getRedeemablePositionData(name);
            const price = NamingUtils.getRedeemablePriceData(name);
            const expiry = NamingUtils.getRedeemableEODTimestampHigh(name);
            if (
              misc.oracleCID === oracleData.oracleCid &&
              misc.oraclePK === oracleData.oraclePk[chain]
            ) {
              parsedData.push({ asset, start, ticker, position, price });
              mintData.push({ low: start, key: ticker, high: expiry });
            }
          }
        }
        const { valuesResult } = await OracleService.GetValues({
          oracleUrl: oracleData.oracleUrl[chain],
          dispatch,
          mintData,
        });
        let isRedeemable = [];
        //merge parsedDAta with valuesResult only if start ticker is the same
        for (let values of valuesResult) {
          const { value, key: ticker, low: start } = values;
          for (let parsed of parsedData) {
            const { start: parsedStart, ticker: parsedTicker, position, price, asset } = parsed;
            if (parsedStart === start && parsedTicker === ticker)
              isRedeemable.push({
                asset,
                value,
                isRedeemable: FpUtils.checkRedeemPosition({
                  position,
                  isBull: Number(value) >= Number(price),
                }),
              });
          }
        }

        const zpBalance = balances.find((data) => assetUtils.isZP(data.asset));
        const redeemable = balances.filter(
          (data) =>
            FpUtils.isRedeem({ asset: data.asset, list: isRedeemable }) &&
            !assetUtils.isZP(data.asset)
        );
        const notRedeemInList = balances.filter(
          (data) =>
            !FpUtils.isRedeem({ asset: data.asset, list: isRedeemable }) &&
            (FpUtils.isInList({ asset: data.asset, list: isRedeemable }) || false) &&
            !assetUtils.isZP(data.asset)
        );
        const notRedeemable = balances.filter(
          (data) =>
            FpUtils.isRedeem({ asset: data.asset, list: isRedeemable }) === undefined &&
            (!FpUtils.isInList({ asset: data.asset, list: isRedeemable }) || false) &&
            !assetUtils.isZP(data.asset)
        );
        const combined = [...redeemable, ...notRedeemInList, ...notRedeemable];
        if (zpBalance) combined.unshift(zpBalance);
        dispatch({
          type: ACTIONS.SET,
          payload: { prop: 'result', value: { redeemBalance: combined, isRedeemable } },
        });
        dispatch({ type: ACTIONS.PROGRESS, payload: { value: false } });
      }
    },
    [oracleData]
  );
  const generate = useCallback(
    async ({ asset: assetValue, setMessage, chain }) => {
      if (assetValue) {
        dispatch({ type: ACTIONS.PROGRESS, payload: { value: true } });
        try {
          const {
            start,
            ticker,
            expiry,
            contractId,
            price,
            publicKey,
            asset,
          } = await OracleService.GetMint({
            nodeUrl,
            dispatch,
            assetValue,
          });

          if (publicKey !== oracleData.oraclePk[chain] || contractId !== oracleData.oracleCid)
            throw new Error('Not redeemable by this provider');

          const { queryResult } = await OracleService.GetQuery({
            oracleUrl: oracleData.oracleUrl[chain],
            mintData: {
              expiry,
              start,
              ticker,
            },
            dispatch,
          });
          const [
            {
              index,
              path,
              root,
              timestamp,
              item: { value },
            },
          ] = queryResult;

          let position;
          try {
            position = FpUtils.getPosition({
              assetId: assetValue,
              contractId,
              publicKey,
              start,
              ticker,
              expiry,
              price,
              asset,
            }).position;
          } catch (error) {
            throw new Error('Error parsing the asset in order to get the position');
          }

          const messageBody = FpUtils.getMessageBody({
            isRedeem: true,
            auditPath: path,
            index,
            root,
            timestamp,
            value,
            oracleContractId: contractId,
            oraclePubKey: publicKey,
            position,
            price,
            start,
            ticker,
            expiry,
            asset,
          });

          setMessage(messageBody);

          dispatch({ type: ACTIONS.PROGRESS, payload: { value: false } });
        } catch (error) {
          dispatch({ type: ACTIONS.PROGRESS, payload: { value: false } });
          throw error;
        }
      }
    },
    [nodeUrl, oracleData]
  );

  return {
    state,
    actions: {
      setAsset: useCallback((value) => {
        dispatch({ type: ACTIONS.SET, payload: { prop: 'asset', value } });
      }, []),
      setResult: useCallback((value) => {
        dispatch({ type: ACTIONS.SET, payload: { prop: 'result', value } });
      }, []),
      reset: useCallback(() => {
        dispatch({ type: ACTIONS.RESET });
      }, []),
      isBalancesRedeemable,
      generate,
    },
  };
}

const PropsSettersMap = {
  asset: setAsset,
  result: setResult,
  fetchPhase: setFetchPhase,
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.SET:
      setter({ PropsSettersMap, draft, prop: action.payload.prop, value: action.payload.value });
      break;
    case ACTIONS.PROGRESS:
      draft.progress = action.payload.value;
      break;
    case ACTIONS.RESET:
      reset(draft);
      break;
    default:
      throw new Error(`Unhandled action type ${action.type}`);
  }
});

function reset(draft) {
  setAsset('', draft);
  setResult({ error: '', messageBody: '' }, draft);
}
