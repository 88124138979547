import * as ApiService from './ApiService';
import * as FpUtils from './FpUtils';

const { sendHttpRequest, getUrl } = ApiService;

/**
 * @param {string} serviceUrl
 * @param {Object} param1
 * @param {string} param1.ticker
 * @param {string} param1.start
 * @param {string} param1.expiry
 * @param {number} param1.skip
 * @param {number} param1.take
 *
 * @returns {{
    item: {
      key: string,
      value: string,
    },
    timestamp: string,
    index: string,
    commit: string,
    hash: string,
    path: string,
    root: string,
  }}
 */
export function query(serviceUrl, { ticker, start, expiry, skip = 0, take = 5 }) {
  return sendHttpRequest({
    url: getUrl(serviceUrl, '/query'),
    method: 'get',
    params: {
      key: ticker ? ticker : undefined,
      low: start,
      high: expiry,
      skip,
      take,
    },
  });
}

/**
 * @param {string} serviceUrl
 * @param {Object} param1
 * @param {string} param1.ticker
 * @param {string} param1.start
 * @param {string} param1.expiry
 *
 * @returns {{
    count: string
  }}
 */
export function queryCount(serviceUrl, { ticker, start, expiry }) {
  return sendHttpRequest({
    url: getUrl(serviceUrl, '/count'),
    method: 'get',
    params: {
      key: ticker ? ticker : undefined,
      low: start,
      high: expiry,
    },
  });
}

/**
 * @param {string} serviceUrl
 * @param {Object[]} data
 * @param {string} data.ticker
 * @param {string} data.start
 * @param {string} data.expiry
 *
 * @returns {{
    item: {
      key: string,
      value: string,
    },
    timestamp: string,
    index: string,
    commit: string,
    hash: string,
    path: string,
    root: string,
  }}
 */
export function getValues(serviceUrl, data) {
  return sendHttpRequest({
    url: getUrl(serviceUrl, '/getValues'),
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });
}

export async function GetMint({ dispatch, assetValue, nodeUrl }) {
  let mint;
  try {
    dispatchFetchStatus(dispatch, 'node-fetch');
    mint = await ApiService.getContractMint(nodeUrl, assetValue);
    dispatchFetchStatus(dispatch, 'node-done');
  } catch (error) {
    dispatchFetchStatus(dispatch, 'node-fail');
    if (error.status === 400) {
      throw new Error('Could not find the requested asset ID');
    }
    throw new Error('Error getting data from the node');
  }

  return FpUtils.parseIssue(mint);
}
export async function GetQuery({ dispatch, mintData, oracleUrl }) {
  const { expiry, start, ticker } = mintData;

  let queryResult;
  try {
    dispatchFetchStatus(dispatch, 'oracle-fetch');
    queryResult = await query(oracleUrl, {
      ticker,
      expiry,
      start,
    });
    if (!queryResult.length) {
      throw new Error('empty');
    }
    dispatchFetchStatus(dispatch, 'oracle-done');
  } catch (error) {
    dispatchFetchStatus(dispatch, 'oracle-fail');
    if (error.message === 'empty') {
      throw new Error('No available data in the oracle for this asset ID');
    }
    throw new Error('Error getting data from the oracle service');
  }
  return {
    queryResult,
  };
}
export async function GetValues({ dispatch, mintData, oracleUrl }) {
  let valuesResult;
  try {
    dispatchFetchStatus(dispatch, 'oracle-fetch');
    valuesResult = await getValues(oracleUrl, mintData);
    dispatchFetchStatus(dispatch, 'oracle-done');
  } catch (error) {
    dispatchFetchStatus(dispatch, 'oracle-fail');
    if (error.message === 'empty') {
      throw new Error('No available data in the oracle for this asset ID');
    }
    throw new Error('Error getting data from the oracle service');
  }
  return {
    valuesResult,
  };
}
function dispatchFetchStatus(dispatch, status) {
  dispatch({ type: 'SET', payload: { prop: 'fetchPhase', value: status } });
}
