import { useRef, useState, useEffect, useCallback } from 'react';
import { useFetchTxCount } from '../utils/ApiQueries';

const REFETCH_INTERVAL = 10000;
/**
 * Sets a flag when tx count is changed
 */
export default function useTxCountChanged() {
  const lastTxCount = useRef(0);
  const initialFetchDone = useRef(false);
  const [changed, setChanged] = useState(false);

  const { data } = useFetchTxCount({
    refetchInterval: REFETCH_INTERVAL,
    enabled: true,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    if (data !== undefined) {
      // this must be first
      if (initialFetchDone.current && lastTxCount.current !== data) {
        setChanged(true);
      }
      lastTxCount.current = data;
      initialFetchDone.current = true;
    }
  }, [data]);

  const clear = useCallback(() => {
    setChanged(false);
  }, []);
  const reset = useCallback(() => {
    initialFetchDone.current = false;
    lastTxCount.current = 0;
    setChanged(false);
  }, []);

  return {
    state: {
      changed,
    },
    actions: {
      clear,
      reset,
    },
  };
}
