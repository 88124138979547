import styled from 'styled-components';
import { resetButton } from '../../../utils/styles/mixins';

const TransparentButton = styled.button.attrs({ type: 'button' })`
  ${resetButton}

  padding: 2px;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  }
`;

export default TransparentButton;
