import {
  ContractId,
  Address,
  YAML,
  ExtendedKey,
  SecurePhrase,
  Wallet,
  Payout,
  Allocation,
  Ballot,
  Spend,
  Asset,
  Str,
  PublicKey,
} from '@zen/zenjs';
import { assetUtils } from '@zen/common-utils';
import { RemoteNode } from './ApiService';

export function decryptPassword({ password, encryptedSeed }) {
  return SecurePhrase.decrypt(password, encryptedSeed).toString();
}

export function encryptPassword({ password, mnemonic }) {
  return SecurePhrase.encrypt(password, mnemonic);
}

export function walletFromMnemonic({ chain, mnemonic, nodeUrl }) {
  return Wallet.fromMnemonic(mnemonic, new RemoteNode(nodeUrl), chain);
}

function privateKey({ mnemonic, change }) {
  return ExtendedKey.getZenPrivateKey(mnemonic, 0, change, 0);
}

export function privateKeys({ mnemonic }) {
  return [privateKey({ mnemonic, change: 0 }), privateKey({ mnemonic, change: 1 })];
}

export function getPrivateKey({ mnemonic }) {
  const extended = ExtendedKey.fromMnemonic(mnemonic, 'pair');
  return extended.derivePath("m/44'/258'/0'/0/0").toBase58();
}

export function getXPub({ mnemonic }) {
  const extended = ExtendedKey.fromMnemonic(mnemonic, 'pair');
  return extended.derivePath("m/44'/258'/0'").neutered().toBase58();
}

/**
 * @param {Chain} chain
 * @param {string} yaml
 */
export function validateMessageBody(chain, yaml) {
  try {
    YAML.fromYaml(chain, yaml);
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * @param {Chain} chain
 * @param {string} address
 */
export function validateAddress(chain, address) {
  try {
    Address.decode(chain, address);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * @param {string} hex
 */
export function validateBallot(hex) {
  try {
    const payout = Ballot.fromHex(hex);
    return 2 * payout.getSize() === hex.length;
  } catch (e) {
    return false;
  }
}

/**
 * @param {string} value
 */
export function validateAsset(value) {
  try {
    new Asset(assetUtils.getAssetFullId(value));
    return true;
  } catch (error) {
    return false;
  }
}

export function getAddress(recipient) {
  if (!recipient) return '';
  switch (recipient.kind) {
    case 'PKRecipient':
      return recipient.hash.hash;
    case 'ContractRecipient':
      return recipient.contractId;
    default:
      return '';
  }
}

/**
 * @param {Chain} chain
 * @param {string} ballotId
 */
export function deserializePayoutBallotId(chain, ballotId) {
  const { data } = Ballot.fromHex(ballotId);
  const address = Address.getPublicKeyHashAddress(chain, getAddress(data.recipient));
  return {
    address,
    spends: data.spends.map((spend) => ({
      asset: spend.asset.asset,
      amount: assetUtils.fromKalapas(spend.amount),
    })),
  };
}

/**
 *
 * @param {Chain} chain
 * @param {string} recipient
 * @param {*} spends
 */
export function serializePayoutBallotId(chain, recipient, spends) {
  return new Ballot(toCgpPayout(chain, recipient, spends)).toHex();
}

/**
 * @param {string} ballotId
 */
export function deserializeAllocationBallotId(ballotId) {
  return Ballot.fromHex(ballotId).getData().allocation;
}

/**
 * @param {Chain} chain
 * @param {string} yaml
 */
export function getMessageBody(chain, yaml) {
  return yaml ? YAML.fromYaml(chain, yaml) : new Str('');
}

/**
 * @param {Chain} chain
 * @param {string} contractId
 */
export function getContractAddress(chain, contractId) {
  return Address.getPublicKeyHashAddress(chain, ContractId.fromString(contractId));
}

/**
 * @param {string} assetId
 */
export function getContractIdFromAsset(assetId) {
  try {
    return new Asset(assetId).toContractId();
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

/**
 * @param {string} assetId
 */
export function getContractFromAsset(assetId) {
  try {
    return new Asset(assetId).toContractId().toHex();
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

/**
 * @param {Chain} chain
 * @param {string} assetId
 */
export function getContractAddressFromAsset(chain, assetId) {
  try {
    return Address.getPublicKeyHashAddress(chain, getContractIdFromAsset(assetId));
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

/**
 * @param {Chain} chain
 * @param {string} pk
 */
export function getPublicKeyAddress(chain, pk) {
  return PublicKey.fromString(pk).toAddress(chain);
}

/**
 * Sorts the spends by asset ASC and converts each to a Spend
 * @param {[AssetAmount]} spends
 */
export function toSpend(spends) {
  return spends
    .sort((a, b) => {
      if (a.asset > b.asset) {
        return 1;
      }
      if (a.asset < b.asset) {
        return -1;
      }
      return 0;
    })
    .map((spend) => {
      const { asset, amount } = spend;
      return new Spend(new Asset(asset), String(amount));
    });
}

/**
 * Creates a cgp payout, make sure the spends are sorted
 * @param {Chain} chain
 * @param {string} recipient
 * @param {[AssetAmount]} spends
 */
export function toCgpPayout(chain, recipient, spends) {
  const spendArr = toSpend(spends);
  const address = Address.decode(chain, recipient);
  if (address instanceof ContractId) {
    return new Payout(
      {
        kind: 'ContractRecipient',
        contractId: address,
      },
      spendArr
    );
  }
  return new Payout(
    {
      kind: 'PKRecipient',
      hash: address,
    },
    spendArr
  );
}

export function toCgpAllocation(percentage) {
  return new Allocation(percentage);
}

/** @typedef {('main'|'test')} Chain */
/** @typedef {{ asset: string, amount: string }} AssetAmount */

/**
 * Get a Zenjs Asset
 * @param {string} a
 */
export function getAsset(a) {
  try {
    return new Asset(a);
    // eslint-disable-next-line no-empty
  } catch {}
}

/**
 * Get a Zenjs ContractId String
 * @param {string} a
 */
export function getContractId(a) {
  try {
    return getAsset(a).toContractId().toHex();
    // eslint-disable-next-line no-empty
  } catch {}
}
