import React from 'react';
import { SendExecuteStore, WalletStore, SettingsStore } from '@zen/common-app-parts';

export default function Provider(props) {
  const {
    state: {
      settings: { chain, nodeUrl },
    },
  } = SettingsStore.useStore();
  const wallet = WalletStore.useStore();

  return <SendExecuteStore.Provider chain={chain} nodeUrl={nodeUrl} wallet={wallet} {...props} />;
}
