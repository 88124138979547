import React from 'react';
import styled from 'styled-components';
import { color, layout, border, typography, space, compose } from 'styled-system';
import tu from '../../utils/themeUtils';
import { getTransition, getPaddings } from '../../utils/styles/mixins';
import Box from '../Box';

export const StyledSideNavContainer = styled(Box)`
  height: 100%;
  display: flex;
`;

export const StyledSideNavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const StyledSideNav = styled.nav`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  margin-top: ${(p) => tu.space(p.marginTop)(p) || '0'};
  width: ${(p) => tu.size(p.width)(p) || '300px'};
  height: 100%;
  transform: translate3d(100%, 0, 0);
  background-color: ${tu.color('bg2')};
  ${getTransition()}
  &.sidenav-is-open {
    transform: initial;
  }
  > ${StyledSideNavList} {
    height: calc(100% - ${(p) => tu.space(p.marginTop)(p) || '0'});
  }
`;

export const StyledSideNavItem = styled.li`
  box-sizing: border-box;
  user-select: none;
  display: block;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  border-bottom: 1px solid ${tu.color('bg')};
  color: ${tu.color('label')};
  font-size: ${tu.fontSize('md')};

  > *:first-child {
    ${getPaddings({ x: 'smd', y: 'md' })}
  }

  a {
    display: block;
    color: ${tu.color('label')} !important;
    text-align: left;
    &.active {
      color: ${tu.color('text')} !important;
    }
  }
  ${getTransition({ property: 'background-color', timing: '0.2s', duration: 'ease-in-out' })}

  ${compose(layout, color, border, typography, space)}
`;

export const StyledSideNavMenuTitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => (p.active ? tu.color('bg3')(p) : 'transparent')};
`;

export const StyledSideNavContent = Box;

export const StyledSideNavMenu = styled(StyledSideNavItem)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  cursor: initial; /* prevent tap highlight on mobile */

  ${StyledSideNavList} {
    border-top: 1px solid ${tu.color('bg')};
  }

  ${StyledSideNavItem} {
    text-transform: capitalize;

    > *:first-child {
      padding-left: ${tu.space('lg')};
    }

    &:last-child {
      border-bottom: 0;
    }
  }
`;

const SideNavMenuToggler = styled(Box)`
  pointer-events: none;
  &.is-open {
    svg {
      transform: rotate(180deg);
    }
  }
  svg {
    font-size: 1.3rem;
    ${getTransition()}
  }
`;

export const StyledSideNavMenuToggler = ({ children, ...p }) => (
  <SideNavMenuToggler {...p}>{children}</SideNavMenuToggler>
);
