/* eslint-disable react/display-name */
import * as React from 'react';
import { WalletStore, PasswordRequiredModalContent } from '@zen/common-app-parts';
import usePreventAction from '../../../utils/usePreventAction';
import VoteOnceModalContent from './VoteOnceModalContent';

/**
 * The voting modal process
 * @param {Object} props
 * @param {string} props.commitId
 * @param {string} props.snapshotZpBalance
 * @param {number} props.interval
 * @param {string} props.phase
 * @param {string} props.votingContractAddress
 * @param {React.SetStateAction<boolean} props.setProgress
 * @param {() => void} props.onSuccess
 * @param {(error: Error) => void} props.onError
 * @param {() => void} props.onCancel
 */
export default function VotingModalProcess({
  commitId,
  snapshotZpBalance,
  interval,
  phase,
  votingContractAddress,
  setProgress,
  onSuccess,
  onError,
  onCancel,
}) {
  const [status, setStatus] = React.useState('vote-once');
  const { preventAction } = usePreventAction();
  const {
    actions: { submitRepoVote },
  } = WalletStore.useStore();

  const handleVoteOnceCancel = () => {
    onCancel();
  };
  const handleVoteOnceContinue = () => {
    setStatus('password');
  };

  const handlePasswordBack = () => {
    setStatus('vote-once');
  };
  const handlePasswordContinue = async (password) => {
    if (preventAction()) return;
    try {
      setProgress(true);
      await submitRepoVote({
        commitId,
        interval,
        phase,
        votingContractAddress,
        password,
      });

      onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  if (status === 'vote-once') {
    return (
      <VoteOnceModalContent
        commitId={commitId}
        snapshotZpBalance={snapshotZpBalance}
        onCancel={handleVoteOnceCancel}
        onConfirm={handleVoteOnceContinue}
      />
    );
  }

  if (status === 'password') {
    return (
      <PasswordRequiredModalContent
        cancelText="Back"
        submitText="Vote"
        onCancel={handlePasswordBack}
        onSuccess={handlePasswordContinue}
      />
    );
  }

  return null;
}
