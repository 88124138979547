import * as React from 'react';
import ConfigStore from '../../stores/ConfigStore';
import SettingsStore from '../../stores/SettingsStore';

/**
 * Checks if the chain or nodeUrl in settings is invalid and resets it to the ones from the config
 */
export default function ResetUnmatchedNodeUrlOrChain() {
  const { nodes, chain: configChain } = ConfigStore.useStore();
  const {
    state: {
      settings: { nodeUrl, chain },
    },
    actions: { setSetting },
  } = SettingsStore.useStore();

  React.useEffect(() => {
    const nodeUrlValid = nodes.some((node) => {
      const [curChain, curUrl] = node.value.split(' ');
      return curChain === chain && curUrl === nodeUrl;
    });
    const chainValid = ['main', 'test'].includes(chain);

    if (!nodeUrlValid || !chainValid) {
      const validChain = chainValid ? chain : configChain;
      if (!chainValid) {
        setSetting({ name: 'chain', value: validChain });
      }

      if (!nodeUrlValid) {
        const validNode = nodes.find((node) => {
          const [curChain] = node.value.split(' ');
          return curChain === validChain;
        });
        setSetting({ name: 'nodeUrl', value: validNode.value.split(' ')[1] });
      }
    }
  }, [chain, configChain, nodeUrl, nodes, setSetting]);

  return null;
}
