import React from 'react';
import styled from 'styled-components';
import tu from '../../utils/themeUtils';
import Box from '../Box';
import Loading from '../Loading';
import Dot from '../Dot';

const { color, fontSize, space } = tu;

/**
 * Display a status indicator (sync status)
 *
 * @param {Object} props
 * @param {[{status: string, label: string, color: string, progress: boolean}]} props.options
 * @param {string} props.status
 */
const StatusIndicator = ({ options = [], status, ...props }) => {
  const option = options.find((o) => o.status === status) ||
    options[0] || { label: '', progress: false, color: 'success' };
  return (
    <StyledWrapper {...props}>
      {option.progress ? <StyledLoading {...option} /> : <StyledDot size="10px" {...option} />}
      <StyledText>{option.label}</StyledText>
    </StyledWrapper>
  );
};

const StyledLoading = styled(Loading)`
  font-size: ${fontSize('sm')};
  margin-right: ${space('xxs')};
`;

const StyledWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
const StyledDot = styled(Dot)`
  margin-right: 6px;
`;

const StyledText = styled.span`
  letter-spacing: 0.32px;
  font-size: ${fontSize('xs')};
  color: ${color('text')};
`;

export default StatusIndicator;
