import { validatePassword, VALIDATE_PASSWORD_MESSAGES } from '@zen/common-utils';

export const formSchema = {
  currentPassword: {
    value: '',
    required: true,
  },
  newPassword: {
    value: '',
    required: true,
    validator: (newPassword, values) => {
      if (newPassword && !validatePassword(newPassword)) {
        return VALIDATE_PASSWORD_MESSAGES.NOT_VALID;
      } else if (values.currentPassword && newPassword === values.currentPassword) {
        return 'New password must be different from current password';
      } else if (values.verifyPassword && newPassword !== values.verifyPassword) {
        return 'Passwords do not match';
      } else return '';
    },
  },
  verifyPassword: {
    value: '',
    required: true,
    validator: (verifyPassword, values) => {
      if (verifyPassword && !validatePassword(verifyPassword)) {
        return VALIDATE_PASSWORD_MESSAGES.NOT_VALID;
      } else if (values.newPassword && verifyPassword !== values.newPassword) {
        return 'Passwords do not match';
      } else return '';
    },
  },
};
