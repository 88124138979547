// required check
const development = [
  'REACT_APP_CGP_FUND_CONTRACT_ID_MAIN',
  'REACT_APP_CGP_VOTING_CONTRACT_ID_MAIN',
  'REACT_APP_CGP_FUND_CONTRACT_ID_TEST',
  'REACT_APP_CGP_VOTING_CONTRACT_ID_TEST',
  'REACT_APP_GOVERNANCE_CONTRACT_ID_MAIN',
  'REACT_APP_GOVERNANCE_CONTRACT_ID_TEST',
  'REACT_APP_CONTRACT_NAMING_JSON',
];
const production = [
  'REACT_APP_CGP_FUND_CONTRACT_ID_MAIN',
  'REACT_APP_CGP_VOTING_CONTRACT_ID_MAIN',
  'REACT_APP_CGP_FUND_CONTRACT_ID_TEST',
  'REACT_APP_CGP_VOTING_CONTRACT_ID_TEST',
  'REACT_APP_GOVERNANCE_CONTRACT_ID_MAIN',
  'REACT_APP_GOVERNANCE_CONTRACT_ID_TEST',
  'REACT_APP_CONTRACT_NAMING_JSON',
  'REACT_APP_CONFIG_REPORT_ERRORS_ENDPOINT',
  'REACT_APP_CONFIG_REPORT_ERRORS_TOKEN',
];

(() => {
  const required = process.env.NODE_ENV === 'development' ? development : production;
  const errors = required.filter((key) => {
    return !process.env[key];
  });

  if (errors.length) {
    throw new Error(
      'Missing environment variables, please provide values for: ' + JSON.stringify(errors, null, 2)
    );
  }
})();
