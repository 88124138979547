import React from 'react';
import PropTypes from 'prop-types';
import { AutoLogoutInput } from '@zen-common/components-base';
import SettingLayout from '../containers/SettingLayout';

export const actions = {
  change: 'VALUE_CHANGE',
};

const AutoLogoutSetting = ({ useSetting, ...props }) => {
  const { value, onAction } = useSetting();

  const handleChange = (val) => {
    if (typeof onAction === 'function') {
      onAction({ type: actions.change, value: Number(val) });
    }
  };

  return (
    <SettingLayout
      header="Auto Logout"
      paragraph="Configure auto logout after inactivity timeout"
      {...props}
    >
      <AutoLogoutInput
        data-testid="AutoLogoutSetting-input"
        value={String(value)}
        onChange={handleChange}
      />
    </SettingLayout>
  );
};

AutoLogoutSetting.propTypes = {
  useSetting: PropTypes.func,
};

export default AutoLogoutSetting;
