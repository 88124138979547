import React from 'react';
import { ExplorerLink } from '@zen/common-app-parts';
import GeneralMessageWithFooter from '../../../components/GeneralMessageWithFooter';
import { getExplorerUrl } from '../modules/utils';

export default function MessageWithExplorerLink({
  type = 'warning',
  isOngoing = false,
  interval = 0,
  phase = 'contestant',
  children,
}) {
  return (
    <GeneralMessageWithFooter
      footer={
        <>
          To see the {isOngoing ? 'ongoing' : 'past'} results please visit the{' '}
          <ExplorerLink path={getExplorerUrl({ interval, phase })}>Block Explorer</ExplorerLink>.
        </>
      }
      type={type}
    >
      {children}
    </GeneralMessageWithFooter>
  );
}
