/**
 * Calculates the vote status
 * @param {Object} params
 * @param {number} params.headers
 * @param {number} params.beginBlock
 * @param {number} params.endBlock
 * @param {import('../types').PhaseName} params.phase
 * @returns {import('../types').VoteStatus}
 */
export default function getVoteStatus({ headers, beginBlock, endBlock, phase }) {
  if (!headers || !beginBlock || !endBlock || !phase) return null;

  const headersStatus = headers < beginBlock ? 'before' : headers < endBlock ? 'during' : 'after';

  return headersStatus === 'before'
    ? phase === 'Contestant'
      ? 'before'
      : 'between'
    : headersStatus === 'during'
    ? phase === 'Contestant'
      ? 'contestant'
      : 'candidate'
    : phase === 'Contestant'
    ? 'between'
    : 'after';
}
