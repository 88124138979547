import React, { useEffect } from 'react';
import { useCurrentRoute } from 'react-navi';
import { useModal, ModalRoot } from '@zen-common/components-base';

export default function RouteAwareModalRoot({ children }) {
  return (
    <ModalRoot>
      <CloseModalOnRouteChange />
      {children}
    </ModalRoot>
  );
}

function CloseModalOnRouteChange() {
  const {
    url: { href },
  } = useCurrentRoute();
  const { hide } = useModal();

  useEffect(() => {
    hide();
  }, [hide, href]);

  return null;
}
