import React, { useState, useCallback } from 'react';
import { useNavigation } from 'react-navi';
import {
  WalletStore,
  SettingsStore,
  ConfigStore,
  DeleteWalletSettingActions,
} from '@zen/common-app-parts';
import { notification } from '@zen-common/components-base';
import PATHS from '../../paths';

export const useChangePasswordSetting = () => {
  const { actions } = WalletStore.useStore();
  const {
    error,
    loading,
    setActionError,
    setActionStart,
    setActionSuccess,
    success,
  } = useErrorSuccessLoading();

  const handleAction = async (action) => {
    try {
      setActionStart();
      await actions.changePassword(action.value);
      setActionSuccess();
    } catch (error) {
      setActionError(error);
    }
  };

  return {
    error,
    success,
    loading,
    onAction: handleAction,
  };
};

export const useBackupWalletSetting = () => {
  const {
    actions,
    state: { keys },
  } = WalletStore.useStore();
  const {
    error,
    loading,
    setActionError,
    setActionStart,
    setActionSuccess,
    success,
  } = useErrorSuccessLoading();

  const handleAction = async (action) => {
    try {
      setActionStart();
      const result = await actions.getPrivateKeys(action.value.password);
      setActionSuccess();
      return { ...result, ...keys };
    } catch (error) {
      setActionError(error);
    }
  };

  return {
    error,
    success,
    loading,
    onAction: handleAction,
  };
};

export const useDeleteWalletSetting = () => {
  const {
    actions,
    state: { keys },
  } = WalletStore.useStore();
  const {
    error,
    loading,
    setActionError,
    setActionStart,
    setActionSuccess,
    success,
  } = useErrorSuccessLoading();

  const handleAction = async ({ type, value }) => {
    try {
      if (type === DeleteWalletSettingActions.backup) {
        setActionStart();
        const result = await actions.getPrivateKeys(value.password);
        setActionSuccess();
        return { ...result, ...keys };
      } else if (type === DeleteWalletSettingActions.delete) {
        setActionStart();
        actions.wipeSeed();
        // do not set success here, a redirect is in action
      }
    } catch (error) {
      setActionError(error);
    }
  };

  return {
    error,
    success,
    loading,
    onAction: handleAction,
  };
};

export const useAutoLogoutSetting = () => {
  const { state, actions } = SettingsStore.useStore();

  const handleAction = (action) => {
    const truncatedValue = action.value < 1 ? 1 : action.value > 120 ? 120 : action.value;
    actions.setSetting({ name: 'logoutMinutes', value: truncatedValue });
  };

  return {
    value: state.settings.logoutMinutes,
    onAction: handleAction,
  };
};

export const useLogoutSetting = () => {
  const { actions } = WalletStore.useStore();
  const {
    error,
    loading,
    setActionError,
    setActionStart,
    setActionSuccess,
    success,
  } = useErrorSuccessLoading();

  const handleAction = () => {
    try {
      setActionStart();
      actions.logout();
      setActionSuccess();
    } catch (error) {
      setActionError(error);
    }
  };

  return {
    error,
    success,
    loading,
    onAction: handleAction,
  };
};

export const useNodeConnectivitySetting = () => {
  const { state, actions } = SettingsStore.useStore();
  const config = ConfigStore.useStore();
  const navigation = useNavigation();

  const handleAction = async (action) => {
    const [chain, nodeUrl] = action.value.split(' ');
    actions.setSetting({ name: 'nodeUrl', value: nodeUrl });
    actions.setSetting({ name: 'chain', value: chain });

    const chainName = chain === 'main' ? 'Mainnet' : 'Testnet';
    // navigate only after next render, all values need to be updated first
    setTimeout(() => {
      notification(
        <>
          Chain successfully switched,
          <br />
          you are currently running on {chainName}
        </>,
        { type: notification.TYPE.SUCCESS }
      );
      navigation.navigate(PATHS.CHAIN_SWITCHED);
    }, 0);
  };

  return {
    value: `${state.settings.chain} ${state.settings.nodeUrl}`,
    items: config.nodes,
    onAction: handleAction,
  };
};

export const useConnectWalletSettings = () => {
  const navigation = useNavigation();

  const handleAction = () => {
    navigation.navigate(PATHS.ONBOARDING);
  };

  return {
    onAction: handleAction,
  };
};
export const useCreateWalletSettings = () => {
  const navigation = useNavigation();

  const handleAction = () => {
    navigation.navigate(PATHS.ONBOARDING_CREATE);
  };

  return {
    onAction: handleAction,
  };
};
export const useImportWalletSettings = () => {
  const navigation = useNavigation();

  const handleAction = () => {
    navigation.navigate(PATHS.ONBOARDING_IMPORT);
  };

  return {
    onAction: handleAction,
  };
};

export const useReportErrorSetting = () => {
  const {
    state: {
      settings: { errorReporting },
    },
    actions: { setSetting },
  } = SettingsStore.useStore();

  const handleAction = ({ value }) => {
    setSetting({
      name: 'errorReporting',
      value: { report: value, doNotAsk: errorReporting.doNotAsk },
    });
  };

  return {
    value: errorReporting.report,
    onAction: handleAction,
  };
};

function useErrorSuccessLoading() {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const setActionStart = useCallback(() => {
    setError('');
    setSuccess(false);
    setLoading(true);
  }, []);
  const setActionSuccess = useCallback(() => {
    setSuccess(true);
    setLoading(false);
  }, []);
  const setActionError = useCallback((error) => {
    setError(error.message);
    setSuccess(false);
    setLoading(false);
  }, []);

  return { setActionStart, setActionError, setActionSuccess, error, success, loading };
}
