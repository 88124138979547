import { useCallback } from 'react';
import { PreventActions } from '@zen/common-app-parts';
import useAuthRedirect from './modules/useAuthRedirect';

/**
 * A hook to check all prerequisites before executing an action
 *
 * @returns {{preventAction:PreventActionFunction}}
 */
export default function usePreventAction() {
  const handleNotAuthenticated = useAuthRedirect();
  const showModalIfOffline = PreventActions.useOfflineModal();
  const handleNodeInaccessible = PreventActions.useNodeInaccessible();
  const handleActionInProgress = PreventActions.useActionInProgress();
  const handleNodeNotSynced = PreventActions.useNodeNotSynced();

  /**
   * Check all and act if a prevention is needed
   */
  const preventAction = useCallback(
    ({
      checkAuth = true,
      checkOffline = true,
      checkNodeNotSynced = true,
      checkNodeInaccessible = true,
      checkActionInProgress = true,
    } = {}) => {
      if (checkAuth && handleNotAuthenticated()) {
        return true;
      }
      if (checkOffline && showModalIfOffline()) {
        return true;
      }
      if (checkNodeNotSynced && handleNodeNotSynced()) {
        return true;
      }
      if (checkNodeInaccessible && handleNodeInaccessible()) {
        return true;
      }
      if (checkActionInProgress && handleActionInProgress()) {
        return true;
      }
      return false;
    },
    [
      handleActionInProgress,
      handleNodeInaccessible,
      handleNodeNotSynced,
      handleNotAuthenticated,
      showModalIfOffline,
    ]
  );

  return { preventAction };
}

/** @typedef {({
      checkAuth,
      checkOffline,
      checkNodeNotSynced,
      checkNodeInaccessible,
      checkActionInProgress,
    } = {
      checkAuth = true,
      checkOffline = true,
      checkNodeNotSynced = true,
      checkNodeInaccessible = true,
      checkActionInProgress = true,
    }) => boolean} PreventActionFunction */
