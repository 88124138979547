import React, { useCallback } from 'react';
import { Alert, useModal } from '@zen-common/components-base';
import WalletStore from '../../stores/WalletStore';

/**
 * Exposes a function to check if there is an ongoing send/execute action
 *
 * @returns {preventAction} preventAction
 */
export default function useActionInProgress() {
  const {
    state: { executing },
  } = WalletStore.useStore();
  const { show, hide } = useModal();

  /**
   * Handles ongoing actions
   * @function
   * @typedef {Function} preventAction
   * @returns {boolean} true if there is an action in progress, otherwise false
   */
  const preventAction = useCallback(() => {
    if (executing) {
      show(() => (
        <Alert title="Action in progress" onDismiss={hide} type={Alert.TYPE.WARNING}>
          There is an ongoing send/execute action
          <br />
          Please try again later
        </Alert>
      ));
      return true;
    }
    return false;
  }, [executing, hide, show]);

  return preventAction;
}
