import { last } from 'ramda';
import { Decimal } from 'decimal.js';
import { ValueDisplay, uniqueId, assetUtils } from '@zen/common-utils';

const { getAssetRepresentationId } = assetUtils;

/**
 *
 * @param {Object} params
 * @param {string=} params.asset
 * @param {boolean=} params.assetValid
 * @param {import('@zen/common-utils/lib/utils/ValueDisplay').ValueDisplay=} params.amount
 * @param {boolean=} params.amountValid
 * @param {string=} params.id
 *
 * @returns (import('./types').Spend)
 */
export function getSpend({
  asset = '',
  assetValid = false,
  amount = ValueDisplay.create(''),
  amountValid = false,
  id = uniqueId(),
} = {}) {
  return {
    asset,
    assetValid,
    amount,
    amountValid,
    id,
  };
}

export function lastSpendsItemValid(spends) {
  const lastItem = last(spends);
  return lastItem && lastItem.assetValid && lastItem.amountValid;
}

export function validateSpends({ spends, balance }) {
  const assets = Object.keys(balance);
  return (
    spends.length <= 100 &&
    spends.length > 0 &&
    spends.length <= assets.length &&
    !spends.some((spend) => !assets.includes(getAssetRepresentationId(spend.asset))) &&
    !spends.some((spend) => {
      const amount = new Decimal(spend.amount.safeValue);
      return amount.gt(balance[getAssetRepresentationId(spend.asset)]) || amount.lte(0);
    })
  );
}

export function filterOutAlreadyUsedAssets({ assets, spends, index }) {
  const alreadyUsed = spends.filter((item, i) => index !== i).map((item) => item.asset);
  return assets.filter((asset) => !alreadyUsed.includes(asset));
}

export function canAddAssetAmount({ spends, spendsValid, assetsCount }) {
  return (
    lastSpendsItemValid(spends) && spendsValid && spends.length < 100 && spends.length < assetsCount
  );
}
