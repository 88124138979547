import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Button,
  LabelText,
  Box,
  Input,
  QRGenerator,
  CopiedMessage,
  Modal,
  useModal,
  themeUtils as tu,
  useTheme,
} from '@zen-common/components-base';
import { useCopy } from '@zen/common-react-hooks';

function ReceiveForm({ address, config }) {
  const [addressCopied, errorCopying, onAddressCopy] = useCopy({ value: address });

  const handlePkFocus = useCallback(() => {
    onAddressCopy();
  }, [onAddressCopy]);

  return (
    <>
      <Box display="flex" flexDirection={['column', null, 'row']}>
        <Box flexGrow="1" width={['100%', 'auto', null]} mr={['xxs', 'sm']}>
          <LabelText>Your address</LabelText>
          <Input
            onClick={handlePkFocus}
            readOnly
            inputType="expandable"
            value={address}
            variant="block"
          />
          <CopiedMessage
            keepHeight
            copied={addressCopied}
            error={errorCopying}
            what="Public address"
          />
        </Box>
        <Button
          bg="secondary"
          onClick={onAddressCopy}
          alignSelf={['start', null, 'center']}
          mt={['smd', null, '0']}
        >
          Copy
        </Button>
      </Box>
      <QrDisplay address={address} config={config} />
    </>
  );
}
ReceiveForm.propTypes = {
  address: PropTypes.string,
};

export default ReceiveForm;

const QrCodeWrapper = styled(Box)`
  display: flex;
  ${css(
    tu.mq({
      justifyContent: ['center', null, 'flex-start'],
      marginTop: [tu.space('xl'), null, tu.space('xs')],
    })
  )}
`;

function QrDisplay({ address, config }) {
  const theme = useTheme();
  const { show, hide } = useModal();

  const convertAddress = (address) =>
    config
      ? `https://${config.uri}/app/wallet/${
          config.appNameShort === 'Wallet' ? 'send' : 'withdraw'
        }?fields={"address":"${address}"}`
      : address;

  function openModal() {
    show(() => (
      <Modal>
        <BigQrWrapper onClick={hide}>
          <BigQr
            value={convertAddress(address)}
            includeMargin
            renderAs="svg"
            bgColor={tu.color('text')({ theme })}
          />
        </BigQrWrapper>
      </Modal>
    ));
  }

  return (
    <QrCodeWrapper onClick={openModal}>
      <QRGenerator
        value={convertAddress(address)}
        includeMargin
        renderAs="svg"
        bgColor={tu.color('text')({ theme })}
      />
    </QrCodeWrapper>
  );
}

const BigQrWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BigQr = styled(QRGenerator)`
  width: 80vw;
  height: 80vh;
  max-width: 500px;
  max-height: 500px;
`;
