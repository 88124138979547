import { isDuringVote } from '../modules/utils';

/**
 * Calculates the vote status
 * @param {Object} params
 * @param {import('../types').VoteStatus} params.voteStatus
 * @param {import('react-query').QueryStatus} params.relevantStatus
 * @param {import('react-query').QueryStatus} params.snapshotZpBalanceStatus
 * @param {import('react-query').QueryStatus} params.candidatesStatus
 * @param {import('react-query').QueryStatus} params.voteFetchStatus
 * @returns {import('../types').Status}
 */
export default function getStatus({
  voteStatus,
  relevantStatus,
  snapshotZpBalanceStatus,
  candidatesStatus,
  initialVotesFetchDone,
  voteFetchStatus,
}) {
  const _isSnapshotZpBalanceLoading =
    snapshotZpBalanceStatus === 'loading' && isDuringVote(voteStatus);
  const _isSnapshotZpBalanceError = snapshotZpBalanceStatus === 'error' && isDuringVote(voteStatus);
  const _isCandidatesLoading = candidatesStatus === 'loading' && voteStatus === 'candidate';
  const _isCandidatesError = candidatesStatus === 'error' && voteStatus === 'candidate';
  const _isInitialVoteLoading =
    !initialVotesFetchDone && isDuringVote(voteStatus) && voteFetchStatus === 'loading';
  const _isInitialVoteFetchError =
    !initialVotesFetchDone && voteFetchStatus === 'error' && isDuringVote(voteStatus);

  return relevantStatus === 'error' ||
    _isInitialVoteFetchError ||
    _isCandidatesError ||
    _isSnapshotZpBalanceError
    ? 'error'
    : !voteStatus ||
      relevantStatus === 'loading' ||
      _isSnapshotZpBalanceLoading ||
      _isCandidatesLoading ||
      _isInitialVoteLoading
    ? 'loading'
    : voteStatus;
}
