import * as backTrace from 'backtrace-js';

/**
 * Report errors to the server
 *
 * @export
 * @param {Object} config - the app config file
 * @returns {{report: ReportFunc}} a report function
 */
export default function errorReporter(config) {
  if (process.env.NODE_ENV === 'development') {
    if (!config.errorReporting.endpoint || !config.errorReporting.token) {
      console.warn(
        `Config does not contain needed errorReporting values, 
        please add to .env: 
        REACT_APP_CONFIG_REPORT_ERRORS_ENDPOINT
        REACT_APP_CONFIG_REPORT_ERRORS_TOKEN
        `
      );
    }
  }

  backTrace.initialize({
    disableGlobalHandler: true,
    endpoint: config.errorReporting.endpoint,
    token: config.errorReporting.token,
    attributes: config.errorReporting.attributes,
  });

  /**
   * Reports an error
   * @type {ReportFunc}
   */
  function report({ error, attributes } = {}) {
    backTrace.report(error, attributes);
  }

  return Object.freeze({ report });
}

/**
 * @typedef {Function} ReportFunc
 * @param {ReportFuncParams} params
 */

/**
 * @typedef {Object} ReportFuncParams
 * @property {Error} error - the error to report
 * @property {Object} attributes - attributes to add to the report
 */
