import { produce } from 'immer';
import { assetUtils, numberUtils } from '@zen/common-utils';
import { getSpend, lastSpendsItemValid, validateSpends } from './spendUtils';

export const actions = {
  SET_SPENDS: 'spends-set',
  VALIDATE_SPENDS: 'spends-validate',
  ADD_SPEND: 'spend-add',
  REMOVE_SPEND: 'spend-remove',
  ASSET_CHANGED: 'asset-changed',
  AMOUNT_CHANGED: 'amount-changed',
  CLEAR: 'clear',
};

/**
 * The reducer
 * @param {SpendsState} draft
 * @param {*} { type, payload }
 */
function reducerHandler(draft, { type, payload }) {
  switch (type) {
    case actions.SET_SPENDS:
      draft.spends = payload.value;
      validateEachSpend(draft, payload);
      draft.spendsValid = validateSpends({ spends: draft.spends, balance: payload.balance });
      break;
    case actions.VALIDATE_SPENDS:
      validateEachSpend(draft, payload);
      draft.spendsValid = validateSpends({ spends: draft.spends, balance: payload.balance });
      break;
    case actions.ADD_SPEND:
      addSpend(draft);
      break;
    case actions.REMOVE_SPEND:
      removeSpend(draft, payload);
      draft.spendsValid = validateSpends({ spends: draft.spends, balance: payload.balance });
      break;
    case actions.ASSET_CHANGED:
      if (payload.value !== draft.spends[payload.index].asset) {
        draft.spends[payload.index].asset = payload.value;
        draft.spends[payload.index].assetValid = validateAsset({
          ...payload,
          spends: draft.spends,
        });
        draft.spendsValid = validateSpends({ spends: draft.spends, balance: payload.balance });
      }
      break;
    case actions.AMOUNT_CHANGED:
      draft.spends[payload.index].amount = getChangedAmount(
        draft.spends[payload.index].amount,
        payload.value
      );
      draft.spends[payload.index].amountValid = assetUtils.validateAmount({
        asset: draft.spends[payload.index].asset,
        balance: payload.balance,
        amount: draft.spends[payload.index].amount.safeValue,
      });
      draft.spendsValid = validateSpends({ spends: draft.spends, balance: payload.balance });
      break;
    case actions.CLEAR:
      draft.spends = [getSpend()];
      draft.spendsValid = false;
      break;
    default:
      break;
  }
}

export const reducer = produce(reducerHandler);

/**
 *
 * @param {Object} params
 * @param {string} params.value - the asset
 * @param {number} params.index - the spend index
 * @param {Object} params.balance - the balance
 * @param {Array} params.spends - the spends list
 */
function validateAsset({ value, index, balance, spends }) {
  const assetId = assetUtils.getAssetRepresentationId(value);
  return (
    Object.keys(balance).includes(assetId) &&
    !spends.some((spend, i) => i !== index && spend.asset === assetId)
  );
}

function getChangedAmount(amount, value) {
  return amount.onDisplayChange(numberUtils.truncateToDecimalPlaces(value));
}

function addSpend(draft) {
  if (!lastSpendsItemValid(draft.spends)) return;
  if (draft.spends.length >= 100) return;
  draft.spends.push(getSpend());
  draft.spendsValid = false;
}

/**
 * @param {initialState} draft
 */
function removeSpend(draft, payload) {
  draft.spends.splice(payload.index, 1);
}

function validateEachSpend(draft, payload) {
  draft.spends.forEach((spend, index) => {
    spend.assetValid = validateAsset({
      spends: draft.spends,
      balance: payload.balance,
      index,
      value: spend.asset,
    });
    spend.amountValid = assetUtils.validateAmount({
      asset: spend.asset,
      balance: payload.balance,
      amount: spend.amount.safeValue,
    });
  });
}

/**
 * @typedef {Object} SpendsState
 * @property {[import('./types').Spend]} spends
 * @property {boolean} spendsValid
 */
