import { useCallback, useEffect, useState } from 'react';

/**
 * Custom React hook for form management and input field validation
 *
 * @param {object} props.formSchema - form initial field state and validation model.
 * @param {function} props.formSubmitCallback - function to run on form submission.
 * @returns {{handleOnChange: function, handleOnSubmit: function, values: object, errors: object, isDirty: object,isDisabled: boolean}}
 */
function useForm(formSchema = {}, formSubmitCallback) {
  const [values, setValues] = useState(getPropValuesOrUndefined(formSchema, 'value'));
  const [errors, setErrors] = useState(getPropValuesOrUndefined(formSchema, 'error'));
  const [isDirty, setIsDirty] = useState(getPropValuesOrUndefined(formSchema, 'isDirty'));
  const [isSubmitted, setIsSubmitted] = useState(undefined);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    /*
    Re-validate all dirty inputs via the
    'validator' function when the values state changes
    */
    const dirtyFields = Object.keys(isDirty).reduce((acc, cur) => {
      if (isDirty[cur]) acc[cur] = isDirty[cur];
      return acc;
    }, {});

    Object.keys(dirtyFields).forEach(key => {
      let error;
      const name = key;
      const value = values[key];

      const _field = formSchema[name];

      error = !value && _field.required ? 'This field is required' : '';

      if (error === '' && _field['validator']) {
        /*Run only if the field has a validation function*/
        const validator = _field['validator'];
        error = validator(value, values);
      }

      setErrors(errors => ({ ...errors, [name]: error }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const isFormInvalid = useCallback(() => {
    /*
    The form will be invalid if one of its fields
    has some errors or a required field is empty
    */
    const requiredFieldState = getPropValuesOrUndefined(formSchema, 'required');

    const requiredFields = Object.keys(requiredFieldState).reduce((p, c) => {
      if (requiredFieldState[c]) p[c] = requiredFieldState[c];
      return p;
    }, {});

    const formHasErrors = () => Object.values(errors).some(error => error);
    const isRequiredFieldEmpty = () => Object.keys(requiredFields).some(key => !values[key]);

    return formHasErrors() || isRequiredFieldEmpty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    /*
    This will be fired after every change in the errors
    state to be able to disable the submit button
    */
    if (isTouched) {
      setIsDisabled(isFormInvalid());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleOnChange = useCallback(
    e => {
      setIsTouched(true);

      if (isSubmitted) {
        setIsSubmitted(undefined);
      }

      const name = e.target.name;
      const value = e.target.value;

      /*Proceeding only if the change field exits in the formSchema*/
      if (formSchema[name]) {
        setValues(values => ({ ...values, [name]: value }));
        setIsDirty(isDirty => ({ ...isDirty, [name]: true }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  const handleOnSubmit = useCallback(
    e => {
      e.preventDefault();

      if (!isFormInvalid()) {
        formSubmitCallback(values);
        setIsSubmitted(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFormInvalid, values]
  );

  return {
    handleOnChange,
    handleOnSubmit,
    values,
    errors,
    isDirty,
    isDisabled,
    isSubmitted,
  };
}

/*Maps the initial form model into the state*/
function getPropValuesOrUndefined(formSchema, key) {
  return Object.keys(formSchema).reduce((acc, cur) => {
    acc[cur] = formSchema[cur][key];

    return acc;
  }, {});
}

export default useForm;
