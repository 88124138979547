import React from 'react';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { color, space, typography } from 'styled-system';
import { useMedia } from '@zen/common-react-hooks';
import styles from 'react-toastify/dist/ReactToastify.min.css';
import tu from '../../utils/themeUtils';
import CloseButton from './components/CloseButton';

/**
 * A component for displaying notification toast messages
 * Shows the notification top-right on desktop and bottom-center on mobile
 *
 * @param {Object} props
 * @param {boolean} props.hideProgressBar - display a progress bar below the notification
 * @param {string} props.topMargin - add top margin in pixels
 */
function NotificationsContainer({ hideProgressBar, topMargin, ...props }) {
  const isMobile = useMedia(`(max-width: ${tu.breakpoints[0]})`);
  /* *
   * The ToastContainer is the parent element that renders the
   * the toast messages to the screen. It's currently optional
   * to use it and there's an alternative for it's implementation:
   * https://bit.ly/2z7nUSa
   * */
  return (
    <StyledNotificationsContainer
      position={isMobile ? 'bottom-center' : 'top-right'}
      closeButton={<CloseButton />}
      hideProgressBar={hideProgressBar}
      topMargin={topMargin}
      {...props}
    />
  );
}

const StyledNotificationsContainer = styled(ToastContainer)`
  ${styles}
  &:first-of-type {
    margin-top: ${(p) => (p.topMargin ? p.topMargin : null)};
  }
  .Toastify__toast {
    font-family: inherit;
    border-radius: 4px;

    ${(p) =>
      p.position.includes('right')
        ? 'border-left: 4px solid;'
        : p.position.includes('left')
        ? 'border-right: 4px solid;'
        : p.position === 'top-center'
        ? 'border-bottom: 4px solid;'
        : 'border-top: 4px solid;'}

    border-color: ${tu.color('bg6')};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    ${color}
    ${space}
    ${typography}
  }
  .Toastify__toast--success {
    border-color: ${tu.color('primary')};
    ${color}
  }
  .Toastify__toast--warning {
    border-color: ${tu.color('danger')};
    ${color}
  }
  .Toastify__progress-bar--default {
    background: ${tu.color('label')};
  }
`;

NotificationsContainer.propTypes = {
  hideProgressBar: PropTypes.bool,
  topMargin: PropTypes.string,
};

NotificationsContainer.defaultProps = {
  p: 'md',
  fontSize: 'md',
  lineHeight: '22px',
  color: 'text',
  bg: 'bg4',
};

export const notification = toast;

export default NotificationsContainer;
