import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Header, Paragraph, themeUtils as tu } from '@zen-common/components-base';

const SettingLayout = ({ header, paragraph, children, ...props }) => {
  return (
    <SettingsContainer {...props}>
      <Box width={['100%', null, '50%']}>
        <Header size="3">{header}</Header>
        <StyledParagraph>{paragraph}</StyledParagraph>
      </Box>
      <Box display="flex" alignSelf="center" width="120px" minWidth="120px">
        {children}
      </Box>
    </SettingsContainer>
  );
};

SettingLayout.propTypes = {
  header: PropTypes.string,
  paragraph: PropTypes.string,
};

export default SettingLayout;

const SettingsContainer = styled(Box)`
  display: flex;
  justify-content: start;
  flex-wrap: nowrap;
  color: ${tu.color('text')};
  padding: ${tu.space('mlg')} 0;
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 0;
  color: ${tu.color('label')};
  font-size: ${tu.fontSize('md')};

  ${tu.mq({
    maxWidth: ['90%', '80%'],
  })}
`;
