import { useMemo, useState, useEffect } from 'react';
import { useQueryUserBalance } from '@zen/common-app-parts';
import { assetUtils } from '@zen/common-utils';
import { isDuringVote } from '../modules/utils';

/**
 * Fetches the user's balance at snapshot block
 * @param {Object} params
 * @param {import('../types').Phase} params.currentPhase
 * @param {import('../types').VoteStatus} params.voteStatus
 * @returns {{snapshotZpBalance: string, snapshotZpBalanceStatus: ('loading'|'success'|'error')}}
 */
export default function useFetchUserSnapshotZpBalance({ currentPhase, voteStatus } = {}) {
  const [fetched, setFetched] = useState(false);
  const { interval, phase, beginBlock } = currentPhase || {};
  const enabled = isDuringVote(voteStatus);
  const { data: userBalance, status } = useQueryUserBalance({
    blockNumber: beginBlock,
    options: { enabled, refetchOnWindowFocus: false },
  });

  // reset fetched on interval change
  useEffect(() => {
    setFetched(false);
  }, [interval, phase]);
  // set as fetched as soon as there is data
  useEffect(() => {
    if (userBalance) {
      setFetched(true);
    }
  }, [userBalance]);

  const snapshotZpBalance = useMemo(() => {
    if (!userBalance) return '0';
    const balanceObject = (userBalance || []).find((pointedOutput) => pointedOutput.asset === '00');
    return balanceObject ? assetUtils.fromKalapas(balanceObject.balance) : '0';
  }, [userBalance]);

  return {
    snapshotZpBalance,
    snapshotZpBalanceStatus:
      status === 'success' && fetched ? 'success' : status === 'error' ? 'error' : 'loading',
  };
}
