import React from 'react';
import { WalletStore, SettingsStore } from '@zen/common-app-parts';

/**
 * a wrapper around the wallet provider with the needed settings
 */
function WalletProvider({ storage, ...props }) {
  const {
    state: {
      settings: { chain, nodeUrl },
    },
  } = SettingsStore.useStore();
  return <WalletStore.Provider storage={storage} chain={chain} nodeUrl={nodeUrl} {...props} />;
}

export default WalletProvider;
