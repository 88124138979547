import React from 'react';
import InputMessage from './InputMessage';

/**
 * Display a copied to clipboard message
 * @param {Object} props
 * @param {string} props.what - what was copied?
 * @param {boolean} props.copied
 * @param {boolean} props.error
 * @param {boolean} props.keepHeight - allow an empty message (keep the height), by default it will not render if empty
 */
export default function CopiedMessage({ what, copied, error, keepHeight, ...props }) {
  return (
    <InputMessage keepHeight={keepHeight} type={copied ? 'success' : 'error'} {...props}>
      {copied
        ? `${what || ''} copied to clipboard`
        : error
        ? `Could not copy ${(what || '').toLowerCase()} to clipboard`
        : ''}
    </InputMessage>
  );
}
CopiedMessage.defaultProps = {
  keepHeight: false,
};
