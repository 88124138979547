import { useState, useEffect, useCallback } from 'react';
import { clipboard } from '@zen/common-utils-browser';

/**
 * add a copy functionality
 *
 * @param {Object} props
 * @param {string} props.value - the value to copy
 * @param {number} props.showCopiedMs - time in milliseconds to show copied
 * @returns {[boolean, Function]} [copied, onCopy] a copied boolean and an onCopy function
 */
export default function useCopy({ value, showCopiedMs = 3500 }) {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(false);

  /**
   * Copy the value to clipboard
   * @function
   */
  const onCopy = useCallback(() => {
    clipboard
      .writeText(value)
      .then(() => setCopied(true))
      .catch(() => setError(true));
  }, [value]);

  // clear copied after timeout
  useEffect(() => {
    let timeout = null;
    if (copied || error) {
      timeout = setTimeout(() => {
        setCopied(false);
        setError(false);
      }, showCopiedMs);
    }

    return () => clearTimeout(timeout);
  }, [copied, error, showCopiedMs]);

  return [copied, error, onCopy];
}
