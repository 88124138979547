import { useEffect, useState } from 'react';

/**
 * Get whether the browser is offline
 *
 * @export
 * @returns {{offline: boolean}}
 */
export default function useOfflineStatus() {
  const [offline, setOffline] = useState(
    navigator && typeof navigator.onLine === 'boolean' ? !navigator.onLine : false
  );

  useEffect(() => {
    const onOnline = () => {
      setOffline(false);
    };
    const onOffline = () => {
      setOffline(true);
    };

    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  return {
    offline,
  };
}
