/**
 * Set back door keys in session storage
 */

if (process.env.NODE_ENV === 'development') {
  const backDoors = [
    { key: 'backdoor-add-headers', value: '' },
    { key: 'backdoor-easy-password', value: 'yes' },
  ];
  backDoors.forEach((bd) => {
    window.sessionStorage.setItem(bd.key, window.sessionStorage.getItem(bd.key) || bd.value);
  });
}
