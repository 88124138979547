import styled, { css } from 'styled-components';
import { border, layout, flexbox, space, color, typography } from 'styled-system';
import tu from '../utils/themeUtils';
import PasswordInput from './inputs/PasswordInput';

/**
 * get the css column-count property depending on the columns prop
 * Pass an array to get a responsive column-count
 */
const getColumnCountCss = ({ columns }) => {
  if (!columns)
    return css`
      column-count: 4;
    `;

  return Array.isArray(columns)
    ? css(tu.mq({ columnCount: columns }))
    : css`
        column-count: ${columns};
      `;
};

const COLUMN_GAP = '80px';

export const StyledListItem = styled.li`
  ${layout}
`;

export const StyledList = styled.ul`
  padding: ${(p) => (p.isEdit ? '0' : '0 0 0 48px')};
  margin: 0;
  width: 100%;
  position: relative;
  ${getColumnCountCss({ columns: ['2', '4'] })};
  list-style-type: none;
  ${layout}
  ${flexbox}
  ${tu.mq({
    padding: [
      (p) => (p.isEdit ? '0' : p.isPopupModal ? '0 0 0 30px' : '0 0 0 70px'),
      (p) => (p.isEdit ? '0' : '0 0 0 22px'),
    ],
    display: ['flex', 'block'],
    flexWrap: ['wrap', 'unset'],
    columnGap: ['0', (p) => (p.isEdit ? tu.space('sm')(p) : p.isPopupModal ? '34px' : COLUMN_GAP)],
  })}
  ${StyledListItem} {
    box-sizing: border-box;
    color: ${tu.color('label')};
    font-size: ${tu.fontSize('lg')};
    white-space: nowrap;
    font-weight: 400;
    height: ${(p) => (p.isVerify || p.isEdit ? 'auto' : tu.space('xl'))};
    margin-bottom: ${(p) => (p.isVerify || p.isEdit ? tu.space('smd') : '0')};
    ${tu.mq({
      width: [(p) => (p.isVerify ? '100%' : '50%'), 'auto'],
      '&:nth-child(odd)': {
        borderRight: [(p) => (p.isVerify ? 'none' : '6px solid transparent'), 'none'],
      },
      '&:nth-child(even)': {
        borderLeft: [(p) => (p.isVerify ? 'none' : '6px solid transparent'), 'none'],
      },
    })}
  }

  &.view {
    list-style-type: decimal;
    ${StyledListItem} {
      &:hover {
        span {
          transform: scale(1.2);
        }
      }
    }
  }
`;

export const StyledInput = styled(PasswordInput)`
  min-width: auto;
  height: 36px;
  ${css(
    tu.mq({
      width: ['100%', null, 'auto'],
    })
  )}
  ${border}
`;

export const SeedNumber = styled.div`
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  user-select: none;
  ${space}
  ${color}
  ${typography}
  ${border}
`;
