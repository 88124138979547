import React from 'react';
import { Box } from '@zen-common/components-base';

export default function TestnetWarning() {
  return (
    <Box color="danger" mb="smd">
      You are currently casting a vote on the testnet.
    </Box>
  );
}
