import React from 'react';
import { Header, Box, Paragraph } from '@zen-common/components-base';

export default function AppHeader({ title, text }) {
  return (
    <Box>
      <Header fontWeight="400">{title}</Header>
      <Paragraph color="label" mb="0" fontSize={['md', null, 'lg']}>
        {text}
      </Paragraph>
    </Box>
  );
}
