import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tu from '../utils/themeUtils';
import Loading from './Loading';

/**
 * Displays a loading indicator or something else
 *
 * @param {Object} props
 * @param {boolean} props.loading
 */
export default function LoadingOr({ loading, loadingContent, children, ...props }) {
  return (
    <RelativeContainer>
      <LoadingWrapper visible={loading}>
        <Loading data-testid="LoadingOr-loading" {...props} />
        {loadingContent && <LoadingContentWrapper>{loadingContent}</LoadingContentWrapper>}
      </LoadingWrapper>
      <VisibilityWrapper visible={!loading}>{children}</VisibilityWrapper>
    </RelativeContainer>
  );
}
LoadingOr.propTypes = {
  loading: PropTypes.bool,
  loadingContent: PropTypes.any,
  children: PropTypes.any,
};

const RelativeContainer = styled.span`
  position: relative;
`;
const VisibilityWrapper = styled.span`
  visibility: ${p => (p.visible ? 'visible' : 'hidden')};
`;
const LoadingWrapper = styled(VisibilityWrapper)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingContentWrapper = styled.span`
  padding-left: ${tu.space(1)};
`;
