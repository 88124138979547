import styled, { css } from 'styled-components';
import { typography, color, space, compose } from 'styled-system';
import tu from '../../utils/themeUtils';
import Paragraph from '../Paragraph';
import Title from '../titles/Title';
import { getTransition, containerXPadding } from '../../utils/styles/mixins';
import Box from '../Box';
import { StyledInputButton } from '../Button/Button';

export const TopButtonsContainer = styled(Box)`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;

  ${css(
    tu.mq({
      paddingTop: [tu.space('xxs'), null, tu.space('sm')],
      paddingRight: [tu.space('xxs'), null, tu.space('sm')],
    })
  )}
`;

export const StyledModalSeparator = styled.div`
  border-top: 1px solid ${tu.color('borderDark')};
  ${defaultMarginBottom}
`;

export const StyledModalFooter = styled(Box)`
  ${containerXPadding}
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  /* style a footer button */
  ${StyledInputButton} {
    min-width: 120px;
    margin-right: ${tu.space('smd')};

    ${tu.mq({
      width: ['100%', 'auto'],
    })}

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledModalSection = styled(Box)`
  ${containerXPadding}
  ${defaultMarginBottom}
  ${Paragraph}:last-child {
    margin-bottom: 0;
  }
`;

export const StyledModalHeader = styled(Box)`
  ${containerXPadding}
  ${defaultMarginBottom}

  ${Paragraph}:last-child {
    margin-bottom: 0;
  }

  ${Title} {
    margin-bottom: ${tu.space('xs')};
    ${(p) =>
      css(
        tu.mq({
          fontSize: tu.fontSize(['lg', null, 'xl'])(p),
        })
      )}
  }
`;

export const StyledModalContent = styled(Box)`
  max-height: 90vh;
  overflow: auto;
  transform: scale(0.5);
  ${getTransition({ property: 'all', timing: '80ms', duration: 'ease-in-out' })}
  &.is-open {
    transform: scale(1);
  }
  ${css(
    tu.mq({
      width: ['100%', '85%', 'auto'],
    })
  )}
`;
export const StyledModalContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  ${getTransition({ property: 'all', timing: '80ms', duration: 'ease-in-out' })}
  &.is-open {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const StyledModalPanel = styled(Box)`
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  max-width: ${(p) => (p.expanded ? '100%' : p.large ? '580px' : '522px')};
  text-align: left;
  ${compose(space, color, typography)}
  ${css(
    tu.mq({
      minWidth: ['100%', null, (p) => (p.large ? '580px' : '522px')],
      borderRadius: [0, '8px'],
    })
  )}

  > div:last-child {
    margin-bottom: 0;
  }

  form {
    line-height: initial;
  }
`;
StyledModalPanel.defaultProps = {
  bg: 'bg3',
  pt: ['mlg', null, 'lg'],
  pb: 'mlg',
  color: 'label',
  fontSize: 'md',
  lineHeight: 6,
};

function defaultMarginBottom(props) {
  return tu.mq({
    marginBottom: tu.space(['sm', null, 'mlg'])(props),
  });
}
