import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Form,
  HiddenSubmitButton,
  InputMessage,
  LinkButton,
  Mnemonic,
  MnemonicInput,
  MnemonicListItem,
  Paragraph,
  LoadingOr,
  cssMixins,
} from '@zen-common/components-base';
import { useMnemonic } from '@zen/common-react-hooks';
import Layout from '../blocks/Layout';
import Header from '../blocks/Header';
import Footer from '../blocks/Footer';
import useSubmitWithLoading from '../hooks/useSubmitWithLoading';
import ResetShowAllSection from '../blocks/ResetShowAllSection';
import PaginationButton from '../blocks/PaginationButton';
import getMarginBottom from '../modules/getMarginBottom';
import { INVALID_SEED_MSG } from '../modules/texts';

export default function ImportWallet({ onBack, onContinue, onCreateWallet, onValidPhraseEntered }) {
  const [showAll, setShowAll] = useState(false);
  const [focusIndex, setFocusIndex] = useState(-1);
  const inputRefs = useRef([]); // contain all refs of all inputs

  const {
    wordChangeHandler,
    wordsList,
    mnemonic,
    clear,
    validMnemonic,
    allWordsValid,
  } = useMnemonic();

  const handleWordChange = (index, value) => {
    setFocusIndex(index);
    wordChangeHandler(index, value);
  };

  // focus next input on final valid word
  useEffect(() => {
    if (focusIndex >= 0 && focusIndex < 23 && wordsList[focusIndex].validity.final) {
      inputRefs.current[focusIndex + 1].focus();
    }
  }, [focusIndex, wordsList]);

  useEffect(() => {
    if (validMnemonic) {
      inputRefs.current[23].focus(); // focus on last in case of a paste
      onValidPhraseEntered(mnemonic);
    }
  }, [validMnemonic, mnemonic, onValidPhraseEntered]);

  const toggleShowAll = () => {
    setShowAll((showAll) => !showAll);
  };

  const handleReset = () => {
    clear();
    /**
     * trick: this will trigger the effect in the mnemonic input since the value is changing,
     * but it will not cause a flicker since the value is not a boolean
     */
    setShowAll('trick');
    setTimeout(() => setShowAll(false), 0);
  };

  const { handleSubmit, loading } = useSubmitWithLoading({
    validate: () => validMnemonic,
    onSubmit: onContinue,
  });

  return (
    <Layout
      header={
        <>
          <Header
            title="Import your 24 words Seed"
            text="Make sure you enter it in the correct order"
          />
        </>
      }
      body={
        <MainContainer>
          <ResetShowAllSection
            disabled={loading}
            onReset={handleReset}
            onShowAll={toggleShowAll}
            showingAll={showAll === true}
            css={cssMixins.showOnMobile()}
            pb="md"
            mt="1"
          />
          <Form onSubmit={handleSubmit} disabled={loading}>
            <HiddenSubmitButton />
            <Mnemonic isEdit={true} wordsList={wordsList} columns={[1, 2, 2, 4]}>
              {({ item, index }) => (
                <MnemonicListItem key={index}>
                  <MnemonicInput
                    ref={(input) => {
                      inputRefs.current[index] = input;
                    }}
                    onBlur={() => setFocusIndex(-1)}
                    index={index}
                    word={item.word}
                    validity={item.validity}
                    onChange={handleWordChange}
                    visible={showAll}
                    disabled={loading}
                  />
                </MnemonicListItem>
              )}
            </Mnemonic>
          </Form>
          <ResetShowAllSection
            disabled={loading}
            onReset={handleReset}
            onShowAll={toggleShowAll}
            showingAll={showAll === true}
            css={cssMixins.showOnDesktop()}
          />
          <InputMessage mt={[0, null, 'smd']} pb="smd">
            {allWordsValid && !validMnemonic ? INVALID_SEED_MSG : ''}
          </InputMessage>
        </MainContainer>
      }
      footer={
        <Footer
          left={
            <Box
              css={cssMixins.showOnDesktop()}
              flexDirection="column"
              alignItems="flex-start"
              fontSize="md"
            >
              <Paragraph mb="0" color="label">
                {"Don't have a wallet?"}
              </Paragraph>
              <LinkButton onClick={onCreateWallet}>Create a wallet</LinkButton>
            </Box>
          }
          right={
            <>
              <PaginationButton disabled={loading} onClick={onBack} mr="smd" bg="secondary">
                Cancel
              </PaginationButton>
              <PaginationButton disabled={!validMnemonic || loading} onClick={handleSubmit}>
                <LoadingOr loading={loading}>Continue</LoadingOr>
              </PaginationButton>
            </>
          }
        />
      }
    />
  );
}

ImportWallet.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onValidPhraseEntered: PropTypes.func.isRequired,
};

const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${getMarginBottom}
`;
