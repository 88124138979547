import React, { Suspense } from 'react';
import { View } from 'react-navi';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider, NotificationsContainer } from '@zen-common/components-base';
import {
  ErrorPage,
  CustomNotFoundBoundary,
  AutoLogout,
  RouteAwareModalRoot,
  Router,
  TooltipFix,
  ResetUnmatchedNodeUrlOrChain,
  CheckAppUpdates,
} from '@zen/common-app-parts';
import AppLayout from './components/layouts/AppLayout';
import StoreProviders from './components/StoreProviders';
import Notifications from './components/Notifications';
import StoreReset from './components/StoreReset';
import initialTheme from './initialTheme';
import routes from './mainRoutes';
import Analytics from './Analytics';
import './styles/font-loader';

/**
 * The main entry point of the Wallet react app
 *
 * @param {Object} props
 * @param {Object} props.storage - a storage implementation
 * @param {Object} props.config - a configuration object
 * @param {Function} props.onRestart - a function to restart the app
 */
function App({ storage, config, onRestart }) {
  return (
    <StoreProviders storage={storage} config={config}>
      <ThemeProvider theme={initialTheme}>
        <Router routes={routes}>
          <Analytics />
          <RouteAwareModalRoot>
            <StoreReset />
            <ResetUnmatchedNodeUrlOrChain />
            <AutoLogout />
            <NotificationsContainer hideProgressBar topMargin="56px" />
            <Notifications />
            <TooltipFix />
            <CheckAppUpdates
              remotePropPrefix="wallet"
              title="A new version of the wallet is available!"
            />
            <AppLayout>
              <ErrorBoundary
                FallbackComponent={({ error }) => <ErrorPage error={error} onRestart={onRestart} />}
              >
                <CustomNotFoundBoundary>
                  <Suspense fallback={null}>
                    <View />
                  </Suspense>
                </CustomNotFoundBoundary>
              </ErrorBoundary>
            </AppLayout>
          </RouteAwareModalRoot>
        </Router>
      </ThemeProvider>
    </StoreProviders>
  );
}

export default App;
