import * as React from 'react';
import { Decimal } from 'decimal.js';
import {
  Box,
  Button,
  Title,
  Label,
  LabelText,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  ModalFooter,
  Form,
  FormGroup,
  InputAddonNumberControls,
  Input,
  InputMessage,
  useUpDownNumberControl,
  FileDropZone,
  LoadingOr,
} from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';
import { useClickAway, useUnmountedRef } from '@zen/common-react-hooks';

const R_LIMIT_MIN = 2723280;

/**
 * @typedef {import('@zen/common-utils/lib/utils/ValueDisplay').ValueDisplay} TValueDisplay
 */

/**
 * @param {Object} props
 * @param {TValueDisplay} props.numberOfBlocks
 * @param {(display: string) => TValueDisplay} props.onNumberOfBlocksChange
 * @param {TValueDisplay} props.rLimit
 * @param {(display: string) => TValueDisplay} props.onRLimitChange
 * @param {Object} props.fileDropZoneProps
 * @param {boolean} props.progress
 * @param {() => void} props.onCancel
 * @param {() => void} props.onContinue
 */
export default function ActivateFormModalContent({
  numberOfBlocks,
  onNumberOfBlocksChange,
  rLimit,
  onRLimitChange,
  fileDropZoneProps,
  progress,
  onCancel,
  onContinue,
}) {
  const rLimitInvalid = new Decimal(rLimit.safeValue).lessThan(R_LIMIT_MIN);
  const submitDisabled =
    progress ||
    !numberOfBlocks.display ||
    !fileDropZoneProps.acceptedFiles.length ||
    numberUtils.isZero(numberOfBlocks.safeValue) ||
    rLimitInvalid;

  function handleSubmit(e) {
    e.preventDefault();

    onContinue();
  }
  return (
    <Box>
      <ModalHeader>
        <Title>Activate a Contract</Title>
      </ModalHeader>
      <Form onSubmit={handleSubmit} disabled={progress}>
        <ModalSection>
          <FormGroup>
            <FileDropZone disabled={progress} {...fileDropZoneProps} />
            <InputMessage />
          </FormGroup>
          <NumberOfBlocksFormGroup
            numberOfBlocks={numberOfBlocks}
            onNumberOfBlocksChange={onNumberOfBlocksChange}
            progress={progress}
          />
          <RLimitFormGroup
            onRLimitChange={onRLimitChange}
            progress={progress}
            rLimit={rLimit}
            rLimitInvalid={rLimitInvalid}
          />
        </ModalSection>
        <ModalSeparator />
        <ModalFooter>
          <Button type="button" onClick={onCancel} bg="secondary" disabled={progress}>
            Cancel
          </Button>
          <Button type="submit" disabled={submitDisabled}>
            <LoadingOr loading={progress}>Continue</LoadingOr>
          </Button>
        </ModalFooter>
      </Form>
    </Box>
  );
}

function NumberOfBlocksFormGroup({ numberOfBlocks, onNumberOfBlocksChange, progress }) {
  const errorBlocks =
    !numberOfBlocks.display || !numberUtils.isZero(numberOfBlocks.safeValue)
      ? ''
      : 'Please choose a positive number of blocks';

  const numberControlBlocks = useUpDownNumberControl({
    min: 0,
    value: numberOfBlocks.safeValue,
    onChange: onNumberOfBlocksChange,
  });
  return (
    <FormGroup>
      <Label htmlFor="choose-blocks-modal-content-blocks">
        <LabelText>Number of blocks:</LabelText>
      </Label>
      <Input
        id="choose-blocks-modal-content-blocks"
        inputType="input"
        value={numberOfBlocks.display}
        onChange={(e) => onNumberOfBlocksChange(e.target.value.trim())}
        variant="block"
        valid={numberOfBlocks.display ? !errorBlocks : undefined}
        disabled={progress}
        renderRightAddon={
          <InputAddonNumberControls
            onChange={onNumberOfBlocksChange}
            value={numberOfBlocks.safeValue}
            min="0"
          />
        }
        {...numberControlBlocks}
      />
      <InputMessage>{errorBlocks}</InputMessage>
    </FormGroup>
  );
}

function RLimitFormGroup({ rLimitInvalid, rLimit, onRLimitChange, progress }) {
  const unmounted = useUnmountedRef();
  const rLimitRef = React.useRef();
  const rLimitClickAwayRef = React.useRef();
  const [rLimitEnabled, setRLimitEnabled] = React.useState(false);

  const errorRLimit = !rLimitInvalid ? '' : 'Limit should be at least ' + R_LIMIT_MIN;

  useClickAway(rLimitClickAwayRef, () => {
    if (!unmounted.current) {
      setRLimitEnabled(false);
    }
  });

  function enableRLimit() {
    setRLimitEnabled(true);
    setTimeout(() => {
      rLimitRef.current && rLimitRef.current.focus();
    }, 0);
  }

  const numberControlRLimit = useUpDownNumberControl({
    min: R_LIMIT_MIN,
    value: rLimit.safeValue,
    onChange: onRLimitChange,
  });

  return (
    <FormGroup>
      <Box ref={rLimitClickAwayRef} onClick={enableRLimit}>
        <Label htmlFor="activate-form-modal-content-rlimit">
          <LabelText>R limit: (advanced)</LabelText>
        </Label>
        <Input
          ref={rLimitRef}
          id="activate-form-modal-content-rlimit"
          inputType="input"
          value={rLimit.display}
          onChange={(e) => onRLimitChange(e.target.value.trim())}
          variant="block"
          valid={rLimit.display ? !rLimitInvalid : undefined}
          disabled={!rLimitEnabled || progress}
          renderRightAddon={
            !rLimitEnabled || progress ? null : (
              <InputAddonNumberControls
                onChange={onRLimitChange}
                value={rLimit.safeValue}
                min={String(R_LIMIT_MIN)}
              />
            )
          }
          {...numberControlRLimit}
        />
      </Box>
      <InputMessage>{errorRLimit}</InputMessage>
    </FormGroup>
  );
}
