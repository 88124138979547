import * as React from 'react';
import { produce } from 'immer';
import { ValueDisplay, assetUtils } from '@zen/common-utils';
import {
  Title,
  Button,
  Form,
  FormBody,
  FormGroup,
  Label,
  LabelText,
  InputMessage,
  ClearInput,
  ModalContent,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Box,
  themeUtils as tu,
  Input,
  CopiedMessage,
} from '@zen-common/components-base';
import {
  ZenJsUtils,
  SpendsReducer,
  SpendUtils,
  PasteButton,
  SpendsGroup,
  SettingsStore,
  NamingAssetStore,
} from '@zen/common-app-parts';
import { useCopy, useUnmountedRef } from '@zen/common-react-hooks';
import styled from 'styled-components';
import { serializePayoutBallotId } from '../../store/modules/execute';
import InputAndRest from '../InputAndRest';

function initState(chain) {
  return {
    chain,
    ballotId: '',
    ballotIdValid: false,
    address: '',
    addressValid: false,
    spends: [SpendUtils.getSpend()],
    spendsValid: false,
  };
}
export default function BallotIdGenerator({ cgpBalance, onClose }) {
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const { state: naming } = NamingAssetStore.useStore();
  const addressRef = React.useRef();
  const spendsRef = React.useRef();

  const assets = Object.keys(cgpBalance);

  const unmountedRef = useUnmountedRef();
  /** @type {[state: TState]} */
  const [state, dispatch] = React.useReducer(reducer, chain, initState);

  const [addressCopied, errorCopying, onAddressCopy] = useCopy({ value: state.ballotId });

  const handleBallotFocus = React.useCallback(() => {
    !!state.ballotId && onAddressCopy();
  }, [onAddressCopy, state]);

  const onAddressChange = React.useCallback((value) => {
    dispatch({ type: 'address-changed', payload: { value } });
  }, []);
  const onAssetChange = React.useCallback(
    ({ value, index }) => {
      dispatch({
        type: SpendsReducer.actions.ASSET_CHANGED,
        payload: { value, index, balance: cgpBalance },
      });
    },
    [cgpBalance]
  );
  const onAmountChange = React.useCallback(
    ({ value, index }) => {
      dispatch({
        type: SpendsReducer.actions.AMOUNT_CHANGED,
        payload: { value, index, balance: cgpBalance },
      });
    },
    [cgpBalance]
  );
  const onSpendAdd = React.useCallback(() => {
    dispatch({ type: SpendsReducer.actions.ADD_SPEND });
    // make sure focus after the removed
    setTimeout(() => {
      spendsRef.current && spendsRef.current.focus();
    }, 0);
  }, []);
  const onSpendRemove = React.useCallback(
    (index) => {
      dispatch({
        type: SpendsReducer.actions.REMOVE_SPEND,
        payload: { index, balance: cgpBalance },
      });
      // make sure focus after the removed
      setTimeout(() => {
        spendsRef.current && spendsRef.current.focus();
      }, 0);
    },
    [cgpBalance]
  );
  const onReset = React.useCallback(() => {
    if (!unmountedRef.current) {
      dispatch({ type: 'clear' });
      addressRef.current && addressRef.current.focus();
    }
  }, [unmountedRef]);

  const resetDisabled = React.useMemo(
    () =>
      state.address === '' && state.spends[0].asset === '' && state.spends[0].amount.value === '',
    [state.address, state.spends]
  );

  return (
    <ModalContent large>
      <Form>
        <ModalHeader>
          <Title>Generate a Ballot ID</Title>
        </ModalHeader>
        <ModalSection>
          <FormBody>
            <FormGroup>
              <Label htmlFor="recipient-address">
                <LabelText>Recipient Address</LabelText>
              </Label>
              <InputAndRest
                input={
                  <ClearInput
                    ref={addressRef}
                    id="recipient-address"
                    inputType="expandable"
                    valid={!state.address ? undefined : state.addressValid}
                    variant="block"
                    width="100%"
                    value={state.address}
                    onChange={(e) => onAddressChange(e.target.value.trim())}
                    onClear={() => onAddressChange('')}
                  />
                }
                rest={<PasteButton onPaste={onAddressChange} />}
              />

              <InputMessage>
                {!state.address || state.addressValid ? '' : 'Invalid address'}
              </InputMessage>
            </FormGroup>

            <SpendsGroup
              ref={spendsRef}
              alignment="grid"
              noMarginTop
              spends={state.spends}
              spendsValid={state.spendsValid}
              assets={assets}
              balance={cgpBalance}
              addSpend={onSpendAdd}
              removeSpend={onSpendRemove}
              setAsset={onAssetChange}
              setAmount={onAmountChange}
              naming={naming}
            />

            <FormGroup>
              <TextSeparator>{'AS'}</TextSeparator>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="ballot-id">
                <LabelText>Ballot ID</LabelText>
              </Label>
              <InputAndRest
                input={
                  <Box>
                    <Input
                      onClick={handleBallotFocus}
                      readOnly
                      inputType="expandable"
                      value={state.ballotId}
                      variant="block"
                    />
                    <CopiedMessage
                      keepHeight
                      copied={addressCopied}
                      error={errorCopying}
                      what="BallotId"
                    />
                  </Box>
                }
                rest={
                  <Button
                    bg="secondary"
                    ml="xs"
                    display={['none', 'block']}
                    alignSelf="flex-start"
                    onClick={!!state.ballotId && onAddressCopy}
                  >
                    Copy
                  </Button>
                }
              />
              <InputMessage>
                {!state.ballotId || state.ballotIdValid ? '' : 'Invalid ballot'}
              </InputMessage>
            </FormGroup>
          </FormBody>
        </ModalSection>
        <ModalFooter>
          <Button bg="secondary" onClick={onReset} disabled={resetDisabled}>
            Reset
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </Form>
    </ModalContent>
  );
}
BallotIdGenerator.defaultProps = {
  cgpBalance: {},
};

/**
 * @typedef {{
    chain: string,
    ballotId: string,
    ballotIdValid: boolean,
    address: string,
    addressValid: boolean,
    spends: [import('@zen/common-app-parts/lib/components/Spend/types').Spend],
    spendsValid: boolean,
  }} TState
 */

/**
 * The reducer
 * @param {TState} draft
 * @param {*} { type, payload }
 */
function payoutReducerHandler(draft, { type, payload }) {
  switch (type) {
    case 'ballot-changed':
      handleBallotChange(draft, payload);
      break;
    case 'address-changed':
      draft.ballotIdValid = false;
      draft.address = payload.value;
      draft.addressValid =
        payload.value === '' ? undefined : ZenJsUtils.validateAddress(draft.chain, payload.value);
      break;
    case 'clear':
      draft.ballotId = '';
      draft.ballotIdValid = false;
      draft.address = '';
      draft.addressValid = false;
      break;
    default:
      break;
  }
}
const payoutReducer = produce(payoutReducerHandler);

function calcBallotReducerHandler(draft, { type }) {
  switch (type) {
    case 'address-changed':
    case SpendsReducer.actions.AMOUNT_CHANGED:
    case SpendsReducer.actions.ASSET_CHANGED:
    case SpendsReducer.actions.REMOVE_SPEND:
    case SpendsReducer.actions.ADD_SPEND:
    case SpendsReducer.actions.SET_SPENDS:
      draft.ballotId = safeSerializeBallotId({ draft });
      draft.ballotIdValid = ZenJsUtils.validateBallot(draft.ballotId);
      break;
    default:
      break;
  }
}
const calcBallotReducer = produce(calcBallotReducerHandler);

export function reducer(state, action) {
  // combine all reducers
  return calcBallotReducer(payoutReducer(SpendsReducer.reducer(state, action), action), action);
}

function handleBallotChange(draft, payload) {
  const deserialized = deserializeBallotId({
    ballotId: payload.value,
    chain: draft.chain,
    balance: payload.balance,
  });
  draft.ballotId = payload.value;
  draft.ballotIdValid = deserialized.ballotIdValid;
  draft.address = deserialized.address;
  draft.addressValid =
    draft.address === '' ? undefined : ZenJsUtils.validateAddress(draft.chain, draft.address);

  let spendReducerResult = SpendsReducer.reducer(draft, {
    type: SpendsReducer.actions.SET_SPENDS,
    payload: { value: deserialized.spends, balance: payload.balance },
  });
  draft.spends = spendReducerResult.spends;
  draft.spendsValid = spendReducerResult.spendsValid;

  // reset if empty
  if (payload.value === '') {
    draft.ballotIdValid = false;
  }
}

function deserializeBallotId({ ballotId, chain, balance }) {
  if (ZenJsUtils.validateBallot(ballotId)) {
    const { address, spends: _spends } = ZenJsUtils.deserializePayoutBallotId(chain, ballotId);
    const assets = _spends.map((spend) => {
      const asset = spend.asset;
      const amount = ValueDisplay.create(spend.amount);
      return SpendUtils.getSpend({
        asset,
        amount,
        assetValid: Object.keys(balance).includes(asset),
        amountValid: assetUtils.validateAmount({
          asset,
          balance,
          amount: amount.safeValue,
        }),
      });
    });
    return {
      ballotIdValid: true,
      address,
      spends: assets,
    };
  }
  return {
    ballotIdValid: false,
    address: '',
    spends: [SpendUtils.getSpend()],
  };
}

/**
 * @param {Object} params
 * @param {TState} params.draft
 */
function safeSerializeBallotId({ draft }) {
  try {
    if (!draft.addressValid || !draft.spendsValid) {
      return '';
    }

    return serializePayoutBallotId({
      chain: draft.chain,
      address: draft.address,
      spends: draft.spends.map((spend) => ({
        asset: spend.asset,
        amount: spend.amount.safeValue,
      })),
    });
  } catch (error) {
    return '';
  }
}

function TextSeparator({ children }) {
  return (
    <Box display="flex" alignItems="center">
      <Divider />
      <Box fontSize="sm" color="label">
        {children}
      </Box>
      <Divider />
    </Box>
  );
}

const Divider = styled(Box)`
  height: 0;
  flex-grow: 1;
  flex-shrink: 0;
  border-bottom: 1px solid ${tu.color('borderDark')};
`;
