import React from 'react';
import styled from 'styled-components';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import Box from '../../Box';
import FaSvg, { StyledSvg } from '../../helpers/FaSvg';
import TransparentButton from './TransparentButton';

const StyledBox = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 34px;
  &:hover {
    ${StyledSvg} {
      filter: brightness(1.25);
    }
  }
  ${StyledSvg} {
    -webkit-appearance: none;
  }
`;

const ClearButton = ({ onClear }) => {
  return (
    <StyledBox px="xxs">
      <TransparentButton onClick={onClear}>
        <FaSvg icon={faTimesCircle} size="1rem" color="label" />
      </TransparentButton>
    </StyledBox>
  );
};

export default ClearButton;
