import * as React from 'react';
import { useQuery } from 'react-query';
import { ExplorerService } from '@zen/common-app-parts';

const REFETCH_MS = 10000;

/**
 * Fetch all the contestants
 * @param {Object} params
 * @param {import('../types').Chain} params.chain
 * @param {Object} params.config
 * @param {import('../types').Phase} params.currentPhase
 * @param {import('../types').VoteStatus} params.voteStatus
 * @returns {import('react-query').QueryResult<import('../types').Candidates>}
 */
export default function useFetchContestants({ chain, config, currentPhase, voteStatus } = {}) {
  const enabled = voteStatus === 'contestant';
  const { interval, phase } = currentPhase || {};
  return useQuery(
    ['governance-contestants', { interval, phase }],
    React.useCallback(async () => {
      const service = ExplorerService({ chain, config });
      const result = await service.governance.getResults({
        interval,
        phase,
        page: 0,
        pageSize: 1000,
      });
      return result.data.items;
    }, [chain, config, interval, phase]),
    {
      enabled,
      refetchInterval: REFETCH_MS,
      refetchIntervalInBackground: true,
    }
  );
}
