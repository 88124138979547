/* eslint-disable eqeqeq */
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Box, themeUtils as tu, cssMixins } from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';
import EstimatedTimeLeft from '../../EstimatedTimeLeft';
import SectionTitle from '../SectionTitle';
import SectionSubTitle from '../SectionSubTitle';

const { toDisplay } = numberUtils;

export default function PhaseBar({ start, end, currentBlock, title }) {
  const percentage = React.useMemo(() => {
    const phaseLength = end - start;

    if (currentBlock < start) return 0;
    if (currentBlock >= end) return 100;
    if (currentBlock == start) return ((1 / phaseLength) * 100) / 2; // half of 1 percentage;

    // adjust current block to start from 1
    const adjusted = currentBlock - start;

    return (adjusted / phaseLength) * 100;
  }, [currentBlock, end, start]);

  const blocksRemain = end - currentBlock;
  const status =
    currentBlock < start
      ? 'Closed'
      : currentBlock < end - 1
      ? `${toDisplay(blocksRemain)} blocks remain`
      : currentBlock == end - 1
      ? `${toDisplay(blocksRemain)} block remains`
      : `Closed at block ${toDisplay(end)}`;

  return (
    <PhaseBarContainer>
      <TextsContainer>
        <Box>
          <SectionTitle>{title}</SectionTitle>
          <SectionSubTitle>
            {status} | <EstimatedTimeLeft blocksCount={blocksRemain} />
          </SectionSubTitle>
        </Box>
      </TextsContainer>
      <StartEndBlocksWrapper>
        <Box>Start block {toDisplay(start)}</Box>
        <Box>End block {toDisplay(end)}</Box>
      </StartEndBlocksWrapper>
      <LineWrapper>
        <LineContent percentage={percentage} />
      </LineWrapper>
      {percentage > 0 && percentage < 100 && (
        <StyledTooltipText percentage={percentage}>
          Current block {toDisplay(currentBlock)}
        </StyledTooltipText>
      )}
    </PhaseBarContainer>
  );
}

const PhaseBarContainer = styled(Box)`
  position: relative;
`;

const TextsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${tu.space('md')};
`;

const StartEndBlocksWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${tu.fontSize(1)};
  margin-bottom: ${tu.space(2)};
  color: ${tu.color('label')};
`;

const LineWrapper = styled(Box)`
  position: relative;
  height: ${tu.size(2)};
  border-radius: ${tu.radius(3)};
  overflow: hidden;
  background-color: ${tu.color('secondary')};
`;

const LineContent = styled(Box)`
  ${cssMixins.getTransition()}
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${(p) => p.percentage}%;
  background-color: ${tu.color('primary')};
  opacity: ${(p) => (p.percentage < 100 ? '1' : '0.3')};
`;

const StyledTooltipText = styled(Box)`
  ${cssMixins.getTransition()}
  display: inline-block;
  box-sizing: content-box;
  position: relative;
  font-size: ${tu.fontSize(0)};
  text-align: center;
  border-radius: ${tu.radius(1)};
  padding: ${tu.space(1)} ${tu.space(2)};
  margin-top: 10px;
  white-space: nowrap;
  will-change: width;
  background-color: ${tu.color('primary')};
  ${(p) =>
    p.percentage < 25
      ? css`
          left: calc(${(p) => p.percentage}% - 7px);
        `
      : p.percentage < 75
      ? css`
          left: ${(p) => p.percentage}%;
          transform: translateX(-50%);
        `
      : css`
          left: calc(${(p) => p.percentage}% + 7px);
          transform: translateX(-100%);
        `}
  &::after {
    content: ' ';
    position: absolute;
    border-width: 5px;
    border-style: solid;
    bottom: 100%; /* At the top of the tooltip */
    border-color: transparent transparent ${tu.color('primary')} transparent;
    ${(p) =>
      p.percentage < 25
        ? css`
            left: 0;
            margin-left: 2px;
          `
        : p.percentage < 75
        ? css`
            left: 50%;
            margin-left: -5px;
          `
        : css`
            left: 100%;
            margin-left: -12px;
          `}
  }
`;
