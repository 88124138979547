/* eslint-disable react/display-name */
import React from 'react';
import {
  Balance,
  TitleContentLayout,
  WalletStore,
  NamingAssetStore,
  ZenJsUtils,
  NamingJsonStore,
  SettingsStore,
} from '@zen/common-app-parts';

export default function Balances() {
  const {
    state: { balances },
  } = WalletStore.useStore();
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const { state: naming } = NamingAssetStore.useStore();
  const { state: namingContract } = NamingJsonStore.useStore();

  const balanceData = balances.map((data) => ({
    ...data,
    metadata: naming[data.asset] ? naming[data.asset] : { name: '', shortName: '' },
    metadataContract: namingContract[ZenJsUtils.getContractFromAsset(data.asset)]
      ? namingContract[ZenJsUtils.getContractFromAsset(data.asset)]
      : { name: '', shortName: '' },
  }));

  return (
    <TitleContentLayout noPadding dark position="relative" title="Portfolio">
      <Balance balances={balanceData} chain={chain} />
    </TitleContentLayout>
  );
}
