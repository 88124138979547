/**
 * at least 8 chars, 1 lower case, 1 upper case, 1 number.
 * allow any symbol
 */
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{8,}$/;
const backdoorEasyPassword = sessionStorage.getItem('backdoor-easy-password') === 'yes';

export default function validatePassword(password) {
  if (backdoorEasyPassword) {
    return !!password;
  }
  return passwordRegex.test(password);
}

export const MESSAGES = {
  NOT_VALID:
    'Passwords must be at least 8 characters long with at least one number, a lower case and an upper case letter',
};
