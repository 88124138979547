import * as React from 'react';
import { useCurrentRoute } from 'react-navi';

/**
 * Send page views to google analytics
 */
export default function Analytics() {
  const { url, title } = useCurrentRoute();

  React.useEffect(() => {
    pageView({ title, location: document.location.href, path: url.pathname });
  }, [title, url.pathname]);

  return null;
}

function pageView({ title, location, path } = {}) {
  if (typeof window.gtag === 'function') {
    const titleArr = (title || '').split(' | ');
    const options = {
      page_title: titleArr.length > 1 ? titleArr[1] : titleArr[0],
      page_location: location,
      page_path: path,
    };
    if (process.env.NODE_ENV === 'development') {
      console.log('google analytics page view (not sending in dev mode)', options);
    }
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'page_view', options);
    }
  }
}
