import React from 'react';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';

const QRGenerator = ({
  value,
  renderAs,
  size,
  bgColor,
  fgColor,
  level,
  includeMargin,
  ...props
}) => {
  return (
    <QRCode
      value={value}
      renderAs={renderAs}
      size={size}
      bgColor={bgColor}
      fgColor={fgColor}
      level={level}
      includeMargin={includeMargin}
      {...props}
    />
  );
};

QRGenerator.propTypes = {
  value: PropTypes.string.isRequired,
  renderAs: PropTypes.oneOf(['canvas', 'svg']),
  size: PropTypes.number,
  bgColor: PropTypes.string,
  fgColor: PropTypes.string,
  level: PropTypes.oneOf(['L', 'M', 'Q', 'H']),
  includeMargin: PropTypes.bool,
};

export default QRGenerator;
