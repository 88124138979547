import createStore from '../../utils/createStore';
import useNamingAssetStore from './useNamingAssetStore';

const { Provider, useStore } = createStore({
  useStoreHook: useNamingAssetStore,
  contextDisplayName: 'NamingAssetStoreStoreContext',
});

export default {
  /**
   * @type {NamingAssetStoreProvider}
   */
  Provider,
  /**
   * @type {() => import('./types').TNamingAssetStore}
   */
  useStore,
};

/**
 * @typedef {({url: string}) => void} NamingJsonAssetProvider
 */
