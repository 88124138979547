import React from 'react';
import { SubNav } from '@zen-common/components-base';
import { SubNavLink } from '@zen/common-app-parts';
import PATHS from '../../paths';

export default function ContractsNav() {
  return (
    <SubNav>
      <SubNavLink href={PATHS.CONTRACTS_ACTIVE} activeClassName="active">
        Active Contracts
      </SubNavLink>
    </SubNav>
  );
}
