import { default as useActionInProgress } from './useActionInProgress';
import { default as useNodeInaccessible } from './useNodeInaccessible';
import { default as useOfflineModal } from './useOfflineModal';
import { default as useNodeNotSynced } from './useNodeNotSynced';

export const PreventActions = {
  useActionInProgress,
  useNodeInaccessible,
  useOfflineModal,
  useNodeNotSynced,
};
