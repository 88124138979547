import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import tu from '../../utils/themeUtils';
import Box from '../Box';
import Paragraph from '../Paragraph';
import Button from '../Button/Button';
import { UnorderedList, ListItem } from '../List';

/**
 * A presentational component to be used with the accompanying hook
 * @param {Object} props
 * @param {boolean} props.multiple
 * @param {(props: import('react-dropzone').DropzoneRootProps) => import('react-dropzone').DropzoneRootProps} props.getRootProps
 * @param {(props: import('react-dropzone').DropzoneInputProps) => import('react-dropzone').DropzoneInputProps} props.getInputProps
 * @param {Function} props.open
 * @param {boolean} props.isDragActive
 * @param {FileList} props.acceptedFiles
 * @param {boolean} props.disabled
 */
export function FileDropZone({
  multiple = false,
  getRootProps,
  getInputProps,
  open,
  isDragActive,
  acceptedFiles,
  disabled,
}) {
  const dragMessage = multiple ? 'Drag some files here, or' : 'Drag a file here, or';
  const buttonMessage = multiple ? 'Choose files' : 'Choose a file';

  return (
    <Box>
      <FileUploadContainer {...getRootProps()} isDragActive={isDragActive}>
        {!!acceptedFiles.length && (
          <UnorderedList pl="smd" mt="0">
            {acceptedFiles.map((file) => (
              <ListItem key={file.path}>
                <FilePathWrapper>{file.path}</FilePathWrapper>
              </ListItem>
            ))}
          </UnorderedList>
        )}
        <input {...getInputProps({ disabled })} />
        <Paragraph mb="2" display={['none', null, 'block']}>
          {dragMessage}
        </Paragraph>

        <Button sizeVariant="small" type="button" onClick={open}>
          {buttonMessage}
        </Button>
      </FileUploadContainer>
    </Box>
  );
}

const defaultOptions = {
  multiple: false,
  noClick: true,
  noKeyboard: true,
};

/**
 * @param {import('react-dropzone').DropzoneOptions} options
 */
export function useFileDropZone(options) {
  return useDropzone({ ...defaultOptions, ...options });
}

const FileUploadContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${tu.space('smd')};
  background-color: ${tu.color('bg4')};
  border: ${(p) =>
    p.isDragActive
      ? `2px dashed ${tu.color('primary')(p)}`
      : `2px solid ${tu.color('borderDark')(p)}`};
  font-size: ${tu.fontSize(2)};
`;

const FilePathWrapper = styled(Box)`
  word-break: break-all;
  font-size: ${tu.fontSize(1)};
  line-height: ${tu.lineHeight(2)};
`;
