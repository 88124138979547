import styled from 'styled-components';
import { Link } from 'react-navi';
import { cssMixins, themeUtils as tu } from '@zen-common/components-base';

const SubNavLink = styled(Link)`
  display: block;
  color: ${tu.color('text')};
  text-decoration: none;
  ${cssMixins.getTransition()}
  ${cssMixins.getPaddings({ y: 2, x: 4 })}
`;

export default SubNavLink;
