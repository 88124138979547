import createStore from '../../utils/createStore';

const { Provider, useStore } = createStore({
  useStoreHook: ({ config }) => config,
  contextDisplayName: 'ConfigStoreContext',
});

export default {
  /**
   * @type {ConfigProvider}
   */
  Provider,
  useStore,
};

/**
 * @typedef {({config: Object}) => void} ConfigProvider
 */
