import * as React from 'react';
import styled from 'styled-components';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import FaSvg from '../helpers/FaSvg';
import { Tooltip, useTooltip } from '../Tooltip';
import Box from '../Box';

export default function InfoBox({ info, children, isMobile }) {
  const { hide, ref, showAndUpdate, showAndHide, tooltipProps } = useTooltip({ placement: 'top' });
  const onMouseEnter = isMobile ? showAndHide : showAndUpdate;
  return (
    <InfoBoxWrapper>
      {children}
      <InfoIconWrapper
        onClick={onMouseEnter}
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={hide}
      >
        <FaSvg fontSize="sm" icon={faInfoCircle} />
        <Tooltip {...tooltipProps}>{info}</Tooltip>
      </InfoIconWrapper>
    </InfoBoxWrapper>
  );
}

const InfoBoxWrapper = styled(Box)`
  position: relative;
`;
const InfoIconWrapper = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;
