import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-navi-helmet-async';
import storage from '@zen/common-utils/lib/utils/localStorage';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './utils/config';
import { migrate } from './migrations';
import './back-doors';
import './check-env-vars';

migrate();

ReactDOM.render(
  <HelmetProvider>
    <App
      storage={storage}
      config={config}
      onRestart={() => {
        document.location.href = '/';
      }}
    />
  </HelmetProvider>,
  document.getElementById('root')
);

// Learn more about service workers: http://bit.ly/CRA-PWA
// TODO: add a config with onUpdate & onSuccess callbacks
serviceWorker.register();
