import { css } from 'styled-components';

const fadeIntoView = css`
  animation: fadein 0.3s linear;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export default fadeIntoView;
