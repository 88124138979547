import React from 'react';
import {
  StatusBar,
  StatusBarItem,
  StatusBarTextItem,
  StatusIndicator,
} from '@zen-common/components-base';
import { numberUtils, SyncStatus } from '@zen/common-utils';
import { BlockchainInfoStore, SettingsStore, ConfigStore } from '@zen/common-app-parts';
import { useOfflineStatus } from '@zen/common-react-hooks';

const { toDisplay } = numberUtils;

const STATUS_OPTIONS = [
  { status: SyncStatus.Synced, label: 'SYNCED', color: 'success', progress: false },
  { status: SyncStatus.Syncing, label: 'SYNCING', color: 'success', progress: true },
  { status: SyncStatus.Error, label: 'NOT SYNCED', color: 'danger', progress: false },
  { status: 'error-node', label: 'Node inaccessible', color: 'danger', progress: false },
];

export default function StatusBarContainer() {
  const { offline } = useOfflineStatus();
  const {
    state: {
      settings: { chain, nodeUrl },
    },
  } = SettingsStore.useStore();
  const {
    state: {
      error: nodeError,
      infos: { blocks, headers },
    },
  } = BlockchainInfoStore.useStore();
  const { zenJsVersion, buildVersion } = ConfigStore.useStore();

  const syncStatus = offline
    ? SyncStatus.Error
    : nodeError
    ? 'error-node'
    : blocks != headers // eslint-disable-line eqeqeq
    ? SyncStatus.Syncing
    : SyncStatus.Synced;

  const isLocalNode = nodeUrl.includes('localhost') || nodeUrl.includes('127.0.0.1');

  return (
    <StatusBar>
      <StatusBarItem>
        <StatusIndicator options={STATUS_OPTIONS} status={syncStatus} />
      </StatusBarItem>
      {isLocalNode && <StatusBarItem>LOCAL</StatusBarItem>}
      <StatusBarTextItem label="Chain">{chain}NET</StatusBarTextItem>
      {headers && <StatusBarTextItem label="Headers">{toDisplay(headers)}</StatusBarTextItem>}
      {zenJsVersion && (
        <StatusBarTextItem label="Zen js Version">v{zenJsVersion}</StatusBarTextItem>
      )}
      {buildVersion && <StatusBarTextItem label="Build Version">v{buildVersion}</StatusBarTextItem>}
    </StatusBar>
  );
}
