import { Decimal } from 'decimal.js';
import { ZenJsUtils } from '@zen/common-app-parts';
import { assetUtils } from '@zen/common-utils';
import { convertZPtoPercentage } from './utils';

export async function submitAllocationVote({
  password,
  allocation,
  addressVote,
  currentInterval,
  submit,
} = {}) {
  const ballotData = ZenJsUtils.toCgpAllocation(convertZPtoPercentage(allocation));
  return submit({
    password,
    ballotData,
    command: 'Allocation',
    interval: currentInterval,
    votingContractAddress: addressVote,
  });
}

export async function submitPayoutVote({
  password,
  chain,
  address,
  spends,
  addressVote,
  currentInterval,
  submit,
} = {}) {
  const ballotData = ZenJsUtils.toCgpPayout(chain, address, prepareSpends(spends));
  return submit({
    password,
    ballotData,
    command: 'Payout',
    interval: currentInterval,
    votingContractAddress: addressVote,
  });
}

export async function submitNominationVote({
  password,
  chain,
  address,
  spends,
  addressVote,
  currentInterval,
  submit,
} = {}) {
  const ballotData = ZenJsUtils.toCgpPayout(chain, address, prepareSpends(spends));
  return submit({
    password,
    ballotData,
    command: 'Nomination',
    interval: currentInterval,
    votingContractAddress: addressVote,
  });
}

export function serializePayoutBallotId({ chain, address, spends } = {}) {
  return ZenJsUtils.serializePayoutBallotId(chain, address, prepareSpends(spends));
}

/**
 * make sure the spends are aggregated and converted to Kalapas
 */
function prepareSpends(spends = []) {
  const aggregated = spends.reduce((aggregated, cur) => {
    if (typeof aggregated[cur.asset] === 'undefined') {
      aggregated[cur.asset] = 0;
    }
    aggregated[cur.asset] = Decimal.add(
      aggregated[cur.asset],
      assetUtils.toKalapas(cur.amount)
    ).toString();
    return aggregated;
  }, {});

  return Object.keys(aggregated).map((asset) => ({
    asset,
    amount: aggregated[asset],
  }));
}
