import React from 'react';
import {
  WalletNotifications,
  SendNotifications,
  ExecuteContractNotifications,
  ReceivedNotifications,
  NodeInaccessibleNotifications,
  SendExecuteStore,
  ExecuteContractStore,
} from '@zen/common-app-parts';

export default function Notifications() {
  return (
    <>
      <WalletNotifications />
      <SendNotificationsContainer />
      <ExecuteFromContractsNotificationsContainer />
      <ExecuteFromSendNotificationsContainer />
      <ReceivedNotifications />
      <NodeInaccessibleNotificationsContainer />
    </>
  );
}

function SendNotificationsContainer() {
  const { sendStore } = SendExecuteStore.useStore();

  return <SendNotifications sendStore={sendStore} />;
}
function ExecuteFromContractsNotificationsContainer() {
  const executeStore = ExecuteContractStore.useStore();

  return <ExecuteContractNotifications executeStore={executeStore} />;
}
function ExecuteFromSendNotificationsContainer() {
  const { executeStore } = SendExecuteStore.useStore();

  return <ExecuteContractNotifications executeStore={executeStore} />;
}
function NodeInaccessibleNotificationsContainer() {
  // currently there is no point in showing this on web
  if (process.env.REACT_APP_END_PLATFORM === 'web') {
    return null;
  }

  return <NodeInaccessibleNotifications />;
}
