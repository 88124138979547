import * as React from 'react';
import { useQuery } from 'react-query';
import { ExplorerService } from '@zen/common-app-parts';

const REFETCH_MS = 10000;

/**
 * Fetch all the candidates (winners of contestant phase)
 * @param {Object} params
 * @param {import('../types').Chain} params.chain
 * @param {Object} params.config
 * @param {import('../types').Phase} params.currentPhase
 * @param {import('../types').VoteStatus} params.voteStatus
 * @returns {{data: import('../types').Candidates, status: ('error'|'loading'|'success')}}
 */
export default function useFetchCandidates({ chain, config, currentPhase, voteStatus } = {}) {
  const enabled = voteStatus === 'candidate';
  const { interval, phase } = currentPhase || {};

  // fetch contestant winners
  const { data: contestantWinners, status: contestantWinnersStatus } = useQuery(
    ['governance-candidates-contestant-winners', { interval }],
    React.useCallback(async () => {
      const service = ExplorerService({ chain, config });
      const result = await service.governance.getCandidates({ interval });
      return result.data;
    }, [chain, config, interval]),
    {
      enabled,
    }
  );

  // fetch current results (and refetch in interval)
  const { data: currentResults, status: currentResultsStatus } = useQuery(
    ['governance-candidates-current-results', { interval, phase }],
    React.useCallback(async () => {
      const service = ExplorerService({ chain, config });
      const result = await service.governance.getResults({
        interval,
        phase: 'Candidate',
        page: 0,
        pageSize: 1000,
      });

      return result.data.items;
    }, [chain, config, interval]),
    {
      enabled,
      refetchInterval: REFETCH_MS,
    }
  );

  // set current results amount in contestant winners
  const data = React.useMemo(() => {
    if (!contestantWinners || !currentResults) return;

    return contestantWinners.map((item) => {
      const matchedCandidate = currentResults.find((c) => c.commitId === item.commitId);

      return {
        ...item,
        zpAmount: matchedCandidate ? matchedCandidate.zpAmount : '0',
      };
    });
  }, [contestantWinners, currentResults]);

  const status =
    contestantWinnersStatus === 'error' || currentResultsStatus === 'error'
      ? 'error'
      : contestantWinnersStatus === 'success' && currentResultsStatus === 'success'
      ? 'success'
      : 'loading';

  return { data, status };
}
