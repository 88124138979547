import createStore from '../../utils/createStore';
import useExecuteContractStore from './useExecuteContractStore';

const { Provider, useStore } = createStore({
  useStoreHook: useExecuteContractStore,
  contextDisplayName: 'ExecuteContractStoreContext',
});

export default {
  /**
   * @type {ExecuteContractStoreProvider}
   */
  Provider,
  /**
   * @type {TUseExecuteContractStore}
   */
  useStore,
};

/**
 * @typedef {({chain: string, wallet: Object}) => void} ExecuteContractStoreProvider
 */

/**
 * @typedef {() => import('./types').TExecuteContractStore} TUseExecuteContractStore
 */
