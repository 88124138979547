import * as React from 'react';
import { useQueryUserBalance } from '@zen/common-app-parts';
import { assetUtils } from '@zen/common-utils';

/**
 * Fetches the user's balance at snapshot block
 */
export default function useUserSnapshotBalance({ voteStatus, snapshotBlock, currentInterval }) {
  const [fetched, setFetched] = React.useState(false);
  const { data: userBalance } = useQueryUserBalance({
    blockNumber: snapshotBlock,
    options: { enabled: voteStatus !== 'between' },
  });

  // mark as not fetched when currentInterval changes
  React.useEffect(() => {
    setFetched(false);
  }, [currentInterval]);
  // mark as fetch as soon as there is a balance
  React.useEffect(() => {
    if (userBalance) {
      setFetched(true);
    }
  }, [userBalance]);

  const snapshotZpBalance = React.useMemo(() => {
    if (!userBalance) return '0';
    const balanceObject = (userBalance || []).find((pointedOutput) => pointedOutput.asset === '00');
    return balanceObject ? assetUtils.fromKalapas(balanceObject.balance) : '0';
  }, [userBalance]);

  return {
    snapshotZpBalance,
    snapshotZpBalanceFetched: fetched,
  };
}
