import { useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ApiService } from '@zen/common-app-parts';
import { convertPercentageToZP } from '../../utils';

/**
 * Fetches the CGP current allocation and payout
 */
export default function useCgpCurrent({ nodeUrl, currentInterval } = {}) {
  // currentInterval - invalidate when interval changes
  const { data: cgpCurrent } = useQuery(
    ['cgp-current', { currentInterval, nodeUrl }],
    fetchCgpCurrent,
    {
      refetchOnWindowFocus: false,
    }
  );

  const allocation = (cgpCurrent || {}).allocation;

  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    if (cgpCurrent) {
      setFetched(true);
    }
  }, [cgpCurrent]);

  // computed ---
  const cgpCurrentAllocation = useMemo(
    () =>
      allocation
        ? {
            percentage: allocation,
            zp: convertPercentageToZP(allocation),
          }
        : {
            percentage: 0,
            zp: 0,
          },
    [allocation]
  );

  return {
    cgpCurrentAllocation,
    cgpCurrentAllocationFetched: fetched,
  };
}
function fetchCgpCurrent(_, { nodeUrl }) {
  return ApiService.cgp.getCurrent(nodeUrl);
}
