import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, useTheme } from 'styled-components';
import tu from '../utils/themeUtils';

const ComponentLibThemeProvider = (props) => {
  const theme = tu.createTheme(props.theme);
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
ComponentLibThemeProvider.propTypes = {
  theme: PropTypes.any,
  children: PropTypes.any,
};

export default ComponentLibThemeProvider;

export { useTheme };
