import React, { useEffect } from 'react';
import { useIdle } from '@zen/common-react-hooks';
import { useModal } from '@zen-common/components-base';
import WalletStore from '../stores/WalletStore';
import SettingsStore from '../stores/SettingsStore';
import { AutoLogoutModal } from './modals/AutoLogoutModal/AutoLogoutModal';

/**
 * Shows a logout modal after { logoutMinutes }
 */
export default function AutoLogout() {
  const { show, hide } = useModal();
  const {
    state: { isLoggedIn },
    actions: { logout },
  } = WalletStore.useStore();
  const {
    state: {
      settings: { logoutMinutes },
    },
  } = SettingsStore.useStore();
  const isIdle = useIdle(logoutMinutes * 60 * 1000);

  useEffect(() => {
    if (isIdle && isLoggedIn) {
      show(() => (
        <AutoLogoutModal
          onStayLoggedIn={hide}
          onLogout={() => {
            hide();
            logout();
          }}
        />
      ));
    }
  }, [hide, isIdle, isLoggedIn, logout, show]);

  return null;
}
