import { css } from 'styled-components';
import tu from '../../themeUtils';

export const showOnMobile = (display = 'block') => css`
  ${tu.mq({
    display: [display, null, 'none'],
  })}
`;

export const showOnDesktop = (display = 'block') => css`
  ${tu.mq({
    display: ['none', null, display],
  })}
`;
