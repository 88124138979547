const CHAIN_SWITCHED = '/chain-switched';
const WIPE_SEED = '/wipe-seed';
const UNLOCK = '/unlock';
const ONBOARDING = '/app/onboarding';
const ONBOARDING_CREATE = '/app/onboarding/create';
const ONBOARDING_IMPORT = '/app/onboarding/import';
const SETTINGS = '/app/settings';
const SETTINGS_ACCOUNT = '/app/settings/account';
const SETTINGS_CONNECTIVITY = '/app/settings/connectivity';
const WALLET = '/app/wallet';
const WALLET_BALANCES = '/app/wallet/portfolio';
const WALLET_RECEIVE = '/app/wallet/receive';
const WALLET_SEND = '/app/wallet/send';
const WALLET_TX_HISTORY = '/app/wallet/history';
const CONTRACTS = '/app/contracts';
const CONTRACTS_ACTIVE = '/app/contracts/active';
const VOTING = '/app/voting';
const VOTING_GOVERNANCE = '/app/voting/governance';
const VOTING_CGP = '/app/voting/cgp';
const DEFAULT = WALLET;

const PATHS = {
  DEFAULT,
  WIPE_SEED,
  CHAIN_SWITCHED,
  UNLOCK,
  ONBOARDING,
  ONBOARDING_CREATE,
  ONBOARDING_IMPORT,
  SETTINGS,
  SETTINGS_ACCOUNT,
  SETTINGS_CONNECTIVITY,
  WALLET,
  WALLET_BALANCES,
  WALLET_RECEIVE,
  WALLET_SEND,
  WALLET_TX_HISTORY,
  CONTRACTS,
  CONTRACTS_ACTIVE,
  VOTING,
  VOTING_GOVERNANCE,
  VOTING_CGP,
};

export default PATHS;
