import React from 'react';
import PropTypes from 'prop-types';
import { useModal, Modal, ModalContent } from '@zen-common/components-base';
import SettingLayout from '../containers/SettingLayout';
import SettingButton from '../common/SettingButton';
import DeleteWalletSettingProcess from './DeleteWalletSettingProcess';

const DeleteWalletSetting = ({ useSetting, ...props }) => {
  const { show, hide } = useModal();

  function openModal() {
    show(() => (
      <Modal>
        <ModalContent>
          <DeleteWalletSettingProcess onCancel={hide} onDone={hide} useSetting={useSetting} />
        </ModalContent>
      </Modal>
    ));
  }

  return (
    <SettingLayout
      header="Wipe Wallet"
      paragraph="Wipe the wallet secured mnemonic to import a new one"
      {...props}
    >
      <SettingButton onClick={openModal}>Wipe</SettingButton>
    </SettingLayout>
  );
};

DeleteWalletSetting.propTypes = {
  useSetting: PropTypes.func,
};

export default DeleteWalletSetting;
