import * as React from 'react';
import styled from 'styled-components';
import { useMnemonic, useCopy } from '@zen/common-react-hooks';
import {
  Box,
  Button,
  FormGroup,
  LabelText,
  Mnemonic,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  Title,
  Input,
  CopiedMessage,
  themeUtils as tu,
} from '@zen-common/components-base';

export default function BackupWalletModalContent({
  pkHash,
  mnemonic,
  xPubKey,
  privateKey,
  onCancel,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Done',
}) {
  const confirmButtonRef = React.useRef();
  const cancelButtonRef = React.useRef();

  React.useEffect(() => {
    // prioritize cancel
    if (cancelButtonRef.current) {
      cancelButtonRef.current.focus();
    } else if (confirmButtonRef.current) {
      confirmButtonRef.current.focus();
    }
  }, []);

  return (
    <Box>
      <ModalHeader>
        <Title>Backup Wallet</Title>
      </ModalHeader>
      <ModalSeparator />
      <ModalSection>
        <ExposedDataSection value={pkHash} name="pkHash" />
      </ModalSection>
      <ModalSection>
        <ExposedDataSection value={xPubKey} name="Xpub Key" />
      </ModalSection>
      <ModalSeparator />
      <ModalSection>
        <MnemonicSection value={mnemonic} />
      </ModalSection>
      <ModalSeparator />
      <ModalSection>
        <XPrivSection value={privateKey} />
      </ModalSection>
      <ModalSeparator />
      <ModalFooter>
        {onCancel && (
          <Button bg="secondary" ref={cancelButtonRef} onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        {onConfirm && (
          <Button ref={confirmButtonRef} onClick={onConfirm}>
            {confirmText}
          </Button>
        )}
      </ModalFooter>
    </Box>
  );
}

const LabelAndTogglerWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HiddenDetails = () => {
  return <Input value="************" variant="block" inputType="expandable" readOnly />;
};

const VisibilityBtn = styled(Button).attrs({ bg: 'secondary' })`
  font-size: ${tu.fontSize(1)};
  line-height: ${tu.fontSize(1)};
  padding: ${tu.space(1)};
  width: 44px;
`;

const ToggleVisibilityBtn = ({ showState }) => {
  return (
    <VisibilityBtn onClick={showState.toggle}>{showState.shown ? 'hide' : 'show'}</VisibilityBtn>
  );
};

const ExposedDataSection = ({ value, name }) => {
  const copy = useCopyVal(value);

  return (
    <FormGroup mb="0">
      <LabelText>{name}</LabelText>
      <Input onClick={copy.onCopy} value={value} variant="block" inputType="expandable" readOnly />
      <CopiedMessage what={name} copied={copy.copied} error={copy.error} />
    </FormGroup>
  );
};

const XPrivSection = ({ value }) => {
  const copy = useCopyVal(value);
  const showState = useShowState();

  return (
    <FormGroup mb="0">
      <LabelAndTogglerWrapper>
        <LabelText>Xpriv Key</LabelText>
        <ToggleVisibilityBtn showState={showState} />
      </LabelAndTogglerWrapper>
      {showState.shown ? (
        <>
          <Input
            onClick={copy.onCopy}
            value={value}
            variant="block"
            inputType="expandable"
            readOnly
          />
          <CopiedMessage what="Private Key" copied={copy.copied} error={copy.error} />
        </>
      ) : (
        <HiddenDetails />
      )}
    </FormGroup>
  );
};

const MnemonicSection = ({ value }) => {
  const mnemonicProps = useMnemonic({ generate: false, initialValue: value.split(' ') });
  const showState = useShowState();

  return (
    <FormGroup mb="0">
      <LabelAndTogglerWrapper>
        <LabelText>Mnemonic</LabelText>
        <ToggleVisibilityBtn showState={showState} />
      </LabelAndTogglerWrapper>
      {showState.shown ? (
        <Mnemonic isPopupModal={true} isEdit={false} padding="0" margin="0" {...mnemonicProps} />
      ) : (
        <HiddenDetails />
      )}
    </FormGroup>
  );
};

function useShowState() {
  const [shown, setShown] = React.useState(false);

  return {
    shown,
    toggle: () => setShown((s) => !s),
  };
}

function useCopyVal(value) {
  const [copied, error, onCopy] = useCopy({ value });

  return { copied, error, onCopy };
}
