import styled from 'styled-components';
import tu from '../../utils/themeUtils';
import { containerXPadding } from '../../utils/styles/mixins';
import Box from '../Box';
import Title from '../titles/Title';

const SubPageHeader = styled(Box)`
  background-color: ${tu.color('bg2')};
  border-bottom: 1px solid ${tu.color('borderDark')};
  padding-top: ${tu.space('xl')};
  padding-bottom: ${tu.space('smd')};
  ${containerXPadding}

  ${Title} {
    margin-bottom: 0;
  }
`;

export default SubPageHeader;
