import { useShowMore } from '@zen/common-react-hooks';

const NUM_OF_SPENDS_TO_SHOW = 3;

/**
 * To be used in a confirmation modal
 * @param {Object} props
 * @param {[import('./spendUtils').Spend]} props.spends
 * @param {number} props.defaultCount - number of spends to show first
 */
export default function useSpendsTechnicalData({ spends, defaultCount = NUM_OF_SPENDS_TO_SHOW }) {
  const validSpends = (spends || []).filter((spend) => spend.amountValid && spend.assetValid);

  const { isMore, items: finalSpends, toggleMore } = useShowMore({
    items: validSpends,
    defaultCount,
  });

  return {
    spends: finalSpends,
    isMore,
    toggleMore,
  };
}
