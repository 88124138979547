import { useCallback, useRef, useEffect } from 'react';
import { Decimal } from 'decimal.js';
import { notification } from '@zen-common/components-base';
import { assetUtils, numberUtils } from '@zen/common-utils';
import WalletStore from '../../stores/WalletStore';
import NamingAssetStore from '../../stores/NamingAssetStore';
import useBalanceChanged from '../../hooks/useBalanceChanged';

export default function ReceivedNotifications() {
  const { state } = WalletStore.useStore();
  useBalanceChangedAfterInitialDone(state);

  return null;
}

/**
 * Notify only after the 1st fetch is done, prevent showing notification on the 1st balance fetch
 */
function useBalanceChangedAfterInitialDone(walletState) {
  const canShow = useRef(false);
  const { state: naming } = NamingAssetStore.useStore();

  useEffect(() => {
    // set on 2nd fetch start
    if (walletState.initialBalanceFetched && walletState.loading.fetchBalance) {
      canShow.current = true;
    }
    // reset when initialBalanceFetched is being reset
    else if (!walletState.initialBalanceFetched) {
      canShow.current = false;
    }
  }, [walletState.initialBalanceFetched, walletState.loading.fetchBalance]);

  useBalanceChanged({
    balance: walletState.balance,
    onChange: useCallback(
      (changes) => {
        if (canShow.current) {
          notifyOnBalanceChanged(changes, naming);
        }
      },
      [naming]
    ),
  });
}

function notifyOnBalanceChanged(changes, naming) {
  const displayName = (asset) =>
    naming && naming[asset] ? naming[asset].shortName : assetUtils.truncateOrNameAsset(asset);
  Object.keys(changes).forEach((asset) => {
    if (new Decimal(changes[asset]).gt(0)) {
      notification(
        `You have just received ${numberUtils.toDisplay(changes[asset])} ${displayName(asset)}`,
        {
          type: notification.TYPE.SUCCESS,
        }
      );
    } else {
      notification(
        `You have just sent ${numberUtils.toDisplay(
          new Decimal(changes[asset]).abs()
        )} ${displayName(asset)}`,
        {
          type: notification.TYPE.SUCCESS,
        }
      );
    }
  });
}
