import styled from 'styled-components';
import { Box, themeUtils as tu, cssMixins } from '@zen-common/components-base';

const FormContainerPadding = styled(Box)`
  ${cssMixins.containerXPadding}
  @media screen and (min-width: ${tu.breakpoints.xl}) {
    padding-right: 0;
  }
  flex-grow: 1;
  flex-shrink: 0;
  ${tu.mq({
    marginRight: ['0', null, null, null, tu.space('mlg')],
  })}
`;

export default FormContainerPadding;
