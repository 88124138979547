import * as React from 'react';
import { useQuery } from 'react-query';
import { ApiService, WalletStore } from '@zen/common-app-parts';
import { isDuringVote } from '../modules/utils';

const REFETCH_MS = 5000;

/**
 * Fetches the user's votes
 * @param {Object} params
 * @param {string} params.contractId
 * @param {string} params.nodeUrl
 * @param {import('../types').Phase} params.currentPhase
 * @param {import('../types').VoteStatus} params.voteStatus
 * 
 * @returns {{
    voteFetchStatus: ('loading'|'success'|'error'),
    initialVotesFetchDone: boolean,
    vote: string,
    refetchVote: () => void,
    voted: import('../types').Voted,
    setVoted: React.SetStateAction,
  }}
 */
export default function useFetchVote({ contractId, nodeUrl, currentPhase = {}, voteStatus } = {}) {
  const {
    state: {
      keys: { addresses },
    },
  } = WalletStore.useStore();
  const { interval, phase, beginBlock, endBlock } = currentPhase || { interval: 0, phase: '' };
  const [voted, setVoted] = React.useState({ interval: 0, phase: '' });
  const [enableFetchState, setEnableFetchState] = React.useState({
    found: false,
    interval: 0,
    phase: '',
  });
  const enabled = isDuringVote(voteStatus) && !enableFetchState.found;

  const { data: userTxsWithHeaders, refetch: refetchTxs, status: statusTxs } = useQuery(
    [
      'governance-user-vote-transactions',
      { addresses, nodeUrl, beginBlock, endBlock, interval, phase },
    ],
    fetchUserTxsWithHeaders,
    { enabled, refetchInterval: REFETCH_MS, refetchIntervalInBackground: true }
  );

  const { data: contractHistory, refetch: refetchHistory, status: statusHistory } = useQuery(
    [
      'governance-contract-vote-transaction',
      { nodeUrl, contractId, beginBlock, endBlock, interval, phase },
    ],
    fetchContractHistory,
    { enabled, refetchInterval: REFETCH_MS, refetchIntervalInBackground: true }
  );

  const initialVotesFetchDone = useInitialVoteFetchDone({
    statusHistory,
    statusTxs,
    ...currentPhase,
  });

  const voteFetchStatus =
    statusTxs === 'error' || statusHistory === 'error'
      ? 'error'
      : statusTxs === 'success' && statusHistory === 'success'
      ? 'success'
      : 'loading';

  const refetchVote = React.useCallback(() => {
    refetchTxs({ force: true });
    refetchHistory({ force: true });
  }, [refetchHistory, refetchTxs]);

  const vote = React.useMemo(() => {
    if (!beginBlock || !endBlock) return null;

    return findVote({
      contractHistory,
      transactions: userTxsWithHeaders ? userTxsWithHeaders[1] : [],
      currentBlock: userTxsWithHeaders ? userTxsWithHeaders[0] : 0,
      snapshotBlock: beginBlock,
      tallyBlock: endBlock,
    });
  }, [beginBlock, contractHistory, endBlock, userTxsWithHeaders]);

  // set voted when there is a vote
  React.useEffect(() => {
    if (vote && isDuringVote(voteStatus)) {
      setVoted({ interval, phase });
      setEnableFetchState({ found: true, interval, phase });
    }
  }, [interval, phase, vote, voteStatus]);

  // reset to enable fetch
  React.useEffect(() => {
    if (interval !== enableFetchState.interval || phase !== enableFetchState.phase) {
      setEnableFetchState({ found: false, interval, phase });
    }
  }, [enableFetchState.interval, enableFetchState.phase, interval, phase]);

  return {
    voteFetchStatus,
    initialVotesFetchDone,
    vote,
    refetchVote,
    voted,
    setVoted,
  };
}
/**
 * Fetch the user txs in the voting range with the current headers
 * (some calculations need to know the block number from the confirmations)
 */
function fetchUserTxsWithHeaders(key, { nodeUrl, addresses, beginBlock, endBlock }) {
  return Promise.all([
    ApiService.blockchainInfo(nodeUrl).then((data) => data.headers),
    ApiService.getTransactionsByBlockNumber(nodeUrl, {
      addresses,
      start: beginBlock + 1,
      end: endBlock + 1,
    }),
  ]);
}
/**
 * Fetch the contract history in the voting range
 */
function fetchContractHistory(key, { nodeUrl, contractId, beginBlock, endBlock }) {
  return ApiService.contractHistoryByBlockNumber(nodeUrl, {
    contractId: contractId,
    start: beginBlock + 1,
    end: endBlock + 1,
  });
}

function useInitialVoteFetchDone({ interval, phase, statusHistory, statusTxs } = {}) {
  const [initialFetchDone, setInitialFetchDone] = React.useState(false);
  // reset on phase change
  React.useEffect(() => {
    setInitialFetchDone(false);
  }, [interval, phase]);
  // set to true once
  React.useEffect(() => {
    if (statusHistory === 'success' && statusTxs === 'success') {
      setInitialFetchDone(true);
    }
  }, [statusHistory, statusTxs]);

  return initialFetchDone;
}

/**
 * Find the contract execution of the first vote
 *
 * @param {Object} params
 * @param {Array} params.transactions - the user's transactions
 * @param {Array} params.contractHistory
 * @param {number} params.currentBlock
 * @param {number} params.snapshotBlock
 * @param {number} params.tallyBlock
 */
export function findVote({
  transactions = [],
  contractHistory = [],
  currentBlock,
  snapshotBlock,
  tallyBlock,
} = {}) {
  if (
    !transactions.length ||
    !contractHistory.length ||
    !currentBlock ||
    !snapshotBlock ||
    !tallyBlock ||
    snapshotBlock > currentBlock
  ) {
    return null;
  }
  const inRange = (tx) => {
    const blockNumber = currentBlock - tx.confirmations + 1;
    return blockNumber > snapshotBlock && blockNumber <= tallyBlock;
  };
  const txsFromSnapshot = transactions.filter(inRange);
  const historyFromSnapshot = contractHistory.filter(inRange);

  // contract history is ordered old to new, find the first valid vote
  const execution = historyFromSnapshot.find((execution) => {
    return txsFromSnapshot.some((tx) => tx.txHash === execution.txHash);
  });

  return getCommitIdFromExecution(execution);
}

function getCommitIdFromExecution(execution) {
  if (!execution) return null;

  try {
    return execution.messageBody.list[0].string;
  } catch (_) {
    return null;
  }
}
