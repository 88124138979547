import { produce } from 'immer';
import { ValueDisplay, assetUtils } from '@zen/common-utils';
import { ZenJsUtils, SpendsReducer, SpendUtils } from '@zen/common-app-parts';

export const initialState = {
  ballotIdValid: false,
  address: '',
  addressValid: false,
  spends: [SpendUtils.getSpend()],
  spendsValid: false,
};

/**
 * The reducer
 * @param {initialState} draft
 * @param {*} { type, payload }
 */
function payoutReducerHandler(draft, { type, payload }) {
  switch (type) {
    case 'ballot-changed':
      handleBallotChange(draft, payload);
      break;
    case 'address-changed':
      draft.ballotIdValid = false;
      draft.address = payload.value;
      draft.addressValid =
        payload.value === '' ? undefined : ZenJsUtils.validateAddress(payload.chain, payload.value);
      break;
    case 'clear':
      draft.ballotIdValid = false;
      draft.address = '';
      draft.addressValid = false;
      break;
    default:
      break;
  }
}
const payoutReducer = produce(payoutReducerHandler);

export function reducer(state, action) {
  // combine all reducers
  return payoutReducer(SpendsReducer.reducer(state, action), action);
}

function handleBallotChange(draft, payload) {
  const deserialized = deserializeBallotId({
    ballotId: payload.value,
    chain: payload.chain,
    balance: payload.balance,
  });
  draft.ballotIdValid = deserialized.ballotIdValid;
  draft.address = deserialized.address;
  draft.addressValid =
    draft.address === '' ? undefined : ZenJsUtils.validateAddress(payload.chain, draft.address);

  let spendReducerResult = SpendsReducer.reducer(draft, {
    type: SpendsReducer.actions.SET_SPENDS,
    payload: { value: deserialized.spends, balance: payload.balance },
  });
  draft.spends = spendReducerResult.spends;
  draft.spendsValid = spendReducerResult.spendsValid;

  // reset if empty
  if (payload.value === '') {
    draft.ballotIdValid = false;
  }
}

function deserializeBallotId({ ballotId, chain, balance }) {
  if (ZenJsUtils.validateBallot(ballotId)) {
    const { address, spends: _spends } = ZenJsUtils.deserializePayoutBallotId(chain, ballotId);
    const assets = _spends.map((spend) => {
      const asset = spend.asset;
      const amount = ValueDisplay.create(spend.amount);
      return SpendUtils.getSpend({
        asset,
        amount,
        assetValid: Object.keys(balance).includes(asset),
        amountValid: assetUtils.validateAmount({
          asset,
          balance,
          amount: amount.safeValue,
        }),
      });
    });
    return {
      ballotIdValid: true,
      address,
      spends: assets,
    };
  }
  return {
    ballotIdValid: false,
    address: '',
    spends: [SpendUtils.getSpend()],
  };
}
